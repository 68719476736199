/** @namespace SwiatKsiazkiBasic/Util/Cookie/CookieObservable */
export class CookieObservable {
    observers = new Map();

    __construct() {
        this.subscribe = this.subscribe.bind(this);
        this.unsubscribe = this.unsubscribe.bind(this);
        this.notify = this.notify.bind(this);
    }

    subscribe(event, callback) {
        this.observers.set(event, callback);
    }

    unsubscribe(event) {
        this.observers.delete(event);
    }

    notify(trigger, data) {
        this.observers.forEach((callback, event) => {
            if (trigger === event) {
                callback(data);
            }
        });
    }
}

export default new CookieObservable();
