export const FB_VIEW_CONTENT = 'ViewContent';
export const FB_ADD_TO_CART = 'AddToCart';
export const FB_ADD_TO_WISHLIST = 'AddToWishlist';
export const FB_INITIATE_CHECKOUT = 'InitiateCheckout';
export const FB_ADD_PAYMENT_INFO = 'AddPaymentInfo';
export const FB_PURCHASE = 'Purchase';
export const FB_COMPLETE_REGISTRATION = 'CompleteRegistration';
export const FB_SEARCH = 'Search';
export const FB_VIEW_CATEGORY = 'ViewCategory';
export const FB_CONTACT = 'Contact';
