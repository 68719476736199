import Icon from 'Component/Icon';
import Image from 'Component/Image';
import Link from 'Component/Link';
import ProductCard from 'Component/ProductCard';
import { GRID_LAYOUT } from 'Route/CategoryPage/CategoryPage.config';
import { MenuItem as SourceMenuItem } from 'SourceComponent/MenuItem/MenuItem.component';

/** @namespace SwiatKsiazkiBasic/Component/MenuItem/Component */
export class MenuItem extends SourceMenuItem {
    renderItemContent(item, itemMods) {
        const { noIcon, isBanner, isTopProduct } = this.props;
        const { title, icon, image_url, image_path, top_product } = item;
        const url = window.location.origin;

        if (isTopProduct) {
            return (
                <div block="Menu" elem="ItemTopProduct">
                    <ProductCard product={top_product} selectedFilters={{}} layout={GRID_LAYOUT} isMenuVariant />
                </div>
            );
        }

        if (isBanner && image_path && image_url) {
            return (
                <div block="Menu" elem="ItemBanner">
                    <Link to={image_url}>
                        <Image src={image_path} isPlain removeDefaultClasses />
                    </Link>
                </div>
            );
        }

        return (
            <figcaption block="Menu" elem="ItemCaption" mods={itemMods}>
                {icon && !noIcon && <img src={`${url}/media/${icon}`} alt={title} />}
                {title}
                {this.renderExpandButton()}
            </figcaption>
        );
    }

    renderExpandIcon() {
        return <Icon name="small_arrow_right" width="8" height="13" fill="#3E3D3D" />;
    }

    renderExpandButton() {
        const { isExpandable, itemMods } = this.props;

        if (!isExpandable) {
            return null;
        }

        return (
            <figcaption block="Menu" elem="ExpandedState" mods={itemMods}>
                {this.renderExpandIcon()}
            </figcaption>
        );
    }

    renderItemLinkContent() {
        const {
            activeMenuItemsStack,
            item,
            itemMods,
            handleCategoryHover,
            handleLinkLeave,
            onItemClick,
            withShowMore,
        } = this.props;

        const { url, item_id } = item;

        const isHovered = activeMenuItemsStack.includes(item_id);

        return (
            <Link
                to={url}
                block="Menu"
                elem="Link"
                id={item_id}
                onMouseEnter={handleCategoryHover}
                onMouseLeave={handleLinkLeave}
                mods={{ isHovered, withShowMore: !!withShowMore }}
                onClick={onItemClick}
            >
                {this.renderItemContent(item, itemMods)}
            </Link>
        );
    }
}

export default MenuItem;
