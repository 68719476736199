import { getStaticReducers as sourceGetStaticReducers } from 'SourceStore';
import AdvoxFreeDeliveryReducer from 'Store/AdvoxFreeDelivery/AdvoxFreeDelivery.reducer';
import AgreementsReducer from 'Store/Agreements/Agreements.reducer';
import LastViewedProductsReducer from 'Store/LastViewedProducts/LastViewedProducts.reducer';

/** @namespace SwiatKsiazkiBasic/Store/Index/getStaticReducers */
export const getStaticReducers = () => ({
    ...sourceGetStaticReducers(),
    AdvoxFreeDeliveryReducer,
    LastViewedProductsReducer,
    AgreementsReducer,
});

export default function injectStaticReducers(store) {
    // eslint-disable-next-line no-param-reassign
    store.asyncReducers = [];

    // Inject all the static reducers into the store
    Object.entries(getStaticReducers()).forEach(([name, reducer]) => {
        // eslint-disable-next-line no-param-reassign
        store.asyncReducers[name] = reducer;
        store.injectReducer(name, reducer);
    });

    return store;
}
