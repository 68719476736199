import { UPDATE_META } from 'Store/Meta/Meta.action';
import { appendWithStoreCode } from 'Util/Url';

export const updateEveryTime = [
    'title',
    'description',
    'keywords',
    'canonical_url',
    'robots',
    'status_code',
    'opengraph',
    'head_prefix',
];

/** @namespace SwiatKsiazkiBasic/Store/Meta/Reducer/filterData */
export const filterData = (data) => {
    const updated = updateEveryTime.reduce((acc, key) => {
        acc[key] = data[key];

        return acc;
    }, {});

    return { ...data, ...updated };
};

/** @namespace SwiatKsiazkiBasic/Store/Meta/Reducer/getInitialState */
export const getInitialState = () => ({
    title: '',
    title_prefix: '',
    title_suffix: '',
    description: '',
    keywords: '',
    canonical_url: '',
    robots: '',
    status_code: '',
    opengraph: {},
    head_prefix: '',
});

/** @namespace SwiatKsiazkiBasic/Store/Meta/Reducer/getCanonicalURL */
export const getCanonicalURL = (canonical) => {
    if (canonical) {
        return `${window.location.origin}${appendWithStoreCode(canonical)}`;
    }

    return `${window.location.origin}${window.location.pathname}`;
};

/** @namespace SwiatKsiazkiBasic/Store/Meta/Reducer/MetaReducer */
export const MetaReducer = (state = getInitialState(), action) => {
    const { payload = {}, type } = action;

    switch (type) {
        case UPDATE_META:
            const filteredData = filterData(payload);

            return {
                ...state,
                ...filteredData,
            };

        default:
            return state;
    }
};

export default MetaReducer;
