import lazy from '@loadable/component';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import CmsBlock from 'Component/CmsBlock';
import { CURRENT_FORM_SHOW } from 'Component/Html/Html.config';
import { PRODUCT_SLIDER_WITH_BANNER } from 'Component/ProductSlider/ProductSlider.config';

import './ProductSliderTabs.style';

export const ProductSliderWidget = lazy(() =>
    import(
        /* webpackMode: "lazy", webpackChunkName: "widget" */
        'Component/ProductSliderWidget'
    )
);

export const BannerWidget = lazy(() =>
    import(
        /* webpackMode: "lazy", webpackChunkName: "widget" */
        'Component/BannerWidget'
    )
);

export const ButtonWidget = lazy(() =>
    import(
        /* webpackMode: "lazy", webpackChunkName: "widget" */
        'Component/ButtonWidget'
    )
);

/** @namespace SwiatKsiazkiBasic/Component/ProductSliderTabs/Component */
export class ProductSliderTabsComponent extends PureComponent {
    static propTypes = {
        ProductSliderTabs: PropTypes.arrayOf(PropTypes.object).isRequired,
        activeTab: PropTypes.number.isRequired,
        handleTabClick: PropTypes.func.isRequired,
    };

    renderChildren() {
        const { children } = this.props;

        return children;
    }

    renderProductSliderTabs() {
        const { productSliderTabs } = this.props;
        const { activeTab, handleTabClick } = this.props;

        return productSliderTabs.map(({ name, id }) => (
            <button
                key={id}
                // eslint-disable-next-line react/jsx-no-bind
                onClick={() => handleTabClick(id)}
                block="ProductSliderTabs"
                elem="Tab"
                mods={{ isActive: activeTab === id }}
            >
                {name}
            </button>
        ));
    }

    renderCmsBlocksTabContent(rows) {
        const [block] = rows;

        if (block.content['data-form-component']) {
            return (
                <div block="ProductSliderTabs" elem="Content">
                    {CURRENT_FORM_SHOW[block.content['data-form-component']].cmsComponent}
                </div>
            );
        }

        return (
            <div block="ProductSliderTabs" elem="Content">
                <CmsBlock identifier={block.content['data-cms-block-id']} />
            </div>
        );
    }

    renderProductSliderTabsContent() {
        const { productSliderTabs, activeTab, device: { isMobile } = {} } = this.props;

        return productSliderTabs.map(({ id, rows }) => {
            if (activeTab !== id) {
                return null;
            }

            if (rows[0].tag === 'cms_block') {
                return this.renderCmsBlocksTabContent(rows);
            }

            return (
                <div block="ProductSliderTabs" elem="Content" key={id}>
                    {rows.map(({ banner, productSlider, button }, key) => (
                        <div
                            key={key}
                            block="ProductSliderTabs"
                            elem="ContentRow"
                            mods={{
                                withBanner: !!banner?.length,
                                withSlider: !!productSlider,
                                isDoubleSlider: !!(productSlider?.double_slider || productSlider?.isDoubleSlider),
                                isButton: !!button,
                            }}
                        >
                            {button && <ButtonWidget {...button} />}
                            {banner && (
                                <div>
                                    {banner.map((bannerContent, key) => (
                                        <BannerWidget {...bannerContent} key={key} />
                                    ))}
                                </div>
                            )}
                            {productSlider && (
                                <ProductSliderWidget
                                    {...productSlider}
                                    {...(banner && !isMobile ? { settings: PRODUCT_SLIDER_WITH_BANNER } : null)}
                                />
                            )}
                        </div>
                    ))}
                </div>
            );
        });
    }

    render() {
        return (
            <div block="ProductSliderTabs">
                <div block="ProductSliderTabs" elem="Nav">
                    <div className="ProductSliderTabs-ProductSliderTabs">{this.renderProductSliderTabs()}</div>
                </div>
                {this.renderProductSliderTabsContent()}
            </div>
        );
    }
}

export default ProductSliderTabsComponent;
