import PRODUCT_TYPE from 'Component/Product/Product.config';
import { ADD_TO_CART } from 'SourceUtil/Product/Product';

export * from 'SourceUtil/Product/Transform';

/** @namespace SwiatKsiazkiBasic/Util/Product/Transform/magentoProductWishlistTransform */
export const magentoProductWishlistTransform = (
    action = ADD_TO_CART,
    product,
    quantity = 1,
    enteredOptions = [],
    selectedOptions = []
) => {
    const { sku, type_id: typeId, name } = product;

    const productData = [];

    if (typeId === PRODUCT_TYPE.grouped && action === ADD_TO_CART) {
        if (Object.keys(quantity).length === 0) {
            return productData;
        }

        const { items } = product;
        const groupedProducts = [];

        items.forEach(({ product: { id } }) => {
            const { [id]: groupedQuantity = 0 } = quantity;
            groupedProducts.push(btoa(`grouped/${id}/${groupedQuantity}`));
        });

        productData.push({
            sku,
            quantity: 1,
            selected_options: [...selectedOptions, ...groupedProducts],
            entered_options: enteredOptions,
            name,
        });
    } else {
        const baseProductToAdd = {
            sku,
            quantity,
            selected_options: selectedOptions,
            entered_options: enteredOptions,
            name,
        };

        productData.push(baseProductToAdd);
    }

    return productData;
};
