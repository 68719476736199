import PropTypes from 'prop-types';

import Button from 'Component/Button';
import Icon from 'Component/Icon';
import { AddToCart as SourceAddToCart } from 'SourceComponent/AddToCart/AddToCart.component';

import './AddToCart.override.style';

/** @namespace SwiatKsiazkiBasic/Component/AddToCart/Component */
export class AddToCart extends SourceAddToCart {
    static propTypes = {
        ...SourceAddToCart.propTypes,
        variant: PropTypes.oneOf(['primary', 'outline', 'hollow']),
        size: PropTypes.oneOf(['large', 'medium', 'small']),
        shortText: PropTypes.bool,
        label: PropTypes.string,
    };

    static defaultProps = {
        variant: 'primary',
        size: 'medium',
        shortText: false,
        label: '',
    };

    render() {
        const { mix, addProductToCart, layout, isDisabled, isAdding, isIconEnabled, variant, size, shortText, label } =
            this.props;

        const btnText = label || (shortText ? __('To cart') : __('Add to cart'));

        return (
            <Button
                onClick={addProductToCart}
                block="AddToCart Button"
                mix={mix}
                mods={{ layout, variant, size }}
                disabled={isDisabled || isAdding}
                iconBefore={
                    isIconEnabled ? (
                        <Icon name="cart" width="16" height="16" fill={variant === 'primary' ? '#ffffff' : '#dd151e'} />
                    ) : null
                }
                title={isAdding ? __('Adding...') : btnText}
            >
                <span>{isAdding ? __('Adding...') : btnText}</span>
            </Button>
        );
    }
}

export default AddToCart;
