import { FieldNumberContainer as SourceFieldNumberContainer } from 'SourceComponent/FieldNumber/FieldNumber.container';

/** @namespace SwiatKsiazkiBasic/Component/FieldNumber/Container */
export class FieldNumberContainer extends SourceFieldNumberContainer {
    componentDidUpdate() {
        const { attr: { min, max, defaultValue = min } = {}, updateMaxValue } = this.props;

        if (defaultValue >= max && updateMaxValue) {
            this.handleInitialLoad(max);
        }

        if (defaultValue <= min) {
            this.handleInitialLoad(min);
        }
    }

    handleOnChange({ target: { value } }) {
        this.handleValueChange(Number(value));
    }

    containerProps() {
        const { events } = this.props;

        return {
            ...super.containerProps(),
            events: {
                ...events,
                onChange: this.handleOnChange.bind(this),
            },
        };
    }
}

export default FieldNumberContainer;
