import PropTypes from 'prop-types';

import Form from 'Component/Form';
import { PRIVACY_POLICY_URL } from 'Component/Html/Html.config';
import Icon from 'Component/Icon';
import Link from 'Component/Link';
import { NewsletterSubscription as SourceNewsletterSubscription } from 'SourceComponent/NewsletterSubscription/NewsletterSubscription.component';

import newsletterSubscriptionForm from './NewsletterForm.form';

import './NewsletterSubscription.override.style';

/** @namespace SwiatKsiazkiBasic/Component/NewsletterSubscription/Component */
export class NewsletterSubscription extends SourceNewsletterSubscription {
    static propTypes = {
        ...SourceNewsletterSubscription.propTypes,
        successInfo: PropTypes.string,
    };

    get fieldMap() {
        return newsletterSubscriptionForm();
    }

    renderInfo() {
        return (
            <p>
                {__(
                    'The administrator of your personal data is Dressler Dublin Ltd. For more information on data processing rules you can find in'
                )}
                <Link to={PRIVACY_POLICY_URL}> {__('Privacy policy')}</Link>.
            </p>
        );
    }

    render() {
        const { successInfo } = this.props;

        if (successInfo && successInfo !== '') {
            return (
                <div block="NewsletterSubscription" elem="SuccessInfo">
                    <div block="NewsletterSubscription" elem="SuccessInfoButton">
                        <Icon name="checkmark" width="20" height="13" />
                        {successInfo}
                    </div>
                    <p block="NewsletterSubscription" elem="SuccessInfoText">
                        {__(
                            'A message has been sent to the e-mail address provided with confirmation of subscription to the newsletter.'
                        )}
                    </p>
                </div>
            );
        }

        return (
            <div block="NewsletterSubscription">
                {this.renderInfo()}
                <Form {...this.getFormProps()} block="FieldForm">
                    {this.renderFormBody()}
                </Form>
            </div>
        );
    }
}

export default NewsletterSubscription;
