import { connect } from 'react-redux';

import {
    DEFAULT_STATE,
    mapDispatchToProps,
    mapStateToProps,
    NavigationAbstractContainer as SourceNavigationAbstractContainer,
} from 'SourceComponent/NavigationAbstract/NavigationAbstract.container';
import { isSignedIn } from 'Util/Auth';

export { DEFAULT_STATE, mapStateToProps, mapDispatchToProps };

/** @namespace SwiatKsiazkiBasic/Component/NavigationAbstract/Container */
export class NavigationAbstractContainer extends SourceNavigationAbstractContainer {
    onRouteChanged(history) {
        const { device } = this.props;

        // check if token is expired and logout
        isSignedIn();

        if (!device.isMobile && !device.isTablet) {
            return this.handleDesktopRouteChange(history);
        }

        return this.handleMobileUrlChange(history);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavigationAbstractContainer);
