import FIELD_TYPE from 'Component/Field/Field.config';
import { newsletterSubscriptionForm as sourceNewsletterSubscriptionForm } from 'SourceComponent/NewsletterSubscription/NewsletterForm.form';
import { VALIDATION_INPUT_TYPE } from 'Util/Validator/Config';

/** @namespace SwiatKsiazkiBasic/Component/NewsletterSubscription/NewsletterForm/Form/newsletterSubscriptionForm */
export const newsletterSubscriptionForm = () => [
    ...sourceNewsletterSubscriptionForm(),
    {
        type: FIELD_TYPE.checkbox,
        attr: {
            name: 'newsletterAgreement',
            defaultChecked: false,
        },
        label: __(
            'I agree to receive marketing and commercial information related to the activities of Dressler Dublin Ltd. operating under the Book World brand at the e-mail address provided. I am aware that I may withdraw this consent at any time.'
        ),
        validateOn: ['onSubmit'],
        validationRule: {
            inputType: VALIDATION_INPUT_TYPE.checkbox,
            isRequired: true,
        },
        addRequiredTag: true,
    },
];
export default newsletterSubscriptionForm;
