import { GET_ADVOX_FREE_DELIVERY_DATA } from './AdvoxFreeDelivery.action';

/** @namespace SwiatKsiazkiBasic/Store/AdvoxFreeDelivery/Reducer/getInitialState */
export const getInitialState = () => ({
    canShow: false,
    treshold: null,
    missing: null,
});

/** @namespace SwiatKsiazkiBasic/Store/AdvoxFreeDelivery/Reducer/AdvoxFreeDeliveryReducer */
export const AdvoxFreeDeliveryReducer = (state = getInitialState(), action) => {
    const { type, payload = {} } = action;

    switch (type) {
        case GET_ADVOX_FREE_DELIVERY_DATA:
            return {
                ...state,
                ...payload?.advoxFreeDeliveryData,
            };
        default:
            return state;
    }
};

export default AdvoxFreeDeliveryReducer;
