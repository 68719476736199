import omit from 'lodash/omit';
import pick from 'lodash/pick';
import { PureComponent } from 'react';

import Agreements from 'Component/Agreements';
import CmsBlock from 'Component/CmsBlock';
import Field from 'Component/Field';
import FieldGroup from 'Component/FieldGroup';
import Form from 'Component/Form';
import {
    GIFT_CARDS_BUY_CARD_DETAILS_CMS_ID,
    REQUEST_TYPE_PAYMENT,
    REQUEST_TYPE_QUERY,
} from 'Component/GiftCardsBuyCardForm/GiftCardsBuyCardForm.config';
import { PRIVACY_POLICY_URL } from 'Component/Html/Html.config';
import Link from 'Component/Link';
import Loader from 'Component/Loader';
import { formatPrice } from 'Util/Price';

import { GIFT_CARD_ORDER_CONSENT } from './GiftCardsBuyCardForm.config';
import { buyCardForm, buyCardNumberFields, cardTypesToCheck } from './GiftCardsBuyCardForm.form';

import './GiftCardsBuyCardForm.style.scss';

/** @namespace SwiatKsiazkiBasic/Component/GiftCardsBuyCardForm/Component */
export class GiftCardsBuyCardForm extends PureComponent {
    renderTagTitle(Tag = 'h2', title) {
        return (
            <Tag block="GiftCardsBuyCardForm" elem="TabTitle">
                {__(`${title}`)}
            </Tag>
        );
    }

    renderAdministratorInfo() {
        return (
            <p block="GiftCardsBuyCardForm" elem="Administrator">
                {__(
                    'The administrator of your personal data is Dressler Dublin Ltd. For more information on data processing rules you can find in'
                )}
                <Link to={PRIVACY_POLICY_URL}> {__('Privacy policy')}</Link>.
            </p>
        );
    }

    renderRequireInfo() {
        return (
            <p block="GiftCardsBuyCardForm" elem="AdditionalInfo">
                {__('* Required fields')}
            </p>
        );
    }

    renderOrderButton() {
        const { setSubmitType } = this.props;

        return (
            <button
                block="GiftCardsBuyCardForm"
                elem="OrderButton"
                mix={{ block: 'Button' }}
                type="submit"
                onClick={setSubmitType(REQUEST_TYPE_PAYMENT)}
            >
                {__('Order and pay online')}
            </button>
        );
    }

    renderAskButton() {
        const { setSubmitType } = this.props;

        return (
            <button
                block="GiftCardsBuyCardForm"
                elem="AskButton"
                mix={{ block: 'Button' }}
                type="submit"
                onClick={setSubmitType(REQUEST_TYPE_QUERY)}
            >
                {__('Send question')}
            </button>
        );
    }

    renderCardTypesFields() {
        const { setCardVariant } = this.props;
        const images = cardTypesToCheck(this.props).map((card) => pick(card, ['image', 'attr']));
        const inputs = cardTypesToCheck(this.props).map((card) => omit(card, ['image']));

        return (
            <div block="GiftCardsBuyCardForm" elem="CardsFieldWrapper">
                {images.map(({ image, attr: { id } = {} }) => (
                    <label htmlFor={id}>{image}</label>
                ))}
                {inputs.map((elem) => (
                    <Field
                        {...elem}
                        events={{
                            onChange: () => setCardVariant(elem?.attr?.value),
                        }}
                    />
                ))}
            </div>
        );
    }

    renderNumberFields() {
        const inputs = buyCardNumberFields(this.props);

        return inputs.map((elem) => <Field {...elem} />);
    }

    renderAgreements() {
        return <Agreements section={GIFT_CARD_ORDER_CONSENT} name={GIFT_CARD_ORDER_CONSENT} />;
    }

    renderCreateBuyCardFields() {
        const inputs = buyCardForm(this.props);
        return inputs.map((elem) => <Field {...elem} />);
    }

    renderTotalAmount() {
        const { props } = this;
        const {
            cardVariant,
            giftCardsAvailableCardValues = [],
            giftCardsDeliveryCost,
            advoxFreeDeliveryEnabled = false,
            advoxFreeDeliveryThreshold = '',
        } = this.props;

        const cardAmount = giftCardsAvailableCardValues
            .map((amount) => {
                const qty = props[String(amount)];

                if (!qty && qty !== 0) {
                    return 0;
                }

                return amount * qty;
            })
            .reduce((sum, num) => sum + num);

        const isVoucher = cardVariant === 'VOUCHER';
        const isFreeShipping =
            (advoxFreeDeliveryEnabled && cardAmount > Number(advoxFreeDeliveryThreshold)) || isVoucher;
        const shippingAmount = cardAmount && !isFreeShipping ? giftCardsDeliveryCost : 0;

        const shippingLabel = !isFreeShipping ? __('incl. shipping cost') : __('shipping cost');
        const totalAmount = cardAmount + shippingAmount;

        return (
            <p block="GiftCardsBuyCardForm" elem="TotalAmountInfo">
                <span>
                    <span block="GiftCardsBuyCardForm" elem="TotalAmountLabel">{`${__('Total amount')}: `}</span>
                    <span block="GiftCardsBuyCardForm" elem="TotalAmountValue">
                        {formatPrice(totalAmount)}
                    </span>
                </span>
                {totalAmount && !isVoucher ? (
                    <span block="GiftCardsBuyCardForm" elem="TotalAmountShippingInfo">
                        {`(${shippingLabel} ${formatPrice(shippingAmount)})`}
                    </span>
                ) : null}
            </p>
        );
    }

    renderCreateBuyCardForm() {
        const { onError, onSuccess, setFormRef } = this.props;

        return (
            <Form key="activate-card" onSubmit={onSuccess} onError={onError} elemRef={setFormRef}>
                <FieldGroup
                    validateOn={['onChange']}
                    validationRule={{ isRequired: true }}
                    mix={{ block: 'GiftCardsBuyCardForm', elem: 'CardTypes' }}
                >
                    <fieldset block="GiftCardsBuyCardForm" elem="CardTypesWrapper">
                        {this.renderCardTypesFields()}
                    </fieldset>
                </FieldGroup>
                <div block="GiftCardsBuyCardDetails">
                    <CmsBlock identifier={GIFT_CARDS_BUY_CARD_DETAILS_CMS_ID} />
                </div>
                <div block="GiftCardsBuyCardForm" elem="ThickWrapper">
                    <div block="GiftCardsBuyCardForm" elem="PrimaryFieldsWrapper">
                        {this.renderTagTitle('h2', 'Full in the form')}
                        <p block="GiftCardsBuyCardForm" elem="SubTitle">
                            {__('To order a gift card')}
                        </p>
                        {this.renderTagTitle('h4', 'Data to shipping')}
                        {this.renderCreateBuyCardFields()}
                        {this.renderRequireInfo()}
                    </div>
                    <FieldGroup>
                        <div block="GiftCardsBuyCardForm" elem="NumberFieldsWrapper">
                            {this.renderTagTitle('h3', 'Cards ordered')}
                            {this.renderNumberFields()}
                        </div>
                    </FieldGroup>
                    <div block="GiftCardsBuyCardForm" elem="TotalAmountWrapper">
                        {this.renderTotalAmount()}
                    </div>
                    {this.renderAgreements()}
                    {this.renderAdministratorInfo()}
                    <div block="GiftCardsBuyCardForm" elem="ButtonsWrapper">
                        {this.renderOrderButton()}
                        {this.renderAskButton()}
                    </div>
                </div>
            </Form>
        );
    }

    render() {
        const { isLoading, giftCardsAvailableCardValues } = this.props;

        if (!giftCardsAvailableCardValues) {
            return null;
        }

        return (
            <div block="GiftCardsBuyCardForm">
                <Loader isLoading={isLoading} isFullPage />
                {this.renderTagTitle('h2', 'Choose card')}
                <div block="GiftCardsBuyCardForm" elem="FormWrapper">
                    {this.renderCreateBuyCardForm()}
                </div>
            </div>
        );
    }
}

export default GiftCardsBuyCardForm;
