import parser from 'html-react-parser';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import Icon from 'Component/Icon';
import { ExtendedLocationsType } from 'Type/Locations.type';

import './MapSidebar.style';

/** @namespace SwiatKsiazkiBasic/Component/MapSidebar/Component */
export class MapSidebar extends PureComponent {
    static propTypes = {
        locations: ExtendedLocationsType,
        handleSearch: PropTypes.func,
        handleClickLocation: PropTypes.func,
    };

    getLocations() {
        const { locations, handleClickLocation } = this.props;

        return locations?.map(({ sidebarShownInfo, sidebarHiddenInfo, isExpanded }, i) => (
            <li
                block="MapSidebar"
                elem="Location"
                mods={{ isExpanded: !!isExpanded }}
                onClick={() => handleClickLocation(i)}
                key={i}
            >
                {isExpanded}
                <div block="MapSidebar" elem="Location" mods={{ type: 'ShownInfo' }}>
                    {parser(sidebarShownInfo)}
                </div>
                <div block="MapSidebar" elem="Location" mods={{ type: 'HiddenInfo' }}>
                    <br />
                    <br />
                    <span block="MapSidebar" elem="LocationText">
                        {parser(sidebarHiddenInfo)}
                    </span>
                </div>
                <div block="MapSidebar" elem="ArrowIcon">
                    <Icon name="double_arrows_right" />
                </div>
            </li>
        ));
    }

    render() {
        const { handleSearch, handleNearestLocation, isGeolocation } = this.props;

        return (
            <div block="MapSidebar">
                <div block="MapSidebar" elem="Search">
                    <input placeholder={__('Enter a city or area...')} onChange={(e) => handleSearch(e)} />
                    <Icon name="magnifier" width="18" height="18" fill="#b9b9b9" />
                </div>
                {isGeolocation ? (
                    <button block="MapSidebar" elem="FindLocation" type="button" onClick={handleNearestLocation}>
                        {__('Find the nearest')} <Icon name="small_arrow_right" height="11" width="6" />
                    </button>
                ) : null}
                <div block="MapSidebar" elem="Locations">
                    <ul>{this.getLocations()}</ul>
                </div>
            </div>
        );
    }
}

export default MapSidebar;
