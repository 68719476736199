import { getInitialState } from 'Store/Product/Product.reducer';

export const RESET_CURRENT_PRODUCT = 'RESET_CURRENT_PRODUCT';

export const resetCurrentProduct = () => ({
    type: RESET_CURRENT_PRODUCT,
});

const overrideReducer = (args, callback, context) => {
    const [, action] = args;
    const originalUpdatedState = callback.apply(context, args);

    if (!action) {
        return originalUpdatedState;
    }

    const { type } = action;

    if (type !== RESET_CURRENT_PRODUCT) {
        return originalUpdatedState;
    }

    return getInitialState();
};

const appendToMapDispatchToProps = (args, callback, context) => {
    const [dispatch] = args;

    return {
        ...callback.apply(context, args),
        resetCurrentProduct: () => dispatch(resetCurrentProduct()),
    };
};

const appendComponentWillUnmount = (args, callback, context) => {
    const { resetCurrentProduct } = context.props;

    if (resetCurrentProduct) {
        resetCurrentProduct();
    }

    if (callback) {
        callback.apply(context, args);
    }
};

export default {
    'Store/Product/Reducer/ProductReducer': {
        function: overrideReducer,
    },
    'Route/ProductPage/Container/mapDispatchToProps': {
        function: appendToMapDispatchToProps,
    },
    'Route/ProductPage/Container': {
        'member-function': {
            componentWillUnmount: appendComponentWillUnmount,
        },
    },
};
