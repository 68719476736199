export * from 'SourceStore/Notification/Notification.action';

export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';

/** @namespace SwiatKsiazkiBasic/Store/Notification/Action/showNotification */
export const showNotification = (msgType, msgText, msgDebug, lifeTime) => ({
    type: SHOW_NOTIFICATION,
    msgType,
    msgText,
    msgDebug,
    lifeTime,
});
