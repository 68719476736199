import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { showNotification } from 'Store/Notification/Notification.action';
import transformToNameValuePair from 'Util/Form/Transform';
import { getErrorMessage } from 'Util/Request';

import GiftCardsCheckValidityForm from './GiftCardsCheckValidityForm.component';

export const GiftCardCheckValidityDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/GiftCardCheckValidity/GiftCardCheckValidity.dispatcher'
);

/** @namespace SwiatKsiazkiBasic/Component/GiftCardsCheckValidityForm/Container/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace SwiatKsiazkiBasic/Component/GiftCardsCheckValidityForm/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    giftcardCheck: (cardNumber, securityCode) =>
        GiftCardCheckValidityDispatcher.then(({ default: dispatcher }) =>
            dispatcher.requestCardCheck(dispatch, cardNumber, securityCode)
        ),
});

/** @namespace SwiatKsiazkiBasic/Component/GiftCardsCheckValidityForm/Container */
export class GiftCardsCheckValidityFormContainer extends PureComponent {
    state = {
        isLoading: false,
        checkResponse: {},
        cardNumber: '',
    };

    containerFunctions = {
        onSuccess: this.onSuccess.bind(this),
    };

    async onSuccess(_, fields) {
        const { giftcardCheck, showNotification } = this.props;
        const { cardNumber, securityCode } = transformToNameValuePair(fields);

        this.setState({ isLoading: true });

        try {
            const { giftcardCheck: checkResponse } = await giftcardCheck(cardNumber, securityCode);
            if (checkResponse) {
                this.setState({
                    isLoading: false,
                    checkResponse,
                    cardNumber,
                });
            }
        } catch (error) {
            this.setState({
                checkResponse: {},
                cardNumber: '',
            });
            showNotification('error', getErrorMessage(error));
        } finally {
            this.setState({ isLoading: false });
        }
    }

    containerProps() {
        const { isLoading, checkResponse, cardNumber } = this.state;

        return {
            isLoading,
            checkResponse,
            cardNumber,
        };
    }

    render() {
        return <GiftCardsCheckValidityForm {...this.containerFunctions} {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GiftCardsCheckValidityFormContainer);
