import getStore from 'Util/Store';

/** @namespace SwiatKsiazkiBasic/Component/Edrone/Script/sendEvent */
export const sendEvent = (event, data = {}) => {
    const { MyAccountReducer } = getStore().getState();
    const { isSignedIn, customer } = MyAccountReducer || {};
    const { firstname, lastname, email, is_subscribed, addresses = [] } = customer || {};
    const { city, country_id, telephone } =
        addresses?.find(({ default_shipping: defaultAddress }) => defaultAddress) || {};

    window._edrone = window._edrone || {};
    window._edrone.product_skus = '';
    window._edrone.product_ids = '';
    window._edrone.product_titles = '';
    window._edrone.product_images = '';
    window._edrone.product_urls = '';
    window._edrone.product_category_ids = '';
    window._edrone.product_category_names = '';
    window._edrone.first_name = '';
    window._edrone.last_name = '';
    window._edrone.email = '';
    window._edrone.subscriber_status = '';
    window._edrone.city = '';
    window._edrone.country = '';
    window._edrone.phone = '';

    if (isSignedIn) {
        window._edrone.first_name = firstname || '';
        window._edrone.last_name = lastname || '';
        window._edrone.email = email || '';
        window._edrone.subscriber_status = Number(is_subscribed) || '';
        window._edrone.city = city || '';
        window._edrone.country = country_id || '';
        window._edrone.phone = telephone || '';
    }

    Object.entries(data).forEach(([key, value]) => {
        window._edrone[key] = value;
    });

    window._edrone.action_type = event;

    if (typeof window._edrone.init === 'function') {
        window._edrone.init();
    }
};
