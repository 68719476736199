import { showNotification } from '@scandipwa/scandipwa/src/store/Notification/Notification.action';
import { prepareQuery } from '@scandipwa/scandipwa/src/util/Query';
import { executePost, getErrorMessage } from '@scandipwa/scandipwa/src/util/Request';
import { PropTypes } from 'prop-types';
import React, { createRef, PureComponent } from 'react';
import { connect } from 'react-redux';

import RecaptchaQuery from '../../query/Recaptcha.query';
import SessionDatabase from '../../util/SessionDatabase';
import Recaptcha from './Recaptcha.component';
import { CAPTCHA_RESET, CAPTCHA_TOKEN, TWO_MINUTES } from './Recaptcha.config';

/** @namespace Advox/Recaptcha/Component/Recaptcha/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    captchaSetup: state.ConfigReducer.captchaSetup,
});

/** @namespace Advox/Recaptcha/Component/Recaptcha/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, msg, debug = null) => dispatch(showNotification(type, msg, debug)),
});

/** @namespace Advox/Recaptcha/Component/Recaptcha/Container/RecaptchaContainer */
export class RecaptchaContainer extends PureComponent {
    static propTypes = {
        id: PropTypes.string.isRequired,
        captchaSetup: PropTypes.object.isRequired,
        showNotification: PropTypes.func.isRequired,
    };

    static getCaptchaToken() {
        return SessionDatabase.getItem(CAPTCHA_TOKEN);
    }

    state = {
        captchaToken: '',
    };

    containerFunctions = {
        onChange: this.onChange.bind(this),
        onError: this.onError.bind(this),
        setCaptchaRef: this.setCaptchaRef.bind(this),
    };

    captchaRef = createRef();

    componentWillUnmount() {
        this.reset();
    }

    setCaptchaRef(node) {
        if (node) {
            this.captchaRef.current = node;
        }
    }

    onChange(captchaToken) {
        SessionDatabase.setItem(captchaToken, CAPTCHA_TOKEN, TWO_MINUTES);
        this.setState({ captchaToken });
    }

    onError() {
        if (this.captchaRef.current) {
            this.captchaRef.current.reset();
        }

        SessionDatabase.deleteItem(CAPTCHA_TOKEN);
        this.setState({ captchaToken: '' });
    }

    reset() {
        SessionDatabase.deleteItem(CAPTCHA_TOKEN);
        window.removeEventListener(CAPTCHA_TOKEN, this.onError);
    }

    get isCaptchaEnabled() {
        const { captchaSetup } = this.props;
        return (captchaSetup ?? {}).enabled || false;
    }

    containerProps() {
        const { id, captchaSetup } = this.props;
        const { captchaToken } = this.state;

        return { id, captchaToken, captchaSetup, isCaptchaEnabled: this.isCaptchaEnabled };
    }

    render() {
        return <Recaptcha {...this.containerProps()} {...this.containerFunctions} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RecaptchaContainer);
