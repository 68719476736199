import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    NewVersionPopupContainer as SourceNewVersionPopupContainer,
} from 'SourceComponent/NewVersionPopup/NewVersionPopup.container';
import { isCrawler, isSSR } from 'Util/Browser';
import ServiceWorker, { OPEN_SERVICE_WORKER_POPUP, UPDATE_SERVICE_WORKER } from 'Util/ServiceWorker';

import NewVersionPopup from './NewVersionPopup.component';

/** @namespace SwiatKsiazkiBasic/Component/NewVersionPopup/Container */
export class NewVersionPopupContainer extends SourceNewVersionPopupContainer {
    componentDidMount() {
        if (isCrawler() || isSSR()) {
            return;
        }

        ServiceWorker.subscribe(OPEN_SERVICE_WORKER_POPUP, this.handlePopup.bind(this));
    }

    componentWillUnmount() {
        ServiceWorker.unsubscribe(OPEN_SERVICE_WORKER_POPUP, this.handlePopup.bind(this));
    }

    toggleNewVersion() {
        ServiceWorker.notify(UPDATE_SERVICE_WORKER);
    }

    handlePopup() {
        const { showPopup, goToPreviousHeaderState, device } = this.props;

        showPopup({
            title: __('A new version of the website is available!'),
        });

        if (device.isMobile) {
            goToPreviousHeaderState();
        }

        setTimeout(() => this.toggleNewVersion(), 0);
    }

    render() {
        return <NewVersionPopup {...this.containerFunctions} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewVersionPopupContainer);
