import Loader from 'Component/Loader';
import { NewVersionPopup as SourceNewVersionPopup } from 'SourceComponent/NewVersionPopup/NewVersionPopup.component';

import './NewVersionPopup.style';

/** @namespace SwiatKsiazkiBasic/Component/NewVersionPopup/Component */
export class NewVersionPopup extends SourceNewVersionPopup {
    renderHeading() {
        return (
            <h3 block="NewVersionPopup" elem="Heading">
                {__('A new version of the website is available!')}
            </h3>
        );
    }

    renderNotice() {
        return <p>{__('We are downloading an updated version of our website for you.')}</p>;
    }

    renderLoader() {
        return <Loader isLoading loadingText={null} />;
    }

    renderContent() {
        return (
            <div block="NewVersionPopup" elem="Content">
                {this.renderHeading()}
                {this.renderNotice()}
                {this.renderLoader()}
            </div>
        );
    }
}

export default NewVersionPopup;
