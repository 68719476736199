import { PureComponent } from 'react';
import { createPortal } from 'react-dom';

import ChevronIcon from 'Component/ChevronIcon';
import { LEFT } from 'Component/ChevronIcon/ChevronIcon.config';
import CookieShowMore from 'Component/CookieShowMore';
import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import Form from 'Component/Form';
import Html from 'Component/Html';
import Icon from 'Component/Icon';
import ShowMoreLessContent from 'Component/ShowMoreLessContent';
import { appendWithStoreCode } from 'Util/Url';

import './CookiePopup.style';

/** @namespace SwiatKsiazkiBasic/Component/CookiePopup/Component */
export class CookiePopup extends PureComponent {
    renderBody() {
        const { isCustomize, content, groups, accepted, isMobile } = this.props;

        if (isCustomize) {
            return (
                <div block="CookiePopup" elem="Groups">
                    {groups.map(({ group_id, is_essential, name, description, cookies, identifier }) => (
                        <div block="CookiePopup" elem="Group" key={group_id}>
                            <Field
                                attr={{
                                    name: identifier,
                                    id: identifier,
                                    defaultChecked: accepted.includes(identifier),
                                    ...(is_essential ? { checked: true } : {}),
                                }}
                                type={FIELD_TYPE.switcher}
                                label={
                                    <div block="CookiePopup" elem="GroupLabel">
                                        {is_essential ? (
                                            <span block="CookiePopup" elem="GroupLabelIcon">
                                                <Icon height="16" width="16" name="lock" />
                                            </span>
                                        ) : null}
                                        {name}
                                        <span block="CookiePopup" elem="GroupLabelLength">
                                            {cookies.length}
                                        </span>
                                    </div>
                                }
                                mix={{ block: 'CookiePopup', elem: 'GroupCheckbox' }}
                                validationRule={{
                                    isRequired: is_essential,
                                }}
                            />
                            <p block="CookiePopup" elem="GroupText">
                                {description}
                            </p>
                            <CookieShowMore>
                                <ul block="CookiePopup" elem="GroupList">
                                    {cookies?.map((cookie) => (
                                        <li key={cookie.name} block="CookiePopup" elem="GroupItem">
                                            <p block="CookiePopup" elem="GroupText">
                                                {cookie.name}
                                            </p>
                                            <p block="CookiePopup" elem="GroupText">
                                                {cookie.description}
                                            </p>
                                            <div block="CookiePopup" elem="GroupItemDetail">
                                                <p block="CookiePopup" elem="GroupText">
                                                    {__('Expiration date: %s', cookie.lifetime)}
                                                </p>
                                                <p block="CookiePopup" elem="GroupText">
                                                    {__('Provider: %s', cookie.provider)}
                                                </p>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </CookieShowMore>
                        </div>
                    ))}
                </div>
            );
        }

        if (!content) {
            return null;
        }

        return (
            <div block="CookiePopup" elem="Text">
                <ShowMoreLessContent contentLength={isMobile ? 250 : 350}>
                    <Html content={content} />
                </ShowMoreLessContent>
            </div>
        );
    }

    renderActions() {
        const {
            isMobile,
            isLoading,
            isCustomize,
            isCookieRestriction,
            toggleCustomize,
            acceptAllGroups,
            acceptEssentialGroups,
            groups,
        } = this.props;

        return (
            <div block="CookiePopup" elem="Footer">
                {isCustomize ? (
                    isMobile ? (
                        <>
                            <button
                                block="Button"
                                mix={{ block: 'CookiePopup', elem: 'Button' }}
                                mods={{ size: 'extraSmall', variant: 'secondary' }}
                                type={FIELD_TYPE.button}
                                onClick={acceptAllGroups}
                                disabled={isLoading}
                            >
                                {__('Allow all')}
                            </button>
                            <button
                                block="Button"
                                mix={{ block: 'CookiePopup', elem: 'Button' }}
                                mods={{ size: 'extraSmall', variant: 'hollow' }}
                                type={FIELD_TYPE.submit}
                                disabled={isLoading}
                            >
                                {__('Save my choice')}
                            </button>
                            <button
                                block="Button"
                                mix={{ block: 'CookiePopup', elem: 'Button' }}
                                mods={{ size: 'extraSmall', variant: 'hollow' }}
                                type={FIELD_TYPE.button}
                                onClick={acceptEssentialGroups}
                                disabled={isLoading}
                            >
                                {__('Reject all')}
                            </button>
                            <button
                                block="Button"
                                mix={{ block: 'CookiePopup', elem: 'Button' }}
                                mods={{ size: 'extraSmall', variant: 'hollow', back: true, desktop: true }}
                                type={FIELD_TYPE.button}
                                onClick={toggleCustomize}
                                disabled={isLoading}
                            >
                                <ChevronIcon direction={LEFT} />
                                <span>{__('Back')}</span>
                            </button>
                        </>
                    ) : (
                        <>
                            <button
                                block="Button"
                                mix={{ block: 'CookiePopup', elem: 'Button' }}
                                mods={{ size: 'extraSmall', variant: 'hollow', back: true, desktop: true }}
                                type={FIELD_TYPE.button}
                                onClick={toggleCustomize}
                                disabled={isLoading}
                            >
                                <ChevronIcon direction={LEFT} />
                                <span>{__('Back')}</span>
                            </button>
                            <button
                                block="Button"
                                mix={{ block: 'CookiePopup', elem: 'Button' }}
                                mods={{ size: 'extraSmall', variant: 'hollow' }}
                                type={FIELD_TYPE.button}
                                onClick={acceptEssentialGroups}
                                disabled={isLoading}
                            >
                                {__('Reject all')}
                            </button>
                            <button
                                block="Button"
                                mix={{ block: 'CookiePopup', elem: 'Button' }}
                                mods={{ size: 'extraSmall', variant: 'hollow' }}
                                type={FIELD_TYPE.submit}
                                disabled={isLoading}
                            >
                                {__('Save my choice')}
                            </button>
                            <button
                                block="Button"
                                mix={{ block: 'CookiePopup', elem: 'Button' }}
                                mods={{ size: 'extraSmall', variant: 'secondary' }}
                                type={FIELD_TYPE.button}
                                onClick={acceptAllGroups}
                                disabled={isLoading}
                            >
                                {__('Allow all')}
                            </button>
                        </>
                    )
                ) : isMobile ? (
                    <>
                        <button
                            block="Button"
                            mods={{ size: 'extraSmall', variant: 'secondary' }}
                            mix={{ block: 'CookiePopup', elem: 'Button' }}
                            type={FIELD_TYPE.button}
                            onClick={acceptAllGroups}
                            disabled={isLoading}
                        >
                            {__('Allow all')}
                        </button>
                        {isCookieRestriction && groups.length > 0 ? (
                            <button
                                block="Button"
                                mods={{ size: 'extraSmall', variant: 'hollow' }}
                                mix={{ block: 'CookiePopup', elem: 'Button' }}
                                type={FIELD_TYPE.button}
                                onClick={toggleCustomize}
                                disabled={isLoading}
                            >
                                {__('Personalize')}
                            </button>
                        ) : null}
                        {isCookieRestriction && groups.length > 0 ? (
                            <button
                                block="Button"
                                mods={{ size: 'extraSmall', variant: 'hollow' }}
                                mix={{ block: 'CookiePopup', elem: 'Button' }}
                                type={FIELD_TYPE.button}
                                onClick={acceptEssentialGroups}
                                disabled={isLoading}
                            >
                                {__('Reject all')}
                            </button>
                        ) : null}
                    </>
                ) : (
                    <>
                        {isCookieRestriction && groups.length > 0 ? (
                            <button
                                block="Button"
                                mods={{ size: 'extraSmall', variant: 'hollow' }}
                                mix={{ block: 'CookiePopup', elem: 'Button' }}
                                type={FIELD_TYPE.button}
                                onClick={acceptEssentialGroups}
                                disabled={isLoading}
                            >
                                {__('Reject all')}
                            </button>
                        ) : null}
                        {isCookieRestriction && groups.length > 0 ? (
                            <button
                                block="Button"
                                mods={{ size: 'extraSmall', variant: 'hollow' }}
                                mix={{ block: 'CookiePopup', elem: 'Button' }}
                                type={FIELD_TYPE.button}
                                onClick={toggleCustomize}
                                disabled={isLoading}
                            >
                                {__('Personalize')}
                            </button>
                        ) : null}
                        <button
                            block="Button"
                            mods={{ size: 'extraSmall', variant: 'secondary' }}
                            mix={{ block: 'CookiePopup', elem: 'Button' }}
                            type={FIELD_TYPE.button}
                            onClick={acceptAllGroups}
                            disabled={isLoading}
                        >
                            {__('Allow all')}
                        </button>
                    </>
                )}
            </div>
        );
    }

    renderForm() {
        const { acceptSelectedGroups } = this.props;

        return (
            <div block="CookiePopup" elem="Content">
                <div block="CookiePopup" elem="Wrapper">
                    <Form onSubmit={acceptSelectedGroups} mix={{ block: 'CookiePopup', elem: 'Form' }}>
                        {this.renderBody()}
                        {this.renderActions()}
                    </Form>
                </div>
            </div>
        );
    }

    render() {
        const { isEnabled, isLoaded, isVisible, isAccepted, isCustomize, isCookieRestriction, togglePopup, location } =
            this.props;

        if (
            !isEnabled ||
            !isLoaded ||
            location.pathname.match(new RegExp(`${appendWithStoreCode('/cart')}|${appendWithStoreCode('/checkout')}`))
        ) {
            return null;
        }

        if (!isVisible && isAccepted) {
            return createPortal(
                <button
                    block="CookiePopupButton"
                    mods={{ isCustomize, isCookieRestriction }}
                    onClick={togglePopup}
                    type="button"
                >
                    <Icon height="24" width="24" name="cookie" />
                </button>,
                document.body
            );
        }

        return createPortal(
            <div block="CookiePopup" mods={{ isCustomize, isCookieRestriction }}>
                {this.renderForm()}
            </div>,
            document.body
        );
    }
}

export default CookiePopup;
