import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import CookieDispatcher from 'Store/Cookie/Cookie.dispatcher';
import { showNotification } from 'Store/Notification/Notification.action';
import { getBooleanValue } from 'Util/Data';
import transformToNameValuePair from 'Util/Form/Transform';
import { getErrorMessage } from 'Util/Request';

import CookiePopup from './CookiePopup.component';

/** @namespace SwiatKsiazkiBasic/Component/CookiePopup/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isMobile: state.ConfigReducer.device.isMobile,
    isEnabled: state.ConfigReducer.cookieSettings.gdpr_cookie_bar,
    content: state.ConfigReducer.cookieSettings.gdpr_cookie_bar_text,
});

/** @namespace SwiatKsiazkiBasic/Component/CookiePopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, msg, debug) => dispatch(showNotification(type, msg, debug)),
});

/** @namespace SwiatKsiazkiBasic/Component/CookiePopup/Container */
export class CookiePopupContainer extends PureComponent {
    containerFunctions = {
        acceptAllGroups: this.acceptAllGroups.bind(this),
        acceptEssentialGroups: this.acceptEssentialGroups.bind(this),
        acceptSelectedGroups: this.acceptSelectedGroups.bind(this),
        toggleCustomize: this.toggleCustomize.bind(this),
        togglePopup: this.togglePopup.bind(this),
    };

    componentDidMount() {
        const { isEnabled } = this.props;

        if (isEnabled) {
            this.getCookieData();
        }
    }

    componentDidUpdate(prevProps) {
        const { isEnabled } = this.props;
        const { isEnabled: prevIsEnabled } = prevProps;

        if (prevIsEnabled !== isEnabled) {
            if (isEnabled) {
                this.getCookieData();
            }
        }
    }

    __construct() {
        super.__construct();

        this.state = {
            isAccepted: CookieDispatcher.isAccepted(),
            isVisible: !CookieDispatcher.isAccepted(),
            isCustomize: false,
            isLoaded: false,
            isLoading: true,
            groups: [],
            accepted: CookieDispatcher.getAccepted(),
        };
    }

    togglePopup() {
        this.setState({
            isVisible: true,
            isCustomize: false,
        });
    }

    toggleCustomize(event) {
        event.preventDefault();
        this.setState((prevState) => ({
            isCustomize: !prevState.isCustomize,
        }));
    }

    async getCookieData() {
        const { showNotification } = this.props;

        this.setState({
            isLoading: true,
        });

        try {
            const { cookieGroups: groups = [] } = await CookieDispatcher.getGroups();

            this.setState({
                isLoaded: true,
                isLoading: false,
                isVisible: !CookieDispatcher.isAccepted(),
                groups,
            });
        } catch (error) {
            showNotification('error', getErrorMessage(error), error);
            this.setState({
                isLoaded: false,
                isLoading: false,
                isVisible: false,
                groups: [],
            });
        }
    }

    async acceptAllGroups() {
        const { showNotification } = this.props;
        const { groups } = this.state;

        this.setState({
            isLoading: true,
        });

        try {
            const identifiers = groups.filter(({ identifier }) => !!identifier).map(({ identifier }) => identifier);
            await CookieDispatcher.acceptGroups(identifiers);
            this.setState({
                isVisible: false,
                isAccepted: true,
                accepted: identifiers,
            });
        } catch (error) {
            showNotification('error', getErrorMessage(error), error);
        } finally {
            this.setState({
                isLoading: false,
            });
        }
    }

    async acceptEssentialGroups() {
        const { showNotification } = this.props;
        const { groups } = this.state;

        this.setState({
            isLoading: true,
        });

        try {
            const identifiers = groups
                .filter(({ identifier, is_essential }) => !!identifier && is_essential === true)
                .map(({ identifier }) => identifier);

            await CookieDispatcher.acceptGroups(identifiers);
            this.setState({
                isVisible: false,
                isAccepted: true,
                accepted: identifiers,
            });
        } catch (error) {
            showNotification('error', getErrorMessage(error), error);
        } finally {
            this.setState({
                isLoading: false,
            });
        }
    }

    async acceptSelectedGroups(_, fields) {
        const { showNotification } = this.props;

        this.setState({
            isLoading: true,
        });

        try {
            const identifiers = Object.entries(transformToNameValuePair(fields))
                .filter(([_, value]) => !!value)
                .map(([name]) => name);

            await CookieDispatcher.acceptGroups(identifiers);
            this.setState({
                isVisible: false,
                isAccepted: true,
                accepted: identifiers,
            });
        } catch (error) {
            showNotification('error', getErrorMessage(error), error);
        } finally {
            this.setState({
                isLoading: false,
            });
        }
    }

    containerProps() {
        const { content, isEnabled, isMobile, location } = this.props;
        const { isCustomize, isVisible, isAccepted, isLoaded, isLoading, groups, accepted } = this.state;

        return {
            isCookieRestriction: getBooleanValue(window.isCookieRestriction),
            isCustomize,
            isAccepted,
            isVisible,
            isEnabled,
            isLoaded,
            isLoading,
            isMobile,
            groups,
            content,
            accepted,
            location,
        };
    }

    render() {
        return <CookiePopup {...this.containerFunctions} {...this.containerProps()} />;
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CookiePopupContainer));
