import { UPDATE_CONFIG } from '@scandipwa/scandipwa/src/store/Config/Config.action';
import BrowserDatabase from '@scandipwa/scandipwa/src/util/BrowserDatabase';

import RecaptchaQuery from '../query/Recaptcha.query';

const { captchaSetup } = BrowserDatabase.getItem('config') || {
    captchaSetup: {},
};

const ConfigReducer_getInitialState = (args, callback) => ({
    ...callback(...args),
    captchaSetup,
});

const ConfigReducer_reducer = (args, callback, context) => {
    const [, action] = args;
    const originalUpdatedState = callback.apply(context, args);

    if (!action) {
        return originalUpdatedState;
    }

    const { type, config: { captchaSetup = {} } = {} } = action;

    if (type !== UPDATE_CONFIG) {
        return originalUpdatedState;
    }

    return {
        ...originalUpdatedState,
        captchaSetup,
    };
};

const ConfigDispatcher_prepareRequest = (args, callback) => [...callback.apply(args), RecaptchaQuery.getSetupQuery()];

export default {
    'Store/Config/Reducer/getInitialState': {
        function: ConfigReducer_getInitialState,
    },
    'Store/Config/Reducer': {
        function: ConfigReducer_reducer,
    },
    'Store/Config/Dispatcher': {
        'member-function': {
            prepareRequest: ConfigDispatcher_prepareRequest,
        },
    },
};
