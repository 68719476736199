import { FIELD_TYPE } from 'Component/Field/Field.config';
import { FieldNumber as SourceFieldNumber } from 'SourceComponent/FieldNumber/FieldNumber.component';
import { DEFAULT_MAX_PRODUCTS } from 'Util/Product/Extract';

import './FieldNumber.override.style';

/** @namespace SwiatKsiazkiBasic/Component/FieldNumber/Component */
export class FieldNumber extends SourceFieldNumber {
    render() {
        const {
            attr,
            attr: { min = 1, max = DEFAULT_MAX_PRODUCTS },
            value,
            events,
            setRef,
            stateValue,
            handleValueChange,
            isDisabled,
        } = this.props;

        const numberValue = +value || +stateValue;

        return (
            <>
                <button
                    disabled={numberValue + 1 === min || numberValue <= min || isDisabled}
                    // eslint-disable-next-line react/jsx-no-bind
                    onClick={() => handleValueChange(numberValue - 1)}
                    aria-label={__('Subtract')}
                    type={FIELD_TYPE.button}
                >
                    -
                </button>
                <input
                    ref={(elem) => setRef(elem)}
                    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                    {...attr}
                    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                    {...events}
                    value={numberValue}
                    type={FIELD_TYPE.number}
                    aria-label={__('Value')}
                    disabled={isDisabled}
                />
                <button
                    disabled={max === 1 || numberValue >= max || isDisabled}
                    // eslint-disable-next-line react/jsx-no-bind
                    onClick={() => handleValueChange(numberValue + 1)}
                    aria-label={__('Add')}
                    type={FIELD_TYPE.button}
                >
                    +
                </button>
            </>
        );
    }
}

export default FieldNumber;
