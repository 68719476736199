import PropTypes from 'prop-types';
import { Children, cloneElement, createRef, PureComponent } from 'react';

import { ChildrenType } from 'Type/Common.type';
import { noopFn } from 'Util/Common';

import { PREVENT_OUTSIDE_CLICK_CLASS } from './ClickOutside.config';

/** @namespace SwiatKsiazkiBasic/Component/ClickOutside/Component */
export class ClickOutside extends PureComponent {
    static propTypes = {
        onClick: PropTypes.func,
        children: ChildrenType,
    };

    static defaultProps = {
        onClick: noopFn,
        children: [],
    };

    handleClick = this.handleClick.bind(this);

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClick);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClick);
    }

    __construct(props) {
        super.__construct(props);

        const { children } = this.props;

        this.childrenRefs = Children.map(children, () => createRef());
    }

    handleClick({ target }) {
        const { onClick } = this.props;

        if (!target || !target.isConnected || PREVENT_OUTSIDE_CLICK_CLASS.includes(target?.className)) {
            return;
        }

        const isOutside = Array.isArray(this.childrenRefs)
            ? this.childrenRefs.filter((r) => Boolean(r.current)).every((r) => r.current && !r.current.contains(target))
            : this.childrenRefs.current && !this.childrenRefs.current.contains(target);

        if (isOutside) {
            onClick();
        }
    }

    render() {
        const { children } = this.props;

        return Children.map(children, (element, idx) => cloneElement(element, { ref: this.childrenRefs[idx] }));
    }
}

export default ClickOutside;
