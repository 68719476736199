export * from 'SourceRoute/Checkout/Checkout.config';

export const ORDER_DETAILS = 'ORDER_DETAILS';
export const CHECKOUT_COMPANY_AGREEMENT = 'checkoutCompanyConsent';

export const SHIPPING_FIELDS = 'shipping_fields';
export const SHIPPING_FIELDS_EXPIRATION = 1800;

export const CHECKOUT_ERRORS = {
    QTY_IS_NOT_AVAILABLE: 'The requested qty is not available',
    COULD_NOT_UPDATE: 'Could not update the product with SKU ',
};
