import { PureComponent } from 'react';

import './FieldInputRange.style';

/** @namespace SwiatKsiazkiBasic/Component/FieldInputRange/Container */
export class FieldInputRangeContainer extends PureComponent {
    componentDidUpdate(pevProps) {
        const { defaultStartValue: prevDefaultStartValue, defaultEndValue: prevDefaultEndValue } = pevProps;
        const { defaultStartValue, defaultEndValue } = this.props;

        if (prevDefaultStartValue !== defaultStartValue) {
            this.setState({
                startValue: defaultStartValue || null,
            });
        }

        if (prevDefaultEndValue !== defaultEndValue) {
            this.setState({
                endValue: defaultEndValue || null,
            });
        }
    }

    __construct(props) {
        super.__construct(props);

        const { defaultStartValue, defaultEndValue } = this.props;

        this.state = {
            startValue: defaultStartValue ? Number(defaultStartValue) : null,
            endValue: defaultEndValue ? Number(defaultEndValue) : null,
        };
    }

    setStartValue(e) {
        const { value } = e.target;

        this.setState({
            startValue: Number(value),
        });
    }

    setEndValue(e) {
        const { value } = e.target;

        this.setState({
            endValue: Number(value),
        });
    }

    render() {
        const { startValue, endValue } = this.state;
        const { disabled, name, isDisabled, setRef, labelTo, labelFrom } = this.props;

        return (
            <div block="FieldInputRange" mods={{ isDisabled: disabled }}>
                <div block="Field">
                    <input name={`${name}_from`} type="text" value={startValue || ''} hidden />
                    <input
                        ref={(elem) => setRef(elem)}
                        name={`${name}_number_from`}
                        type="number"
                        aria-label={labelFrom}
                        onChange={(value) => this.setStartValue(value)}
                        value={startValue || ''}
                        disabled={isDisabled}
                        placeholder={labelFrom}
                    />
                    <div block="Field" elem="LabelContainer">
                        <label block="Field" elem="Label" htmlFor={`${name}_number_from`}>
                            {labelFrom}
                        </label>
                    </div>
                </div>
                <span>-</span>
                <div block="Field">
                    <input name={`${name}_to`} type="text" value={endValue} hidden />
                    <input
                        ref={(elem) => setRef(elem)}
                        name={`${name}_number_to`}
                        type="number"
                        aria-label={labelTo}
                        value={endValue}
                        onChange={(value) => this.setEndValue(value)}
                        placeholder={labelTo}
                        disabled={isDisabled}
                    />
                    <div block="Field" elem="LabelContainer">
                        <label block="Field" elem="Label" htmlFor={`${name}_number_to`}>
                            {labelTo}
                        </label>
                    </div>
                </div>
            </div>
        );
    }
}

export default FieldInputRangeContainer;
