import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { CART_URL } from 'Route/CartPage/CartPage.config';
import { CHECKOUT_URL } from 'Route/Checkout/Checkout.config';
import { GIFT_CARDS_ORDER_SUCCESS_URL_FRAGMENT } from 'Route/GiftCardsOrderSuccess/GiftCardsOrderSuccess.config';
import { INVOICE_INFORMATION } from 'Route/InvoiceInformation/InvoiceInformation.config';
import {
    FooterContainer as SourceFooterContainer,
    mapDispatchToProps,
    mapStateToProps,
} from 'SourceComponent/Footer/Footer.container';
import { isHomePageUrl } from 'Util/Url/Url';

import Footer from './Footer.component';

export { mapStateToProps, mapDispatchToProps };

/** @namespace SwiatKsiazkiBasic/Component/Footer/Container */
export class FooterContainer extends SourceFooterContainer {
    containerProps() {
        const {
            location: { pathname },
        } = this.props;

        const isHomePage = isHomePageUrl(pathname);
        const isCheckout = pathname.includes(CHECKOUT_URL);
        const isCart = pathname.includes(CART_URL);
        const isGiftCardOrderSuccess =
            pathname.includes(GIFT_CARDS_ORDER_SUCCESS_URL_FRAGMENT) || pathname.includes(INVOICE_INFORMATION);

        return {
            ...super.containerProps(),
            isVisibleNewsletter: isCheckout || isGiftCardOrderSuccess || isCart,
            isHomePage,
        };
    }

    render() {
        return <Footer {...this.props} {...this.containerProps()} {...this.containerFunctions} />;
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FooterContainer));
