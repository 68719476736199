import { PureComponent } from 'react';

import './SeparatorWidget.style';

/** @namespace SwiatKsiazkiBasic/Component/SeparatorWidget/Component */
export class SeparatorWidget extends PureComponent {
    render() {
        return (
            <div block="SeparatorWidget">
                <hr />
            </div>
        );
    }
}

export default SeparatorWidget;
