import PropTypes from 'prop-types';
import { createRef } from 'react';

import { IMAGE_HUNDRED_PERCENT } from 'Component/Image/Image.config';
import { ImageContainer as SourceImageContainer } from 'SourceComponent/Image/Image.container';
import { inViewport, mergeRefs } from 'Util/Element';

/** @namespace SwiatKsiazkiBasic/Component/Image/Container */
export class ImageContainer extends SourceImageContainer {
    static propTypes = {
        ...SourceImageContainer.propTypes,
        useNativeLazyLoading: PropTypes.bool,
        removeDefaultClasses: PropTypes.bool,
        lazyLoadingType: PropTypes.oneOf(['default']),
        lazyLoadingMode: PropTypes.oneOf(['eager', 'lazy']),
        fetchPriority: PropTypes.oneOf(['high', 'low']),
    };

    static defaultProps = {
        ...SourceImageContainer.defaultProps,
        useNativeLazyLoading: false,
        removeDefaultClasses: false,
        lazyLoadingType: 'default',
        lazyLoadingMode: 'lazy',
        fetchPriority: 'low',
    };

    internalImageRef = createRef();

    state = {
        visibleByDefault: false,
    };

    componentDidMount() {
        this.setState({
            visibleByDefault: inViewport(this.internalImageRef),
        });
    }

    _parseSize(size) {
        const trimmedSize = String(size).trim();

        if (!trimmedSize) {
            return IMAGE_HUNDRED_PERCENT;
        }

        const PX_LENGTH = -2;
        const PERCENT_LENGTH = -1;

        if (trimmedSize.slice(PX_LENGTH) === 'px' || trimmedSize.slice(PERCENT_LENGTH) === '%') {
            return trimmedSize;
        }

        return trimmedSize;
    }

    _getStyle() {
        const { style } = this.props;

        return style;
    }

    containerProps() {
        const {
            useNativeLazyLoading,
            removeDefaultClasses,
            lazyLoadingType,
            lazyLoadingMode,
            fetchPriority,
            imageRef,
        } = this.props;

        const { visibleByDefault } = this.state;

        return {
            ...super.containerProps(),
            useNativeLazyLoading,
            removeDefaultClasses,
            lazyLoadingType,
            lazyLoadingMode,
            fetchPriority,
            visibleByDefault,
            imageRef: mergeRefs(imageRef, this.internalImageRef),
            sizes: this._getCorrectSize(),
        };
    }
}

export default ImageContainer;
