export const UPDATE_SERVICE_WORKER = 'UPDATE_SERVICE_WORKER';
export const OPEN_SERVICE_WORKER_POPUP = 'OPEN_SERVICE_WORKER_POPUP';

/** @namespace SwiatKsiazkiBasic/Util/ServiceWorker */
export class ServiceWorkerObservable {
    observers = new Map();

    subscribe(event, callback) {
        this.observers.set(event, callback);
    }

    unsubscribe(event) {
        this.observers.delete(event);
    }

    notify(trigger, data) {
        this.observers.forEach((callback, event) => {
            if (trigger === event) {
                callback(data);
            }
        });
    }
}

export default new ServiceWorkerObservable();
