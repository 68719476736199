import { connect } from 'react-redux';

import DatePicker from 'Component/DatePicker';
import DateSelect from 'Component/DateSelect';
import FieldDateRange from 'Component/FieldDateRange';
import {
    FieldDateContainer as SourceFieldDateContainer,
    mapDispatchToProps,
    mapStateToProps,
} from 'SourceComponent/FieldDate/FieldDate.container';

export { mapStateToProps, mapDispatchToProps };

/** @namespace SwiatKsiazkiBasic/Component/FieldDate/Container */
export class FieldDateContainer extends SourceFieldDateContainer {
    containerProps() {
        const {
            placeholder,
            label,
            disabled,
            rangeDate,
            defaultCurrentDate,
            currentDate,
            name,
            defaultStartDate,
            defaultEndDate,
            withYearRange,
            datePickerProps,
        } = this.props;

        return {
            ...super.containerProps(),
            placeholder,
            label,
            disabled,
            rangeDate,
            currentDate,
            defaultCurrentDate,
            name,
            defaultStartDate,
            defaultEndDate,
            withYearRange,
            datePickerProps,
        };
    }

    render() {
        const { useCalendar, rangeDate } = this.props;

        if (useCalendar) {
            if (rangeDate) {
                return <FieldDateRange {...this.containerProps()} />;
            }

            return <DatePicker {...this.containerProps()} />;
        }

        return <DateSelect {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FieldDateContainer);
