import React, { PureComponent } from 'react';

import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import Html from 'Component/Html';
import Loader from 'Component/Loader';

import './Agreements.style';

/** @namespace SwiatKsiazkiBasic/Component/Agreements/Component */
export class Agreements extends PureComponent {
    renderAgreementContent({ checkbox_text, content }) {
        const { disabledPopupOnClick } = this.props;

        if (checkbox_text === content || !disabledPopupOnClick) {
            return null;
        }

        return (
            <div block="Agreements" elem="ItemContent">
                <Html content={content} />
            </div>
        );
    }

    renderAgreements(agreement, key) {
        const {
            handleNotificationError,
            handleSelectAgreement,
            getArrangementFieldName,
            handleShowPopup,
            customErrorMessages,
        } = this.props;
        const { agreement_id, is_required, is_html, checkbox_text, content } = agreement;

        return (
            <div block="Agreements" elem="Item">
                <Field
                    type={FIELD_TYPE.checkbox}
                    attr={{
                        id: getArrangementFieldName(agreement_id),
                        name: getArrangementFieldName(agreement_id),
                        value: getArrangementFieldName(agreement_id),
                    }}
                    events={{
                        onChange: () => handleSelectAgreement(agreement),
                        onLabelClick: (event) => {
                            handleShowPopup(event, agreement);
                        },
                    }}
                    label={is_html ? <Html content={checkbox_text} /> : checkbox_text}
                    validateOn={['onChange']}
                    validationRule={{
                        isRequired: is_required,
                        customErrorMessages: customErrorMessages[key] || {},
                    }}
                    onError={handleNotificationError}
                />
                {this.renderAgreementContent({ checkbox_text, content })}
            </div>
        );
    }

    render() {
        const { isLoading, isVisible, agreements, disabledLoader, getArrangementSectionName } = this.props;

        if (!isLoading && !agreements.length) {
            return null;
        }

        if (!isLoading && !agreements.length) {
            return null;
        }

        return (
            <div id={getArrangementSectionName()} block="Agreements" hidden={!isVisible}>
                {!disabledLoader ? <Loader isLoading={isLoading} /> : null}
                {agreements.map(this.renderAgreements.bind(this))}
            </div>
        );
    }
}

export default Agreements;
