// import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Icon from 'Component/Icon';

import './MapWidget.style';

/** @namespace SwiatKsiazkiBasic/Component/MapWidget/Component */
export class MapWidget extends PureComponent {
    static propTypes = {
        script: PropTypes.string.isRequired,
    };

    getConfigMap() {
        const { script } = this.props;

        if (script) {
            const googleApiKey = script.split('?key=')[1].split('&')[0];
            const lng = script.split('lng:')[1].split('\t')[0];
            const lat = script.split('lat:')[1].split(',')[0];
            const zoom = script.split('zoom:')[1].split(',')[0];

            return {
                googleApiKey,
                center: { lat: parseFloat(lat), lng: parseFloat(lng) },
                zoom: parseFloat(zoom),
            };
        }

        return null;
    }

    renderMarker(lat, lng) {
        return (
            <div lat={lat} lng={lng}>
                <Icon name="marker_with_logo" height="48" width="38" />
            </div>
        );
    }

    render() {
        const config = this.getConfigMap();

        if (config) {
            const { googleApiKey, center, zoom } = config;
            const { lat, lng } = center;

            return (
                <div block="MapWidget" elem="Wrapper">
                    <GoogleMapReact bootstrapURLKeys={{ key: googleApiKey }} defaultCenter={center} defaultZoom={zoom}>
                        {this.renderMarker(lat, lng)}
                    </GoogleMapReact>
                </div>
            );
        }

        return null;
    }
}

export default MapWidget;
