import PropTypes from 'prop-types';

import ProductListQuery from 'Query/ProductList.query';
import { getIndexedProducts } from 'Util/Product';
import DataContainer from 'Util/Request/DataContainer';

import ProductListMagezonWidget from './ProductListMagezonWidget.component';

/** @namespace SwiatKsiazkiBasic/Component/ProductListMagezonWidget/Container */
export class ProductListMagezonWidgetContainer extends DataContainer {
    static propTypes = {
        productsSkuArray: PropTypes.arrayOf(PropTypes.object).isRequired,
        title: PropTypes.string,
        url: PropTypes.string,
        isLightVariant: PropTypes.bool,
    };

    state = {
        items: [],
        isLoading: false,
    };

    componentDidMount() {
        this.getSingleProductsData();
    }

    __construct(props) {
        super.__construct(props, 'ProductListMagezonWidget');
    }

    getSingleProductsData() {
        const { productsSkuArray, max_items = 4, direction: sortDirection, order: sortKey, categoryId } = this.props;

        if (
            !productsSkuArray ||
            !Array.isArray(productsSkuArray) ||
            (Array.isArray(productsSkuArray) && productsSkuArray.length === 0)
        ) {
            return;
        }

        this.setState({ isLoading: true });

        const options = {
            args: {
                filter: {
                    productsSkuArray,
                    categoryIds: categoryId,
                },
                currentPage: 1,
                pageSize: max_items,
                ...(!sortKey || sortKey === 'default' ? {} : { sort: { sortKey, sortDirection } }),
            },
            isPlp: true,
            noVariantAttributes: true,
        };

        this.fetchData(
            [ProductListQuery.getQuery(options)],
            ({ products }) => {
                this.setState({
                    items: getIndexedProducts(products?.items),
                    isLoading: false,
                });
            },
            () => {
                this.setState({
                    items: [],
                    isLoading: false,
                });
            }
        );
    }

    containerProps() {
        const { isLoading, items } = this.state;
        const { title, url, isLightVariant } = this.props;

        return {
            isLoading,
            products: items,
            title,
            url,
            isLightVariant,
        };
    }

    render() {
        return <ProductListMagezonWidget {...this.containerProps()} />;
    }
}

export default ProductListMagezonWidgetContainer;
