import { RestoreScrollContainer } from 'Component/RestoreScroll/RestoreScroll.container';

export const aroundUpdateProductListItems = (args, callback) => {
    RestoreScrollContainer.scrollToPosition();

    return callback(...args);
};

export default {
    'Store/ProductList/Action/updateProductListItems': {
        function: aroundUpdateProductListItems,
    },
};
