import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';

import { isPageBuilderEnable } from 'Util/MagezoneWidget';
import { isHomePageUrl } from 'Util/Url/Url';

import CmsPageRenderer from './renderer/CmsPageRenderer.component';
import HomePageRenderer from './renderer/HomePageRenderer.component';

/** @namespace SwiatKsiazkiBasic/Component/MagezonRenderer/Component */
export class MagezonRenderer extends PureComponent {
    render() {
        const {
            location: { pathname },
            children,
            domToReact,
            attributesToProps,
            attribs,
            ...props
        } = this.props;

        if (isHomePageUrl(pathname) && !isPageBuilderEnable({ attribs })) {
            return (
                <HomePageRenderer
                    {...props}
                    attribs={attribs}
                    domToReact={domToReact}
                    attributesToProps={attributesToProps}
                >
                    {children}
                </HomePageRenderer>
            );
        }

        return (
            <CmsPageRenderer {...props} attribs={attribs} domToReact={domToReact} attributesToProps={attributesToProps}>
                {children}
            </CmsPageRenderer>
        );
    }
}

export default withRouter(MagezonRenderer);
