import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CmsPageQuery from 'Query/CmsPage.query';
import { toggleBreadcrumbs } from 'Store/Breadcrumbs/Breadcrumbs.action';
import { updateMeta } from 'Store/Meta/Meta.action';
import { changeNavigationState } from 'Store/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import { setBigOfflineNotice } from 'Store/Offline/Offline.action';
import { LocationType, MatchType } from 'Type/Router.type';
import { scrollToTop } from 'Util/Browser';
import { debounce } from 'Util/Request';
import DataContainer from 'Util/Request/DataContainer';

import CmsPage from './CmsPage.component';
import { LOADING_TIME } from './CmsPage.config';

export const BreadcrumbsDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Breadcrumbs/Breadcrumbs.dispatcher'
);

/** @namespace SwiatKsiazkiBasic/Route/CmsPage/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isOffline: state.OfflineReducer.isOffline,
    isLoading: state.UrlRewritesReducer.isLoading,
});

/** @namespace SwiatKsiazkiBasic/Route/CmsPage/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    updateBreadcrumbs: (breadcrumbs) =>
        BreadcrumbsDispatcher.then(({ default: dispatcher }) => dispatcher.updateWithCmsPage(breadcrumbs, dispatch)),
    setHeaderState: (stateName) => dispatch(changeNavigationState(TOP_NAVIGATION_TYPE, stateName)),
    setBigOfflineNotice: (isBig) => dispatch(setBigOfflineNotice(isBig)),
    updateMeta: (meta) => dispatch(updateMeta(meta)),
    toggleBreadcrumbs: (isActive) => {
        BreadcrumbsDispatcher.then(({ default: dispatcher }) => dispatcher.update([], dispatch));
        dispatch(toggleBreadcrumbs(isActive));
    },
});

/** @namespace SwiatKsiazkiBasic/Route/CmsPage/Container */
export class CmsPageContainer extends DataContainer {
    static propTypes = {
        match: MatchType.isRequired,
        setHeaderState: PropTypes.func.isRequired,
        updateBreadcrumbs: PropTypes.func.isRequired,
        setBigOfflineNotice: PropTypes.func.isRequired,
        location: LocationType.isRequired,
        toggleBreadcrumbs: PropTypes.func.isRequired,
        isOnlyPlaceholder: PropTypes.bool,
        isBreadcrumbsActive: PropTypes.bool,
        isHomePage: PropTypes.bool,
    };

    static defaultProps = {
        isOnlyPlaceholder: false,
        isBreadcrumbsActive: true,
        isHomePage: false,
    };

    state = {
        isLoading: true,
        page: {},
    };

    setOfflineNoticeSize = this.setOfflineNoticeSize.bind(this);

    componentDidMount() {
        const { isOffline, isOnlyPlaceholder } = this.props;
        const { isLoading } = this.state;

        scrollToTop();

        if (isOffline && isLoading) {
            debounce(this.setOfflineNoticeSize, LOADING_TIME)();
        }

        this.updateBreadcrumbs();

        if (!isOnlyPlaceholder) {
            this.requestPage();
        }
    }

    componentDidUpdate(prevProps) {
        const { pageIds } = this.props;

        const { pageIds: prevPageIds } = prevProps;

        if (pageIds !== prevPageIds) {
            this.requestPage();
        }
    }

    requestPage() {
        const { pageIds } = this.props;

        this.setState({
            isLoading: true,
            page: {},
        });

        if (!pageIds) return;

        this.fetchData(
            CmsPageQuery.getQuery({ id: pageIds }),
            ({ cmsPage }) => {
                this.setState({
                    page: cmsPage,
                    isLoading: false,
                });
            },
            () => {
                this.setState({
                    page: {},
                    isLoading: false,
                });
            }
        );
    }

    updateBreadcrumbs() {
        const { toggleBreadcrumbs, isBreadcrumbsActive } = this.props;

        toggleBreadcrumbs(isBreadcrumbsActive);
    }

    setOfflineNoticeSize() {
        const { setBigOfflineNotice } = this.props;
        const { isLoading } = this.state;

        if (isLoading) {
            setBigOfflineNotice(true);
        } else {
            setBigOfflineNotice(false);
        }
    }

    containerProps() {
        const { isBreadcrumbsActive, isHomePage } = this.props;
        const { page, isLoading } = this.state;

        return {
            isHomePage,
            isBreadcrumbsActive,
            isLoading,
            isPageLoaded: Object.keys(page).length > 0,
            page,
        };
    }

    render() {
        return <CmsPage {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CmsPageContainer);
