import { FIELD_DATE_TYPE } from 'Component/FieldDate/FieldDate.config';
import { getFieldsData, getTimeFormat, isMagentoDateFormatValid } from 'SourceUtil/Form/Extract';
import { DEFAULT_DATE_FORMAT } from 'Util/Date';

export * from 'SourceUtil/Form/Extract';

/** @namespace SwiatKsiazkiBasic/Util/Form/Extract/getDateFormat */
export const getDateFormat = (dateFieldsOrder) => {
    if (!isMagentoDateFormatValid(dateFieldsOrder)) {
        return DEFAULT_DATE_FORMAT;
    }

    const dateMap = {
        d: 'dd',
        m: 'MM',
        y: 'yyyy',
    };

    return dateFieldsOrder
        .split(',')
        .map((field) => dateMap[field])
        .join('.');
};

/** @namespace SwiatKsiazkiBasic/Util/Form/Extract/getDateTimeFormat */
export const getDateTimeFormat = (type, dateFieldsOrder, timeFormat) => {
    const timePart =
        type === FIELD_DATE_TYPE.time || type === FIELD_DATE_TYPE.dateTime ? getTimeFormat(timeFormat) : '';

    const datePart =
        type === FIELD_DATE_TYPE.date || type === FIELD_DATE_TYPE.dateTime ? getDateFormat(dateFieldsOrder) : '';

    return `${datePart} ${timePart}`.trim();
};

export default getFieldsData;
