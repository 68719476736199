import { PureComponent } from 'react';

import { CmsBlockContext } from './CmsBlockProvider.container';

/** @namespace SwiatKsiazkiBasic/Component/CmsBlockProvider/Hoc/withCmsBlockProvider */
export const withCmsBlockProvider = (Component) =>
    class extends PureComponent {
        static contextType = CmsBlockContext;

        containerFunctions = {
            hasIdentifier: this.hasIdentifier.bind(this),
            hasBlock: this.hasBlock.bind(this),
            getBlock: this.getBlock.bind(this),
        };

        hasIdentifier(name) {
            const { identifiers } = this.context;
            return identifiers.includes(name);
        }

        hasBlock(name) {
            const { blocks } = this.context;
            return blocks.filter((block) => !!block)?.findIndex(({ identifier }) => identifier === name) !== -1;
        }

        getBlock(name) {
            const { blocks } = this.context;
            return blocks?.filter((block) => !!block)?.find(({ identifier }) => identifier === name);
        }

        containerProps() {
            const { blocks } = this.context;

            return {
                shared: 'blocks' in this.context,
                blocks,
            };
        }

        render() {
            return <Component {...this.containerFunctions} {...this.containerProps()} {...this.props} />;
        }
    };

export default withCmsBlockProvider;
