import currencyMap from 'SourceUtil/Price/Price.config';
import { getDefaultCurrency } from 'Util/Currency';

export * from 'SourceUtil/Price/Price';

/** @namespace SwiatKsiazkiBasic/Util/Price/formatCurrency */
export const formatCurrency = (currency = getDefaultCurrency()) => currencyMap[currency];

/** @namespace SwiatKsiazkiBasic/Util/Price/formatPrice */
export const formatPrice = (price, currency = getDefaultCurrency()) =>
    new Intl.NumberFormat(window.defaultLocale.split('_')[0], { style: 'currency', currency }).format(price);
