import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    MenuItemContainer as SourceMenuItemContainer,
} from 'SourceComponent/MenuItem/MenuItem.container';
import { scrollToTop } from 'Util/Browser';

/** @namespace SwiatKsiazkiBasic/Component/MenuItem/Container */
export class MenuItemContainer extends SourceMenuItemContainer {
    static propTypes = {
        ...super.propTypes,
        updateBreadcrumbs: PropTypes.func,
    };

    containerProps() {
        const { noIcon, isBanner, isTopProduct, withShowMore } = this.props;

        return {
            ...super.containerProps(),
            noIcon,
            isBanner,
            isTopProduct,
            withShowMore,
        };
    }

    onItemClick() {
        const {
            closeMenu,
            updateBreadcrumbs,
            activeMenuItemsStack,
            item: { url: { pathname: newPathname = '' } = {} } = {},
        } = this.props;

        scrollToTop();
        closeMenu();

        const { pathname } = location;

        if (activeMenuItemsStack.length > 1) {
            window.activeMenuItemsStack = activeMenuItemsStack;
        }

        if (pathname !== newPathname) {
            updateBreadcrumbs();
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuItemContainer);
