// eslint-disable-next-line import/prefer-default-export
export const STOCK_TYPE = {
    IN_STOCK: 'IN_STOCK',
    OUT_OF_STOCK: 'OUT_OF_STOCK',
};

export const QTY_VALUE_CHANGER = 9;

/** @namespace SwiatKsiazkiBasic/Component/Product/Stock/Config/qtyRenderOptions */
export const qtyRenderOptions = (renderItemCount, maxQuantity) => {
    const MAX_VISIBLE_OPTIONS_SELECT_ARRAY = Array.from(Array(renderItemCount).keys());

    return MAX_VISIBLE_OPTIONS_SELECT_ARRAY.map((elem, index) => {
        const isLast = index + 1 === MAX_VISIBLE_OPTIONS_SELECT_ARRAY.length;
        const labelWithPlus = isLast && maxQuantity > QTY_VALUE_CHANGER;
        const increasedItem = elem + 1;

        return {
            id: `${increasedItem}`,
            name: `${increasedItem}`,
            value: increasedItem,
            label: labelWithPlus ? `${increasedItem}+` : `${increasedItem}`,
        };
    });
};
