import { PureComponent } from 'react';

import ShowMoreLessText from './ShowMoreLessText.component';

/** @namespace SwiatKsiazkiBasic/Component/ShowMoreLessText/Container */
export class ShowMoreLessTextContainer extends PureComponent {
    state = {
        isExpanded: false,
    };

    containerFunctions = {
        toggleExpanded: this.toggleExpanded.bind(this),
    };

    containerProps() {
        const { isExpanded } = this.state;

        return { ...this.props, isExpanded };
    }

    toggleExpanded(event) {
        if (event) {
            event.stopPropagation();
        }

        this.setState((prevState) => ({
            isExpanded: !prevState.isExpanded,
        }));
    }

    render() {
        return <ShowMoreLessText {...this.props} {...this.containerFunctions} {...this.containerProps()} />;
    }
}

export default ShowMoreLessTextContainer;
