import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Popup from 'Component/Popup';
import ProductCard from 'Component/ProductCard';
import { ProductType } from 'Type/ProductList.type';

import { WISHLIST_PRICE_ALERT_POPUP_ID } from './WishlistPriceAlertPopup.config';

import './WishListPriceAlertPopup.style';

/** @namespace SwiatKsiazkiBasic/Component/WishListPriceAlertPopup/Component */
export class WishListPriceAlertPopup extends PureComponent {
    static propTypes = {
        products: PropTypes.arrayOf(ProductType).isRequired,
    };

    render() {
        const { products, navigateToWishList } = this.props;

        return (
            <Popup
                id={WISHLIST_PRICE_ALERT_POPUP_ID}
                noHeaderStateChange
                mix={{ block: 'WishListPriceAlert', elem: 'Popup' }}
            >
                <div block="WishListPriceAlert" elem="Header">
                    <h2 block="WishListPriceAlert" elem="Name">
                        {__('check out the new lower prices for products in Your wishlist')}
                    </h2>
                </div>
                <div block="WishListPriceAlert" elem="ProductList">
                    {products?.map((product) => (
                        <ProductCard product={product} key={product.id} isWishlistPriceAlert />
                    ))}
                </div>
                <div block="WishListPriceAlert" elem="ActionContainer">
                    <button onClick={navigateToWishList} block="Button" mods={{ variant: 'primary' }}>
                        {__('Go to wishlist')}
                    </button>
                </div>
            </Popup>
        );
    }
}

export default WishListPriceAlertPopup;
