export const DICTIONARY_ATTRIBUTES = [
    'authors_ms',
    'actors_ms',
    'performers_ms',
    'composers_ms',
    'directors_ms',
    'producers_ms',
    'publishers_ms',
    'series_ms',
    'conductor_ms',
    'lectors',
    'lectors_ms',
    'conductor',
    'conductor_ms',
];

/** @namespace SwiatKsiazkiBasic/Util/Dictionary/isDictionaryAttribute */
export const isDictionaryAttribute = (attributeCode) => {
    if (!attributeCode) {
        return false;
    }

    return !!DICTIONARY_ATTRIBUTES.find((value) => value === attributeCode);
};
