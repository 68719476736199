import { connect } from 'react-redux';

import { FIELD_DATE_TYPE } from 'Component/FieldDate/FieldDate.config';
import {
    DatePickerContainer as SourceDatePickerContainer,
    mapDispatchToProps,
    mapStateToProps,
} from 'SourceComponent/DatePicker/DatePicker.container';
import { toDate } from 'Util/Date';
import { getDateTimeFormat, getTimeFormat, getYearRangeAttributes } from 'Util/Form/Extract';

export { mapStateToProps, mapDispatchToProps };

/** @namespace SwiatKsiazkiBasic/Component/DatePicker/Container */
export class DatePickerContainer extends SourceDatePickerContainer {
    __construct(props) {
        super.__construct(props);
        const { currentDate, yearRange, defaultCurrentDate, withYearRange = true } = props;

        if (defaultCurrentDate) {
            if (withYearRange) {
                const { minDate, maxDate } = getYearRangeAttributes(yearRange);
                const currentDate = new Date();
                const validMinDate = minDate > currentDate ? minDate : currentDate;
                const selectedDate = maxDate < validMinDate ? maxDate : validMinDate;

                this.state = { selectedDate };
            } else {
                this.state = { selectedDate: toDate(currentDate ?? defaultCurrentDate) };
            }
        } else {
            this.state = { selectedDate: null };
        }
    }

    containerProps() {
        const { selectedDate } = this.state;
        const {
            type,
            yearRange,
            timeFormat: magentoTimeFormat,
            dateFieldsOrder,
            uid,
            placeholder,
            label,
            disabled,
            rangeDate,
            isClearable,
            withYearRange = true,
            datePickerProps = {},
            currentDate,
            ...rest
        } = this.props;

        const showTimeSelect = type === FIELD_DATE_TYPE.dateTime || type === FIELD_DATE_TYPE.time;
        const showTimeSelectOnly = type === FIELD_DATE_TYPE.time;
        const dateFormat = getDateTimeFormat(type, dateFieldsOrder, magentoTimeFormat);
        const timeFormat = getTimeFormat(magentoTimeFormat);

        const { minDate, maxDate } = getYearRangeAttributes(yearRange);

        return {
            selectedDate: currentDate !== undefined ? currentDate : selectedDate,
            showTimeSelect,
            showTimeSelectOnly,
            dateFormat,
            timeFormat,
            uid,
            placeholder,
            label,
            disabled,
            rangeDate,
            isClearable,
            datePickerProps,
            ...(withYearRange ? { minDate, maxDate } : {}),
            ...rest,
        };
    }

    onSetDate(date) {
        const { updateSelectedValues } = this.props;

        this.setState({ selectedDate: date }, () => updateSelectedValues(date));
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DatePickerContainer);
