import { CATEGORY_SINGLE_FILTER_OVERLAY } from 'Component/CategorySingleFilterOverlay/CategorySingleFilterOverlay.config';
import { CART_OVERLAY } from 'Component/Header/Header.config';
import { CUSTOMER_ACCOUNT_OVERLAY_KEY } from 'Component/MyAccountOverlay/MyAccountOverlay.config';
import { PopupSuspense as SourcePopupSuspense } from 'SourceComponent/PopupSuspense/PopupSuspense.component';

import { HEADER_HELP_OVERLAY } from '../HeaderHelp/HeaderHelp.config';

import '../HeaderHelp/HeaderHelp.style';
import '../CategorySingleFilterOverlay/CategorySingleFilterOverlay.style';

/** @namespace SwiatKsiazkiBasic/Component/PopupSuspense/Component */
export class PopupSuspense extends SourcePopupSuspense {
    styleMap = {
        [CART_OVERLAY]: 'CartOverlay',
        [CUSTOMER_ACCOUNT_OVERLAY_KEY]: 'MyAccountOverlay',
        [HEADER_HELP_OVERLAY]: 'HeaderHelp',
        [CATEGORY_SINGLE_FILTER_OVERLAY]: 'CategorySingleFilterOverlay',
    };
}

export default PopupSuspense;
