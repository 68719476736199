const FieldContainer_containerProps = (args, callback, instance) => {
    const { hidden } = instance.props;
    return {
        ...callback(args),
        hidden,
    };
};

export default {
    'Component/Field/Container': {
        'member-function': {
            containerProps: FieldContainer_containerProps,
        },
    },
};
