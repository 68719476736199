import lazy from '@loadable/component';
import { Suspense } from 'react';

import { CATEGORY_SINGLE_FILTER_OVERLAY } from 'Component/CategorySingleFilterOverlay/CategorySingleFilterOverlay.config';
import { CATEGORY_TREE_OVERLAY } from 'Component/CategoryTreeOverlay/CategoryTreeOverlay.config';
import { HEADER_HELP_OVERLAY } from 'Component/HeaderHelp/HeaderHelp.config';
import Icon from 'Component/Icon';
import Link from 'Component/Link';
import Logo from 'Component/Logo';
import Menu from 'Component/Menu';
import OfflineNotice from 'Component/OfflineNotice';
import PopupSuspense from 'Component/PopupSuspense';
import { REVIEW_POPUP_ID } from 'Component/ProductReviews/ProductReviews.config';
import SearchField from 'Component/SearchField';
import { DELIVERY_POPUP_ID } from 'Route/ProductPage/ProductPage.config';
import { CartOverlay, Header as SourceHeader } from 'SourceComponent/Header/Header.component';
import { isCrawler, isSSR } from 'Util/Browser';
import { decodeString } from 'Util/Common';
import CSS from 'Util/CSS';
import history from 'Util/History';
import media from 'Util/Media';
import { LOGO_MEDIA } from 'Util/Media/Media';

import {
    CART_OVERLAY,
    CATEGORY,
    CUSTOMER_ACCOUNT,
    CUSTOMER_ACCOUNT_MENU,
    CUSTOMER_ACCOUNT_PAGE,
    CUSTOMER_SUB_ACCOUNT,
    CUSTOMER_WISHLIST,
    FILTER,
    MENU,
    MENU_SUBCATEGORY,
    SEARCH,
} from './Header.config';

import './Header.override.style';

export { CartOverlay };

export const MyAccountOverlay = lazy(() =>
    import(/* webpackMode: "lazy", webpackChunkName: "overlay" */ 'Component/MyAccountOverlay')
);

export const HeaderHelp = lazy(() =>
    import(/* webpackMode: "lazy", webpackChunkName: "overlay" */ 'Component/HeaderHelp')
);

/** @namespace SwiatKsiazkiBasic/Component/Header/Component */
export class Header extends SourceHeader {
    stateMap = {
        ...this.stateMap,
        [CUSTOMER_WISHLIST]: {
            share: false,
            title: true,
        },
        [CUSTOMER_ACCOUNT_MENU]: {
            back: false,
            title: true,
            search: false,
        },
        [CUSTOMER_ACCOUNT_PAGE]: {
            back: true,
            title: true,
            search: false,
        },
        [MENU_SUBCATEGORY]: {
            back: true,
            title: true,
            search: false,
            close: true,
        },
        [CATEGORY]: {
            back: true,
            title: true,
            search: true,
            logo: false,
        },
        [FILTER]: {
            back: false,
            title: true,
            search: false,
            close: true,
            logo: false,
        },
        [CATEGORY_SINGLE_FILTER_OVERLAY]: {
            back: true,
            title: true,
            search: false,
            close: false,
            logo: false,
        },
        [CATEGORY_TREE_OVERLAY]: {
            back: true,
            title: true,
            search: false,
            close: false,
            logo: false,
        },
    };

    renderCloseButton(isVisible = false) {
        const {
            activeOverlay,
            onCloseButtonClick,
            device: { isMobile },
        } = this.props;

        if (!isMobile || [DELIVERY_POPUP_ID, REVIEW_POPUP_ID].includes(activeOverlay)) {
            return null;
        }

        return (
            <button
                key="close"
                block="Header"
                elem="Button"
                mods={{ type: 'close', isVisible }}
                onClick={onCloseButtonClick}
                aria-label="Close"
                aria-hidden={!isVisible}
                tabIndex={isVisible ? 0 : -1}
            >
                <Icon name="close" width="16" height="16" />
            </button>
        );
    }

    renderAccountOverlay() {
        const { isCheckout, showMyAccountLogin, onSignIn, onMyAccountOutsideClick } = this.props;

        // This is here to prevent the popup-suspense from rendering
        if (!showMyAccountLogin) {
            return null;
        }

        return (
            <Suspense fallback={this.renderAccountOverlayFallback()}>
                <MyAccountOverlay
                    onSignIn={onSignIn}
                    isCheckout={isCheckout}
                    onMyAccountOutsideClick={onMyAccountOutsideClick}
                />
            </Suspense>
        );
    }

    renderHelpButton() {
        const { device, onHelpButtonHover } = this.props;

        if (device.isMobile) {
            return null;
        }

        return (
            <button
                block="Header"
                elem="HelpWrapper"
                tabIndex="0"
                onMouseOver={onHelpButtonHover}
                onFocus={onHelpButtonHover}
                aria-label={__('Help')}
            >
                <Icon name="headphones_2" width="26" height="27" fill="#000000" />
                <span>{__('Help')}</span>
            </button>
        );
    }

    renderHelpOverlayFallback() {
        return <PopupSuspense actualOverlayKey={HEADER_HELP_OVERLAY} />;
    }

    renderHelpOverlay() {
        const {
            device: { isMobile },
            shouldRenderHelpOverlay,
        } = this.props;

        if (isMobile || !shouldRenderHelpOverlay) {
            return null;
        }

        return (
            <Suspense fallback={this.renderHelpOverlayFallback()}>
                <HeaderHelp />
            </Suspense>
        );
    }

    renderHelp() {
        const { hideHelpOverlay } = this.props;

        return (
            <div onMouseLeave={hideHelpOverlay} key="headerhelp">
                <div>
                    {this.renderHelpButton()}
                    {this.renderHelpOverlay()}
                </div>
            </div>
        );
    }

    renderAccountButton() {
        const { onMyAccountButtonClick, device, customer } = this.props;
        const { firstname } = customer || {};

        if (device.isMobile) {
            return null;
        }

        return (
            <button
                block="Header"
                elem="MyAccountWrapper"
                tabIndex="0"
                onClick={onMyAccountButtonClick}
                aria-label={__('Open my account')}
                id="myAccount"
                key="myAccount"
            >
                {firstname ? (
                    <Icon name="user_logged_in" width="24" height="28px" />
                ) : (
                    <Icon name="user" width="24" height="28px" />
                )}
                {firstname ? (
                    <span block="Header" elem="MyAccountName">
                        {firstname}
                    </span>
                ) : (
                    <span>{__('Account')}</span>
                )}
            </button>
        );
    }

    renderAccount(isVisible = false) {
        const {
            device: { isMobile },
        } = this.props;

        // on mobile hide button if not in checkout
        if (isMobile) {
            return null;
        }

        return (
            <div key="account" block="Header" elem="MyAccountContainer">
                {this.renderWelcomeMessage()}
                <div aria-label="My account" block="Header" elem="MyAccount">
                    {this.renderAccountButton(isVisible)}
                    {this.renderAccountOverlay()}
                </div>
            </div>
        );
    }

    renderWelcomeMessage() {
        return null;
    }

    renderTitle() {
        const {
            device: { isMobile, isTablet },
            navigationState: { title, titleIconBefore = null, url = {} },
        } = this.props;

        if (!isMobile && !isTablet) {
            return null;
        }

        if (Object.keys(url).length) {
            return (
                <h1 key="title" block="Header" elem="Title" mods={{ isVisible: true }}>
                    <Link to={url}>
                        {titleIconBefore && (
                            <img src={`${window.location.origin}/media/${titleIconBefore}`} alt={title} />
                        )}
                        {title ? <span>{decodeString(title.replace(/\+/g, ' '))}</span> : <span>{title}</span>}
                    </Link>
                </h1>
            );
        }

        return (
            <h1 key="title" block="Header" elem="Title" mods={{ isVisible: true }}>
                {titleIconBefore && <img src={`${window.location.origin}/media/${titleIconBefore}`} alt={title} />}
                {title ? <span>{decodeString(title.replace(/\+/g, ' '))}</span> : <span>{title}</span>}
            </h1>
        );
    }

    renderBackButton(isVisible = false) {
        const {
            onBackButtonClick,
            device: { isMobile, isTablet },
        } = this.props;

        if (!isMobile && !isTablet) {
            return null;
        }

        return (
            <button
                key="back"
                block="Header"
                elem="Button"
                mods={{ type: 'back', isVisible }}
                onClick={onBackButtonClick}
                aria-label={__('Go back')}
                aria-hidden={!isVisible}
                tabIndex={isVisible ? 0 : -1}
            >
                <Icon name="arrow_left" width="20" height="12" />
            </button>
        );
    }

    renderSearchField(isVisible = false) {
        const {
            searchCriteria,
            onSearchOutsideClick,
            onSearchBarFocus,
            onSearchBarChange,
            onClearSearchButtonClick,
            navigationState: { name },
            isCheckout,
            hideActiveOverlay,
            isOnMenu,
        } = this.props;

        if (isCheckout) {
            return null;
        }

        return (
            <SearchField
                key="search"
                searchCriteria={searchCriteria}
                onSearchOutsideClick={onSearchOutsideClick}
                onSearchBarFocus={onSearchBarFocus}
                onSearchBarChange={onSearchBarChange}
                onClearSearchButtonClick={onClearSearchButtonClick}
                isVisible={isVisible}
                isActive={name === SEARCH}
                isOnMenu={isOnMenu}
                hideActiveOverlay={hideActiveOverlay}
            />
        );
    }

    renderLogoImage() {
        const { header_logo_src, logo_alt, logo_height, logo_width } = this.props;

        const logoSrc = header_logo_src ? media(header_logo_src, LOGO_MEDIA) : null;

        CSS.setVariable(this.logoRef, 'header-logo-height', `${logo_height}px`);
        CSS.setVariable(this.logoRef, 'header-logo-width', `${logo_width}px`);

        return <Logo src={logoSrc} alt={logo_alt} height={logo_height} width={logo_width} title={logo_alt} />;
    }

    renderLogo(isVisible = false) {
        const { isLoading } = this.props;

        if (isLoading) {
            return null;
        }

        const {
            location: { pathname },
        } = history;

        const isShownOrWhileSearch = isVisible || pathname === '/';
        return (
            <Link
                to="/"
                aria-label={__('Go to homepage by clicking on Świat Książki logo')}
                aria-hidden={!isShownOrWhileSearch}
                tabIndex={isShownOrWhileSearch ? 0 : -1}
                block="Header"
                elem="LogoWrapper"
                mods={{ isVisible: isShownOrWhileSearch }}
                key="logo"
            >
                {this.renderLogoImage()}
            </Link>
        );
    }

    renderTopMenu() {
        return null;
    }

    renderWishlistCount() {
        const { wishlistItemsCount } = this.props;

        if (!wishlistItemsCount || wishlistItemsCount === 0) {
            return null;
        }

        return (
            <span aria-label={__('Wishlist items count')} block="Header" elem="WishlistItemCount">
                {wishlistItemsCount}
            </span>
        );
    }

    renderWishlistPageButton() {
        const { device: { isMobile } = {}, isCheckout, onWishlistButtonOnClick } = this.props;

        if (isCheckout || isMobile) {
            return null;
        }

        return (
            <div block="Header" elem="WishlistButtonWrapper" key="wishlist">
                <button
                    to="wishlist"
                    block="Header"
                    elem="Button"
                    mods={{ type: 'wishlist' }}
                    aria-label={__('Wishlist Page')}
                    onClick={onWishlistButtonOnClick}
                >
                    <Icon name="heart" width="28" height="25" />
                    {this.renderWishlistCount()}
                    <span>{__('Wish List')}</span>
                </button>
            </div>
        );
    }

    renderDesktopIcons() {
        return (
            <div block="Header" elem="IconsWrapper" key="IconsWrapper">
                {this.renderHelp()}
                {this.renderWishlistPageButton()}
                {this.renderAccount()}
                {this.renderMinicart()}
            </div>
        );
    }

    renderMinicartButton() {
        const { onMinicartButtonClick, onMinicartButtonHover } = this.props;

        return (
            <button
                block="Header"
                elem="MinicartButtonWrapper"
                tabIndex="0"
                onClick={onMinicartButtonClick}
                onMouseOver={onMinicartButtonHover}
                onFocus={onMinicartButtonHover}
                aria-label={__('Cart')}
            >
                <Icon name="cart" width="30" height="27" />
                {this.renderMinicartItemsQty()}
                <span>{__('Cart')}</span>
            </button>
        );
    }

    renderMinicart(isVisible = false) {
        const {
            isCheckout,
            onMinicartOutsideClick,
            device: { isMobile },
        } = this.props;

        if (isMobile || isCheckout) {
            return null;
        }

        return (
            <div onMouseLeave={onMinicartOutsideClick} key="minicart">
                <div block="Header" elem="Button" mods={{ isVisible, type: 'minicart' }}>
                    {this.renderMinicartButton()}
                    {this.renderMinicartOverlay()}
                </div>
            </div>
        );
    }

    renderMenu() {
        const {
            isCheckout,
            isGiftCardOrderSuccess,
            device: { isMobile, isTablet },
        } = this.props;

        if (isMobile || isTablet || isCheckout || isGiftCardOrderSuccess) {
            return null;
        }

        return <Menu />;
    }

    renderNavigationState() {
        const {
            navigationState: { name, hiddenElements = [] },
            device: { isMobile, isTablet },
        } = this.props;
        const source = this.stateMap[name] ? this.stateMap[name] : this.stateMap[this.defaultStateName];

        return Object.entries(this.renderMap).map(([key, renderFunction]) => {
            if (key in source && (isMobile || isTablet)) {
                if (source[key] && !hiddenElements.includes(key)) {
                    return renderFunction(source[key] && !hiddenElements.includes(key), key);
                }

                return null;
            }

            return renderFunction(source[key] && !hiddenElements.includes(key), key);
        });
    }

    render() {
        const { stateMap } = this;
        const {
            navigationState: { name, isHiddenOnMobile = false },
            isCheckout,
            isGiftCardOrderSuccess,
            device: { isMobile, isTablet },
            closeMobileAccountForm,
        } = this.props;

        if (!isMobile) {
            // hide edit button on desktop
            stateMap[CUSTOMER_WISHLIST].edit = false;
            stateMap[CUSTOMER_WISHLIST].share = false;
            stateMap[CART_OVERLAY].edit = false;
        }

        const isMobileAccountForm =
            (isMobile || isTablet) && (name === CUSTOMER_ACCOUNT || name === CUSTOMER_SUB_ACCOUNT);

        return (
            <section
                block="Header"
                elem="Wrapper"
                mods={{
                    isPrerendered: isSSR() || isCrawler(),
                    isCheckout: isCheckout || isGiftCardOrderSuccess,
                    isSearchField: name === MENU || name === SEARCH,
                }}
            >
                <header
                    block="Header"
                    mods={{ name, isHiddenOnMobile, isCheckout }}
                    mix={{ block: 'FixedElement', elem: 'Top' }}
                    ref={this.logoRef}
                >
                    {this.renderTopMenu()}
                    <div block="Header" elem="NavWrapper">
                        <nav block="Header" elem="Nav">
                            {this.renderNavigationState()}
                            {isMobileAccountForm && (
                                <button block="Header" elem="NavClose" onClick={closeMobileAccountForm}>
                                    <Icon name="close" width="16" height="16" />
                                </button>
                            )}
                        </nav>
                    </div>
                    {this.renderMenu()}
                </header>
                <OfflineNotice />
            </section>
        );
    }
}

export default Header;
