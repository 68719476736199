export const appendComponentWillUnmount = (args, callback, ctx) => {
    if (typeof callback === 'function') {
        callback(...args);
    }

    ctx.hidePopUp();
};

export default {
    'Component/Popup/Component': {
        'member-function': {
            componentWillUnmount: appendComponentWillUnmount,
        },
    },
};
