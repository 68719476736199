import { Suspense } from 'react';

import ClickOutside from 'Component/ClickOutside';
import Icon from 'Component/Icon';
import { SearchField as SourceSearchField, SearchOverlay } from 'SourceComponent/SearchField/SearchField.component';
import { scrollToTop } from 'Util/Browser';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

import './SearchField.override.style';

export { SearchOverlay };

/** @namespace SwiatKsiazkiBasic/Component/SearchField/Component */
export class SearchField extends SourceSearchField {
    __construct(props) {
        super.__construct(props);
        this.openSearch = this.openSearch.bind(this);
        this.closeSearch = this.closeSearch.bind(this);
        this.onSearch = this.onSearch.bind(this);
    }

    onSearchEnterPress(e) {
        if (e.key === 'Enter') {
            this.onSearch();
        }
    }

    onSearch() {
        const { searchCriteria, hideActiveOverlay, onSearchBarChange } = this.props;
        const search = encodeURIComponent(searchCriteria.trim().replace(/%/g, '%25'));
        const trimmedSearch = searchCriteria.trim();

        if (trimmedSearch !== '') {
            history.push(appendWithStoreCode(`/search/${search}`));
            hideActiveOverlay();
            onSearchBarChange({ target: { value: '' } });
            this.searchBarRef.current.blur();
            this.closeSearch();
            scrollToTop();
        }
    }

    renderOverlayFallback() {
        return null;
    }

    renderSearch() {
        const { searchCriteria, onSearchBarFocus, isActive, device, isOnMenu } = this.props;

        return (
            <div block="SearchField" elem="SearchInnerWrapper">
                <input
                    id="search-field"
                    ref={this.searchBarRef}
                    block="SearchField"
                    elem="Input"
                    onFocus={onSearchBarFocus}
                    onChange={this.handleChange}
                    onKeyDown={this.onSearchEnterPress}
                    value={searchCriteria}
                    mods={{ isActive, isOnMenu }}
                    placeholder={__('Enter title, product, EAN, author...')}
                    autoComplete="off"
                    aria-label={__('Search')}
                />
                {this.renderSearchIcon()}
                <Suspense fallback={this.renderOverlayFallback()}>
                    <SearchOverlay
                        isHideOverlay={!device.isMobile && !device.isTablet}
                        clearSearch={this.clearSearch}
                        searchCriteria={searchCriteria}
                        isOnMenu={isOnMenu}
                    />
                </Suspense>
            </div>
        );
    }

    renderSearchIcon() {
        const { isOnMenu } = this.props;

        return (
            <div
                block="SearchField"
                elem="SearchIcon"
                role="button"
                tabIndex="0"
                onClick={isOnMenu ? null : this.openSearch}
                aria-label={__('Search')}
            >
                <Icon onClick={this.onSearch} name="magnifier" width="18" height="18" fill="#ffffff" />
            </div>
        );
    }

    render() {
        const { isVisible, isActive, isOnMenu } = this.props;

        return (
            <div block="SearchField" mods={{ isVisible, isActive, isOnMenu }}>
                <ClickOutside onClick={this.closeSearch}>
                    <div block="SearchField" elem="Wrapper">
                        {this.renderSearch()}
                    </div>
                </ClickOutside>
            </div>
        );
    }
}

export default SearchField;
