import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import BannerWidget from './BannerWidget.component';

/** @namespace SwiatKsiazkiBasic/Component/BannerWidget/Container */
export class BannerWidgetContainer extends PureComponent {
    static propTypes = {
        image: PropTypes.string,
        url: PropTypes.string,
        title: PropTypes.string,
    };

    containerProps() {
        const { backgroundColor, blank, image, 'link-title': linkTitle, nofollow, title, type, url } = this.props;

        return {
            image,
            url,
            title,
            backgroundColor,
            blank,
            linkTitle,
            nofollow,
            type,
        };
    }

    render() {
        return <BannerWidget {...this.containerProps()} />;
    }
}

export default BannerWidgetContainer;
