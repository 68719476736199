import Field from 'Util/Query/Field';

/** @namespace SwiatKsiazkiBasic/Query/Edrone/Query */
export class EdroneQuery {
    getProductQuery(productId) {
        return new Field('getEdroneProductData')
            .addArgument('productId', 'Int!', productId)
            .addFieldList(this._getProductFields());
    }

    getOrderQuery(incrementId, guestCartId) {
        const field = new Field('sendOrderToEdrone')
            .addArgument('incrementId', 'String!', incrementId)
            .addFieldList(this._getOrderFields());

        if (guestCartId) {
            field.addArgument('guestCartId', 'String', guestCartId);
        }

        return field;
    }

    _getProductFields() {
        return ['sku', 'id', 'title', 'product_url', 'image', 'product_category_names', 'product_category_ids'];
    }

    _getOrderFields() {
        return [
            this._getCustomerOrderField(),
            this._getOrderField(),
            this._getRelatedOrderField(),
            this._getDigitalRelatedOrderField(),
        ];
    }

    _getCustomerOrderField() {
        return new Field('customer').addFieldList(this._getCustomerOrderFields());
    }

    _getCustomerOrderFields() {
        return ['first_name', 'last_name', 'email', 'country', 'city', 'phone'];
    }

    _getOrderField() {
        return new Field('order').addFieldList(this._getOrderDataFields());
    }

    _getRelatedOrderField() {
        return new Field('related_order').addFieldList(this._getOrderDataFields());
    }

    _getDigitalRelatedOrderField() {
        return new Field('digital_related_order').addFieldList(this._getOrderDataFields());
    }

    _getOrderDataFields() {
        return [
            'sku',
            'id',
            'title',
            'image',
            'order_id',
            'order_payment_value',
            'base_payment_value',
            'base_currency',
            'order_currency',
            'coupon',
            'product_category_names',
            'product_category_ids',
            'product_urls',
            'product_counts',
        ];
    }
}

export default new EdroneQuery();
