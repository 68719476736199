import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Button from 'Component/Button';
import Icon from 'Component/Icon';
import { decodeString } from 'Util/Common';
import { getHrefAttributes } from 'Util/MagezoneWidget';

import './ButtonWidget.style';

/** @namespace SwiatKsiazkiBasic/Component/ButtonWidget/Component */
export class ButtonWidget extends PureComponent {
    static propTypes = {
        title: PropTypes.string,
        url: PropTypes.string,
        isOverlay: PropTypes.bool,
        isCustom: PropTypes.bool,
    };

    render() {
        const { title, url, isOverlay, isCustom, blank, nofollow, className } = this.props;

        if (!title) {
            return null;
        }

        return (
            <Button
                asLink
                to={decodeString(url)}
                block={isOverlay || isCustom ? '' : undefined}
                mix={{ block: 'ButtonWidget', mods: { isOverlay: !!isOverlay, isCustom: !!isCustom } }}
                {...(isCustom ? { className } : {})}
                {...getHrefAttributes({ blank, nofollow })}
            >
                {isCustom ? (
                    <span className="mgz-link mgz-btn">{title}</span>
                ) : (
                    <>
                        <span>{title}</span>
                        <Icon name="small_arrow_right" width="5" height="9" />
                    </>
                )}
            </Button>
        );
    }
}

export default ButtonWidget;
