import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
} from 'SourceComponent/Breadcrumbs/Breadcrumbs.container';
import BreadcrumbsReducer from 'SourceStore/Breadcrumbs/Breadcrumbs.reducer';
import { withReducers } from 'SourceUtil/DynamicReducer';

import Breadcrumbs from './Breadcrumbs.component';

export { mapDispatchToProps };

/** @namespace SwiatKsiazkiBasic/Component/Breadcrumbs/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    isTablet: state.ConfigReducer.device.isTablet,
    isMobile: state.ConfigReducer.device.isMobile,
});

export default withReducers({
    BreadcrumbsReducer,
})(connect(mapStateToProps, mapDispatchToProps)(Breadcrumbs));
