import lazy from '@loadable/component';
import React, { PureComponent } from 'react';

import BannerWidget from 'Component/BannerWidget';
import BookstoreLocations from 'Component/BookstoreLocations';
import ButtonWidget from 'Component/ButtonWidget';
import CategoriesGridImagesWithSingleImage from 'Component/CategoriesGridImagesWithSingleImage';
import CmsSlider from 'Component/CmsSlider';
import CmsStaticSidebarMenu from 'Component/CmsStaticSidebarMenu';
import CmsTabChecker from 'Component/CmsTabChecker';
import GiftCardsActivateCardForm from 'Component/GiftCardsActivateCardForm';
import GiftCardsBuyCardForm from 'Component/GiftCardsBuyCardForm';
import GiftCardsCheckValidityForm from 'Component/GiftCardsCheckValidityForm';
import GridImages from 'Component/GridImages';
import HeadingWidget from 'Component/HeadingWidget';
import { CURRENT_FORM_SHOW } from 'Component/Html/Html.config';
import ImageCarousel from 'Component/ImageCarousel';
import LazyLoad from 'Component/LazyLoad';
import MapWidget from 'Component/MapWidget';
import NewOpeningsWidget from 'Component/NewOpeningsWidget';
import { PRODUCT_SLIDER_WITH_BANNER } from 'Component/ProductSlider/ProductSlider.config';
import RulesCtaWidget from 'Component/RulesCtaWidget';
import SeparatorWidget from 'Component/SeparatorWidget';
import SingleProductWidget from 'Component/SingleProductWidget';
import { parseJSON } from 'Util/Data';
import {
    checkForCategoriesGridImagesWithSingleImage,
    checkForProductListsWithSliderWidgetItems,
    checkForTabs,
} from 'Util/MagezoneWidget';

export const MultipleProductListWidget = lazy(() => import('Component/MultipleProductListWidget'));
export const MultipleSingleProductWidget = lazy(() => import('Component/MultipleSingleProductWidget'));
export const ProductListMagezonWidget = lazy(() => import('Component/ProductListMagezonWidget'));
export const ProductListsWithSliderWidget = lazy(() => import('Component/ProductListsWithSliderWidget'));
export const ProductSliderTabs = lazy(() => import('Component/ProductSliderTabs'));
export const ProductSliderWidget = lazy(() => import('Component/ProductSliderWidget'));

/** @namespace SwiatKsiazkiBasic/Component/MagezonRenderer/Renderer/HomePageRenderer/Component */
export class HomePageRenderer extends PureComponent {
    widgetMap = {
        giftCardsBuyCardForm: (props) => <GiftCardsBuyCardForm {...props} />,
        giftCardsCheckValidityForm: (props) => <GiftCardsCheckValidityForm {...props} />,
        giftCardsActivateCardForm: (props) => <GiftCardsActivateCardForm {...props} />,
        cmsStaticSidebarMenu: (props) => <CmsStaticSidebarMenu {...props} />,
        rulesCtaWidget: (props) => <RulesCtaWidget {...props} />,
        newOpeningsWidget: (props) => <NewOpeningsWidget {...props} />,
        cmsTabChecker: (props) => <CmsTabChecker {...props} />,
        productSliderTabs: (props) => (
            <LazyLoad>
                <ProductSliderTabs {...props} />
            </LazyLoad>
        ),
        productSlider: (props) => (
            <LazyLoad>
                <ProductSliderWidget {...props} />
            </LazyLoad>
        ),
        headingWidget: (props) => <HeadingWidget {...props} />,
        banner: (props) => <BannerWidget {...props} />,
        singleProduct: (props) => (
            <LazyLoad>
                <SingleProductWidget {...props} />
            </LazyLoad>
        ),
        multipleSingleProduct: (props) => (
            <LazyLoad>
                <MultipleSingleProductWidget {...props} />
            </LazyLoad>
        ),
        productList: (props) => (
            <LazyLoad>
                <ProductListMagezonWidget {...props} />
            </LazyLoad>
        ),
        multipleProductListWidget: (props) => (
            <LazyLoad>
                <MultipleProductListWidget {...props} />
            </LazyLoad>
        ),
        productListsWithSliderWidget: (props) => (
            <LazyLoad>
                <ProductListsWithSliderWidget {...props} />
            </LazyLoad>
        ),
        buttonWidget: (props) => <ButtonWidget {...props} />,
        categoriesGridImagesWithSingleImage: (props) => <CategoriesGridImagesWithSingleImage {...props} />,
        mapWidget: (props) => <MapWidget {...props} />,
        bookstoreLocations: (props) => <BookstoreLocations {...props} />,
        cmsStaticRulesMenu: (props) => <CmsStaticSidebarMenu {...props} />,
        separatorWidget: (props) => <SeparatorWidget {...props} />,
        CmsSlider: (props) => <CmsSlider {...props} />,
        gridImages: (props) => <GridImages {...props} />,
        imageCarouselWidget: (props) => <ImageCarousel {...props} />,
    };

    renderWidget() {
        const { attribs, attributesToProps, domToReact, children } = this.props;

        // eslint-disable-next-line fp/no-let
        let currentWidgetName = '';
        // eslint-disable-next-line fp/no-let
        let currentWidgetProps = {};

        children.forEach((item, index) => {
            const { attribs = {} } = item;

            if (item.attribs && `${item.attribs?.id}`.split('-').includes('map')) {
                currentWidgetName = 'mapWidget';
                currentWidgetProps.script = children[1]?.children[0]?.data;
            }

            if (item.attribs && item.attribs['data-form-component']) {
                currentWidgetName = CURRENT_FORM_SHOW[item.attribs['data-form-component']].htmlName;
            }

            if (item.attribs && item.attribs['data-cms-rules-submenu']) {
                currentWidgetName = 'cmsStaticRulesMenu';
                currentWidgetProps.links = item?.children[1].children?.filter(({ name }) => name === 'li');
            }

            if (item.attribs && item.attribs['data-cms-submenu']) {
                currentWidgetName = 'cmsStaticSidebarMenu';
                currentWidgetProps.title = item.children[0]?.children[0]?.data;
                currentWidgetProps.links = item.children[1]?.children.filter(({ name }) => name === 'li');
            }

            if (item.attribs && item.attribs?.type === 'ImageCarousel') {
                currentWidgetName = 'imageCarouselWidget';
                currentWidgetProps = attributesToProps(item.attribs);
            }

            if (item.attribs && `${item.attribs?.class}`.includes('mgz-cta')) {
                currentWidgetName = 'rulesCtaWidget';
                currentWidgetProps.items = item?.children[0]?.children[0]?.children;
            }

            if (attribs && attribs.class === 'mgz-single-image-wrapper') {
                const { class: nextWidgetClassName } = item?.next?.attribs ?? {};

                if (nextWidgetClassName === 'mgz-single-image-wrapper') {
                    currentWidgetName = 'gridImages';

                    currentWidgetProps.images = children.map((item) => {
                        const { attribs: { alt, src } = {} } = item?.children[0].children[0] ?? {};

                        if (alt && src) return { alt, src };

                        return null;
                    });
                }
            }

            if (item.attribs && item.attribs?.type === 'Button') {
                if (item?.next?.attribs?.class === 'mgz-single-image-wrapper') {
                    return null;
                }

                currentWidgetName = 'buttonWidget';
                currentWidgetProps = attributesToProps(item.attribs);
            }

            if (item.attribs && item.attribs?.type === 'Tabs') {
                currentWidgetName = 'cmsTabChecker';
                currentWidgetProps.title = item.attribs?.title;
                currentWidgetProps.isHomepage = true;
                currentWidgetProps.tabs = checkForTabs(item, attributesToProps);
            }

            if (item.attribs && item.attribs?.type === 'ProductSlider') {
                currentWidgetName = 'productSlider';
                currentWidgetProps.productsSkuArray = parseJSON(item.attribs?.items, []);
                currentWidgetProps.max_items = item.attribs?.max_items;
                currentWidgetProps.classVariant = item.attribs?.class;
                currentWidgetProps.order = item.attribs?.order;
                currentWidgetProps.direction = item.attribs?.direction;
                currentWidgetProps.categoryId = item.attribs?.category_id;
                currentWidgetProps.double_slider = item.attribs?.double_slider === '1';
            }

            if (item.attribs && item.attribs?.type === 'Banner') {
                currentWidgetName = 'banner';
                currentWidgetProps = attributesToProps(item.attribs);

                if (children[1].attribs.type === 'ProductSlider') {
                    currentWidgetProps.isBanerVariant = true;
                    currentWidgetProps.settings = PRODUCT_SLIDER_WITH_BANNER;

                    return null;
                }
            }

            if (item.attribs && item.attribs?.type === 'Heading') {
                currentWidgetName = 'headingWidget';
                currentWidgetProps.text = item.attribs?.text;
                currentWidgetProps.url = item.attribs?.url;
            }

            if (item.attribs && item.attribs['data-bookstore-locations']) {
                currentWidgetName = 'bookstoreLocations';
            }

            if (item.attribs && item.attribs?.class?.includes('mgz-element-separator')) {
                currentWidgetName = 'separatorWidget';
            }

            if (item.attribs && item.attribs?.class?.includes('mgz-carousel')) {
                currentWidgetProps.slides = [];
                currentWidgetName = 'CmsSlider';
                item?.children?.map(({ attribs, children }) =>
                    currentWidgetProps?.slides?.push({ image: attribs['data-src'], url: children[0]?.attribs?.href })
                );
            }

            // if we have more singleproduct widgets in row use separate component
            if (item.attribs && item.attribs?.type === 'SingleProduct') {
                if (children[index - 1]?.attribs?.type === 'SingleProduct') {
                    currentWidgetName = 'multipleSingleProduct';
                    currentWidgetProps.items = [];

                    children.forEach((childItem) => {
                        if (childItem.attribs && childItem.attribs?.type === 'SingleProduct') {
                            const {
                                title,
                                class: classVariant,
                                background_color,
                                description,
                                sku,
                                product_options,
                            } = childItem?.attribs ?? {};

                            currentWidgetProps.items.push({
                                title,
                                classVariant,
                                backgroundColor: background_color,
                                subtitle: description,
                                productOptions: product_options,
                                sku,
                            });
                        }
                    });
                } else {
                    currentWidgetName = 'singleProduct';
                    currentWidgetProps.title = item.attribs?.title;
                    currentWidgetProps.sku = item.attribs?.sku;
                    currentWidgetProps.subtitle = item.attribs?.description;
                    currentWidgetProps.backgroundColor = item.attribs?.background_color;
                    currentWidgetProps.classVariant = item.attribs?.class;
                    currentWidgetProps.productOptions = item.attribs?.product_options;
                }
            }

            if (item.attribs && item.attribs?.type === 'Heading') {
                if (children[index + 1]?.attribs?.type === 'ProductList') {
                    currentWidgetName = 'multipleProductListWidget';
                    currentWidgetProps.items = [];

                    children.forEach((childItem, index) => {
                        if (childItem.attribs && childItem.attribs?.type === 'Heading') {
                            currentWidgetProps.items.push({
                                title: childItem.attribs?.text,
                                url: childItem.attribs?.url,
                                order: children[index + 1]?.attribs?.order,
                                direction: children[index + 1]?.attribs?.direction,
                                categoryId: children[index + 1]?.attribs?.category_id,
                                productsSkuArray: parseJSON(children[index + 1]?.attribs?.items, []),
                            });
                        }
                    });
                }

                if (children[index + 1]?.attribs?.type === 'ProductSlider') {
                    currentWidgetName = 'ProductSlider';
                    currentWidgetProps.productsSkuArray = parseJSON(children[index + 1].attribs?.items, []);
                    currentWidgetProps.max_items = children[index + 1].attribs?.max_items;
                    currentWidgetProps.title = item.attribs?.text;
                    currentWidgetProps.classVariant = item.attribs?.class;
                    currentWidgetProps.order = item.attribs?.order;
                    currentWidgetProps.direction = item.attribs?.direction;
                    currentWidgetProps.categoryId = item.attribs?.category_id;
                    currentWidgetProps.double_slider = item.attribs?.double_slider === '1';
                }
            }

            if (item.attribs && item.attribs?.type === 'ProductList') {
                if (children[index - 1]?.attribs?.type === 'Heading') {
                    return null;
                }

                currentWidgetName = 'productList';
                currentWidgetProps.order = item.attribs?.order;
                currentWidgetProps.direction = item.attribs?.direction;
                currentWidgetProps.categoryId = item.attribs?.category_id;
                currentWidgetProps.productsSkuArray = parseJSON(item.attribs?.items, []);
            }

            if (item.attribs && item.attribs['data-widget-row']) {
                if (item.children.length === 4 || item.children.length === 5) {
                    const itemChildren = item?.children;
                    if (
                        itemChildren[0]?.attribs?.type === 'Button' &&
                        itemChildren[1]?.attribs?.class === 'mgz-single-image-wrapper' &&
                        itemChildren[2]?.name === 'p' &&
                        (itemChildren[3]?.attribs?.['data-widget-row'] === 'true' ||
                            itemChildren[4]?.attribs?.['data-widget-row'] === 'true')
                    ) {
                        currentWidgetName = 'categoriesGridImagesWithSingleImage';
                        currentWidgetProps = {
                            ...checkForCategoriesGridImagesWithSingleImage(itemChildren),
                        };
                    }
                }

                if (item.children.length === 6 && children[index - 1].children.length === 2) {
                    currentWidgetName = 'productListsWithSliderWidget';
                    currentWidgetProps.heading = children[index - 1].children[0].attribs?.text;
                    currentWidgetProps.headingUrl = children[index - 1].children[0].attribs?.url;
                    currentWidgetProps.headingExtraText = children[index - 1].children[1].children[0]?.data;
                    currentWidgetProps.items = checkForProductListsWithSliderWidgetItems(item);
                }
            }
        });

        if (!currentWidgetName || !this.widgetMap[currentWidgetName]) {
            return <div {...attributesToProps(attribs)}>{domToReact(children)}</div>;
        }

        return this.widgetMap[currentWidgetName](currentWidgetProps);
    }

    render() {
        return this.renderWidget();
    }
}

export default HomePageRenderer;
