/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const UPDATE_SINGLE_POST = 'UPDATE_SINGLE_POST';
export const NO_POST = 'NO_POST';
export const LOAD_POST = 'LOAD_POST';

/** @namespace SwiatKsiazkiBasic/Blog/Store/PostsDetails/Action/updateSinglePost */
export const updateSinglePost = (post) => ({
    type: UPDATE_SINGLE_POST,
    post
});

/** @namespace SwiatKsiazkiBasic/Blog/Store/PostsDetails/Action/postException */
export const postException = (message) => ({
    type: NO_POST,
    message
});

/** @namespace SwiatKsiazkiBasic/Blog/Store/PostsDetails/Action/loadPost */
export const loadPost = () => ({
    type: LOAD_POST
});
