import { PureComponent } from 'react';

import Html from 'Component/Html';
import Link from 'Component/Link';
import { decodeString } from 'Util/Common';

import './HeadingWidget.style';

/** @namespace SwiatKsiazkiBasic/Component/HeadingWidget/Component */
export class HeadingWidget extends PureComponent {
    renderContent() {
        const { url, text, html } = this.props;

        switch (true) {
            case !!url:
                return <Link to={decodeString(url)}>{text}</Link>;
            case !!html:
                return <Html content={html} />;
            default:
                return text;
        }
    }

    render() {
        return <h2 block="HeadingWidget">{this.renderContent()}</h2>;
    }
}

export default HeadingWidget;
