import { Menu as SourceMenu } from 'SourceUtil/Menu/Menu';

/** @namespace SwiatKsiazkiBasic/Util/Menu/getSortedItems */
export const getSortedItems = (unsortedItems) =>
    Array.from(unsortedItems).sort(
        ({ parent_id: PID, position: P }, { parent_id: prevPID, position: prevP }) => PID - prevPID || P - prevP
    );

/** @namespace SwiatKsiazkiBasic/Util/Menu */
export class Menu extends SourceMenu {
    createItem(data, onlyTopCategories) {
        const { parent_id, item_id, category_id } = data;

        if (parent_id === 0) {
            this.menuPositionReference[item_id] = [];
            this.menu[item_id] = this.getMenuData(data);

            if (onlyTopCategories) {
                this.menu[item_id].category_id = category_id;
            }
        } else if (this.menuPositionReference[parent_id] !== undefined) {
            this.menuPositionReference[item_id] = [...this.menuPositionReference[parent_id], parent_id];

            this.setToValue(
                this.menu,
                `${this.menuPositionReference[item_id].join('.children.')}.children.${item_id}`,
                this.getMenuData(data)
            );
        }
    }

    reduce({ items: unsortedItems }, onlyTopCategories = false) {
        this.menu = {};
        this.menuPositionReference = {};

        getSortedItems(unsortedItems).forEach((realMenuItem) => {
            this.createItem(realMenuItem, onlyTopCategories);
        });

        return this.menu;
    }
}

export default new Menu();
