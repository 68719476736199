import React, { PureComponent } from 'react';

import Link from 'Component/Link';
import { PRODUCT_ATTRIBUTE_SET_NAME } from 'Component/ProductAttributeValue/ProductAttributeValue.config';

import './ProductPublisherOrProducer.style.scss';

/** @namespace SwiatKsiazkiBasic/Component/ProductPublisherOrProducer/Component */
export class ProductPublisherOrProducer extends PureComponent {
    dictionaryKey = {
        [PRODUCT_ATTRIBUTE_SET_NAME.BOOK]: 'publishers',
        [PRODUCT_ATTRIBUTE_SET_NAME.EBOOK]: 'publishers',
        [PRODUCT_ATTRIBUTE_SET_NAME.AUDIOBOOK]: 'publishers',
        [PRODUCT_ATTRIBUTE_SET_NAME.MOVIE]: 'producers',
        [PRODUCT_ATTRIBUTE_SET_NAME.MUSIC]: 'producers',
    };

    getProduct() {
        const { product } = this.props;

        return product || {};
    }

    getDictionary() {
        const { attribute_set_name, dictionary } = this.getProduct();
        const key = this.dictionaryKey[attribute_set_name];
        if (!key) return [];
        return dictionary?.[key] ?? [];
    }

    render() {
        const { className } = this.props;

        return (
            <div block="ProductPublisherOrProducer">
                {this.getDictionary().map((publisher, index) => (
                    <>
                        {index > 0 ? ', ' : null}
                        <Link to={publisher?.url} block={className} elem="PublisherOrProducer" key={index}>
                            {publisher?.name}
                        </Link>
                    </>
                ))}
            </div>
        );
    }
}

export default ProductPublisherOrProducer;
