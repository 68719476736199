import Icon from 'Component/Icon';
import { NavigationTabs as SourceNavigationTabs } from 'SourceComponent/NavigationTabs/NavigationTabs.component';

import { WISHLIST_TAB } from './NavigationTabs.config';

import './NavigationTabs.override.style';

/** @namespace SwiatKsiazkiBasic/Component/NavigationTabs/Component */
export class NavigationTabs extends SourceNavigationTabs {
    stateMap = {
        ...this.stateMap,
        [WISHLIST_TAB]: {
            wishlist: true,
        },
    };

    renderMap = {
        home: this.renderHomeButton.bind(this),
        menu: this.renderMenuButton.bind(this),
        minicart: this.renderMinicartButton.bind(this),
        wishlist: this.renderWishlistButton.bind(this),
        account: this.renderAccountButton.bind(this),
    };

    renderWishlistCount() {
        const { wishlistItemsCount } = this.props;

        if (!wishlistItemsCount || wishlistItemsCount === 0) {
            return null;
        }

        return (
            <span aria-label={__('Wishlist items count')} block="NavigationTabs" elem="WishlistItemCount">
                {wishlistItemsCount}
            </span>
        );
    }

    renderMinicartButton(isActive = false) {
        const { onMinicartButtonClick } = this.props;

        return (
            <button
                key="mincart"
                block="NavigationTabs"
                elem="Button"
                mods={{ isMinicart: true, isActive }}
                onClick={onMinicartButtonClick}
                aria-label="Minicart"
            >
                <div block="Header" elem="MinicartWrapper">
                    <div
                        block="Header"
                        elem="Button"
                        mix={{ block: 'NavigationTabs', elem: 'Icon', mods: { isActive } }}
                        mods={{ isVisible: true, type: 'minicart' }}
                    >
                        <Icon name="cart" width="24" height="23" fill={isActive ? '#DD151E' : '#A2A2A2'} />
                    </div>
                    {this.renderMinicartItemsQty()}
                    <span block="NavigationTabs" elem="ButtonText">
                        {__('Cart')}
                    </span>
                </div>
            </button>
        );
    }

    renderWishlistButton(isActive = false) {
        const { onWishlistButtonClick } = this.props;

        return (
            <button
                to="wishlist"
                key="account"
                block="NavigationTabs"
                elem="Button"
                aria-label={__('Go to wishlist')}
                onClick={onWishlistButtonClick}
                mods={{ isActive }}
            >
                <div block="Header" elem="WishlistWrapper">
                    <Icon name="heart" width="22" height="19" fill={isActive ? '#DD151E' : '#A2A2A2'} />
                    {this.renderWishlistCount()}
                    <span block="NavigationTabs" elem="ButtonText">
                        {__('Wish List')}
                    </span>
                </div>
            </button>
        );
    }

    renderHomeButton(isActive = false) {
        const { onHomeButtonClick } = this.props;

        return (
            <button
                key="home"
                block="NavigationTabs"
                elem="Button"
                aria-label="Home"
                onClick={onHomeButtonClick}
                mods={{ isActive }}
            >
                <Icon name="home" width="24" height="22" fill={isActive ? '#DD151E' : '#A2A2A2'} />
                <span block="NavigationTabs" elem="ButtonText">
                    Home
                </span>
            </button>
        );
    }

    renderMenuButton(isActive = false) {
        const { onMenuButtonClick } = this.props;

        return (
            <button
                key="menu"
                block="NavigationTabs"
                elem="Button"
                aria-label={__('Go to menu and search')}
                onClick={onMenuButtonClick}
                mods={{ isActive }}
            >
                <Icon name="search_and_menu" width="38" height="24" fill={isActive ? '#DD151E' : '#A2A2A2'} />
                <span block="NavigationTabs" elem="ButtonText">
                    {__('Menu')}
                </span>
            </button>
        );
    }

    renderAccountButton(isActive = false) {
        const { onMyAccountButtonClick, customer } = this.props;
        const { firstname } = customer || {};

        return (
            <button
                key="myAccount"
                block="NavigationTabs"
                elem="Button"
                mods={{ isActive }}
                onClick={onMyAccountButtonClick}
                aria-label={__('Open my account')}
            >
                {firstname ? (
                    <Icon name="user_logged_in" width="21" height="24" fill={isActive ? '#DD151E' : '#A2A2A2'} />
                ) : (
                    <Icon name="user" width="21" height="24" fill={isActive ? '#DD151E' : '#A2A2A2'} />
                )}
                {firstname ? (
                    <span block="NavigationTabs" elem="ButtonText">
                        {firstname}
                    </span>
                ) : (
                    <span block="NavigationTabs" elem="ButtonText">
                        {__('Account')}
                    </span>
                )}
            </button>
        );
    }

    render() {
        const {
            navigationState: { isHidden },
            device,
        } = this.props;

        if (!device.isMobile && !device.isTablet) {
            return null;
        }

        return (
            <footer
                block="NavigationTabs"
                mods={{ isHidden: !!isHidden }}
                mix={{ block: 'FixedElement', elem: 'Bottom' }}
            >
                <nav block="NavigationTabs" elem="Nav">
                    {this.renderNavigationState()}
                </nav>
            </footer>
        );
    }
}

export default NavigationTabs;
