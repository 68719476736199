import { connect } from 'react-redux';

import { FB_ADD_TO_WISHLIST, PixelObservable } from 'Component/Pixel';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductWishlistButtonContainer as SourceProductWishlistButtonContainer,
    WishlistDispatcher,
} from 'SourceComponent/ProductWishlistButton/ProductWishlistButton.container';
import { isSignedIn } from 'Util/Auth';

/** @namespace SwiatKsiazkiBasic/Component/ProductWishlistButton/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    productsInWishlistArray: state.WishlistReducer.productsInWishlistArray,
});

/** @namespace SwiatKsiazkiBasic/Component/ProductWishlistButton/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    removeProductFromWishlist: (options) =>
        WishlistDispatcher.then(({ default: dispatcher }) => dispatcher.removeItemFromWishlist(dispatch, options)),
});
/** @namespace SwiatKsiazkiBasic/Component/ProductWishlistButton/Container */
export class ProductWishlistButtonContainer extends SourceProductWishlistButtonContainer {
    getWishlistItem(productSku) {
        const { productsInWishlistArray } = this.props;

        if (!productsInWishlistArray) {
            return null;
        }

        const { id, sku } = productsInWishlistArray?.find((product) => product?.sku === productSku) || {};

        return id ? { wishlist: { id }, sku } : undefined;
    }

    async toggleProductInWishlist(add = true) {
        const {
            product,
            magentoProduct,
            magentoProduct: [{ sku }] = [],
            showNotification,
            addProductToWishlist,
            removeProductFromWishlist,
            wishlistId,
        } = this.props;

        const { isWishListToggle } = this.state;

        if (!isSignedIn()) {
            return showNotification('info', __('You must login or register to add items to your wishlist.'));
        }

        this.setWishlistButtonLoading(true);

        if (isWishListToggle) {
            return;
        }

        this.setState({ isWishListToggle: true });
        if (add) {
            await addProductToWishlist({
                items: magentoProduct,
                wishlistId,
            });

            PixelObservable.send(FB_ADD_TO_WISHLIST, product);

            this.setState({ isWishListToggle: false });

            return;
        }

        const wishlistItem = this.getWishlistItem(sku);

        if (!wishlistItem) {
            this.setState({ isWishListToggle: false });

            return;
        }

        const {
            wishlist: { id: itemId },
        } = wishlistItem;

        this.setState({ isWishListToggle: false });

        return removeProductFromWishlist({ item_id: itemId, name: magentoProduct[0]?.name });
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductWishlistButtonContainer);
