import lazy from '@loadable/component';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import CmsBlock from 'Component/CmsBlock';
import { CURRENT_FORM_SHOW } from 'Component/Html/Html.config';

import './CmsElementsTabs.style';

export const RulesCtaWidget = lazy(() =>
    import(
        /* webpackMode: "lazy", webpackChunkName: "widget" */
        'Component/RulesCtaWidget'
    )
);

/** @namespace SwiatKsiazkiBasic/Component/CmsElementsTabs/Component */
export class CmsElementsTabs extends PureComponent {
    static propTypes = {
        cmsElementsTabs: PropTypes.arrayOf(PropTypes.object).isRequired,
        activeTab: PropTypes.number.isRequired,
        handleTabClick: PropTypes.func.isRequired,
    };

    renderChildren() {
        const { children } = this.props;

        return children;
    }

    renderTag(Tag = 'div', content, className = '') {
        return (
            <Tag block="CmsElementsTabs" elem={`ContentElement ${className}`}>
                {content}
            </Tag>
        );
    }

    renderCmsElementsTabs() {
        const { cmsElementsTabs } = this.props;
        const { activeTab, handleTabClick } = this.props;

        return cmsElementsTabs.map(({ name, id }) => {
            const newName = {
                __html: name.split(' ').slice(2).join(' '),
            };

            return (
                <button
                    key={id}
                    // eslint-disable-next-line react/jsx-no-bind
                    onClick={() => handleTabClick(id)}
                    block="CmsElementsTabs"
                    elem="Tab"
                    mods={{ isActive: activeTab === id }}
                    dangerouslySetInnerHTML={newName}
                />
            );
        });
    }

    renderCmsBlock(rows) {
        const [block = {}] = rows;

        if (!block.content) {
            return null;
        }

        if (block.content['data-form-component']) {
            return (
                <div block="CmsElementsTabs" elem="Content">
                    {CURRENT_FORM_SHOW[block.content['data-form-component']].cmsComponent}
                </div>
            );
        }

        return (
            <div block="CmsElementsTabs" elem="Content">
                <CmsBlock identifier={block.content['data-cms-block-id']} />
            </div>
        );
    }

    checkIsObject(obj) {
        return Object.values(obj).every((elem) => typeof elem === 'object');
    }

    checkIsCmsBlock(elem) {
        return elem.length === 1 && elem[0]?.tag === 'cms_block';
    }

    checkIsTextBlock(elem) {
        return elem.length === 1 && Object.values(elem[0]).length > 0;
    }

    reducerRows(row) {
        let reducedRow = {};

        const filteredElements = Object.values(row).filter((elem) => {
            const elementsWithoutContent = ['SeparatorLine'];
            return elem.content || elementsWithoutContent.includes(elem.class);
        });

        filteredElements.forEach((elem, index) => {
            const { elementID } = elem;

            if (reducedRow[elementID]) {
                if (this.checkIsObject(reducedRow[elementID])) {
                    reducedRow = {
                        ...reducedRow,
                        [elementID]: {
                            ...reducedRow[elementID],
                            [index]: elem,
                        },
                    };

                    return;
                }

                reducedRow = {
                    ...reducedRow,
                    [elementID]: {
                        0: reducedRow[elementID],
                        [index]: elem,
                    },
                };

                return;
            }

            reducedRow = {
                ...reducedRow,
                [elementID]: elem,
            };
        });

        return reducedRow;
    }

    generateElementsToRender(reducedRow) {
        const elements = Object.values(reducedRow).map((elemToRender) => {
            const { content = '', tag: Tag = 'div', class: className = '' } = elemToRender;

            if (className.includes('mgz-cta')) {
                return <RulesCtaWidget items={content?.children[0]?.children[0]?.children} />;
            }

            if (this.checkIsObject(elemToRender)) {
                const innerElements = Object.values(elemToRender).map((innerElemToRender) => {
                    const {
                        content: innerContent = '',
                        tag: InnerTag = 'div',
                        class: innerClassName = '',
                    } = innerElemToRender;

                    return this.renderTag(InnerTag, innerContent, innerClassName);
                });

                return <div block="CmsElementsTabs-TextWrapper">{innerElements}</div>;
            }

            return this.renderTag(Tag, content, className);
        });

        return <div block="CmsElementsTabs-SingleRow">{elements}</div>;
    }

    renderSimpleTags(rows) {
        const elementsToRender = rows.map((row) => {
            const reducedRow = this.reducerRows(row);
            const elements = this.generateElementsToRender(reducedRow);
            return elements;
        });

        return elementsToRender;
    }

    renderCmsElementsTabsContent() {
        const { cmsElementsTabs, activeTab } = this.props;

        if (cmsElementsTabs.length === 0) {
            return null;
        }

        return cmsElementsTabs.map(({ id, rows }) => {
            if (activeTab !== id) {
                return null;
            }

            if (this.checkIsCmsBlock(rows)) {
                return this.renderCmsBlock(rows);
            }

            if (this.checkIsTextBlock(rows)) {
                return this.renderSimpleTags(rows);
            }

            if (rows.length > 1) {
                return rows.map((row) => {
                    if (this.checkIsCmsBlock([row])) {
                        return this.renderCmsBlock([row]);
                    }

                    if (this.checkIsTextBlock([row])) {
                        return this.renderSimpleTags([row]);
                    }

                    return null;
                });
            }

            return null;
        });
    }

    render() {
        return (
            <div block="CmsElementsTabs">
                <div block="CmsElementsTabs" elem="Nav">
                    <div className="CmsElementsTabs-CmsElementsTabs">{this.renderCmsElementsTabs()}</div>
                </div>
                {this.renderCmsElementsTabsContent()}
            </div>
        );
    }
}

export default CmsElementsTabs;
