import AddToCart from 'Component/AddToCart';
import AvailabilityInfo from 'Component/AvailabilityInfo';
import FieldContainer from 'Component/Field';
import { FIELD_TYPE } from 'Component/Field/Field.config';
import { PRODUCT_TYPE } from 'Component/Product/Product.config';
import ProductPrice from 'Component/ProductPrice';
import ProductPublisherOrProducer from 'Component/ProductPublisherOrProducer';
import ProductReviewRating from 'Component/ProductReviewRating';
import ProductWishlistButton from 'Component/ProductWishlistButton';
import TextPlaceholder from 'Component/TextPlaceholder';
import { GRID_LAYOUT, LIST_LAYOUT } from 'Route/CategoryPage/CategoryPage.config';
import { Product as SourceProduct } from 'SourceComponent/Product/Product.component';
import { getCodazonLabels, getProductAuthor } from 'Util/Product';
import { getOmnibusPriceInfo, verifyOmnibus } from 'Util/Product/Product';
import { VALIDATION_INPUT_TYPE_NUMBER } from 'Util/Validator/Config';

import { QTY_VALUE_CHANGER, qtyRenderOptions } from './Stock.config';

/** @namespace SwiatKsiazkiBasic/Component/Product/Component */
export class Product extends SourceProduct {
    renderRatingSummary(isShort = false) {
        const {
            product: { review_summary: { rating_summary, review_count } = {} },
        } = this.props;

        if (!rating_summary) {
            return null;
        }

        return <ProductReviewRating summary={rating_summary || 0} count={review_count} isShort={isShort} />;
    }

    renderAuthor(checkElements = true) {
        const { product } = this.props;

        return getProductAuthor(product, this.className, undefined, undefined, checkElements);
    }

    renderPublisherOrProducer() {
        const { product } = this.props;

        return <ProductPublisherOrProducer className={this.className} product={product} />;
    }

    renderShippingTimeInfo() {
        const { product, inStock } = this.props;

        return <AvailabilityInfo product={product} inStock={inStock} />;
    }

    renderQuantitySelect() {
        const { quantity, minQuantity, maxQuantity, setQuantity, inStock } = this.props;
        const RENDERED_OPTIONS = qtyRenderOptions(
            maxQuantity > QTY_VALUE_CHANGER ? QTY_VALUE_CHANGER : maxQuantity,
            maxQuantity
        );

        return (
            <FieldContainer
                type={FIELD_TYPE.select}
                attr={{
                    id: 'item_qty',
                    name: 'item_qty',
                    defaultValue: quantity,
                    max: maxQuantity,
                    min: minQuantity,
                    noPlaceholder: true,
                }}
                options={RENDERED_OPTIONS}
                validationRule={{
                    inputType: VALIDATION_INPUT_TYPE_NUMBER.numeric,
                    isRequired: true,
                    range: {
                        min: minQuantity,
                        max: maxQuantity,
                    },
                }}
                isDisabled={!inStock}
                mix={{ block: this.className, elem: 'Qty' }}
                events={{ onChange: setQuantity }}
                validateOn={['onChange']}
            />
        );
    }

    renderQuantityNumber() {
        const { quantity, maxQuantity, setQuantity, inStock, setVisibleSelectField } = this.props;

        return (
            <FieldContainer
                type={FIELD_TYPE.number}
                attr={{
                    id: 'item_qty',
                    name: 'item_qty',
                    max: maxQuantity,
                    min: 0,
                    defaultValue: quantity,
                    value: quantity > maxQuantity ? maxQuantity : quantity,
                }}
                isDisabled={!inStock}
                mix={{ block: this.className, elem: 'Qty' }}
                events={{ onChange: setQuantity, onBlur: setVisibleSelectField }}
            />
        );
    }

    renderQuantityChanger() {
        const {
            product: { type_id },
            qtyNumberVisible,
            maxQuantity,
            inStock,
        } = this.props;

        if (type_id === PRODUCT_TYPE.grouped || !inStock) {
            return null;
        }

        return qtyNumberVisible && maxQuantity > QTY_VALUE_CHANGER
            ? this.renderQuantityNumber()
            : this.renderQuantitySelect();
    }

    renderAddToCartButton(layout = GRID_LAYOUT) {
        const { addToCart, inStock, quantity, getActiveProduct } = this.props;

        return (
            <AddToCart
                mix={{ block: this.className, elem: 'AddToCart' }}
                addToCart={addToCart}
                isDisabled={!inStock}
                isIconEnabled
                layout={layout}
                quantity={quantity}
                product={getActiveProduct()}
            />
        );
    }

    renderWishlistButton() {
        const { product, magentoProductWishlist, isWishlistEnabled } = this.props;

        if (magentoProductWishlist.length === 0 || !isWishlistEnabled) {
            return null;
        }

        return (
            <ProductWishlistButton
                product={product}
                magentoProduct={magentoProductWishlist}
                mix={{
                    block: this.className,
                    elem: 'WishListButton',
                }}
            />
        );
    }

    renderOmnibus() {
        const { product } = this.props;

        return verifyOmnibus(product, getOmnibusPriceInfo(product));
    }

    renderProductLabels(isSingleProduct = false) {
        const { product, layout } = this.props;

        if (isSingleProduct || layout === LIST_LAYOUT) {
            return getCodazonLabels(product, isSingleProduct, this.className);
        }

        return verifyOmnibus(
            product,
            getOmnibusPriceInfo(product),
            getCodazonLabels(product, isSingleProduct, this.className)
        );
    }

    getProductLabelType(itemClass) {
        switch (itemClass) {
            case 'new':
                return 'new';
            case 'recommend':
                return 'recommended';
            case 'preview':
                return 'preview';
            case 'preorder':
                return 'preview';
            case 'renewal':
                return 'renewal';
            default:
                return 'default';
        }
    }

    renderBrand() {
        const {
            product: { attributes: { brand: { attribute_value: brand } = {} } = {} },
        } = this.props;

        if (!brand) {
            return null;
        }

        return (
            <h4 block={this.className} elem="Brand">
                <TextPlaceholder content={brand} />
            </h4>
        );
    }

    renderPrice(isPreview = false) {
        const { getActiveProduct, productPrice, layout } = this.props;
        const product = getActiveProduct();
        const { omnibus } = product || {};
        const { is_discount: isOmnibus } = omnibus || {};

        const { type_id: type, price_tiers: priceTiers } = product;

        if (!productPrice) {
            return null;
        }

        return (
            <div block={this.className} elem="PriceWrapper">
                <ProductPrice
                    meta
                    price={productPrice}
                    priceType={type}
                    tierPrices={priceTiers}
                    isPreview={isPreview}
                    isOmnibus={isOmnibus}
                    mix={{ block: this.className, elem: 'Price' }}
                    omnibus={layout === LIST_LAYOUT ? { product } : {}}
                />
            </div>
        );
    }
}

export default Product;
