import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Link from 'Component/Link';

/** @namespace SwiatKsiazkiBasic/Component/Button/Component */
export class ButtonComponent extends PureComponent {
    static propTypes = {
        variant: PropTypes.oneOf([
            'primary',
            'secondary',
            'hollow',
            'outline',
            'outlineSecondary',
            'likeLink',
            'iconOnly',
            'reveal',
        ]),
        size: PropTypes.oneOf(['large', 'medium', 'small', 'extraSmall']),
        asLink: PropTypes.bool,
        iconBefore: PropTypes.node,
        iconAfter: PropTypes.node,
        iconReveal: PropTypes.node,
        block: PropTypes.string,
    };

    static defaultProps = {
        block: 'Button',
        variant: 'primary',
        size: 'large',
        asLink: false,
    };

    render() {
        const { block, children, variant, size, asLink, iconBefore, iconReveal, iconAfter, mods, ...rest } = this.props;
        const Tag = asLink ? Link : 'button';

        const css = block
            ? {
                  block,
                  mods: { variant, size, ...mods },
              }
            : {};

        return (
            <Tag {...css} {...rest}>
                {variant === 'reveal' ? (
                    <>
                        {iconReveal} <span>{children}</span>
                    </>
                ) : (
                    <>
                        {iconBefore}
                        {children}
                        {iconAfter}
                    </>
                )}
            </Tag>
        );
    }
}

export default ButtonComponent;
