import { FIELD_TYPE as sourceFieldType } from 'SourceComponent/Field/Field.config';

export * from 'SourceComponent/Field/Field.config';

export const FIELD_TYPE = {
    ...sourceFieldType,
    normalNumber: 'normalNumber',
    range_date: 'range_date',
    reset: 'reset',
    range: 'range',
    switcher: 'switcher',
    hidden: 'hidden',
};

export const FIELD_RADIO_NONE = 'none';

export default FIELD_TYPE;
