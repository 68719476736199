import { resetCurrentCategory } from 'Store/Category/Category.action';

const appendToMapDispatchToProps = (args, callback, context) => {
    const [dispatch] = args;

    return {
        ...callback.apply(context, args),
        resetCurrentCategory: () => dispatch(resetCurrentCategory()),
    };
};

const appendComponentWillUnmount = (args, callback, context) => {
    const { resetCurrentCategory } = context.props;

    if (resetCurrentCategory) {
        resetCurrentCategory();
    }

    if (callback) {
        callback.apply(context, args);
    }
};

export default {
    'Route/CategoryPage/Container/mapDispatchToProps': {
        function: appendToMapDispatchToProps,
    },
    'SwiatKsiazkiBasic/Route/CategoryPage/Container': {
        'member-function': {
            componentWillUnmount: appendComponentWillUnmount,
        },
    },
    'SwiatKsiazkiBasic/Route/BestsellersPage/Container': {
        'member-function': {
            componentWillUnmount: appendComponentWillUnmount,
        },
    },
    'SwiatKsiazkiBasic/Route/DictionaryPage/Container': {
        'member-function': {
            componentWillUnmount: appendComponentWillUnmount,
        },
    },
};
