import ImageContainer from 'Component/Image/Image.container';

import Logo from './Logo.component';

/** @namespace SwiatKsiazkiBasic/Component/Logo/Container */
export class LogoContainer extends ImageContainer {
    static defaultProps = {
        ...ImageContainer.defaultProps,
        useNativeLazyLoading: true,
        lazyLoadingMode: 'eager',
        fetchPriority: 'high',
    };

    render() {
        return <Logo {...this.containerProps()} {...this.containerFunctions} />;
    }
}

export default LogoContainer;
