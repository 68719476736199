import FIELD_TYPE from 'Component/Field/Field.config';
import Image from 'Component/Image';
import { getRegionFields, getVatFields } from 'SourceComponent/MyAccountAddressForm/MyAccountAddressForm.form';
import { blockedAutocompleteField } from 'Util/Form/Form';
import { VALIDATION_INPUT_TYPE, VALIDATION_INPUT_TYPE_NUMBER } from 'Util/Validator/Config';

import cosmicCard from './assets/cosmicCard.png';
import standardCard from './assets/standardCard.png';
import voucherImage from './assets/voucherImage.png';
import { MAX_INPUT_CARDS, MAX_SALE_CARDS, MIN_INPUT_CARDS, MIN_SALE_CARDS } from './GiftCardsBuyCardForm.config';

export { getRegionFields, getVatFields };

/** @namespace SwiatKsiazkiBasic/Component/GiftCardsBuyCardForm/Form/cardTypesToCheck */
export const cardTypesToCheck = () => [
    {
        image: <Image isPlain src={standardCard} />,
        type: FIELD_TYPE.radio,
        label: <p>{__('Standard światksiążki gift card')}</p>,
        attr: {
            id: 'card',
            name: 'cardType',
            value: 'CARD',
        },
    },
    {
        image: <Image isPlain src={cosmicCard} />,
        type: FIELD_TYPE.radio,
        label: <p>{__('Gift card światksiążki cosmos')}</p>,
        attr: {
            id: 'kids',
            name: 'cardType',
            value: 'KIDS',
        },
    },
    {
        image: <Image isPlain src={voucherImage} />,
        type: FIELD_TYPE.radio,
        label: <p>{__('światksiążki e-voucher')}</p>,
        attr: {
            id: 'voucher',
            name: 'cardType',
            value: 'VOUCHER',
        },
    },
];

/** @namespace SwiatKsiazkiBasic/Component/GiftCardsBuyCardForm/Form/buyCardForm */
export const buyCardForm = (props) => {
    const { countries, defaultCountry } = props;

    return [
        {
            type: FIELD_TYPE.email,
            label: __('E-mail address'),
            attr: {
                id: 'email',
                name: 'email',
                placeholder: __('Your email name'),
                autocomplete: 'email',
            },
            validationRule: {
                inputType: VALIDATION_INPUT_TYPE.email,
                isRequired: true,
            },
            validateOn: ['onChange'],
            addRequiredTag: true,
        },
        {
            type: FIELD_TYPE.email,
            label: __('Repeat the email address'),
            attr: {
                id: 'confirmed_email',
                name: 'confirmed_email',
                placeholder: __('Repeat the email'),
            },
            events: blockedAutocompleteField(),
            validationRule: {
                inputType: VALIDATION_INPUT_TYPE.email,
                isRequired: true,
                match: (value) => {
                    const email = document.getElementById('email');

                    if (value && email.value && email.value !== value) {
                        return __('The emails entered are not identical!');
                    }

                    return value && email.value && email.value === value;
                },
            },
            validateOn: ['onChange'],
            addRequiredTag: true,
        },
        {
            type: FIELD_TYPE.text,
            label: __('First name'),
            attr: {
                id: 'firstName',
                name: 'firstName',
                placeholder: __('Your first name'),
            },
            addRequiredTag: true,
            validateOn: ['onChange'],
            validationRule: {
                isRequired: true,
            },
        },
        {
            type: FIELD_TYPE.text,
            label: __('Last name'),
            attr: {
                name: 'lastName',
                placeholder: __('Your last name'),
            },
            addRequiredTag: true,
            validateOn: ['onChange'],
            validationRule: {
                isRequired: true,
            },
        },
        {
            type: FIELD_TYPE.text,
            label: __('Company'),
            attr: {
                name: 'companyName',
                placeholder: __('Your company'),
            },
            addRequiredTag: false,
            validateOn: ['onChange'],
            validationRule: {
                isRequired: false,
            },
        },
        {
            type: FIELD_TYPE.text,
            label: __('NIP'),
            attr: {
                name: 'nip',
                placeholder: __('Your NIP'),
            },
            addRequiredTag: false,
            validateOn: ['onChange'],
            validationRule: {
                isRequired: false,
            },
        },
        {
            type: FIELD_TYPE.text,
            label: __('Street and house number'),
            attr: {
                name: 'address',
                placeholder: __('Your street and house number'),
            },
            addRequiredTag: true,
            validateOn: ['onChange'],
            validationRule: {
                isRequired: true,
            },
        },
        {
            type: FIELD_TYPE.text,
            label: __('Flat number'),
            attr: {
                name: 'flatNumber',
                placeholder: __('Your flat number'),
            },
            addRequiredTag: false,
            validateOn: ['onChange'],
            validationRule: {
                isRequired: false,
            },
        },
        {
            type: FIELD_TYPE.text,
            label: __('City address'),
            attr: {
                name: 'city',
                placeholder: __('Your city address'),
            },
            addRequiredTag: true,
            validateOn: ['onChange'],
            validationRule: {
                isRequired: true,
            },
        },
        {
            type: FIELD_TYPE.text,
            label: __('Postcode'),
            attr: {
                name: 'postcode',
                placeholder: __('Your postcode'),
            },
            addRequiredTag: true,
            validateOn: ['onChange'],
            validationRule: {
                isRequired: true,
            },
        },
        {
            type: FIELD_TYPE.select,
            label: __('Country'),
            attr: {
                id: 'countryId',
                name: 'countryId',
                defaultValue: defaultCountry,
                selectPlaceholder: __('Select country...'),
            },
            options: countries,
            addRequiredTag: true,
            validateOn: ['onChange'],
            validationRule: {
                isRequired: true,
            },
        },
        {
            type: FIELD_TYPE.text,
            label: __('Phone number'),
            attr: {
                name: 'phone',
                placeholder: __('Your phone number'),
            },
            addRequiredTag: true,
            validateOn: ['onChange'],
            validationRule: {
                inputType: VALIDATION_INPUT_TYPE.phone,
                isRequired: true,
            },
        },
    ];
};

/** @namespace SwiatKsiazkiBasic/Component/GiftCardsBuyCardForm/Form/generateNumberFieldLabel */
export const generateNumberFieldLabel = (value, isMobile) => (
    <>
        <span>{isMobile ? __('Value') : __('Card with a value of')}</span>
        <span block="GiftCardsBuyCardForm" elem="LabelValue">
            {value} zł
        </span>
    </>
);

/** @namespace SwiatKsiazkiBasic/Component/GiftCardsBuyCardForm/Form/buyCardNumberFields */
export const buyCardNumberFields = (props) => {
    const { setCardQuantity, isMobile, giftCardsAvailableCardValues = [] } = props;

    const inputs = giftCardsAvailableCardValues.map((amount) => ({
        type: FIELD_TYPE.number,
        label: generateNumberFieldLabel(amount, isMobile),
        attr: {
            id: String(amount),
            name: String(amount),
            min: MIN_INPUT_CARDS,
            max: MAX_INPUT_CARDS,
            value: props[String(amount)],
            defaultValue: props[String(amount)],
        },
        events: {
            onChange: setCardQuantity,
        },
        validateOn: ['onChange'],
        validationRule: {
            inputType: VALIDATION_INPUT_TYPE_NUMBER.numeric,
            range: {
                min: MIN_SALE_CARDS,
                max: MAX_SALE_CARDS,
            },
        },
    }));

    return inputs;
};
