export const REQUEST_TYPE_PAYMENT = 'PAYMENT';
export const REQUEST_TYPE_QUERY = 'QUERY';
export const GIFT_CARDS_BUY_CARD_DETAILS_CMS_ID = 'pwa-gift-cards-buy-card-details';

export const MIN_SALE_CARDS = 0;
export const MAX_SALE_CARDS = 100000;

export const MIN_INPUT_CARDS = 0;
export const MAX_INPUT_CARDS = 100000;

export const GIFT_CARDS_REGULATIONS_URL = '/karty-podarunkowe#regulaminy';

export const GIFT_CARD_ORDER_CONSENT = 'giftcardOrderConsent';
