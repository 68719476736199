import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';

import { decodeString } from 'Util/Common';
import { appendWithStoreCode } from 'Util/Url';

import ProductLabel from './ProductLabel.component';

/** @namespace SwiatKsiazkiBasic/Component/ProductLabel/Container */
export class ProductLabelContainer extends PureComponent {
    static propTypes = {
        type: PropTypes.oneOf([
            'new',
            'sale',
            'preview',
            'recommend',
            'recommended',
            'renewal',
            'in_stock',
            'top',
            'top_rounded',
            'top_rounded_wide',
            'top_with_number',
            'default',
            'number',
            'ebook',
            'audiobook',
            'only_here',
        ]),
        text: PropTypes.string,
        customCSS: PropTypes.string,
    };

    containerFunctions = {
        goToLabelLink: this.goToLabelLink.bind(this),
    };

    containerProps() {
        const { type, text, discount, customCSS, customClass, topNumber, mods, labelLink } = this.props;

        return {
            type,
            text,
            customCSS,
            customClass,
            discount,
            topNumber,
            mods,
            labelLink,
        };
    }

    goToLabelLink(e) {
        const { labelLink, history } = this.props;

        if (!labelLink) {
            return;
        }

        e.preventDefault();
        e.stopPropagation();

        const labelUrl = new URL(labelLink);

        history.push({ pathname: appendWithStoreCode(labelUrl.pathname), search: decodeString(labelUrl.search) });
    }

    render() {
        return <ProductLabel {...this.containerProps()} {...this.containerFunctions} />;
    }
}

export default withRouter(ProductLabelContainer);
