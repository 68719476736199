import { ITEMS_PER_PAGE } from 'Component/MyAccountMyWishlist/MyAccountMyWishlist.config';
import { WishlistQuery as SourceWishlistQuery } from 'SourceQuery/Wishlist.query';
import { Field } from 'Util/Query';
import { getCurrentCategoryFromUrl, getCurrentPageFromUrl } from 'Util/Wishlist/Params';

/** @namespace SwiatKsiazkiBasic/Query/Wishlist/Query */
export class WishlistQuery extends SourceWishlistQuery {
    setWishlistPriceAlertEnable(isEnabled) {
        return new Field('setWishlistPriceAlertEnable')
            .addArgument('is_enabled', 'Boolean', isEnabled)
            .addFieldList(['status', 'message']);
    }

    setWishlistPriceAlertViewed(productViewed) {
        return new Field('setWishlistPriceAlertViewed')
            .addArgument('skus', '[String]!', productViewed)
            .addFieldList(['status', 'message']);
    }

    getWishlistPriceAlertPopup() {
        return new Field('getWishlistPriceAlertPopup').addField(
            new Field('result').addFieldList(this._getItemsFields())
        );
    }

    getWishlistSkuQuery() {
        return new Field('customer').addField(
            new Field('wishlist').addField(new Field('items').addFieldList(['sku', 'id']))
        );
    }

    getWishlistQuery(sharingCode, paginatedResult = true) {
        const field = new Field('s_wishlist')
            .setAlias('wishlist')
            .addFieldList(this._getWishlistFields(paginatedResult));

        if (sharingCode) {
            field.addArgument('sharing_code', 'ID', sharingCode);
        }

        return field;
    }

    _getWishlistFields(paginatedResult) {
        return [
            'id',
            'updated_at',
            'items_count',
            'creators_name',
            'is_wishlist_have_price_drop',
            ...(paginatedResult ? [this._getPaginatedItemsField(paginatedResult)] : [this._getItemsField()]),
        ];
    }

    getPriceAlertFields() {
        return new Field('price_alert').addFieldList(['price', 'discount_percent', 'is_has_been_viewed']);
    }

    _getItemsFields() {
        return [...this._getWishlistItemsFields(), this._getProductField(), this.getPriceAlertFields()];
    }

    _getPaginatedItemsField(paginatedResult) {
        const field = new Field('query_items').addFieldList(['filtered_items_count', this._getPaginatedItemsFields()]);

        if (paginatedResult) {
            field
                .addArgument('currentPage', 'Int', getCurrentPageFromUrl())
                .addArgument('pageSize', 'Int', ITEMS_PER_PAGE)
                .addArgument('filter', 'WishlistItemsFilterInput', { category_id: getCurrentCategoryFromUrl() });
        }

        return field;
    }

    _getPaginatedItemsFields() {
        return new Field('result').addFieldList(this._getItemsFields());
    }

    getWishlistMenuQuery() {
        return new Field('wishlistMenu').addField(this.getWishlistMenuItemsField());
    }

    getWishlistMenuItemsField() {
        return new Field('items').addFieldList(['category_id', 'position', 'items_count', 'title']);
    }
}

export default new WishlistQuery();
