import Icon from 'Component/Icon';
import Loader from 'Component/Loader';
import { ProductWishlistButton as SourceProductWishlistButton } from 'SourceComponent/ProductWishlistButton/ProductWishlistButton.component';

import './ProductWishlistButton.override.style';

/** @namespace SwiatKsiazkiBasic/Component/ProductWishlistButton/Component */
export class ProductWishlistButton extends SourceProductWishlistButton {
    renderButton() {
        const { isInWishlist, isDisabled, mix } = this.props;

        return (
            <button
                block="ProductWishlistButton"
                elem="Button"
                mods={{ isInWishlist, isDisabled }}
                mix={{ block: 'Button', mix }}
                title={this.getTitle()}
                onClick={this.onClick}
            >
                <Icon name={isInWishlist ? 'heart_in_circle_full' : 'heart_in_circle'} />
            </button>
        );
    }

    renderLoader() {
        const { isLoading } = this.props;

        return <Loader isLoading={isLoading} loadingText={null} />;
    }
}

export default ProductWishlistButton;
