import FIELD_TYPE from 'Component/Field/Field.config';
import { FormContainer as SourceFormContainer } from 'SourceComponent/Form/Form.container';
import getFieldsData from 'Util/Form/Extract';
import { validateGroup } from 'Util/Validator';

/** @namespace SwiatKsiazkiBasic/Component/Form/Container */
export class FormContainer extends SourceFormContainer {
    async onSubmit(e) {
        e.preventDefault();

        const { onSubmit, onError, returnAsObject = false, validationRule } = this.props;

        const fields = getFieldsData(this.formRef, false, [FIELD_TYPE.button], returnAsObject);
        const isValid = validateGroup(this.formRef, validationRule);

        if (isValid !== true) {
            if (typeof onError === 'function') {
                onError(this.formRef, fields, isValid);
            }

            return;
        }

        if (typeof onSubmit === 'function') {
            onSubmit(this.formRef, fields);
        }
    }
}

export default FormContainer;
