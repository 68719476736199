import { isAfter, isBefore } from 'date-fns';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import DatePicker from 'Component/DatePicker';
import { FIELD_DATE_TYPE } from 'Component/FieldDate/FieldDate.config';
import { formatDateBack } from 'Util/Date';

import './FieldDateRange.style';

/** @namespace SwiatKsiazkiBasic/Component/FieldDateRange/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    useCalendar: !!+state.ConfigReducer.use_calendar,
});

/** @namespace SwiatKsiazkiBasic/Component/FieldDateRange/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace SwiatKsiazkiBasic/Component/FieldDateRange/Container */
export class FieldDateRangeContainer extends PureComponent {
    static propTypes = {
        type: PropTypes.oneOf(Object.values(FIELD_DATE_TYPE)).isRequired,
        uid: PropTypes.string.isRequired,
        isRequired: PropTypes.bool,
        updateSelectedValues: PropTypes.func.isRequired,
        useCalendar: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        isRequired: false,
    };

    componentDidUpdate(pevProps) {
        const { defaultStartDate: prevDefaultStartDate, defaultEndDate: prevDefaultEndDate } = pevProps;
        const { defaultStartDate, defaultEndDate } = this.props;

        if (prevDefaultStartDate !== defaultStartDate) {
            this.setState({
                startDate: defaultStartDate ? formatDateBack(defaultStartDate) : null,
            });
        }

        if (prevDefaultEndDate !== defaultEndDate) {
            this.setState({
                endDate: defaultEndDate ? formatDateBack(defaultEndDate) : null,
            });
        }
    }

    __construct(props) {
        super.__construct(props);

        const { defaultStartDate = null, defaultEndDate = null } = this.props;

        this.state = {
            startDate: defaultStartDate ? formatDateBack(defaultStartDate) : null,
            endDate: defaultEndDate ? formatDateBack(defaultEndDate) : null,
        };
    }

    containerProps() {
        const { type, uid, isRequired, updateSelectedValues, label, placeholder } = this.props;

        return {
            type,
            uid,
            isRequired,
            updateSelectedValues,
            placeholder,
            label,
        };
    }

    setStartDate(date) {
        const { endDate } = this.state;

        if (isAfter(date, endDate)) {
            return null;
        }

        this.setState({
            startDate: date,
        });
    }

    setEndDate(date) {
        const { startDate } = this.state;

        if (isBefore(date, startDate)) {
            return null;
        }

        this.setState({
            endDate: date,
        });
    }

    render() {
        const { useCalendar, label, disabled, name } = this.props;
        const { startDate, endDate } = this.state;

        if (useCalendar) {
            return (
                <div block="FieldDateRange" mods={{ isDisabled: disabled }}>
                    <DatePicker
                        {...this.containerProps()}
                        selectsStart
                        selected={startDate}
                        isClearable={false}
                        startDate={startDate}
                        endDate={endDate}
                        onChange={(date) => this.setStartDate(date)}
                        placeholder={__('Date from')}
                        label={label}
                        disabled={disabled}
                        name={`${name}_from`}
                    />
                    <span>-</span>
                    <DatePicker
                        {...this.containerProps()}
                        selectsEnd
                        selected={endDate}
                        isClearable={false}
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        onChange={(date) => this.setEndDate(date)}
                        placeholder={__('Date to')}
                        label=""
                        disabled={disabled}
                        name={`${name}_to`}
                    />
                </div>
            );
        }

        return null;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FieldDateRangeContainer);
