import debounce from 'lodash/debounce';
import { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';

import { getScrollPosition } from 'Util/Browser/Browser';

// eslint-disable-next-line no-unused-vars, @scandipwa/scandipwa-guidelines/export-level-one, @scandipwa/scandipwa-guidelines/create-config-files
let canScrollToPosition = false;

export const positions = {};

/** @namespace SwiatKsiazkiBasic/Component/RestoreScroll/Container */
export class RestoreScrollContainer extends PureComponent {
    static scrollToPosition() {
        window.dispatchEvent(
            new CustomEvent('scrollToPosition', {
                detail: {
                    pathname: window.location.pathname,
                },
            })
        );
    }

    componentDidMount() {
        const {
            location: { pathname },
        } = this.props;

        positions[pathname] = getScrollPosition();
        window.addEventListener('scroll', debounce(this.handleScrollPosition.bind(this), 100));
        window.addEventListener('scrollToPosition', this.handleScrollToPosition.bind(this));
        window.addEventListener('popstate', this.handleCanScrollToPosition.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', debounce(this.handleScrollPosition.bind(this), 100));
        window.removeEventListener('scrollToPosition', this.handleScrollToPosition.bind(this));
        window.removeEventListener('popstate', this.handleCanScrollToPosition.bind(this));
    }

    __construct() {
        super.__construct();

        history.scrollRestoration = 'manual';
    }

    handleCanScrollToPosition() {
        canScrollToPosition = true;
    }

    handleScrollToPosition({ detail: { pathname } }) {
        if (canScrollToPosition) {
            if (positions[pathname]) {
                window.scrollTo({
                    top: positions[pathname],
                });
            }

            canScrollToPosition = false;
        }
    }

    handleScrollPosition() {
        const {
            location: { pathname },
        } = this.props;

        positions[pathname] = getScrollPosition();
    }

    render() {
        return null;
    }
}

export default withRouter(RestoreScrollContainer);
