import { PureComponent } from 'react';

import HeadingWidget from 'Component/HeadingWidget';
import Image from 'Component/Image';
import Link from 'Component/Link';
import ProductPrice from 'Component/ProductPrice';
import ProductPublisherOrProducer from 'Component/ProductPublisherOrProducer';
import { getCodazonLabels, getProductAuthor } from 'Util/Product';
import { getPrice } from 'Util/Product/Extract';
import { getProductIdentificationAttribs } from 'Util/Product/Product';

import './ProductListMagezonWidget.style';

/** @namespace SwiatKsiazkiBasic/Component/ProductListMagezonWidget/Component */
export class ProductListMagezonWidget extends PureComponent {
    renderProducts() {
        const { products } = this.props;

        return (
            <div block="ProductListMagezonWidget" elem="Products">
                {products.map((product) => this.renderProduct(product))}
            </div>
        );
    }

    renderProduct(product) {
        const { isLightVariant } = this.props;

        if (isLightVariant) {
            return (
                <div
                    block="ProductListMagezonWidget"
                    elem="Product"
                    mods={{ isLightVariant }}
                    {...getProductIdentificationAttribs(product)}
                >
                    {this.renderPicture(product)}
                    <div>{this.renderName(product)}</div>
                </div>
            );
        }

        return (
            <div block="ProductListMagezonWidget" elem="Product" {...getProductIdentificationAttribs(product)}>
                {this.renderPicture(product)}
                <div>
                    {this.renderName(product)}
                    {this.renderAuthor(product)}
                    {this.renderPublisherOrProducer(product)}
                    {this.renderPrice(product)}
                    {this.renderProductLabels(product)}
                </div>
            </div>
        );
    }

    renderPrice(product) {
        const { price_range: priceRange, type_id: type, dynamic_price: dynamicPrice, omnibus } = product;
        const { is_discount: isOmnibus } = omnibus || {};

        return (
            <ProductPrice
                price={getPrice(priceRange, dynamicPrice, {}, type)}
                mods={{ type: 'regular' }}
                isOmnibus={isOmnibus}
            />
        );
    }

    renderName(product) {
        const { name, url } = product;

        return (
            <Link to={url} block="ProductListMagezonWidget" elem="Name">
                {name}
            </Link>
        );
    }

    renderAuthor(product) {
        return getProductAuthor(product, 'ProductCard');
    }

    renderProductLabels(product) {
        return getCodazonLabels(product, false, 'ProductCard');
    }

    renderPublisherOrProducer(product) {
        return <ProductPublisherOrProducer className="ProductCard" product={product} />;
    }

    renderPicture(product) {
        const {
            id,
            name,
            url,
            thumbnail: { url: imageUrl },
        } = product;

        return (
            <Link to={url} block="ProductListMagezonWidget" elem="PictureWrapper">
                <Image
                    src={imageUrl}
                    alt={name}
                    ratio="custom"
                    height="120"
                    width="90"
                    mix={{ block: 'ProductCard', elem: 'Picture' }}
                    isPlaceholder={!id}
                />
            </Link>
        );
    }

    renderTitle(product) {
        const { title } = product;

        if (!title) {
            return null;
        }

        return (
            <h2 block="ProductListMagezonWidget" elem="Title">
                {title}
            </h2>
        );
    }

    renderHeading() {
        const { title, url } = this.props;

        if (!title) {
            return null;
        }

        return <HeadingWidget text={title} url={url} />;
    }

    render() {
        const { products, isLoading, isLightVariant } = this.props;

        if (isLoading) {
            return <div block="ProductListMagezonWidget" elem="Placeholder" />;
        }

        if (!products || products.length === 0) {
            return null;
        }

        return (
            <div block="ProductListMagezonWidget" mods={{ isLightVariant: !!isLightVariant }}>
                {this.renderHeading()}
                {this.renderProducts()}
            </div>
        );
    }
}

export default ProductListMagezonWidget;
