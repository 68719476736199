export * from 'SourceComponent/Router/Router.config';

export const ACCOUNT_FORGOT_PASSWORD_SUCCESS = 'ACCOUNT_FORGOT_PASSWORD_SUCCESS';
export const NEWSLETTER_POPUP = 'NEWSLETTER_POPUP';
export const ACCOUNT_SIDEBAR_PAGE = 'ACCOUNT_SIDEBAR_PAGE';
export const MY_ACCOUNT_ADDRESS_NEW = 'MY_ACCOUNT_ADDRESS_NEW';
export const MY_ACCOUNT_ADDRESS_ADD = 'MY_ACCOUNT_ADDRESS_ADD';
export const WISHLIST_PRICE_ALERT_POPUP = 'WISHLIST_PRICE_ALERT_POPUP';
export const BESTSELLERS_PAGE = 'BESTSELLERS_PAGE';
export const MY_ACCOUNT_REVIEWS = 'MY_ACCOUNT_REVIEWS';
export const MY_ACCOUNT_REVIEWS_DETAILS_PAGE = 'MY_ACCOUNT_REVIEWS_DETAILS_PAGE';
export const CHECKOUT_TERMS_AND_CONDITIONS = 'CHECKOUT_TERMS_AND_CONDITIONS';
export const MY_ACCOUNT_ORDER_REPAYMENT = 'MY_ACCOUNT_ORDER_REPAYMENT';
export const MY_ACCOUNT_CUSTOMER_CARDS = 'MY_ACCOUNT_CUSTOMER_CARDS';
export const MY_ACCOUNT_CUSTOMER_CARDS_LIST = 'MY_ACCOUNT_CUSTOMER_CARDS_LIST';
export const MY_ACCOUNT_CUSTOMER_CARD_ADD = 'MY_ACCOUNT_CUSTOMER_CARD_ADD';
export const GIFT_CARDS_ORDER_SUCCESS = 'GIFT_CARDS_ORDER_SUCCESS';
export const MY_ACCOUNT_CONSENTS = 'MY_ACCOUNT_CONSENTS';
export const INVOICE_INFORMATION = 'INVOICE_INFORMATION';
export const NOTIFICATION_OBSERVER = 'NOTIFICATION_OBSERVER';
export const EDRONE_CONTAINER = 'EDRONE_CONTAINER';
export const PIXEL_CONTAINER = 'PIXEL_CONTAINER';
export const LOGIN_AS_USER = 'LOGIN_AS_USER';
export const CLICKONOMETRICS_CONTAINER = 'CLICKONOMETRICS_CONTAINER';
export const BOXBOX_CONTAINER = 'BOXBOX_CONTAINER';
