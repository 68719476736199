/** @namespace SwiatKsiazkiBasic/Util/Element/Index/eventPersist */
export const eventPersist = (callback) => (event) => {
    event.persist();
    callback(event);
};

/** @namespace SwiatKsiazkiBasic/Util/Element/Index/onEnterPressDefault */
export const onEnterPressDefault = (event) => {
    if (event.keyCode === 13) {
        event.preventDefault();
    }
};

/** @namespace SwiatKsiazkiBasic/Util/Element/Index/mergeRefs */
export const mergeRefs =
    (...refs) =>
    (node) => {
        refs.forEach((ref) => {
            ref.current = node;
        });
    };

/** @namespace SwiatKsiazkiBasic/Util/Element/Index/inViewport */
export const inViewport = (elementRef) => {
    if (!elementRef || !elementRef.current) {
        return false;
    }

    const rect = elementRef.current.getBoundingClientRect();

    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
};
