import { connect } from 'react-redux';

import { ACCOUNT_TAB, WISHLIST_TAB } from 'Component/NavigationTabs/NavigationTabs.config';
import { ACCOUNT_LOGIN_URL, ACCOUNT_MENU_RUL, ACCOUNT_WISHLIST_URL } from 'Route/MyAccount/MyAccount.config';
import {
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    NavigationTabsContainer as SourceNavigationTabsContainer,
} from 'SourceComponent/NavigationTabs/NavigationTabs.container';
import { isSignedIn } from 'Util/Auth';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

export { mapDispatchToProps };
/** @namespace SwiatKsiazkiBasic/Component/NavigationTabs/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    wishlistItemsCount: state.WishlistReducer.productsInWishlistCount,
    customer: state.MyAccountReducer.customer,
});

/** @namespace SwiatKsiazkiBasic/Component/NavigationTabs/Container */
export class NavigationTabsContainer extends SourceNavigationTabsContainer {
    routeMap = {
        ...this.routeMap,
        '/reviews': { name: ACCOUNT_TAB },
        '/wishlist': { name: WISHLIST_TAB },
    };

    containerProps() {
        const { customer, wishlistItemsCount } = this.props;

        return {
            ...super.containerProps(),
            wishlistItemsCount,
            customer,
        };
    }

    containerFunctions = {
        ...this.containerFunctions,
        onWishlistButtonClick: this.onWishlistButtonClick.bind(this),
    };

    onWishlistButtonClick() {
        const { pathname } = location;
        const url = appendWithStoreCode(isSignedIn() ? ACCOUNT_WISHLIST_URL : ACCOUNT_LOGIN_URL);

        if (pathname !== url) {
            history.push(url);
        }
    }

    onMyAccountButtonClick() {
        const { pathname } = location;

        const url = appendWithStoreCode(isSignedIn() ? ACCOUNT_MENU_RUL : ACCOUNT_LOGIN_URL);

        if (pathname !== url) {
            history.push(url);
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavigationTabsContainer);
