import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    ProductPriceContainer as SourceProductPriceContainer,
} from 'SourceComponent/ProductPrice/ProductPrice.container';

export { mapDispatchToProps, mapStateToProps };

/** @namespace SwiatKsiazkiBasic/Component/ProductPrice/Container */
export class ProductPriceContainer extends SourceProductPriceContainer {
    containerProps() {
        const {
            price: { price, originalPrice },
            isPdp,
            isInProductAction,
            priceAlert = false,
            isStartingPrice = false,
            isSuggestedPrice = false,
            isWishlistItem = false,
            isOmnibus = false,
            omnibus,
            uom,
        } = this.props;

        if (!price || !originalPrice) {
            return {};
        }

        return {
            ...super.containerProps(),
            isPdp,
            isInProductAction,
            priceAlert,
            isStartingPrice,
            isSuggestedPrice,
            isWishlistItem,
            isOmnibus,
            omnibus,
            uom,
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductPriceContainer);
