import Icon from 'Component/Icon';
import Link from 'Component/Link';
import TextPlaceholder from 'Component/TextPlaceholder';
import { Breadcrumb as SourceBreadcrumb } from 'SourceComponent/Breadcrumb/Breadcrumb.component';

import './Breadcrumb.override.style';

/** @namespace SwiatKsiazkiBasic/Component/Breadcrumb/Component */
export class Breadcrumb extends SourceBreadcrumb {
    renderLink() {
        const { index, isDisabled, name } = this.props;

        const url = this.getLinkUrl() || {};

        return (
            <Link block="Breadcrumb" elem="Link" to={url} tabIndex={isDisabled ? '-1' : '0'}>
                <meta itemProp="item" content={window.location.origin + url.pathname} />
                <span itemProp="name">
                    <TextPlaceholder content={name} />
                </span>
                <Icon name="arrow_right" width="13px" height="11px" />
                <meta itemProp="position" content={index} />
            </Link>
        );
    }
}

export default Breadcrumb;
