import { Field } from 'Util/Query';

/** @namespace SwiatKsiazkiBasic/Query/BookstoreLocations/Query */
export class BookstoreLocationsQuery {
    getQuery(city = '') {
        const fieldName = city ? 'getBookshopListByCity' : 'getBookshopList';

        const query = new Field(fieldName).addFieldList(this._getLocationFields()).setAlias('bookstoreLocations');

        if (city) {
            query.addArgument('city', 'String!', city);
        }

        return query;
    }

    _getLocationFields() {
        return [
            'bookshop_id',
            'symbol',
            'description',
            'post_code',
            'place',
            'address',
            'phone',
            'hidden',
            'latitude',
            'longitude',
            'opening_hours',
            'custom_html',
            'hidden_delivery_point',
        ];
    }
}

export default new BookstoreLocationsQuery();
