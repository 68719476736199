import { connect } from 'react-redux';

import ProductListQuery from 'Query/ProductList.query';
import { showNotification } from 'Store/Notification/Notification.action';
import { parseJSON } from 'Util/Data';
import { ADD_TO_CART, getIndexedProduct } from 'Util/Product';
import { magentoProductTransform } from 'Util/Product/Transform';
import DataContainer from 'Util/Request/DataContainer';

import SingleProductWidget from './SingleProductWidget.component';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

/** @namespace SwiatKsiazkiBasic/Component/SingleProductWidget/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    addProductToCart: (options) =>
        CartDispatcher.then(({ default: dispatcher }) => dispatcher.addProductToCart(dispatch, options)),
    showError: (message) => dispatch(showNotification('error', message)),
});

/** @namespace SwiatKsiazkiBasic/Component/SingleProductWidget/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    cartId: state.CartReducer.id,
    isWishlistEnabled: state.ConfigReducer.wishlist_general_active,
});

/** @namespace SwiatKsiazkiBasic/Component/SingleProductWidget/Container */
export class SingleProductWidgetContainer extends DataContainer {
    state = {
        item: {},
        isLoading: true,
    };

    containerFunctions = {
        addToCart: this.addToCart.bind(this),
    };

    componentDidMount() {
        this.getSingleProductData();
    }

    __construct(props) {
        super.__construct(props, 'SingleProductWidget');
    }

    getSingleProductData() {
        const { sku } = this.props;

        this.setState({ isLoading: true });

        const options = {
            args: {
                filter: {
                    productSKU: sku,
                },
            },
            isPlp: true,
            noVariantAttributes: true,
        };

        this.fetchData(
            [ProductListQuery.getQuery(options)],
            ({ products }) => {
                if (!products?.items?.length) {
                    this.setState({
                        item: {},
                        isLoading: false,
                    });

                    return;
                }

                this.setState({
                    item: getIndexedProduct(products?.items?.[0]),
                    isLoading: false,
                });
            },
            () => {
                this.setState({
                    item: {},
                    isLoading: false,
                });
            }
        );
    }

    containerProps() {
        const { isLoading, item } = this.state;
        const { title, subtitle, backgroundColor, classVariant, productDisplay, productOptions, isWishlistEnabled } =
            this.props;

        return {
            isWishlistEnabled,
            isLoading,
            item,
            subtitle,
            backgroundColor,
            classVariant,
            productDisplay,
            title: parseJSON(title),
            options: parseJSON(productOptions),
        };
    }

    getProduct() {
        const { item } = this.state;

        const quantity = 1;

        return magentoProductTransform(ADD_TO_CART, item, quantity);
    }

    async addToCart() {
        const { showError, addProductToCart, cartId } = this.props;

        const products = this.getProduct();

        await addProductToCart({ products, cartId }).catch(
            /** @namespace SwiatKsiazkiBasic/Component/SingleProductWidget/Container/SingleProductWidgetContainer/addToCart/addProductToCart/catch */
            (error) => {
                if (error) {
                    showError(error);
                }
            }
        );
    }

    render() {
        return <SingleProductWidget {...this.containerFunctions} {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SingleProductWidgetContainer);
