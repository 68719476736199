import PropTypes from 'prop-types';

import { FIELD_TYPE } from 'Component/Field/Field.config';
import FieldDate from 'Component/FieldDate';
import { FIELD_DATE_TYPE } from 'Component/FieldDate/FieldDate.config';
import FieldInputRange from 'Component/FieldInputRange';
import { FieldNumberContainer } from 'Component/FieldNumber/FieldNumber.container';
import FieldSelectContainer from 'Component/FieldSelect/FieldSelect.container';
import Icon from 'Component/Icon';
import PasswordStrength from 'Component/PasswordStrength';
import { Field as SourceField } from 'SourceComponent/Field/Field.component';
import { noopFn } from 'Util/Common';
import CSS from 'Util/CSS';

import './Field.override.style';

/** @namespace SwiatKsiazkiBasic/Component/Field/Component */
export class Field extends SourceField {
    static propTypes = {
        ...SourceField.propTypes,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    };

    renderMap = {
        ...this.renderMap,
        [FIELD_TYPE.range_date]: this.renderRangeDate.bind(this),
        [FIELD_TYPE.range]: this.renderRangeInput.bind(this),
        [FIELD_TYPE.normalNumber]: this.renderDefaultInput.bind(this),
        [FIELD_TYPE.switcher]: this.renderSwitcher.bind(this),
        [FIELD_TYPE.hidden]: this.renderDefaultInput.bind(this),
    };

    renderNumber() {
        const { attr, events, setRef, value, isDisabled = false, updateMaxValue = false } = this.props;

        return (
            <FieldNumberContainer
                value={value}
                attr={attr}
                events={events}
                setRef={setRef}
                isDisabled={isDisabled}
                updateMaxValue={updateMaxValue}
            />
        );
    }

    renderSwitcher() {
        const {
            setRef,
            attr: { defaultChecked = false, ...newAttr } = {},
            events: { onChange, onLabelClick },
            events,
            isDisabled,
            label,
        } = this.props;
        const { id = '', checked, value = '' } = newAttr;
        const inputEvents = {
            ...events,
            onChange: onChange || noopFn,
        };
        // if button value is "none" do not disable
        const isButtonDisabled = !value.match('none') && isDisabled;
        const isChecked = checked || (isButtonDisabled || defaultChecked ? !isDisabled : null);

        return (
            <div block="Field" elem="SwitcherLabel">
                <span onClick={onLabelClick || noopFn}>{label}</span>
                <label htmlFor={id} mods={{ isDisabled }}>
                    <input
                        ref={(elem) => setRef(elem)}
                        disabled={isButtonDisabled ? isDisabled : false}
                        {...newAttr}
                        {...inputEvents}
                        type={FIELD_TYPE.checkbox}
                        // shipping options have checked attr assigned so prioritize its value
                        defaultChecked={isChecked}
                    />
                    <div block="switcher-control" disabled={isDisabled} />
                </label>
            </div>
        );
    }

    renderCheckboxOrRadio() {
        const {
            type,
            setRef,
            attr: { defaultChecked = false, ...newAttr } = {},
            events: { onChange, onLabelClick },
            events,
            isDisabled,
            label,
            validationRule,
        } = this.props;
        const { isRequired } = validationRule || {};
        const { id = '', checked, value = '' } = newAttr;
        const elem = type.charAt(0).toUpperCase() + type.slice(1);
        const inputEvents = {
            ...events,
            onChange: onChange || noopFn,
        };
        // if button value is "none" do not disable
        const isButtonDisabled = !value.match('none') && isDisabled;
        const isChecked = checked || (isButtonDisabled || defaultChecked ? !isDisabled : null);

        return (
            <label htmlFor={id} block="Field" elem={`${elem}Label`} mods={{ isDisabled, isRequired }}>
                <input
                    ref={(elem) => setRef(elem)}
                    disabled={isButtonDisabled ? isDisabled : false}
                    type={type}
                    {...newAttr}
                    {...inputEvents}
                    // shipping options have checked attr assigned so prioritize its value
                    defaultChecked={isChecked}
                />
                <div block="input-control" disabled={isDisabled} />
                <span onClick={onLabelClick || noopFn}>{label}</span>
            </label>
        );
    }

    renderRangeInput() {
        const {
            setRef,
            attr: { name, defaultStartValue, defaultEndValue, disabled, placeholder, labelTo, labelFrom },
        } = this.props;

        return (
            <FieldInputRange
                setRef={setRef}
                labelTo={labelTo}
                labelFrom={labelFrom}
                placeholder={placeholder}
                name={name}
                isDisabled={disabled}
                defaultStartValue={defaultStartValue}
                defaultEndValue={defaultEndValue}
            />
        );
    }

    renderRangeDate() {
        const {
            label,
            attr: { name, defaultStartDate, defaultEndDate },
        } = this.props;

        return (
            <FieldDate
                label={label}
                type={FIELD_DATE_TYPE.date}
                rangeDate
                name={name}
                defaultStartDate={defaultStartDate}
                defaultEndDate={defaultEndDate}
            />
        );
    }

    renderSelect() {
        const {
            attr,
            events,
            setRef,
            options,
            isDisabled = false,
            changeValueOnDoubleClick,
            isSortSelect,
            isDynamicWidth,
        } = this.props;

        return (
            <FieldSelectContainer
                attr={attr}
                events={events}
                options={options}
                setRef={setRef}
                isDisabled={isDisabled}
                isSortSelect={isSortSelect}
                isDynamicWidth={isDynamicWidth}
                changeValueOnDoubleClick={changeValueOnDoubleClick}
            />
        );
    }

    renderDefaultInput() {
        const {
            type,
            setRef,
            attr,
            events,
            isDisabled,
            visiblePassword,
            passwordStrengthVisible,
            updatePasswordStrength,
        } = this.props;

        return (
            <input
                ref={(elem) => setRef(elem)}
                disabled={isDisabled}
                type={
                    (type === FIELD_TYPE.password && (visiblePassword ? FIELD_TYPE.text : FIELD_TYPE.password)) ||
                    (type === FIELD_TYPE.normalNumber && FIELD_TYPE.number) ||
                    type
                }
                {...attr}
                {...events}
                onInput={passwordStrengthVisible ? (e) => updatePasswordStrength(e) : events?.onInput}
            />
        );
    }

    // Renders * for required fields
    renderRequiredTag() {
        const { addRequiredTag } = this.props;

        if (!addRequiredTag) {
            return null;
        }

        return (
            <span block="Field" elem="Label" mods={{ isRequired: true }}>
                *
            </span>
        );
    }

    renderPasswordIcon() {
        const { visiblePassword, toggleVisiblePassword } = this.props;

        return (
            <span onClick={toggleVisiblePassword} role="presentation" block="Field" elem="PasswordIcon">
                <Icon
                    name={visiblePassword ? 'eye_crossed' : 'eye'}
                    width="22"
                    height="14"
                    fill={CSS?.getVariable('--main-icons')}
                />
            </span>
        );
    }

    render() {
        const {
            type,
            validationResponse,
            mix,
            visiblePassword,
            id,
            passwordStrengthVisible,
            passwordStrength,
            hidden,
        } = this.props;
        const inputRenderer = this.renderMap[type];

        return (
            <div block="Field" elem="Wrapper" mods={{ type }} hidden={type === FIELD_TYPE.hidden}>
                <div
                    block="Field"
                    mods={{
                        type,
                        isValid: validationResponse === true,
                        hasError: validationResponse !== true && Object.keys(validationResponse || {}).length !== 0,
                        visiblePassword,
                        hidden,
                    }}
                    mix={mix}
                >
                    {inputRenderer && inputRenderer()}
                    {type !== FIELD_TYPE.checkbox &&
                        type !== FIELD_TYPE.radio &&
                        type !== FIELD_TYPE.switcher &&
                        this.renderLabel()}
                    {type === FIELD_TYPE.password && this.renderPasswordIcon()}
                </div>
                {this.renderErrorMessages()}
                {this.renderSubLabel()}
                {passwordStrengthVisible && <PasswordStrength password={passwordStrength} id={id} />}
            </div>
        );
    }
}

export default Field;
