/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { LOAD_POST, NO_POST, UPDATE_SINGLE_POST } from './PostsDetails.action';

/** @namespace SwiatKsiazkiBasic/Blog/Store/PostsDetails/Reducer/getInitialState */
export const getInitialState = () => ({
    post: {},
    isMatched: false,
    isLoaded: false,
});

/** @namespace SwiatKsiazkiBasic/Blog/Store/PostsDetails/Reducer/PostsDetailsReducer */
export const PostsDetailsReducer = (state = getInitialState(), action) => {
    switch (action.type) {
        case UPDATE_SINGLE_POST:
            const { post } = action;

            return {
                ...state,
                post,
                isMatched: true,
                isLoaded: true,
            };

        case NO_POST: {
            return {
                post: {},
                isMatched: false,
                isLoaded: true,
            };
        }
    case LOAD_POST: {
            return {
                post: {},
                isMatched: false,
                isLoaded: false,
            };
        }
        default:
            return state;
    }
};

export default PostsDetailsReducer;
