import Breadcrumb from 'Component/Breadcrumb';
import ContentWrapper from 'Component/ContentWrapper';
import { GIFT_CARDS_ORDER_SUCCESS_URL_FRAGMENT } from 'Route/GiftCardsOrderSuccess/GiftCardsOrderSuccess.config';
import { INVOICE_INFORMATION } from 'Route/InvoiceInformation/InvoiceInformation.config';
import { ACCOUNT_SALES_PRINT } from 'Route/MyAccount/MyAccount.config';
import { Breadcrumbs as SourceBreadcrumbs } from 'SourceComponent/Breadcrumbs/Breadcrumbs.component';
import { appendWithStoreCode } from 'Util/Url';

import './Breadcrumbs.override.style';

/** @namespace SwiatKsiazkiBasic/Component/Breadcrumbs/Component */
export class Breadcrumbs extends SourceBreadcrumbs {
    renderBreadcrumb({ url, name }, i) {
        const { breadcrumbs } = this.props;
        const isDisabled = !url || breadcrumbs.length - 1 === i;

        if (name === 'GRUPY') {
            return null;
        }

        return <Breadcrumb name={name} url={url} index={i + 1} key={i} isDisabled={isDisabled} />;
    }

    render() {
        const { breadcrumbs, areBreadcrumbsVisible, isTablet, isMobile } = this.props;

        if (
            !areBreadcrumbsVisible ||
            (isTablet && location.pathname.match(appendWithStoreCode('/customer'))) ||
            isMobile
        ) {
            return null;
        }

        if (
            !areBreadcrumbsVisible ||
            location.pathname === appendWithStoreCode('/') ||
            location.pathname.match(appendWithStoreCode('/cart')) ||
            location.pathname.match(appendWithStoreCode('/menu')) ||
            location.pathname.match(appendWithStoreCode(GIFT_CARDS_ORDER_SUCCESS_URL_FRAGMENT)) ||
            location.pathname.match(appendWithStoreCode(ACCOUNT_SALES_PRINT)) ||
            location.pathname.match(appendWithStoreCode(INVOICE_INFORMATION)) ||
            location.pathname === '/'
        ) {
            return null;
        }

        return (
            <ContentWrapper mix={{ block: 'Breadcrumbs' }} label={__('Breadcrumbs (current location)...')}>
                <nav aria-label="Breadcrumbs navigation">
                    <ul block="Breadcrumbs" elem="List" itemScope itemType="http://schema.org/BreadcrumbList">
                        {breadcrumbs.length ? this.renderBreadcrumbList(breadcrumbs) : null}
                    </ul>
                </nav>
            </ContentWrapper>
        );
    }
}

export default Breadcrumbs;
