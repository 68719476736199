import { CUSTOMER } from 'Store/MyAccount/MyAccount.dispatcher';
import { isInitiallySignedIn } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';

const addCustomerToInitialState = (args, callback) => ({
    ...callback(...args),
    customer: isInitiallySignedIn() ? BrowserDatabase.getItem(CUSTOMER) || {} : {},
});

export default {
    'Store/MyAccount/Reducer/getInitialState': {
        function: addCustomerToInitialState,
    },
};
