/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import getStore from 'Util/Store';

import { FB_VIEW_CATEGORY, PixelObservable } from '../../../../../../src/component/Pixel';
import { waitForCallback } from '../util/wait';
import { getPageData } from './page';
import { getProductListEntryData } from './product';

/** @namespace SwiatKsiazkiBasic/Gtm/Data/List/getProductImpressionsData */
export const getProductImpressionsData = async (products, positions, forcedList) => {
    // vvv Wait for currency code
    await waitForCallback(() => getStore().getState().ConfigReducer?.currencyData?.current_currency_code);

    const currencyCode = getStore().getState().ConfigReducer?.currencyData?.current_currency_code;

    const batchedImpressions = await products.map(async (product, index) => ({
        ...(await getProductListEntryData(product, positions[index], forcedList))
    }));

    PixelObservable.send(FB_VIEW_CATEGORY, { products, currencyCode });

    return {
        ecommerce: {
            currencyCode,
            impressions: await Promise.all(batchedImpressions)
        }
    };
};

/** @namespace SwiatKsiazkiBasic/Gtm/Data/List/getProductClickData */
export const getProductClickData = async (product, position, forcedList) => {
    const { pageType: list } = await getPageData();
    // ^^^ Reuse page data as list information

    return {
        ecommerce: {
            click: {
                actionField: {
                    list: forcedList || list
                },
                products: [await getProductListEntryData(product, position)]
            }
        }
    };
};
