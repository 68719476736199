export {
    default as Cookie,
    getCookie,
    hasCookie,
    getAmastyAllowedCookie,
    hasAmastyAllowedCookie,
    AMASTY_ALLOWED_COOKIE_NAME,
    AMASTY_COOKIE_GROUP,
    updateGoogleConsent,
} from './Cookie';
