import { PureComponent } from 'react';

import Icon from 'Component/Icon';

import { CLASS_CHECK } from './RulesCtaWidget.config';

import './RulesCtaWidget.style';

/** @namespace SwiatKsiazkiBasic/Component/RulesCtaWidget/Component */
export class RulesCtaWidget extends PureComponent {
    renderElement(item) {
        const { isMobile } = this.props;
        const {
            name: Tag,
            children: [element],
        } = item;

        if (element.data) {
            const { data } = element;

            return (
                <Tag block="RulesCtaWidget" elem="Title">
                    {data}
                </Tag>
            );
        }

        if (element?.children) {
            return item.children.map((childrenElement) => {
                const { children } = childrenElement;
                const {
                    name: ChildTag,
                    attribs: { href, target },
                    children: [child],
                } = children[0];

                if (!child) {
                    return null;
                }

                return (
                    <ChildTag
                        block="RulesCtaWidget"
                        elem={CLASS_CHECK[child.data]}
                        download={CLASS_CHECK[child.data] === 'Download' ? 'download' : undefined}
                        href={href || ''}
                        {...(target ? { target } : {})}
                    >
                        {CLASS_CHECK[child.data] === 'Download' && (
                            <Icon name="download" width="16" height="16" fill="#000" />
                        )}
                        {!isMobile && child.data}
                    </ChildTag>
                );
            });
        }

        return null;
    }

    render() {
        const { items, isMobile } = this.props;

        return (
            <div block="RulesCtaWidget" mods={{ isMobile }}>
                {items.map((item) => this.renderElement(item))}
            </div>
        );
    }
}

export default RulesCtaWidget;
