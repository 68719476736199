export const mapStyles = [
    {
        featureType: 'landscape.natural',
        elementType: 'geometry.fill',
        stylers: [
            {
                lightness: 55,
            },
        ],
    },
];
