// import PropTypes from 'prop-types';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { ExtendedLocationsType } from 'Type/Locations.type';

import MapSidebar from './MapSidebar.component';

/** @namespace SwiatKsiazkiBasic/Component/MapSidebar/Container */
export class MapSidebarContainer extends PureComponent {
    static propTypes = {
        locations: ExtendedLocationsType,
        handleSearch: PropTypes.func,
        handleClickLocation: PropTypes.func,
    };

    containerProps() {
        const { locations, handleSearch, handleClickLocation, handleNearestLocation } = this.props;

        return {
            locations,
            handleSearch,
            handleClickLocation,
            handleNearestLocation,
            isGeolocation: !!navigator.geolocation,
        };
    }

    render() {
        return <MapSidebar {...this.containerProps()} />;
    }
}

export default MapSidebarContainer;
