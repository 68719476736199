import { connect } from 'react-redux';

import CloseIcon from 'Component/CloseIcon';
import Logo from 'Component/Logo';
import { InstallPromptIOS as SourceInstallPromptIOS } from 'SourceComponent/InstallPromptIOS/InstallPromptIOS.component';
import media from 'Util/Media';
import { LOGO_MEDIA } from 'Util/Media/Media';

/** @namespace SwiatKsiazkiBasic/Component/InstallPromptIOS/Component/mapStateToProps */
export const mapStateToProps = (state) => ({
    header_logo_src: state.ConfigReducer.header_logo_src,
});

/** @namespace SwiatKsiazkiBasic/Component/InstallPromptIOS/Component/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace SwiatKsiazkiBasic/Component/InstallPromptIOS/Component */
export class InstallPromptIOS extends SourceInstallPromptIOS {
    renderLogoImage() {
        const { header_logo_src, logo_alt } = this.props;
        const logoSrc = header_logo_src ? media(header_logo_src, LOGO_MEDIA) : null;

        return <Logo src={logoSrc} alt={logo_alt} title={logo_alt} />;
    }

    renderCloseButton() {
        const { handleBannerClose } = this.props;

        return (
            <button block="InstallPromptIOS" elem="Close" onClick={handleBannerClose} aria-label={__('Close')}>
                <CloseIcon />
            </button>
        );
    }

    renderInstallButton() {
        const { handleAppInstall } = this.props;

        return (
            <button block="InstallPromptIOS" elem="Button" onClick={handleAppInstall}>
                {this.renderLogoImage()}
                <p block="InstallPromptIOS" elem="Content">
                    <span>{__('Use the system icon')}</span>
                    <span block="InstallPromptIOS" elem="Share" />
                    <span>{__('(at the bottom of the screen), then')}</span>
                    <span block="InstallPromptIOS" elem="Plus" />
                    <span>{__('Add to Home Screen')}</span>
                    <span>{__('to install the application')}</span>
                </p>
            </button>
        );
    }

    render() {
        return (
            <div block="InstallPromptIOS">
                {this.renderInstallButton()}
                {this.renderCloseButton()}
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InstallPromptIOS);
