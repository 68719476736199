import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import HeadingWidget from './HeadingWidget.component';

/** @namespace SwiatKsiazkiBasic/Component/HeadingWidget/Container */
export class HeadingWidgetContainer extends PureComponent {
    static propTypes = {
        url: PropTypes.string,
    };

    containerProps() {
        const { text, url, html } = this.props;

        return {
            text,
            url,
            html,
        };
    }

    render() {
        return <HeadingWidget {...this.containerProps()} />;
    }
}

export default HeadingWidgetContainer;
