import { NEW_VERSION_POPUP_ID } from 'Component/NewVersionPopup/NewVersionPopup.config';
import { HIDE_ACTIVE_OVERLAY, TOGGLE_OVERLAY, TOGGLE_OVERLAY_WITH_PROPS } from 'Store/Overlay/Overlay.action';
import { SHOW_POPUP } from 'Store/Popup/Popup.action';

/** @namespace SwiatKsiazkiBasic/Store/Overlay/Reducer/getInitialState */
export const getInitialState = () => ({
    activeOverlay: '',
    option: null,
    areOtherOverlaysOpen: false,
});

/** @namespace SwiatKsiazkiBasic/Store/Overlay/Reducer/OverlayReducer */
export const OverlayReducer = (state = getInitialState(), action) => {
    const { overlayKey } = action;
    const { activeOverlay: prevActiveOverlay } = state;

    switch (action.type) {
        case TOGGLE_OVERLAY:
            if (prevActiveOverlay === NEW_VERSION_POPUP_ID) {
                return state;
            }

            return {
                ...state,
                activeOverlay: overlayKey,
            };
        case TOGGLE_OVERLAY_WITH_PROPS:
            const {
                payload: { overlayKey: overlayKeyWithProps, option },
            } = action;

            if (prevActiveOverlay === NEW_VERSION_POPUP_ID) {
                return state;
            }

            return {
                ...state,
                activeOverlay: overlayKeyWithProps,
                option,
            };
        case SHOW_POPUP:
            const activeOverlay = prevActiveOverlay === overlayKey ? '' : overlayKey;
            const areOtherOverlaysOpen = prevActiveOverlay !== '';

            if (prevActiveOverlay === NEW_VERSION_POPUP_ID) {
                return state;
            }

            return {
                ...state,
                activeOverlay,
                areOtherOverlaysOpen,
            };

        case HIDE_ACTIVE_OVERLAY:
            return {
                ...state,
                activeOverlay: '',
                areOtherOverlaysOpen: false,
            };

        default:
            return state;
    }
};

export default OverlayReducer;
