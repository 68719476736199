import { PureComponent } from 'react';

import './SliderPrevArrow.style.scss';

/** @namespace SwiatKsiazkiBasic/AdvoxSlider/Components/SliderPrevArrow/Component/SliderPrevArrowComponent */
export class SliderPrevArrowComponent extends PureComponent {
    render() {
        const { onClick, className } = this.props;
        const isDisabled = className.search('disabled') !== -1;

        return (
            <div block="SliderPrevArrow" mods={{ disabled: isDisabled }} onClick={onClick}>
                <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="20" cy="20.648" r="20" transform="rotate(180 20 20.648)" fill="white"/>
                    <path opacity="0.5" fill-rule="evenodd" clip-rule="evenodd" d="M16.9279 20.6413L23.1816 26.9816C24.0274 27.8183 22.6337 29.248 21.7879 28.3756L14.6766 21.2319C14.3669 20.912 14.3669 20.3952 14.6766 20.1122L21.7879 12.902C22.6337 12.0654 24.0274 13.4926 23.1816 14.3293L16.9279 20.6413Z" fill="black"/>
                </svg>
            </div>
        );
    }
}

export default SliderPrevArrowComponent;
