import PRODUCT_TYPE from 'Component/Product/Product.config';
import ProductLabel from 'Component/ProductLabel';
import { ProductPrice as SourceProductPrice } from 'SourceComponent/ProductPrice/ProductPrice.component';
import { LabelType } from 'Type/Field.type';
import { DEFAULT_CURRENCY } from 'Util/Currency/Currency';
import { formatPrice } from 'Util/Price';
import { getOmnibusPriceInfo, verifyOmnibus } from 'Util/Product/Product';

import { SHOW_DISCOUNT_AMOUNT } from './ProductPrice.config';

import './ProductPrice.override.style';

/** @namespace SwiatKsiazkiBasic/Component/ProductPrice/Component */
export class ProductPrice extends SourceProductPrice {
    static propTypes = {
        ...SourceProductPrice.propTypes,
        label: LabelType,
    };

    static defaultProps = {
        ...SourceProductPrice.defaultProps,
        priceCurrency: DEFAULT_CURRENCY,
    };

    getCurrentPriceSchema() {
        const {
            isSchemaRequired,
            variantsCount,
            price: { finalPrice: { value: contentPrice = 0 } = {} } = {},
        } = this.props;

        if (variantsCount > 1) {
            return isSchemaRequired ? { itemProp: 'lowPrice', content: contentPrice.toFixed(2) } : {};
        }

        return isSchemaRequired ? { itemProp: 'price', content: contentPrice.toFixed(2) } : {};
    }

    renderPrice(price, label) {
        const { discountPercentage } = this.props;

        const { value: priceValue, valueFormatted: priceFormatted = 0 } = price;

        const { itemProp = null, content = null } = this.getCurrentPriceSchema();

        // Use <ins></ins> <del></del> to represent new price and the old (deleted) one
        const PriceSemanticElementName = discountPercentage > 0 ? 'ins' : 'span';

        // force unequal comparison - unsure of resulting type
        // eslint-disable-next-line
        if (priceValue == 0) {
            return null;
        }

        return (
            <PriceSemanticElementName block="ProductPrice" elem="Price">
                {this.renderPriceBadge(label)}
                <span itemProp={itemProp} content={content} block="ProductPrice" elem="PriceValue">
                    {priceFormatted}
                </span>
            </PriceSemanticElementName>
        );
    }

    renderOmnibus() {
        const { omnibus } = this.props;
        const { product, ...props } = omnibus || {};

        return verifyOmnibus(product, getOmnibusPriceInfo(product, props));
    }

    renderDiscountLabel() {
        const { discountPercentage, priceAlert } = this.props;
        const { discount_percent: priceAlertDiscountPercent = 0 } = priceAlert || {};

        if (
            (!discountPercentage || discountPercentage <= 0) &&
            (!priceAlertDiscountPercent || priceAlertDiscountPercent <= 0)
        ) {
            return null;
        }

        return <ProductLabel type="discount" discount={discountPercentage || priceAlertDiscountPercent} />;
    }

    renderDiscountAmount() {
        const { price: { discount: { amountOff } = {} } = {}, isPdp } = this.props;

        if (!SHOW_DISCOUNT_AMOUNT || !isPdp || !amountOff) {
            return null;
        }

        return (
            <span block="ProductPrice" elem="DiscountAmount">
                {__('You save %s', formatPrice(amountOff))}
            </span>
        );
    }

    renderSuggestedOrStartingPrice() {
        const {
            price: { originalPrice: { value: originalPriceValue, valueFormatted: originalPriceFormatted } = {} } = {},
            discountPercentage,
            isSchemaRequired,
            variantsCount,
            isStartingPrice,
            isSuggestedPrice,
        } = this.props;

        if (discountPercentage === 0 || originalPriceValue === 0) {
            return null;
        }

        /* eslint-disable */
        const priceLabel = isStartingPrice
            ? __('Supplier list price')
            : isSuggestedPrice
                ? __('Publishers suggested price')
                : '';
        /* eslint-enable */

        return (
            <span
                block="ProductPrice"
                elem="HighPrice"
                aria-label={priceLabel}
                itemProp={isSchemaRequired && variantsCount > 1 ? { itemProp: 'highPrice' } : null}
            >
                {`${priceLabel}: ${originalPriceFormatted}`}
            </span>
        );
    }

    renderUOMValue() {
        const { uom } = this.props;
        const { product } = uom ?? {};
        const { uom_value, uom_quantity, uom_price } = product ?? {};

        if (!uom_quantity) {
            return null;
        }

        const { value, currency } = uom_price ?? {};

        return (
            <span block="ProductPrice" elem="UOMValue">
                {formatPrice(value, currency)} / {uom_value}
            </span>
        );
    }

    renderProductPriceIntoProductAction(defaultLabel) {
        const { price: { finalPrice = {}, finalPriceExclTax = {} } = {}, label } = this.props;

        return (
            <>
                {this.renderPriceWithOrWithoutTax(finalPrice, finalPriceExclTax, defaultLabel || label)}
                {this.renderUOMValue()}
                {this.renderOmnibus()}
                {this.renderSuggestedOrStartingPrice()}
                {this.renderDiscountAmount()}
                {this.renderSchema()}
            </>
        );
    }

    renderWishlistItemPrice() {
        const { priceAlert: { price } = {} } = this.props;

        return (
            <>
                {this.renderSuggestedOrStartingPrice()}
                {price ? (
                    <span block="ProductPrice" elem="OldWishlistPrice">
                        {__('Price at time of adding to wishlist: %s', formatPrice(price))}
                    </span>
                ) : null}
            </>
        );
    }

    renderDefaultPrice(defaultLabel = null) {
        const {
            isInProductAction,
            price: { finalPrice = {}, finalPriceExclTax = {} } = {},
            label,
            isWishlistItem,
        } = this.props;

        if (isInProductAction) {
            return this.renderProductPriceIntoProductAction(defaultLabel);
        }

        if (isWishlistItem) {
            return this.renderWishlistItemPrice(defaultLabel);
        }

        return (
            <>
                {this.renderPriceWithOrWithoutTax(finalPrice, finalPriceExclTax, defaultLabel || label)}
                {this.renderOmnibus()}
                {this.renderDiscountAmount()}
                {this.renderSchema()}
            </>
        );
    }

    render() {
        const {
            price: { finalPrice, originalPrice, finalPrice: { value: finalPriceValue = 0 } = {} } = {},
            priceType,
            isPreview,
            isOmnibus,
            discountPercentage,
            mix,
        } = this.props;

        if (!finalPrice || !originalPrice) {
            return this.renderPlaceholder();
        }

        const { [priceType]: renderer } = this.pricePreviewRenderMap;

        return (
            <div
                block="ProductPrice"
                mods={{ hasDiscount: discountPercentage !== 0, hasOmnibus: isOmnibus, isPreview }}
                mix={mix}
                aria-label={`Product price: ${finalPriceValue}`}
            >
                {isPreview && renderer && renderer()}
                {(!isPreview || !renderer) && this.renderDefaultPrice()}
                {priceType !== PRODUCT_TYPE.bundle && this.renderTierPrice()}
            </div>
        );
    }
}

export default ProductPrice;
