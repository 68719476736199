import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import RulesCtaWidget from './RulesCtaWidget.component';

/** @namespace SwiatKsiazkiBasic/Component/RulesCtaWidget/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace SwiatKsiazkiBasic/Component/RulesCtaWidget/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device,
});

/** @namespace SwiatKsiazkiBasic/Component/RulesCtaWidget/Container */
export class RulesCtaWidgetContainer extends PureComponent {
    static propTypes = {
        items: PropTypes.any,
    };

    containerProps() {
        const {
            items,
            device: { isMobile },
        } = this.props;

        return {
            items,
            isMobile,
        };
    }

    render() {
        return <RulesCtaWidget {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RulesCtaWidgetContainer);
