import { PureComponent } from 'react';

import Icon from 'Component/Icon';

import './ScrollToTop.style';

/** @namespace SwiatKsiazkiBasic/Component/ScrollToTop/Component */
export class ScrollToTop extends PureComponent {
    renderScrollTop() {
        const { scrollToTop, isScrollVisible, isCart } = this.props;

        return (
            <button block="ScrollToTop" onClick={scrollToTop} mods={{ isVisible: isScrollVisible, isCart }}>
                <Icon name="arrow_up" fill="#ffffff" width="16" height="23" />
            </button>
        );
    }

    render() {
        const { isVisibleOnCurrentLocation } = this.props;

        if (!isVisibleOnCurrentLocation) {
            return null;
        }

        return this.renderScrollTop();
    }
}

export default ScrollToTop;
