import { LOAD_POSTS, UPDATE_POSTS} from './Posts.action';

export const emptyPosts = {
    items: [],
    count: 0
};

export const initialState = {
    posts: emptyPosts,
    post: {}
};

/** @namespace SwiatKsiazkiBasic/Blog/Store/Posts/Reducer/PostsReducer */
export const PostsReducer = (state = initialState, action) => {
    switch (action.type) {
    case UPDATE_POSTS:
        const {
            posts: {
 items, total_count: count, total_pages: totalPages, categoryId 
},
            isLoaded
        } = action;

        return {
            ...state,
            posts: {
                items,
                count,
                totalPages,
                categoryId
            },
            isLoaded
        };
        case LOAD_POSTS: {
            return {
                ...state,
                isLoaded: false
            }
        }
    default:
        return state;
    }
};

export default PostsReducer;
