import { PureComponent } from 'react';

import { CLICKONOMETRICS_BLOCK_ID } from './Clickonometrics.config';

/** @namespace SwiatKsiazkiBasic/Component/Clickonometrics/Component */
export class Clickonometrics extends PureComponent {
    render() {
        const { variant, scriptWrapperRef } = this.props;

        return <div block={CLICKONOMETRICS_BLOCK_ID[variant]} ref={scriptWrapperRef} />;
    }
}

export default Clickonometrics;
