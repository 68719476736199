import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MetaContainer as SourceMetaContainer,
} from 'SourceComponent/Meta/Meta.container';

/** @namespace SwiatKsiazkiBasic/Component/Meta/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    opengraph: state.MetaReducer.opengraph,
    head_prefix: state.MetaReducer.head_prefix,
});

/** @namespace SwiatKsiazkiBasic/Component/Meta/Container */
export class MetaContainer extends SourceMetaContainer {
    static propTypes = {
        ...SourceMetaContainer.propTypes,
        opengraph: PropTypes.shape({
            'og:type': PropTypes.string.isRequired,
            'og:title': PropTypes.string.isRequired,
            'og:description': PropTypes.string.isRequired,
            'og:image': PropTypes.string.isRequired,
            'og:url': PropTypes.string.isRequired,
            'product:price:amount': PropTypes.string,
            'product:price:currency': PropTypes.string,
        }),
        head_prefix: PropTypes.string,
    };

    static defaultProps = {
        ...SourceMetaContainer.defaultProps,
        opengraph: {},
        head_prefix: '',
    };

    componentDidMount() {
        this._appendPrefixToHead();
    }

    componentDidUpdate(prevProps) {
        const { head_prefix } = this.props;
        const { head_prefix: prev_head_prefix } = prevProps;

        if (prev_head_prefix !== head_prefix && !!head_prefix) {
            this._appendPrefixToHead();
        }

        if (prev_head_prefix !== head_prefix && !head_prefix) {
            this._removePrefixFromHead();
        }
    }

    componentWillUnmount() {
        this._removePrefixFromHead();
    }

    _appendPrefixToHead() {
        const { head_prefix } = this.props;

        if (head_prefix) {
            document.head.setAttribute('prefix', head_prefix);
        }
    }

    _removePrefixFromHead() {
        document.head.removeAttribute('prefix');
    }

    _getOpenGraph() {
        const { opengraph } = this.props;

        return opengraph || {};
    }

    _getRobots() {
        const { robots } = this.props;

        return robots || window.defaultRobots || '';
    }

    _getMetadata() {
        const meta = {
            title: this._getTitle(),
            description: this._getDescription(),
            robots: this._getRobots(),
            'render:status_code': this._getStatusCode(),
            ...this._getOpenGraph(),
        };

        return this._generateMetaFromMetadata(meta);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MetaContainer);
