import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ShowMoreLessContent from 'Component/ShowMoreLessContent/ShowMoreLessContent.component';
import { ChildrenType } from 'Type/Common.type';

/** @namespace SwiatKsiazkiBasic/Component/ShowMoreLessContent/Container */
export class ShowMoreLessContentContainer extends PureComponent {
    static propTypes = {
        children: ChildrenType.isRequired,
        contentType: PropTypes.oneOf(['text', 'elements']),
        contentLength: PropTypes.number,
    };

    static defaultProps = {
        contentType: 'text',
        contentLength: 650,
    };

    state = {
        isExpanded: false,
    };

    containerFunctions = {
        handleClick: this.handleClick.bind(this),
    };

    getDefaultContent() {
        const { children } = this.props;
        if (!children) return null;

        const { props: { content } = {} } = children;

        return content || children;
    }

    getShrunkenContent() {
        const { children, contentType, contentLength } = this.props;
        if (!children) return null;

        if (contentType === 'text') {
            const { props: { content } = {} } = children;
            if (!content) return null;
            return `${content.slice(0, contentLength).trim()}...`;
        }

        if (contentType === 'elements') {
            if (!Array.isArray(children)) return null;
            return children.filter((e, i) => i < contentLength && e);
        }

        return null;
    }

    containerProps() {
        const { isExpanded } = this.state;
        const { contentType, contentLength, afterContent } = this.props;

        const defaultContent = this.getDefaultContent();
        if (!defaultContent) return null;

        const isExtendable = defaultContent.length > contentLength;
        const content = isExtendable && !isExpanded ? this.getShrunkenContent() : defaultContent;

        return {
            defaultContent,
            content,
            contentType,
            isExpanded,
            isExtendable,
            afterContent,
        };
    }

    handleClick(e) {
        e.preventDefault();

        this.setState((prevState) => ({
            isExpanded: !prevState.isExpanded,
        }));
    }

    render() {
        return <ShowMoreLessContent {...this.containerProps()} {...this.containerFunctions} />;
    }
}

export default ShowMoreLessContentContainer;
