import DOMPurify from 'dompurify';

import { PRODUCT_SLIDER_SETTINGS } from 'Component/ProductSlider/ProductSlider.config';
import { getBooleanValue, parseJSON } from 'Util/Data';

/** @namespace SwiatKsiazkiBasic/Util/MagezoneWidget/checkForTabsRowContent */
export const checkForTabsRowContent = (item, indexElem = null, attributesToProps) => {
    let tabRow = {};

    if (item.attribs && item.attribs?.type === 'ProductSlider') {
        const { class: classVariant, double_slider, items, ...attribs } = item.attribs || {};

        tabRow.productSlider = attributesToProps({
            classVariant,
            is_double_slider: double_slider === '1',
            productsSkuArray: parseJSON(items, []),
            ...attribs,
        });
    }

    if (item.attribs && item.attribs?.type === 'Banner') {
        tabRow.banner = [attributesToProps(item.attribs)];
    }

    if (item.attribs && item.attribs?.type === 'Button') {
        tabRow.button = attributesToProps(item.attribs);
    }

    if (!item.attribs && item.data && !item.children) {
        tabRow[indexElem] = {
            content: item.data,
            tag: item?.name,
        };
    }

    if (item.attribs && !item.data && item.children) {
        item.children.forEach((child) => {
            if (child.data) {
                tabRow[indexElem === 0 ? indexElem : `${indexElem}1`] = {
                    content: child.data,
                    tag: item?.name,
                    class: child?.parent?.attribs?.class,
                    style: item?.attribs?.style,
                    elementID: indexElem,
                };

                return;
            }

            if (child?.parent?.attribs?.class?.includes('mgz-cta')) {
                tabRow[indexElem] = {
                    content: child.parent,
                    tag: child.parent?.name,
                    class: child?.parent?.attribs?.class,
                };

                return;
            }

            if (!child.data && child.children.length > 0) {
                item.children.forEach((secondChild, secondIndex) => {
                    if (!secondChild?.children?.[0]?.data) {
                        return null;
                    }

                    tabRow[`${indexElem}${secondIndex}`] = {
                        content: secondChild?.children?.[0]?.data,
                        tag: secondChild?.name,
                        parentTag: item?.type === 'tag' && item?.name,
                        class: child?.parent?.attribs?.class,
                        style: secondChild?.attribs?.style,
                        elementID: indexElem,
                        attribs: item?.children?.[0]?.attribs,
                        parentAttribs: item?.attribs,
                    };
                });
            }

            if (child?.attribs?.class?.includes('separator-line')) {
                tabRow[indexElem] = {
                    content: '',
                    tag: child?.name,
                    class: 'SeparatorLine',
                };
            }
        });

        if (!item?.children?.length && item.name === 'br') {
            tabRow[indexElem] = {
                content: '',
                tag: item.name,
                elementID: indexElem,
                selfClosingTag: true,
            };
        }
    }

    if (item.attribs && item.attribs['data-cms-block-id']) {
        const classBlock = item.attribs.class;
        tabRow = {
            content: item.attribs,
            tag: 'cms_block',
            class: classBlock,
        };
    }

    return tabRow;
};

/** @namespace SwiatKsiazkiBasic/Util/MagezoneWidget/checkForTabs */
export const checkForTabs = (item, attributesToProps) => {
    const tabs = [];

    if (item) {
        item.children.forEach((child) => {
            if (child.attribs && child.attribs?.type === 'Tab') {
                const currentTab = {
                    rows: [],
                };

                child.children.forEach((tabChild1) => {
                    // eslint-disable-next-line fp/no-let
                    let tabRow = {};
                    if ('data-widget-row' in (tabChild1.attribs || {})) {
                        tabChild1.children.forEach((tabChild2, index) => {
                            const { banner, ...rowContent } = checkForTabsRowContent(
                                tabChild2,
                                index,
                                attributesToProps
                            );

                            tabRow = {
                                ...tabRow,
                                ...rowContent,
                                banner: [...(tabRow.banner ?? []), ...(banner ?? [])],
                            };
                        });
                    } else {
                        tabRow = {
                            tabRow,
                            ...checkForTabsRowContent(tabChild1, null, attributesToProps),
                        };
                    }

                    currentTab.rows.push(tabRow);
                });

                currentTab.title = child.attribs?.title;
                tabs.push(currentTab);
            }
        });
    }

    return tabs;
};

/** @namespace SwiatKsiazkiBasic/Util/MagezoneWidget/checkForProductListsWithSliderWidgetItems */
export const checkForProductListsWithSliderWidgetItems = (item) => {
    const currentWidgetPropsItems = [];

    item.children.forEach((childItem, index) => {
        if (childItem.attribs && childItem.attribs?.type === 'ProductSlider') {
            currentWidgetPropsItems.push({
                title: item.children[index - 3].attribs?.text,
                max_items: item.children[index - 2].attribs?.max_items,
                url: item.children[index - 2].attribs?.url,
                order: item.children[index - 2]?.attribs?.order,
                direction: item.children[index - 2]?.attribs?.direction,
                categoryId: item.children[index - 2]?.attribs?.category_id,
                productsSkuArray: parseJSON(item.children[index - 2]?.attribs?.items, []),
                component: 'ProductList',
            });

            currentWidgetPropsItems.push({
                max_items: childItem.attribs?.max_items,
                productsSkuArray: parseJSON(childItem.attribs?.items, []),
                conditions_encoded: childItem.attribs?.conditions_encoded,
                title: item.children[index - 1].attribs?.text,
                component: 'ProductSlider',
                order: childItem.attribs?.order,
                direction: childItem.attribs?.direction,
                categoryId: childItem.attribs?.category_id,
                double_slider: childItem.attribs?.double_slider === '1',
            });

            currentWidgetPropsItems.push({
                title: item.children[index + 1].attribs?.text,
                max_items: item.children[index + 2].attribs?.max_items,
                url: item.children[index + 2].attribs?.url,
                order: item.children[index + 2]?.attribs?.order,
                direction: item.children[index + 2]?.attribs?.direction,
                categoryId: item.children[index + 2]?.attribs?.category_id,
                productsSkuArray: parseJSON(item.children[index + 2]?.attribs?.items, []),
                component: 'ProductList',
            });
        }
    });

    return currentWidgetPropsItems;
};

/** @namespace SwiatKsiazkiBasic/Util/MagezoneWidget/checkForCategoriesGridImagesWithSingleImage */
export const checkForCategoriesGridImagesWithSingleImage = (item) => {
    const currentWidgetPropsItems = [];

    currentWidgetPropsItems.singleItem = {
        url: item[0]?.attribs?.url,
        src: item[1]?.children[0]?.children[0]?.attribs?.src,
        text: item[2]?.children[0]?.children[0]?.children[0]?.data,
        heading: item[3]?.children[0]?.children[0]?.children[0]?.data,
    };
    currentWidgetPropsItems.items = [];
    const buttonIndex = item[3]?.attribs?.type === 'Button' ? 3 : 4;

    item[buttonIndex]?.children?.forEach((thirdChild, index) => {
        if (thirdChild?.attribs?.type === 'Button') {
            const thirdChildObj = {
                url: item[buttonIndex]?.children?.[index]?.attribs?.url,
                src: item[buttonIndex]?.children?.[index + 1]?.children[0]?.children[0]?.attribs?.src,
                text: item[buttonIndex]?.children?.[index + 2]?.children[0]?.children[0]?.children[0]?.data,
                heading: item[buttonIndex]?.children[index + 3]?.children[0]?.children[0]?.children[0].data,
            };

            currentWidgetPropsItems.items.push(thirdChildObj);
        }
    });

    return currentWidgetPropsItems;
};

/** @namespace SwiatKsiazkiBasic/Util/MagezoneWidget/isPageBuilderEnable */
export const isPageBuilderEnable = (initialElement) => {
    if (initialElement instanceof HTMLElement) {
        let element = initialElement;

        while (element && element.parentNode) {
            if (element.tagName !== 'BODY') {
                if (element.classList.contains('page-builder-enable')) {
                    return true;
                }

                element = element.parentNode;
            } else {
                break;
            }
        }

        return false;
    }

    return initialElement?.attribs?.class?.includes('page-builder-enable');
}; /** @namespace SwiatKsiazkiBasic/Util/MagezoneWidget/isTextBlockElement */

export const isTextBlockElement = (initialElement) => {
    if (initialElement instanceof HTMLElement) {
        let element = initialElement;

        while (element && element.parentNode) {
            if (element.tagName !== 'BODY') {
                if (element.classList.contains('mgz-element-text')) {
                    return true;
                }

                element = element.parentNode;
            } else {
                break;
            }
        }

        return false;
    }
};

/** @namespace SwiatKsiazkiBasic/Util/MagezoneWidget/sanitizeHTML */
export const sanitizeHTML = (value) => {
    try {
        return DOMPurify.sanitize(value);
    } catch {
        return '';
    }
};

/** @namespace SwiatKsiazkiBasic/Util/MagezoneWidget/removeMagezoneTags */
export const removeMagezoneTags = (content, removeAllTags = false) => {
    const parsedFromString = new DOMParser().parseFromString(content, 'text/html');
    const ignoredTags = ['SVG', 'CIRCLE'];

    parsedFromString.body.querySelectorAll('[class*="mgz-"]:not(.mgz-button), [data-widget-row]').forEach((element) => {
        if (element && !ignoredTags.includes(element?.tagName?.toUpperCase())) {
            element.querySelectorAll('[data-cms-block-id]').forEach((cmsBlock) => {
                if (cmsBlock?.getAttribute('data-cms-block-id')?.includes('pwa-')) {
                    cmsBlock.parentElement.querySelectorAll('[class*="mgz-"]').forEach((tab) => {
                        if (tab.tagName === 'STYLE' || tab.getAttributeNames().includes('data-background-type')) {
                            tab.remove();
                        } else if (
                            tab.className.includes('mgz-') ||
                            element.getAttributeNames().includes('data-widget-row')
                        ) {
                            tab.replaceWith(...tab.childNodes);
                        }
                    });
                }
            });

            if (isTextBlockElement(element)) {
                element.querySelectorAll('img').forEach((imageElement) => {
                    imageElement.setAttribute('lazy-mode', 'native');
                });
            }

            if (removeAllTags && !isPageBuilderEnable(element)) {
                if (element.tagName === 'STYLE' || element.getAttributeNames().includes('data-background-type')) {
                    element.remove();
                } else if (element.tagName !== 'SCRIPT' && !element.getAttributeNames().includes('data-widget-row')) {
                    element.replaceWith(...element.childNodes);
                }
            }
        }
    });

    return parsedFromString.body.innerHTML;
};

/** @namespace SwiatKsiazkiBasic/Util/MagezoneWidget/getSliderSettings */
export const getSliderSettings = (options = {}, settings = {}, defaultSettings = PRODUCT_SLIDER_SETTINGS) => {
    try {
        const {
            autoHeight: adaptiveHeight,
            autoplay,
            autoplayTimeout: autoplaySpeed,
            center: centerMode,
            dots,
            item_lg,
            item_md,
            item_sm,
            item_xl,
            item_xs,
            lazyLoad,
            loop: infinite,
            nav: arrows,
            rtl,
        } = parseJSON(options);

        const getSlidesDefaultValue = (value) =>
            Number(value || settings?.slidesToShow || defaultSettings?.slidesToShow || 1);
        const getNumberValue = (value) => (Number.isNaN(Number(value)) ? 0 : Number(value));
        const getBooleanValue = (value) => value || false;

        return {
            ...defaultSettings,
            speed: 800,
            draggable: true,
            autoplay: getBooleanValue(autoplay),
            lazyLoad: getBooleanValue(lazyLoad),
            infinite: getBooleanValue(infinite),
            centerMode: getBooleanValue(centerMode),
            adaptiveHeight: getBooleanValue(adaptiveHeight),
            dots: getBooleanValue(dots),
            arrows: getBooleanValue(arrows),
            rtl: getBooleanValue(rtl),
            responsive: [
                {
                    breakpoint: 1280,
                    settings: {
                        slidesToShow: getSlidesDefaultValue(item_lg),
                        slidesToScroll: Math.round(getSlidesDefaultValue(item_lg)),
                    },
                },
                {
                    breakpoint: 1023,
                    settings: {
                        slidesToShow: getSlidesDefaultValue(item_md),
                        slidesToScroll: Math.round(getSlidesDefaultValue(item_md)),
                    },
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: getSlidesDefaultValue(item_sm),
                        slidesToScroll: Math.round(getSlidesDefaultValue(item_sm)),
                    },
                },
                {
                    breakpoint: 480,
                    settings: {
                        arrows: false,
                        slidesToShow: getSlidesDefaultValue(item_xs),
                        slidesToScroll: Math.round(getSlidesDefaultValue(item_xs)),
                    },
                },
            ],
            ...settings,
            slidesToShow: getSlidesDefaultValue(item_xl),
            slidesToScroll: Math.round(getSlidesDefaultValue(item_xl)),
            autoplaySpeed: getNumberValue(autoplaySpeed || settings?.autoplaySpeed || defaultSettings?.autoplaySpeed),
        };
    } catch {
        return {
            ...defaultSettings,
            ...settings,
        };
    }
};

/** @namespace SwiatKsiazkiBasic/Util/MagezoneWidget/getHrefAttributes */
export const getHrefAttributes = ({ nofollow, blank }) => ({
    ...(getBooleanValue(nofollow) ? { ref: 'nofollow' } : {}),
    ...(getBooleanValue(blank) ? { target: '_blank' } : {}),
});
