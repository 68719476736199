import { PureComponent } from 'react';

import './SliderNextArrow.style.scss';

/** @namespace SwiatKsiazkiBasic/AdvoxSlider/Components/SliderNextArrow/Component/SliderNextArrowComponent */
export class SliderNextArrowComponent extends PureComponent {
    render() {
        const { onClick, className } = this.props;
        const isDisabled = className.search('disabled') !== -1;

        return (
            <div block="SliderNextArrow" mods={{ disabled: isDisabled }} onClick={onClick}>
                <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="20" cy="20.5" r="20" fill="white"/>
                    <path opacity="0.5" fill-rule="evenodd" clip-rule="evenodd" d="M23.0721 20.5067L16.8184 14.1664C15.9726 13.3297 17.3663 11.9 18.2121 12.7723L25.3234 19.9161C25.6331 20.236 25.6331 20.7527 25.3234 21.0357L18.2121 28.2459C17.3663 29.0826 15.9726 27.6553 16.8184 26.8186L23.0721 20.5067Z" fill="black"/>
                </svg>
            </div>
        );
    }
}

export default SliderNextArrowComponent;
