import FIELD_TYPE from 'Component/Field/Field.config';

/** @namespace SwiatKsiazkiBasic/Util/Form/Transform/transformToNameValuePair */
export const transformToNameValuePair = (fields) => {
    const filteredFields = {};
    const arrayFormat = !Array.isArray(fields) ? Object.values(fields) : fields;

    arrayFormat.forEach(({ value, name, field }) => {
        if (field.type === FIELD_TYPE.radio) {
            if (value !== false) {
                filteredFields[name] = value;
            }
        } else {
            filteredFields[name] = value;
        }
    });

    return filteredFields;
};

export default transformToNameValuePair;
