import { GET_VIEWED_PRODUCTS } from 'Store/LastViewedProducts/LastViewedProducts.action';

/** @namespace SwiatKsiazkiBasic/Store/LastViewedProducts/Reducer/getInitialState */
export const getInitialState = () => ({
    lastViewedProducts: [],
});

/** @namespace SwiatKsiazkiBasic/Store/LastViewedProducts/Reducer/LastViewedProductsReducer */
export const LastViewedProductsReducer = (state = getInitialState(), action) => {
    const { type } = action;

    switch (type) {
        case GET_VIEWED_PRODUCTS:
            const { data } = action;
            return {
                ...state,
                lastViewedProducts: data,
            };
        default:
            return state;
    }
};

export default LastViewedProductsReducer;
