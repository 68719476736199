import CookieQuery from 'Query/Cookie.query';
import { ConfigDispatcher as SourceConfigDispatcher } from 'SourceStore/Config/Config.dispatcher';

/** @namespace SwiatKsiazkiBasic/Store/Config/Dispatcher */
export class ConfigDispatcher extends SourceConfigDispatcher {
    prepareRequest() {
        return [...super.prepareRequest(), CookieQuery.getCookieSettings()];
    }
}

export default new ConfigDispatcher();
