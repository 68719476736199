import { addDays, format, isAfter, isBefore, isValid, isWithinInterval } from 'date-fns';
import pl from 'date-fns/locale/pl';

export const DEFAULT_DATE_FORMAT = 'dd.MM.yyyy';

/** @namespace SwiatKsiazkiBasic/Util/Date/formatDate */
export const formatDate = (date, dateFormat = DEFAULT_DATE_FORMAT) => {
    //  Prepare a date for Safari 15 and older.
    try {
        date = (date || '')?.replace(/\s/g, 'T');
    } catch {
        date = typeof data === 'string' ? date || '' : date;
    }

    if (!date || !isValid(new Date(date))) {
        return '';
    }

    return format(new Date(date), dateFormat, {
        locale: pl,
    });
};

/** @namespace SwiatKsiazkiBasic/Util/Date/toDate */
export const toDate = (value) => {
    let _value = value;

    try {
        _value = new Date(_value);

        if (isValid(_value)) {
            return _value;
        }

        return null;
    } catch {
        return null;
    }
};

/** @namespace SwiatKsiazkiBasic/Util/Date/formatDateBack */
export const formatDateBack = (date, onlyDateString = false) => {
    if (!date) {
        return null;
    }

    if (onlyDateString) {
        return `${date.split('.')[2]}-${date.split('.')[1]}-${date.split('.')[0]}`;
    }

    const splittedDate = date.split('.');

    return new Date(splittedDate[2], splittedDate[1] - 1, splittedDate[0]);
};

/** @namespace SwiatKsiazkiBasic/Util/Date/inRange */
export const inRange = (start, end, now = new Date()) => {
    if (!start && !end) return true;
    if (start && !end) return isAfter(now, new Date(start?.replace(' ', 'T')));
    if (!start && end) return isBefore(now, addDays(new Date(end?.replace(' ', 'T')), 1));
    return isWithinInterval(now, {
        start: new Date(start?.replace(' ', 'T')),
        end: addDays(new Date(end?.replace(' ', 'T')), 1),
    });
};
