import PropTypes from 'prop-types';

export * from 'SourceType/Category.type';

export const CategoriesBreadcrumbs = PropTypes.arrayOf(
    PropTypes.shape({
        text: PropTypes.string,
        url: PropTypes.string,
    })
);
