import { PureComponent } from 'react';

import ProductSlider from './ProductSlider.component';

/** @namespace SwiatKsiazkiBasic/Component/ProductSlider/Container */
export class ProductSliderContainer extends PureComponent {
    containerProps() {
        const {
            items,
            isLoading,
            heading,
            headingHtml,
            settings,
            isLightVariant,
            isMenuVariant,
            isDoubleSlider,
            isPromotedVariant,
            isAlternativeArrow,
            classVariant,
            mix,
            isBanerVariant,
            image,
            url,
            banerTitle,
        } = this.props;

        return {
            items,
            isLoading,
            heading,
            headingHtml,
            settings,
            isPromotedVariant,
            isLightVariant,
            isMenuVariant,
            isDoubleSlider,
            isAlternativeArrow,
            classVariant,
            mix,
            isBanerVariant,
            image,
            url,
            banerTitle,
        };
    }

    render() {
        return <ProductSlider {...this.containerProps()} />;
    }
}

export default ProductSliderContainer;
