import PropTypes from 'prop-types';

import ChevronIcon from 'Component/ChevronIcon';
import { BOTTOM, TOP } from 'Component/ChevronIcon/ChevronIcon.config';
import Icon from 'Component/Icon';
import TextPlaceholder from 'Component/TextPlaceholder';
import { ExpandableContent as SourceExpandableContent } from 'SourceComponent/ExpandableContent/ExpandableContent.component';

import './ExpandableContent.override.style';

/** @namespace SwiatKsiazkiBasic/Component/ExpandableContent/Component */
export class ExpandableContent extends SourceExpandableContent {
    static propTypes = {
        ...super.propTypes,
        heading: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        isExpandableOnDesktop: PropTypes.bool,
        isExpandableDisabled: PropTypes.bool,
        device: PropTypes.object,
        headingIcon: PropTypes.string,
        headingValue: PropTypes.string,
        headingValueIcon: PropTypes.bool,
    };

    static defaultProps = {
        ...super.defaultProps,
        isExpandableOnDesktop: false,
        isExpandableDisabled: false,
        isExpandedOnDesktopOnStart: true,
        headingValueIcon: false,
    };

    static getDerivedStateFromProps(
        {
            isContentExpanded,
            isExpandableDisabled,
            isExpandableOnDesktop,
            isExpandedOnDesktopOnStart,
            device: { isMobile },
        },
        { prevIsContentExpanded }
    ) {
        if (isExpandableDisabled) {
            return null;
        }

        if (isContentExpanded !== prevIsContentExpanded) {
            return {
                prevIsContentExpanded: isContentExpanded,
                isContentExpanded,
            };
        }

        if (isExpandableOnDesktop && !isMobile && isExpandedOnDesktopOnStart !== isContentExpanded) {
            return {
                prevIsContentExpanded: isExpandedOnDesktopOnStart,
                isContentExpanded: isExpandedOnDesktopOnStart,
            };
        }

        return null;
    }

    componentDidMount() {
        window.addEventListener('toggleExpand', this.onToggleExpand.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener('toggleExpand', this.onToggleExpand.bind(this));
    }

    onToggleExpand({ detail: { section } }) {
        const { name } = this.props;

        if (section === name) {
            this.setState({ isContentExpanded: true }, () => this.scrollToExpandedContent());
        }
    }

    toggleExpand() {
        const { isExpandableDisabled, onClick } = this.props;

        if (isExpandableDisabled) {
            return;
        }

        if (onClick) {
            onClick();

            return;
        }
        this.setState(
            ({ isContentExpanded }) => ({ isContentExpanded: !isContentExpanded }),
            () => this.scrollToExpandedContent()
        );
    }

    renderButtonIcon() {
        const { isContentExpanded } = this.state;
        const {
            isArrow,
            isExpandableOnDesktop,
            device: { isMobile },
        } = this.props;

        if (!isExpandableOnDesktop && !isMobile) {
            return null;
        }

        if (isArrow) {
            return <ChevronIcon direction={isContentExpanded ? TOP : BOTTOM} />;
        }

        return this.renderTogglePlusMinus();
    }

    renderHeading() {
        const { heading, mix, headingIcon } = this.props;

        return (
            <div block="ExpandableContent" elem="Heading" mix={{ ...mix, elem: 'ExpandableContentHeading' }}>
                {headingIcon && <Icon name={headingIcon} />}
                {typeof heading === 'string' ? <TextPlaceholder content={heading} length="medium" /> : heading}
            </div>
        );
    }

    renderHeaderIcon() {
        const { headingIcon } = this.props;

        if (!headingIcon) {
            return null;
        }

        if (typeof headingIcon === 'string') {
            return <Icon name={headingIcon} />;
        }

        return headingIcon;
    }

    renderHeadingWithValue() {
        const {
            heading,
            mix,
            headingValue,
            device: { isMobile },
            headingValueIcon,
        } = this.props;

        return (
            <div block="ExpandableContent" elem="HeadingContainer">
                <div block="ExpandableContent" elem="Heading" mix={{ ...mix, elem: 'ExpandableContentHeading' }}>
                    {this.renderHeaderIcon()}
                    {typeof heading === 'string' ? <TextPlaceholder content={heading} length="medium" /> : heading}
                </div>
                <div block="ExpandableContent" elem="HeadingValueWrapper">
                    <span block="ExpandableContent" elem="HeadingValue">
                        {headingValue}
                    </span>
                    {headingValueIcon && !isMobile ? <Icon name="small_arrow_right" width="6" height="10" /> : null}
                </div>
            </div>
        );
    }

    renderButton() {
        const { isContentExpanded } = this.state;
        const { headingValue, mix } = this.props;

        return (
            <div
                role="button"
                tabIndex={0}
                block="ExpandableContent"
                elem="Button"
                mods={{ isContentExpanded }}
                mix={{ ...mix, elem: 'ExpandableContentButton' }}
                onClick={this.toggleExpand}
                onKeyDown={this.toggleExpand}
            >
                {headingValue ? this.renderHeadingWithValue() : this.renderHeading()}
                {this.renderButtonIcon()}
            </div>
        );
    }

    renderContent() {
        const { children, mix, isExpandableOnDesktop } = this.props;
        const { isContentExpanded } = this.state;
        const mods = { isContentExpanded, isExpandableOnDesktop };

        return (
            <div
                block="ExpandableContent"
                elem="Content"
                mods={mods}
                mix={{ ...mix, elem: 'ExpandableContentContent', mods }}
            >
                {children}
            </div>
        );
    }
}

export default ExpandableContent;
