import lazy from '@loadable/component';
import React, { PureComponent, Suspense } from 'react';

export const Icon = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "icon" */ './Icon.component'));

/** @namespace SwiatKsiazkiBasic/Component/Icon/Container */
export class IconContainer extends PureComponent {
    render() {
        return (
            <Suspense fallback={null}>
                <Icon {...this.props} />
            </Suspense>
        );
    }
}

export default IconContainer;
