import PropTypes from 'prop-types';

export const LocationsType = PropTypes.arrayOf(
    PropTypes.shape({
        address: PropTypes.string,
        place: PropTypes.string,
        description: PropTypes.string,
        sidebarShownInfo: PropTypes.string,
        sidebarHiddenInfo: PropTypes.string,
        lat: PropTypes.number,
        lng: PropTypes.number,
    })
);

export const ExtendedLocationsType = PropTypes.arrayOf(
    PropTypes.shape({
        LocationsType,
        relevance: PropTypes.number,
        isExpanded: PropTypes.bool,
    })
);

export const CoordinatesType = PropTypes.arrayOf(
    PropTypes.shape({
        lat: PropTypes.number,
        lng: PropTypes.number,
    })
);
