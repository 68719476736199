import { TabsComponent } from 'Component/Tabs/Tabs.component';

import './CareerTabs.style';

/** @namespace SwiatKsiazkiBasic/Component/CareerTabs/Component */
export class CareerTabsComponent extends TabsComponent {
    renderTab({ name }) {
        const { prefixClassName } = this.props;

        const tabName = name.split('||');
        const title = tabName[0];
        const subtitle = tabName[1];

        const newTitle = {
            __html: title,
        };

        const newSubtitle = {
            __html: subtitle,
        };

        return (
            <>
                <div block={prefixClassName} elem="TabTitle" dangerouslySetInnerHTML={newTitle} />
                <div block={prefixClassName} elem="TabSubtitle" dangerouslySetInnerHTML={newSubtitle} />
            </>
        );
    }

    renderTabs() {
        const { titles, prefixClassName, activeTab, handleTabClick } = this.props;

        return titles.map((name, key) => (
            <button
                key={key}
                onClick={() => handleTabClick(key)}
                block={prefixClassName}
                elem="Tab"
                mods={{ isActive: activeTab === key }}
            >
                {this.renderTab({ name })}
            </button>
        ));
    }

    render() {
        const { prefixClassName } = this.props;

        return (
            <div block={prefixClassName}>
                <div block="CareerTabs" elem="Nav">
                    <div block={prefixClassName} elem="CareerTabs">
                        {this.renderTabs()}
                    </div>
                </div>
                <div block="CareerTabs" elem="ContentRow">
                    {this.renderChildren()}
                </div>
            </div>
        );
    }
}

export default CareerTabsComponent;
