export * from 'SourceStore/Cart/Cart.action';

export const UPDATE_CART_PROMOTION = 'UPDATE_CART_PROMOTION';

export const UPDATE_DECLARATION = 'UPDATE_DECLARATION';

/** @namespace SwiatKsiazkiBasic/Store/Cart/Action/updateCartPromotion */
export const updateCartPromotion = (promotion) => ({
    type: UPDATE_CART_PROMOTION,
    promotion,
});

/** @namespace SwiatKsiazkiBasic/Store/Cart/Action/updateDeclaration */
export const updateDeclaration = (declaration) => ({
    type: UPDATE_DECLARATION,
    declaration,
});
