import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import ProductSliderTabs from './ProductSliderTabs.component';

/** @namespace SwiatKsiazkiBasic/Component/ProductSliderTabs/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace SwiatKsiazkiBasic/Component/ProductSliderTabs/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device,
});

/** @namespace SwiatKsiazkiBasic/Component/ProductSliderTabs/Container */
export class ProductSliderTabsContainer extends PureComponent {
    static propTypes = {
        title: PropTypes.object,
        productSliderTabs: PropTypes.arrayOf(PropTypes.object).isRequired,
    };

    containerFunctions = {
        handleTabClick: this.handleTabClick.bind(this),
    };

    state = {
        activeTab: 2,
        title: '',
    };

    componentDidMount() {
        this.changeActiveTab();
    }

    componentDidUpdate(prevProps, prevState) {
        const { activeTab } = this.state;

        if (prevState.activeTab !== activeTab) {
            this.changeActiveTab();
        }
    }

    changeActiveTab() {
        const productSliderTabs = document.querySelectorAll('.ProductSliderTabs-Content');
        const { activeTab } = this.state;

        productSliderTabs.forEach((contentTab, index) => {
            if (index + 1 === activeTab && !contentTab.classList.contains('ProductSliderTabs-Content_isActive')) {
                contentTab.classList.add('ProductSliderTabs-Content_isActive');
            } else {
                contentTab.classList.remove('ProductSliderTabs-Content_isActive');
            }
        });
    }

    handleTabClick(id) {
        this.setState({ activeTab: id });
    }

    getArrayProductSliderTabs() {
        const { productSliderTabs } = this.props;

        const preparedProductSliderTabs = [];

        productSliderTabs.forEach((tab, index) => {
            if (index === 0) {
                this.setState({
                    title: tab.title,
                });

                return;
            }

            preparedProductSliderTabs.push({
                name: tab.title,
                id: index + 1,
                rows: tab.rows,
            });
        });

        return preparedProductSliderTabs;
    }

    containerProps() {
        const { activeTab, title } = this.state;
        const { device } = this.props;

        return {
            productSliderTabs: this.getArrayProductSliderTabs(),
            activeTab,
            title,
            device,
        };
    }

    render() {
        return <ProductSliderTabs {...this.containerFunctions} {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductSliderTabsContainer);
