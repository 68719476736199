import { updateKeyValuePairs } from 'SourceUtil/Url/Url';

export * from 'SourceUtil/Url/Url';

/** @namespace SwiatKsiazkiBasic/Util/Url/convertQueryStringToKeyValuePairs */
export const convertQueryStringToKeyValuePairs = (queryString) => {
    const keyValuePairs = {};
    const params = queryString.substring(1).split('&');

    params.forEach((param) => {
        const pair = param.split('=');
        const [keyPair, valuePair = []] = pair;

        if (keyPair.length > 0 && valuePair.length > 0) {
            keyValuePairs[keyPair] = valuePair;
        }
    });

    return keyValuePairs;
};

/** @namespace SwiatKsiazkiBasic/Util/Url/convertKeyValuesToQueryString */
export const convertKeyValuesToQueryString = (keyValuePairs) =>
    Object.entries(keyValuePairs)
        .map((pair) => {
            const [key, value] = pair;
            const keyExists = key !== '';
            const valueExists = typeof value === 'object' ? value.length : value !== '';

            if (valueExists && keyExists) {
                return `${key}=${value}`;
            }

            return null;
        })
        .filter((x) => !!x)
        .join('&');

/** @namespace SwiatKsiazkiBasic/Util/Url/getQueryParam */
export const getQueryParam = (variable, location) => {
    const query = location.search.substring(1);
    const vars = query.split('&');

    return vars.reduce((acc, item) => {
        const splitIdx = item.indexOf('=');
        const [k, v] = [item.slice(0, splitIdx), item.slice(splitIdx + 1)];

        return k === variable ? v.replace(/=/g, ':') : acc;
    }, false);
};

/** @namespace SwiatKsiazkiBasic/Util/Url/generateQuery */
export const generateQuery = (keyValueObject, location, history) =>
    Object.entries(keyValueObject).reduce((acc, [key, value]) => {
        const keyAndValueExist = !!key && !!value;

        if (acc === '' && keyAndValueExist) {
            return `?${key}=${value}`;
        }

        if (getQueryParam(key, location) !== false) {
            const keyValuePairs = convertQueryStringToKeyValuePairs(acc);
            const updatedKeyValuePairs = updateKeyValuePairs(keyValuePairs, key, value);
            const updatedQuery = convertKeyValuesToQueryString(updatedKeyValuePairs);

            return updatedQuery.length ? `?${updatedQuery}` : '';
        }

        if (keyAndValueExist) {
            return `${acc}&${key}=${value}`;
        }

        return acc;
    }, history.location.search);

/** @namespace SwiatKsiazkiBasic/Util/Url/setQueryParams */
export const setQueryParams = (keyValueObject, location, history) => {
    const { state } = location;
    const query = generateQuery(keyValueObject, location, history);

    history.push({ search: query, state });
};

/** @namespace SwiatKsiazkiBasic/Util/Url/parseCustomFiltersToObject */
export const parseCustomFiltersToObject = (location) => {
    if (!location || !getQueryParam('customFilters', location)) {
        return {};
    }

    return Object.fromEntries(
        getQueryParam('customFilters', location)
            .split(';')
            .map((filter) => filter.split(':'))
            .map(([name, value]) => {
                if (value.includes('_')) {
                    const priceValues = value.split('_');

                    return [
                        name,
                        {
                            min: parseInt(priceValues[0], 10) ?? 0,
                            max: parseInt(priceValues[1], 10) ?? 0,
                        },
                    ];
                }

                return [name, value];
            })
    );
};

/** @namespace SwiatKsiazkiBasic/Util/Url/parseCustomFiltersToUrl */
export const parseCustomFiltersToUrl = (filters) => {
    if (!filters) {
        return '';
    }

    return Object.entries(filters)
        .map(([name, value]) => {
            if (value.min || value.max) {
                return [name, `${value.min}_${value.max}`];
            }

            return [name, value];
        })
        .map((filter) => filter.join(':'))
        .join(';');
};

/** @namespace SwiatKsiazkiBasic/Util/Url/translateUrl */
export const translateUrl = (url) => {
    const translateUrl = url.split(':')[1];

    return translateUrl;
};

/** @namespace SwiatKsiazkiBasic/Util/Url/getPathnameWithPrevious */
export const getPathnameWithPrevious = (pathname, redirect = window.location.pathname) => {
    pathname = new URL(pathname, window.location.origin);
    pathname.searchParams.set('redirect', redirect);
    return {
        pathname: pathname.pathname,
        search: pathname.search,
    };
};

/** @namespace SwiatKsiazkiBasic/Util/Url/getPreviousPathname */
export const getPreviousPathname = (search = window.location.search) => {
    search = new URLSearchParams(search);
    search = decodeURIComponent(search.get('redirect'));
    return {
        pathname: search,
    };
};
