// import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './ProductAuthors.style';

/** @namespace SwiatKsiazkiBasic/Component/ProductAuthors/Component */
export class ProductAuthors extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    render() {
        const { children, mixClassName, isShort } = this.props;

        return (
            <div block="ProductAuthors" mix={{ block: mixClassName, elem: 'ProductAuthors' }} mods={{ isShort }}>
                {children}
            </div>
        );
    }
}

export default ProductAuthors;
