import parser from 'html-react-parser';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { ChildrenType } from 'Type/Common.type';
import { isCrawler } from 'Util/Browser';

import './ShowMoreLessContent.style.scss';

/** @namespace SwiatKsiazkiBasic/Component/ShowMoreLessContent/Component */
export class ShowMoreLessContent extends PureComponent {
    static propTypes = {
        content: PropTypes.oneOfType([PropTypes.string, ChildrenType]),
        contentType: PropTypes.oneOf(['text', 'elements']),
        isExpanded: PropTypes.bool,
        isExtendable: PropTypes.bool,
        handleClick: PropTypes.func,
    };

    renderButton() {
        const { isExpanded, handleClick } = this.props;

        const buttonTitle = isExpanded ? __('show less') : __('show more');

        return (
            <button block="ShowMoreLessContent" elem="Button" onClick={handleClick}>
                {buttonTitle}
            </button>
        );
    }

    getContent() {
        const { content, contentType, defaultContent } = this.props;
        const contentToRender = isCrawler() ? defaultContent : content;

        if (contentType === 'text') {
            return parser(contentToRender);
        }

        return contentToRender;
    }

    render() {
        const { isExpanded, isExtendable, afterContent } = this.props;

        return (
            <div block="ShowMoreLessContent" elem="Wrapper" mods={{ isExpanded }}>
                <span block="ShowMoreLessContent" elem="Content">
                    {this.getContent()}
                    {isExpanded && afterContent}
                    {isExtendable && !isCrawler() && this.renderButton()}
                </span>
            </div>
        );
    }
}

export default ShowMoreLessContent;
