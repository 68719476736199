import React, { PureComponent } from 'react';

import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import Form from 'Component/Form';
import Loader from 'Component/Loader';
import { VALIDATION_INPUT_TYPE } from 'Util/Validator/Config';

import './CustomerRevokeConsent.style.scss';

/** @namespace SwiatKsiazkiBasic/Component/CustomerRevokeConsent/Component */
export class CustomerRevokeConsent extends PureComponent {
    renderAdditionalInfo() {
        return (
            <p block="CustomerRevokeConsent" elem="AdditionalInfo">
                {__('* Required fields')}
            </p>
        );
    }

    render() {
        const { onSubmit, isLoading } = this.props;

        return (
            <Form onSubmit={onSubmit} mix={{ block: 'CustomerRevokeConsent' }}>
                <h3 block="CustomerRevokeConsent" elem="Title">
                    {__("I'm contacting about:")}
                </h3>
                <div block="CustomerRevokeConsent" elem="Form">
                    <Loader isLoading={isLoading} />
                    <label block="CustomerRevokeConsent" elem="Field" htmlFor="delete_action_account">
                        <Field
                            attr={{ id: 'delete_action_account', name: 'delete_action_account' }}
                            type={FIELD_TYPE.checkbox}
                            label="Usunięcia konta w sklepie"
                        />
                        <p>
                            {__(
                                'Attention by deleting your account you will lose all data stored on it: purchase history, virtual shelf with electronic files (e-books), shipping addresses.'
                            )}
                        </p>
                    </label>
                    <label block="CustomerRevokeConsent" elem="Field" htmlFor="delete_action_newsletter">
                        <Field
                            attr={{ id: 'delete_action_newsletter', name: 'delete_action_newsletter' }}
                            type={FIELD_TYPE.checkbox}
                            label="Usunięcia mojego adresu mailowego z newslettera sklepu"
                        />
                        <p>
                            {__(
                                'By choosing this option we will stop sending to your email address promotions, discounts and special offers implemented in our store.'
                            )}
                        </p>
                    </label>
                    <Field
                        attr={{ id: 'firstname', name: 'firstname', placeholder: __('Name') }}
                        type={FIELD_TYPE.text}
                        label={__('Name')}
                        validationRule={{
                            isRequired: true,
                        }}
                        addRequiredTag
                    />
                    <Field
                        attr={{ id: 'lastname', name: 'lastname', placeholder: __('Surname') }}
                        type={FIELD_TYPE.text}
                        label={__('Surname')}
                        validationRule={{
                            isRequired: true,
                        }}
                        addRequiredTag
                    />
                    <Field
                        attr={{ id: 'email', name: 'email', placeholder: __('E-mail') }}
                        type={FIELD_TYPE.email}
                        label={__('E-mail')}
                        validationRule={{
                            isRequired: true,
                            inputType: VALIDATION_INPUT_TYPE.email,
                        }}
                        addRequiredTag
                    />
                    <Field
                        attr={{ id: 'reason', name: 'reason', placeholder: __('Content of the request') }}
                        type={FIELD_TYPE.textarea}
                        label={__('Content of the request')}
                        validationRule={{
                            isRequired: true,
                        }}
                        addRequiredTag
                    />
                </div>
                {this.renderAdditionalInfo()}
                <button block="Button" type="submit" disabled={isLoading}>
                    {__('Send')}
                </button>
            </Form>
        );
    }
}

export default CustomerRevokeConsent;
