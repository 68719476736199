import React, { PureComponent } from 'react';

import Script from 'Component/Script';

import { CENEO_VARIANT } from './CeneoScript.config';

import './CeneoScript.style.scss';

/** @namespace SwiatKsiazkiBasic/Component/CeneoScript/Component */
export class CeneoScript extends PureComponent {
    CENEO_WIDGET_ID = 'ceneo-widget';

    isPlaceholder() {
        const element = document.getElementById(this.CENEO_WIDGET_ID);
        return element && element.hasAttribute('placeholder');
    }

    isClosed() {
        return !!localStorage.getItem('ceneo-widget-bar-close-time');
    }

    createPlaceholder() {
        const placeholder = document.createElement('div');
        placeholder.id = this.CENEO_WIDGET_ID;
        placeholder.setAttribute('placeholder', true);
        document.body.appendChild(placeholder);
    }

    removeWidget() {
        const element = document.getElementById(this.CENEO_WIDGET_ID);
        return element && element.remove();
    }

    onGlobalUnmount() {
        this.removeWidget();
        this.createPlaceholder();
    }

    onGlobalMount() {
        if (this.isPlaceholder()) {
            this.removeWidget();
        }

        if (this.isClosed()) {
            this.createPlaceholder();
        }
    }

    renderTrack() {
        const { guid } = this.props;

        return (
            <Script
                async
                type="text/javascript"
                url={`https://ssl.ceneo.pl/transactions/track/v2/script.js?accountGuid=${guid}`}
            />
        );
    }

    renderGlobal() {
        const { guid } = this.props;

        return (
            <Script
                async
                type="text/javascript"
                url={`https://ssl.ceneo.pl/shops/sw.js?accountGuid=${guid}`}
                onMount={this.onGlobalMount.bind(this)}
                onUnmount={this.onGlobalUnmount.bind(this)}
            />
        );
    }

    render() {
        const { variant, enabled, guid } = this.props;

        if (!enabled || (enabled && !guid)) {
            return null;
        }

        switch (variant) {
            case CENEO_VARIANT.GLOBAL: {
                return this.renderGlobal();
            }
            case CENEO_VARIANT.TRACK: {
                return this.renderTrack();
            }
            default: {
                return null;
            }
        }
    }
}

export default CeneoScript;
