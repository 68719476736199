/* eslint-disable no-restricted-syntax */

import omit from 'lodash/omit';
import React, { Suspense } from 'react';

import BannerWidget from 'Component/BannerWidget';
import BookstoreLocations from 'Component/BookstoreLocations';
import ButtonWidget from 'Component/ButtonWidget';
import CareerTabs from 'Component/CareerTabs';
import CmsSlider from 'Component/CmsSlider';
import CmsStaticSidebarMenu from 'Component/CmsStaticSidebarMenu';
import CmsTabChecker from 'Component/CmsTabChecker';
import CountDown from 'Component/CountDown';
import CustomerRevokeConsent from 'Component/CustomerRevokeConsent';
import GiftCardsActivateCardForm from 'Component/GiftCardsActivateCardForm';
import GiftCardsBuyCardForm from 'Component/GiftCardsBuyCardForm';
import GiftCardsCheckValidityForm from 'Component/GiftCardsCheckValidityForm';
import GridImages from 'Component/GridImages';
import HeadingWidget from 'Component/HeadingWidget';
import Icon from 'Component/Icon';
import Image from 'Component/Image';
import ImageCarousel from 'Component/ImageCarousel';
import LazyLoad from 'Component/LazyLoad';
import Link from 'Component/Link';
import MapWidget from 'Component/MapWidget';
import ProductListMagezonWidget from 'Component/ProductListMagezonWidget';
import ProductSliderWidget from 'Component/ProductSliderWidget';
import RulesCtaWidget from 'Component/RulesCtaWidget';
import SeparatorWidget from 'Component/SeparatorWidget';
import SingleProductWidget from 'Component/SingleProductWidget';
import Tabs from 'Component/Tabs';
import { Html } from 'SourceComponent/Html/Html.component';
import { parseJSON } from 'Util/Data';
import { checkForTabs } from 'Util/MagezoneWidget';
import getStore from 'Util/Store';

/** @namespace SwiatKsiazkiBasic/Component/MagezonRenderer/Renderer/CmsPageRenderer/Component */
export class CmsPageRenderer extends Html {
    rules = [
        {
            query: { name: ['widget'], attribs: [{ type: 'ImageCarousel' }] },
            replace: this.renderImageCarousel,
        },
        {
            query: { name: ['widget'], attribs: [{ type: 'Button' }] },
            replace: this.renderButton,
        },
        {
            query: { name: ['widget'], attribs: [{ type: 'ProductSlider' }] },
            replace: this.renderProductSlider,
        },
        {
            query: { name: ['widget'], attribs: [{ type: 'Tabs' }] },
            replace: this.renderTabs,
        },
        {
            query: { name: ['widget'], attribs: [{ type: 'Banner' }] },
            replace: this.renderBanner,
        },
        {
            query: { name: ['widget'], attribs: [{ type: 'Heading' }] },
            replace: this.renderHeading,
        },
        {
            query: { name: ['widget'], attribs: [{ type: 'SingleProduct' }] },
            replace: this.renderSingleProduct,
        },
        {
            query: { name: ['widget'], attribs: [{ type: 'ProductList' }] },
            replace: this.renderProductList,
        },
        {
            query: { name: ['widget'], attribs: [{ type: 'CmsSlider' }] },
            replace: this.renderCarousel,
        },
        {
            query: { name: ['widget'], attribs: [{ type: 'CustomerRevokeConsent' }] },
            replace: this.renderCustomerRevokeConsent,
        },
        {
            query: { attribs: [{ id: 'map' }] },
            replace: this.renderMap,
        },
        {
            query: { attribs: ['data-form-component'] },
            replace: this.renderForm,
        },
        {
            query: { attribs: ['data-cms-submenu', 'data-cms-rules-submenu'] },
            replace: this.renderSidebarMenu,
        },
        {
            query: { attribs: [{ class: 'mgz-cta' }] },
            replace: this.renderCallToAction,
        },
        {
            query: { attribs: [{ class: 'mgz-single-image-wrapper' }] },
            replace: this.renderSingleImageWrapper,
        },
        {
            query: { attribs: ['data-bookstore-locations'] },
            replace: this.renderBookstoreLocations,
        },
        {
            query: { attribs: [{ class: 'mgz-element-separator' }] },
            replace: this.renderSeparator,
        },
        {
            query: { name: ['style'] },
            replace: this.replaceStyle,
        },
        {
            query: { attribs: ['data-src'] },
            replace: this.replaceMedia,
        },
        {
            query: { name: ['img'] },
            replace: this.replaceImages,
        },
        {
            query: { name: ['a'] },
            replace: this.replaceLinks,
        },
        {
            query: { attribs: ['data-widget-row'] },
            replace: this.renderWidgetRow,
        },
        {
            query: { attribs: ['data-icon'] },
            replace: this.renderIcon,
        },
        {
            query: { name: ['table'] },
            replace: this.wrapTable,
        },
        {
            query: { attribs: ['data-mage-init'] },
            replace: this.renderCountDown,
        },
    ];

    parserOptions = {
        replace: (domNode) => {
            const { name: domName, attribs: domAttrs } = domNode;

            const rule = this.rules.find((rule) => {
                const {
                    query: { name, attribs },
                } = rule;

                let result = false;

                if (name && domName && name.indexOf(domName) !== -1) {
                    result = true;
                }

                if (attribs && domAttrs) {
                    // eslint-disable-next-line fp/no-loops, fp/no-let
                    for (let i = 0; i < attribs.length; i++) {
                        const attrib = attribs[i];

                        if (typeof attrib === 'object') {
                            const queryAttrib = Object.keys(attrib)[0];

                            if (Object.prototype.hasOwnProperty.call(domAttrs, queryAttrib)) {
                                result = domAttrs[queryAttrib].match(Object.values(attrib)[0]);
                            }
                        } else if (Object.prototype.hasOwnProperty.call(domAttrs, attrib)) {
                            result = true;
                        }
                    }
                }

                return result;
            });

            if (rule) {
                const { replace } = rule;

                return replace.call(this, domNode);
            }
        },
    };

    renderTabs({ attribs: { title, ...attribs }, children, ...element }) {
        const { attributesToProps, domToReact } = this.props;

        const titles = children
            .map(({ attribs: { title, type } = {}, children = [] }) =>
                type === 'Tab' && children.length > 0 ? title : undefined
            )
            .filter(Boolean);
        const content = children.map(({ children }) => domToReact(children, this.parserOptions)).filter(Boolean);

        const prefixClassName =
            (titles.some((title) => title.includes('Kariera -')) && 'CareerTabs') ||
            (titles.some((title) => title.includes('Karty -')) && 'CmsElementsTabs') ||
            'ProductSliderTabs';
        const clearedTitles = titles.map((title) => title.replace('Kariera - ', '').replace('Karty - ', ''));

        if (prefixClassName === 'CareerTabs') {
            return (
                <CareerTabs prefixClassName={prefixClassName} titles={clearedTitles}>
                    {content}
                </CareerTabs>
            );
        }

        return (
            <CmsTabChecker
                title={title}
                tabs={checkForTabs({ attribs: { title, ...attribs }, children, ...element }, attributesToProps)}
            >
                <Tabs prefixClassName={prefixClassName} titles={clearedTitles}>
                    {content}
                </Tabs>
            </CmsTabChecker>
        );
    }

    renderMap({ next }) {
        return <MapWidget script={next?.children[0]?.data} />;
    }

    renderForm({ attribs: { 'data-form-component': dataFormComponent } = {} }) {
        switch (dataFormComponent) {
            case 'buy-card': {
                return <GiftCardsBuyCardForm />;
            }
            case 'check-vailidity-card': {
                return <GiftCardsCheckValidityForm />;
            }
            case 'activate-card': {
                return <GiftCardsActivateCardForm />;
            }
            default: {
                return null;
            }
        }
    }

    renderSidebarMenu({ children }) {
        return (
            <CmsStaticSidebarMenu
                title={children[0]?.children[0]?.data}
                links={children[1]?.children.filter(({ name }) => name === 'li')}
            />
        );
    }

    renderImageCarousel({ attribs }) {
        const { attributesToProps } = this.props;

        return <ImageCarousel {...attributesToProps(attribs)} />;
    }

    renderCallToAction({ children }) {
        return <RulesCtaWidget items={children[0]?.children[0]?.children} />;
    }

    renderSingleImageWrapper({ children, next = {} }) {
        const { attribs: { class: nextClassName } = {} } = next || {};

        if (nextClassName !== 'mgz-single-image-wrapper') {
            return null;
        }

        return (
            <GridImages
                images={children.map((child) => child?.children?.[0]?.children?.[0]?.attribs).filter(Boolean)}
            />
        );
    }

    renderButton({ attribs, next = {}, parent }) {
        const { attributesToProps } = this.props;

        const { attribs: { class: nextClassName } = {} } = next || {};

        if (nextClassName === 'mgz-single-image-wrapper') {
            return null;
        }

        const isOverlay = parent?.parent?.attribs?.class?.includes('btn-overlay');
        const isCustom = parent?.parent?.attribs?.class?.includes('btn-custom');

        return <ButtonWidget isOverlay={isOverlay} isCustom={isCustom} {...attributesToProps(attribs)} />;
    }

    renderProductSlider({ attribs: { class: classVariant, double_slider, items, ...attribs } = {} }) {
        const { attributesToProps } = this.props;

        return (
            <LazyLoad>
                <ProductSliderWidget
                    {...attributesToProps({
                        ...attribs,
                        classVariant,
                        is_double_slider: double_slider === '1',
                        productsSkuArray: parseJSON(items, []),
                    })}
                />
            </LazyLoad>
        );
    }

    renderBanner({ attribs }) {
        const { attributesToProps } = this.props;

        return <BannerWidget {...attributesToProps(attribs)} />;
    }

    renderHeading({ attribs }) {
        const { attributesToProps } = this.props;

        return <HeadingWidget {...attributesToProps(attribs)} />;
    }

    renderBookstoreLocations() {
        return <BookstoreLocations />;
    }

    renderSeparator() {
        return <SeparatorWidget />;
    }

    renderCarousel({ attribs }) {
        const { attributesToProps } = this.props;

        return <CmsSlider {...attributesToProps(attribs)} />;
    }

    renderSingleProduct({ attribs: { class: classVariant, description: subtitle, ...attribs } = {} }) {
        const { attributesToProps } = this.props;

        return (
            <LazyLoad>
                <SingleProductWidget {...attributesToProps({ classVariant, subtitle, ...attribs })} />{' '}
            </LazyLoad>
        );
    }

    renderProductList({ attribs: { items: productsSkuArray = '[]', ...attribs } = {} }) {
        const { attributesToProps } = this.props;

        return (
            <LazyLoad>
                <ProductListMagezonWidget
                    {...attributesToProps({ productsSkuArray: parseJSON(productsSkuArray, []), ...attribs })}
                />
            </LazyLoad>
        );
    }

    replaceMedia({ attribs: { 'data-src': src, ...attribs }, parent }) {
        const { attributesToProps } = this.props;
        const { attribs: { 'data-type': type } = {} } = parent || {};

        if (type === 'image') {
            return <Image {...attributesToProps(attribs)} src={src} isPlain />;
        }
    }

    replaceLinks({ name: TagName, attribs: { href, 'data-icon-link': icon, ...attrs } = {}, children }) {
        const { domToReact } = this.props;

        if (href) {
            const isAbsoluteUrl = (value) => new RegExp('^(?:[a-z]+:)?//', 'i').test(value);
            const isSpecialLink = (value) => new RegExp('^(sms|tel|mailto):', 'i').test(value);

            if (isAbsoluteUrl(href) && icon) {
                return <Link {...this.attributesToProps({ ...attrs, to: href })}>{icon && <Icon name={icon} />}</Link>;
            }

            if (!isAbsoluteUrl(href) && !isSpecialLink(href)) {
                return (
                    <Link {...this.attributesToProps({ ...attrs, to: href })}>
                        {domToReact(children, this.parserOptions)}
                    </Link>
                );
            }
        }

        return (
            <TagName {...this.attributesToProps(omit({ ...attrs, href }, ['style']))}>
                {domToReact(children, this.parserOptions)}
            </TagName>
        );
    }

    replaceImages({ attribs: { 'data-device': device, 'lazy-mode': lazyMode, ...attribs } = {} }) {
        const { attributesToProps } = this.props;

        if (device) {
            const showOnDesktop = device === 'desktop';
            const showOnMobile = device === 'mobile';
            const showOnTablet = device === 'tablet';
            const showOnTabletDesktop = device === 'tabletAndDesktop';

            const {
                ConfigReducer: {
                    device: { isMobile, isTablet },
                },
            } = getStore().getState() || {};

            const isDesktopImgHidden = showOnDesktop && (isMobile || isTablet);
            const isTabletImgHidden = showOnTablet && !isTablet;
            const isMobileImgHidden = showOnMobile && (isTablet || !isMobile);
            const isTabletDesktopImgHidden =
                showOnTabletDesktop && device === 'tabletAndDesktop' && isMobile && !isTablet;

            if (isDesktopImgHidden || isTabletImgHidden || isMobileImgHidden || isTabletDesktopImgHidden) {
                return <div />;
            }
        }

        if (attribs.src) {
            return (
                <Image
                    {...attributesToProps(attribs)}
                    useNativeLazyLoading={lazyMode === 'native'}
                    removeDefaultClasses={lazyMode === 'native'}
                    isPlain
                />
            );
        }
    }

    renderWidgetRow({ attribs: { 'data-widget-row': isWidgetRow, ...attribs }, children }) {
        const { domToReact, attributesToProps } = this.props;

        if (isWidgetRow) {
            return <div className="inner-content mgz-container">{domToReact(children, this.parserOptions)}</div>;
        }

        return <div {...attributesToProps(attribs)}>{domToReact(children, this.parserOptions)}</div>;
    }

    renderIcon({ attribs: { 'data-icon': icon, ...attribs } = {} }) {
        const { attributesToProps } = this.props;

        return (
            <Suspense fallback={null}>
                <Icon name={icon} {...attributesToProps(attribs)} />
            </Suspense>
        );
    }

    renderCustomerRevokeConsent() {
        return <CustomerRevokeConsent />;
    }

    renderCountDown({ attribs: { 'data-mage-init': dataMageInit, ...attribs }, children }) {
        const { attributesToProps, domToReact } = this.props;

        for (const [key, values] of Object.entries(parseJSON(dataMageInit))) {
            switch (key) {
                case 'Magezon_Builder/js/countdown': {
                    return (
                        <CountDown containerProps={attributesToProps(attribs)} {...values}>
                            {domToReact(children, this.parserOptions)}
                        </CountDown>
                    );
                }
                default: {
                    return;
                }
            }
        }
    }

    render() {
        const { attribs, children } = this.props;

        return this.renderWidgetRow({ children, attribs });
    }
}

export default CmsPageRenderer;
