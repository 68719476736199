import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { createPortal } from 'react-dom';
import SlickSlider from 'react-slick';

import Html from 'Component/Html';
import Link from 'Component/Link';
import { inRange } from 'Util/Date';
import media from 'Util/Media';

import SliderNextArrow from '../SliderNextArrow';
import SliderPrevArrow from '../SliderPrevArrow';
import { DEFAULT_SLICK_SETTINGS, TRUNCATED_LAYOUT } from './AdvoxSlider.config';

import '../../style/main';
import './AdvoxSlider.style';

/** @namespace SwiatKsiazkiBasic/Slider/Components/AdvoxSlider/Component/ClipPathMask */
export const ClipPathMask = () => (
    <svg width="0" height="0">
        <defs>
            <clipPath id="heroSliderClipMaskId">
                <path
                    className="cls-1"
                    d="M1099.64,383H86.46c-9.6,0-17.85-6.81-19.68-16.23L.37,23.8C-2.02,11.46,7.44,0,20.01,0H1099.75c5.53,0,10.01,4.48,10.01,10.01V372.88c0,5.59-4.53,10.12-10.12,10.12Z"
                />
            </clipPath>
        </defs>
    </svg>
);
/** @namespace SwiatKsiazkiBasic/Slider/Components/AdvoxSlider/Component */
export class AdvoxSliderComponent extends PureComponent {
    static propTypes = {
        slider: PropTypes.object.isRequired,
        settings: PropTypes.object,
    };

    static defaultProps = {
        settings: {},
    };

    renderImage(slide) {
        const {
            header,
            image,
            tablet_image: tabletImage,
            mobile_image: mobileImage,
            style_variant: styleVariant,
            cta_link: ctaLink,
        } = slide;
        const { isDragging } = this.props;

        if (!image) {
            return null;
        }

        const isTruncated = styleVariant === TRUNCATED_LAYOUT;

        const content = ctaLink ? (
            <Link to={ctaLink} onClick={(e) => isDragging === true && e.preventDefault()}>
                <picture block="AdvoxSlider" elem="Image" mods={{ isTruncated }}>
                    {mobileImage ? <source media="(max-width: 767px)" srcSet={media(mobileImage)} /> : null}
                    {tabletImage ? <source media="(max-width: 1023px)" srcSet={media(tabletImage)} /> : null}
                    <img src={media(image)} alt={header} />
                </picture>
            </Link>
        ) : (
            <picture block="AdvoxSlider" elem="Image" mods={{ isTruncated }}>
                {mobileImage ? <source media="(max-width: 767px)" srcSet={media(mobileImage)} /> : null}
                {tabletImage ? <source media="(max-width: 1023px)" srcSet={media(tabletImage)} /> : null}
                <img src={media(image)} alt={header} />
            </picture>
        );

        return [
            createPortal(
                // eslint-disable-next-line react/no-unknown-property
                <link key={media(image)} rel="preload" as="image" href={media(image)} fetchPriority="high" />,
                document.head
            ),
            content,
        ];
    }

    renderAdditionalText(slide) {
        const { additional_text: additionalText } = slide;

        if (!additionalText || additionalText === '') {
            return null;
        }

        return (
            <div block="AdvoxSlider" elem="SlideAdditionalText">
                <div>
                    <Html content={additionalText} />
                </div>
            </div>
        );
    }

    renderSlide(slide) {
        const { additional_text: additionalText } = slide;

        return (
            <div block="AdvoxSlider" elem="Slide" mods={{ hasAdditionalText: additionalText !== '' }}>
                {this.renderImage(slide)}
                {this.renderAdditionalText(slide)}
            </div>
        );
    }

    renderSliderSlides(slider) {
        return slider.slides
            .filter(({ date_from, date_to }) => inRange(date_from, date_to))
            .map((slide, index) => <div key={`slider${index}`}>{this.renderSlide(slide)}</div>);
    }

    render() {
        const { slider, settings, isLoading, setDragging } = this.props;

        if (isLoading) {
            return <div block="AdvoxSlider" elem="Placeholder" />;
        }

        if (!slider || !slider?.slides) {
            return null;
        }

        if (slider?.autorotate_time) {
            settings.autoplay = true;
            settings.autoplaySpeed = slider?.autorotate_time * 1000;
        }

        return (
            <div block="AdvoxSlider">
                <SlickSlider
                    prevArrow={<SliderPrevArrow />}
                    nextArrow={<SliderNextArrow />}
                    beforeChange={() => setDragging(true)}
                    afterChange={() => setDragging(false)}
                    {...DEFAULT_SLICK_SETTINGS}
                    {...settings}
                >
                    {this.renderSliderSlides(slider)}
                </SlickSlider>
                <ClipPathMask />
            </div>
        );
    }
}

export default AdvoxSliderComponent;
