import ProductReviewRating from 'Component/ProductReviewRating/ProductReviewRating.component';
import { ProductAttributeValue as SourceProductAttributeValue } from 'SourceComponent/ProductAttributeValue/ProductAttributeValue.component';

import { bestSellerFilterCategoryRegex } from '../CategoryFilterOverlay/CategoryFilterOverlay.config';
import {
    RATINGS_SUMMARY_FILTER_ID,
    RATINGS_SUMMARY_LABELS,
    STRING_ONLY_ATTRIBUTE_CODES,
} from './ProductAttributeValue.config';

import './ProductAttributeValue.override.style';

/** @namespace SwiatKsiazkiBasic/Component/ProductAttributeValue/Component */
export class ProductAttributeValue extends SourceProductAttributeValue {
    getCheckboxLabel(value, subLabel) {
        const {
            attribute: { attribute_code, is_boolean, attribute_value },
        } = this.props;

        if (attribute_code === RATINGS_SUMMARY_FILTER_ID) {
            return (
                <div block="ProductAttributeValue" elem="Label">
                    {this.renderRatingLabel(value, subLabel)}
                </div>
            );
        }

        if (is_boolean && !attribute_code.match(bestSellerFilterCategoryRegex)) {
            return (
                <div block="ProductAttributeValue" elem="Label">
                    {+attribute_value ? __('Yes') : __('No')}
                    {this.renderSublabel(subLabel)}
                </div>
            );
        }

        return (
            <div block="ProductAttributeValue" elem="Label">
                <div>
                    <span>
                        {value} {this.renderSublabel(subLabel)}
                    </span>
                </div>
            </div>
        );
    }

    renderRatingLabel(value, subLabel) {
        const {
            attribute: { attribute_value },
        } = this.props;

        return (
            <>
                <ProductReviewRating
                    summary={+attribute_value}
                    count={+attribute_value / 20}
                    mix={{ block: 'ProductAttributeValue', elem: 'RatingFilter' }}
                />
                {RATINGS_SUMMARY_LABELS[+value]}
                {this.renderSublabel(subLabel)}
            </>
        );
    }

    renderSublabel(subLabel) {
        const { isProductCountVisible } = this.props;

        if (!subLabel || !isProductCountVisible) {
            return null;
        }

        return (
            <strong block="ProductAttributeValue" elem="SubLabel">
                {subLabel}
            </strong>
        );
    }

    renderSelectAttribute() {
        const {
            attribute: { attribute_value, attribute_code, has_swatch, attribute_label },
        } = this.props;
        const attributeOption = this.getOptionLabel(attribute_value);
        const { label, labelText, count, swatch_data } = attributeOption;

        // skip attributes without valid swatches
        if (!swatch_data && has_swatch) {
            return null;
        }

        if (attribute_code.match(bestSellerFilterCategoryRegex))
            return this.renderStringValue(attribute_label || __('N/A'), null, count);

        if (!swatch_data || STRING_ONLY_ATTRIBUTE_CODES.includes(attribute_code)) {
            return this.renderStringValue(labelText || __('N/A'), null, count);
        }

        const { value, type } = swatch_data;
        switch (type) {
            case '0':
                return this.renderStringValue(value, labelText, count);
            case '1':
                return this.renderColorValue(value, label);
            case '2':
                return this.renderImageValue(value, label);
            default:
                return this.renderStringValue(labelText || __('N/A'), labelText, count);
        }
    }

    render() {
        const {
            isAvailable,
            attribute: { attribute_code, attribute_value },
            mix,
            isFormattedAsText,
            showProductAttributeAsLink,
        } = this.props;

        const noAttributeValue = attribute_code && !attribute_value;
        const noRatingAttributeValue = attribute_code === RATINGS_SUMMARY_FILTER_ID && attribute_value === '0';

        if (noAttributeValue || noRatingAttributeValue) {
            return null;
        }

        // Invert to apply css rule without using not()
        const isNotAvailable = !isAvailable;

        if (isFormattedAsText) {
            return (
                <div block="ProductAttributeValue" mix={mix}>
                    {this.renderAttributeByType()}
                </div>
            );
        }

        if (!showProductAttributeAsLink) {
            return (
                <span
                    block="ProductAttributeValue"
                    mods={{ isNotAvailable }}
                    onClick={this.clickHandler}
                    onKeyDown={this.clickHandler}
                    role="link"
                    tabIndex="-1"
                    aria-hidden={isNotAvailable}
                    mix={mix}
                >
                    {this.renderAttributeByType()}
                </span>
            );
        }

        return (
            <button
                block="ProductAttributeValue"
                mods={{ isNotAvailable }}
                onClick={this.clickHandler}
                aria-hidden={isNotAvailable}
                mix={mix}
            >
                {this.renderAttributeByType()}
            </button>
        );
    }
}

export default ProductAttributeValue;
