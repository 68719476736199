export const UPDATE_POSTS = 'UPDATE_POSTS';
export const NO_POSTS = 'NO_POSTS';
export const LOAD_POSTS = 'LOAD_POSTS';

/** @namespace SwiatKsiazkiBasic/Blog/Store/Posts/Action/updatePosts */
export const updatePosts = (posts) => ({
    type: UPDATE_POSTS,
    posts,
    isLoaded: true
});

/** @namespace SwiatKsiazkiBasic/Blog/Store/Posts/Action/postsException */
export const postsException = (message) => ({
    type: NO_POSTS,
    message
});

/** @namespace SwiatKsiazkiBasic/Blog/Store/Posts/Action/postsException */
export const loadPosts = () => ({
    type: LOAD_POSTS
});
