import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { showNotification } from 'Store/Notification/Notification.action';
import { getErrorMessage } from 'Util/Request';
import DataContainer from 'Util/Request/DataContainer';

import AdvoxSliderQuery from '../../query/AdvoxSlider.query';
import AdvoxSlider from './AdvoxSlider.component';

/** @namespace SwiatKsiazkiBasic/Slider/Components/AdvoxSlider/Container/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace SwiatKsiazkiBasic/Slider/Components/AdvoxSlider/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message) => dispatch(showNotification(type, message)),
});

/** @namespace SwiatKsiazkiBasic/Slider/Components/AdvoxSlider/Container */
export class AdvoxSliderContainer extends DataContainer {
    static propTypes = {
        position: PropTypes.string.isRequired,
        code: PropTypes.string.isRequired,
        showNotification: PropTypes.func.isRequired,
    };

    state = {
        slider: {},
        isLoading: false,
        isDragging: false,
    };

    containerFunctions = {
        setDragging: this.setDragging.bind(this),
    };

    componentDidMount() {
        this.getSliderData();
    }

    __construct(props) {
        const { code } = props;

        super.__construct(props, `AdvoxSliderContainer_${code}`);
    }

    async getSliderData() {
        const { position, code, showNotification } = this.props;

        this.fetchData(
            AdvoxSliderQuery.getSliderQuery(position, code),
            ({ slider }) => {
                this.setState({
                    slider,
                    isLoading: false,
                });
            },
            (error) => {
                showNotification('error', getErrorMessage(error));

                this.setState({ isLoading: false });
            }
        );
    }

    setDragging(isDragging) {
        this.setState({ isDragging });
    }

    containerProps = () => {
        const { slider, isLoading, isDragging } = this.state;

        return {
            slider,
            isLoading,
            isDragging,
        };
    };

    render() {
        return <AdvoxSlider {...this.containerFunctions} {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdvoxSliderContainer);
