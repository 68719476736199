import {
    CART_TOTALS,
    getInitialState as sourceGetInitialState,
    updateCartTotals as sourceUpdateCartTotals,
    updateShippingPrice as sourceUpdateShippingPrice,
} from 'SourceStore/Cart/Cart.reducer';
import {
    UPDATE_CART_PROMOTION,
    UPDATE_DECLARATION,
    UPDATE_IS_LOADING_CART,
    UPDATE_SHIPPING_PRICE,
    UPDATE_TOTALS,
} from 'Store/Cart/Cart.action';

export { CART_TOTALS };

/** @namespace SwiatKsiazkiBasic/Store/Cart/Reducer/updateCartTotals */
export const updateCartTotals = (action, state) => ({ ...state, ...sourceUpdateCartTotals(action, state) });

/** @namespace SwiatKsiazkiBasic/Store/Cart/Reducer/updateShippingPrice */
export const updateShippingPrice = (action, state) => ({ ...state, ...sourceUpdateShippingPrice(action, state) });

/** @namespace SwiatKsiazkiBasic/Store/Cart/Reducer/getInitialState */
export const getInitialState = () => ({
    ...sourceGetInitialState(),
    promotion: {
        loaded: false,
        items: [],
    },
});

/** @namespace SwiatKsiazkiBasic/Store/Cart/Reducer/CartReducer */
export const CartReducer = (state = getInitialState(), action) => {
    const { type } = action;

    switch (type) {
        case UPDATE_TOTALS:
            return updateCartTotals(action, state);
        case UPDATE_SHIPPING_PRICE:
            return updateShippingPrice(action, state);
        case UPDATE_IS_LOADING_CART:
            const { isLoading } = action;

            return {
                ...state,
                isLoading,
            };
        case UPDATE_CART_PROMOTION: {
            const { promotion } = action;
            const { items, ...rest } = promotion || {};

            return {
                ...state,
                promotion: {
                    ...rest,
                    loaded: true,
                    items: items || [],
                },
            };
        }
        case UPDATE_DECLARATION:
            const {
                declaration: { validation, country_id },
            } = action;

            return {
                ...state,
                cartTotals: {
                    ...state.cartTotals,
                    last_declaration_validation_result: validation,
                    selected_declaration_country: country_id,
                },
            };
        default:
            return state;
    }
};

export default CartReducer;
