import GiftCardsActivateCardForm from 'Component/GiftCardsActivateCardForm';
import GiftCardsBuyCardForm from 'Component/GiftCardsBuyCardForm';
import GiftCardsCheckValidityForm from 'Component/GiftCardsCheckValidityForm';

export const CURRENT_FORM_SHOW = {
    'activate-card': {
        htmlName: 'giftCardsActivateCardForm',
        cmsComponent: <GiftCardsActivateCardForm />,
    },
    'check-vailidity-card': {
        htmlName: 'giftCardsCheckValidityForm',
        cmsComponent: <GiftCardsCheckValidityForm />,
    },
    'buy-card': {
        htmlName: 'giftCardsBuyCardForm',
        cmsComponent: <GiftCardsBuyCardForm />,
    },
};

export const TERMS_URL = '/terms';
export const TERMS_AND_CONDITIONS_STORE_URL = '/regulamin/regulamin-sklepu';
export const PRIVACY_POLICY_URL = '/iodo/polityka-prywatnosci';
