import EdroneQuery from 'Query/Edrone.query';
import { fetchMutation } from 'Util/Request';
import { fetchQuery } from 'Util/Request/Query';

import { sendEvent } from './Edrone.script';

/** @namespace SwiatKsiazkiBasic/Component/Edrone/Event/prepareProduct */
export const prepareProduct = async (event, { id: productId }) => {
    const { getEdroneProductData } = await fetchQuery(EdroneQuery.getProductQuery(productId));

    const {
        sku: product_skus,
        id: product_ids,
        title: product_titles,
        product_url: product_urls,
        image: product_images,
        product_category_names,
        product_category_ids,
    } = getEdroneProductData || {};

    sendEvent(event, {
        product_skus: product_skus || '',
        product_ids: product_ids || '',
        product_titles: product_titles || '',
        product_images: product_images || '',
        product_urls: product_urls || '',
        product_category_ids: product_category_ids || '',
        product_category_names: product_category_names || '',
    });
};

/** @namespace SwiatKsiazkiBasic/Component/Edrone/Event/prepareCategory */
export const prepareCategory = async (event, { breadcrumbs, id, name }) => {
    const product_category_ids = [...(breadcrumbs?.map((breadcrumb) => breadcrumb?.category_id) || []), id].join('~');
    const product_category_names = [...(breadcrumbs?.map((breadcrumb) => breadcrumb?.category_name) || []), name].join(
        '~'
    );

    sendEvent(event, {
        product_category_ids,
        product_category_names,
    });
};

/** @namespace SwiatKsiazkiBasic/Component/Edrone/Event/prepareAddToCart */
export const prepareAddToCart = async (event, { id: productId }) => {
    const { getEdroneProductData } = await fetchQuery(EdroneQuery.getProductQuery(productId));

    const {
        sku: product_skus,
        id: product_ids,
        title: product_titles,
        product_url: product_urls,
        image: product_images,
        product_category_names,
        product_category_ids,
    } = getEdroneProductData || {};

    sendEvent(event, {
        product_skus: product_skus || '',
        product_ids: product_ids || '',
        product_titles: product_titles || '',
        product_images: product_images || '',
        product_urls: product_urls || '',
        product_category_ids: product_category_ids || '',
        product_category_names: product_category_names || '',
    });
};

/** @namespace SwiatKsiazkiBasic/Component/Edrone/Event/prepareOrder */
export const prepareOrder = async (event, { orderIds = [], guestCartId }) => {
    if (orderIds.length > 0) {
        const { sendOrderToEdrone } = await fetchMutation(EdroneQuery.getOrderQuery(orderIds[0], guestCartId));
        const { customer, order, related_order, digital_related_order } = sendOrderToEdrone || {};
        const orders = [order, related_order, digital_related_order].filter(Boolean);

        orders.forEach(
            ({
                sku,
                title,
                image,
                order_id,
                order_payment_value,
                base_payment_value,
                base_currency,
                order_currency,
                coupon,
                product_category_names,
                product_category_ids,
                product_urls,
                product_counts,
            }) => {
                sendEvent(event, {
                    ...(customer || {}),
                    product_skus: sku || '',
                    product_titles: title || '',
                    product_images: image || '',
                    product_category_names: product_category_names || '',
                    product_category_ids: product_category_ids || '',
                    product_counts: product_counts || '',
                    product_urls: product_urls || '',
                    order_id: order_id || '',
                    order_payment_value: order_payment_value || '',
                    base_payment_value: base_payment_value || '',
                    base_currency: base_currency || '',
                    order_currency: order_currency || '',
                    coupon: coupon || '',
                });
            }
        );
    }
};
