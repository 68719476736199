import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ProductSlider from 'Component/ProductSlider';
import ProductListQuery from 'Query/ProductList.query';
import { showNotification } from 'Store/Notification/Notification.action';
import { getSliderSettings } from 'Util/MagezoneWidget';
import { getIndexedProducts } from 'Util/Product';
import DataContainer from 'Util/Request/DataContainer';

/** @namespace SwiatKsiazkiBasic/Component/ProductSliderWidget/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showError: (message) => dispatch(showNotification('error', message)),
});

/** @namespace SwiatKsiazkiBasic/Component/ProductSliderWidget/Container/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace SwiatKsiazkiBasic/Component/ProductSliderWidget/Container */
export class ProductSliderWidgetContainer extends DataContainer {
    static propTypes = {
        conditions_encoded: PropTypes.string,
        max_items: PropTypes.string,
        settings: PropTypes.object,
        title: PropTypes.string,
        isLightVariant: PropTypes.bool,
        isMenuVariant: PropTypes.bool,
        isDoubleSlider: PropTypes.bool,
        double_slider: PropTypes.bool,
        className: PropTypes.string,
        isBanerVariant: PropTypes.bool,
        image: PropTypes.string,
        url: PropTypes.string,
    };

    state = {
        items: [],
        isLoading: true,
        isLightVariant: false,
        isMenuVariant: false,
        isDoubleSlider: false,
        isBanerVariant: false,
    };

    componentDidMount() {
        this.getProductsData();
    }

    __construct(props) {
        super.__construct(props, 'ProductSliderWidget');
    }

    getProductsData() {
        const {
            productsSkuArray,
            max_items,
            maxItems,
            direction: sortDirection,
            categoryId,
            order: sortKey,
        } = this.props;

        if (
            !productsSkuArray ||
            !Array.isArray(productsSkuArray) ||
            (Array.isArray(productsSkuArray) && productsSkuArray.length === 0)
        ) {
            return;
        }

        this.setState({ isLoading: true });

        const options = {
            args: {
                filter: {
                    productsSkuArray,
                    categoryIds: categoryId,
                },
                currentPage: 1,
                pageSize: max_items || maxItems,
                ...(!sortKey || sortKey === 'default' ? {} : { sort: { sortKey, sortDirection } }),
            },
            isPlp: true,
            noVariantAttributes: true,
        };

        this.fetchData(
            [ProductListQuery.getQuery(options)],
            ({ products }) => {
                this.setState({
                    items: getIndexedProducts(products?.items),
                    isLoading: false,
                });
            },
            () => {
                this.setState({
                    items: [],
                    isLoading: false,
                });
            }
        );
    }

    containerProps() {
        const { isLoading, items } = this.state;

        const {
            isBanerVariant,
            image,
            url,
            settings,
            options,
            title,
            isLightVariant,
            isMenuVariant,
            isAlternativeArrow,
            classVariant,
            isDoubleSlider,
            double_slider,
        } = this.props;

        return {
            settings: getSliderSettings(options, settings),
            isLoading,
            items,
            heading: title,
            banerTitle: title,
            isLightVariant,
            isMenuVariant,
            isDoubleSlider: isDoubleSlider || double_slider,
            isAlternativeArrow,
            classVariant,
            isBanerVariant,
            image,
            url,
        };
    }

    render() {
        return <ProductSlider {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductSliderWidgetContainer);
