import Field from '@scandipwa/scandipwa/src/util/Query/Field';

/** @namespace Advox/Recaptcha/Query/Recaptcha/Query/RecaptchaQuery */
export class RecaptchaQuery {
    getSetupQuery() {
        return new Field('storeConfig').setAlias('captchaSetup').addFieldList(this._getSetupFields());
    }

    _getSetupFields() {
        return [
            new Field('msp_securitysuite_recaptcha_frontend_enabled').setAlias('enabled'),
            new Field('msp_securitysuite_recaptcha_general_type').setAlias('type'),
            new Field('msp_securitysuite_recaptcha_general_public_key').setAlias('publicKey'),
            new Field('msp_securitysuite_recaptcha_frontend_enabled_contact').setAlias('contact_enabled'),
            new Field('msp_securitysuite_recaptcha_frontend_position').setAlias('badge'),
            new Field('msp_securitysuite_recaptcha_frontend_theme').setAlias('theme'),
            new Field(' msp_securitysuite_recaptcha_frontend_size').setAlias('size'), 
        ];
    }
}

export default new RecaptchaQuery();
