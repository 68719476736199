import Html from 'Component/Html';
import NoMatch from 'Route/NoMatch';
import { CmsPage as SourceCmsPage } from 'SourceRoute/CmsPage/CmsPage.component';

import './CmsPage.override.style';

/** @namespace SwiatKsiazkiBasic/Route/CmsPage/Component */
export class CmsPage extends SourceCmsPage {
    renderCheckerContent() {
        const {
            page: { content },
        } = this.props;

        const withSubMenu = `${content}`.includes('data-cms-submenu="true"');
        const withRulesSubMenu = `${content}`.includes('data-cms-rules-submenu="true"');

        if (withRulesSubMenu) {
            return (
                <div block="CmsPage" elem="Page">
                    <div block="CmsPage" elem="HeadingContainer">
                        {this.renderHeading()}
                    </div>
                    <div block="CmsPage" elem="ContentWithMenu">
                        <div block="CmsPage" elem="SubMenu" />
                        <div block="CmsPage" elem="Content">
                            {this.renderContent()}
                        </div>
                    </div>
                </div>
            );
        }

        if (withSubMenu) {
            return (
                <div block="CmsPage" elem="ContentWithMenu">
                    <div block="CmsPage" elem="SubMenu" />
                    <div block="CmsPage" elem="Content">
                        {this.renderHeading()}
                        {this.renderContent()}
                    </div>
                </div>
            );
        }

        return (
            <>
                {this.renderHeading()}
                <div block="CmsPage" elem="Content">
                    {this.renderContent()}
                </div>
            </>
        );
    }

    renderContent() {
        const {
            isLoading,
            page: { content },
        } = this.props;

        if (isLoading) {
            return (
                <>
                    <div block="CmsPage" elem="SectionPlaceholder" />
                    <div block="CmsPage" elem="SectionPlaceholder" />
                    <div block="CmsPage" elem="SectionPlaceholder" />
                </>
            );
        }

        if (!isLoading && !content) {
            return null;
        }

        return (
            <Html
                fallback={
                    <>
                        <div block="CmsPage" elem="SectionPlaceholder" />
                        <div block="CmsPage" elem="SectionPlaceholder" />
                        <div block="CmsPage" elem="SectionPlaceholder" />
                    </>
                }
                content={content}
            />
        );
    }

    render() {
        const {
            page: { identifier, page_width, content },
            isBreadcrumbsActive,
            isLoading,
            isPageLoaded,
            isHomePage,
        } = this.props;
        const isRulesSubMenu = `${content}`.includes('data-cms-rules-submenu="true"');

        if (!isLoading && !isPageLoaded) {
            return <NoMatch />;
        }

        return (
            <main block="CmsPage" mods={{ isBreadcrumbsHidden: !isBreadcrumbsActive, id: identifier, isHomePage }}>
                <div block="CmsPage" elem="Wrapper" mods={{ page_width, isRulesSubMenu }}>
                    {this.renderCheckerContent()}
                </div>
            </main>
        );
    }
}

export default CmsPage;
