/* eslint-disable import/prefer-default-export */

export const STRING_ONLY_ATTRIBUTE_CODES = ['category_id'];
export const RATINGS_SUMMARY_FILTER_ID = 'ratings_summary';
export const BESTSELLERS_FILTER_ID = 'bestsellers';
export const AUTHORS_FILTER_ID = 'authors_ms';
export const COMPOSERS_FILTER_ID = 'composers_ms';
export const DIRECTORS_FILTER_ID = 'directors_ms';
export const PRODUCERS_FILTER_ID = 'producers_ms';
export const PUBLISHERS_FILTER_ID = 'publishers_ms';
export const SERIES_FILTER_ID = 'series_ms';
export const ACTORS_FILTER_ID = 'actors_ms';
export const PERFORMERS_FILTER_ID = 'performers_ms';
export const LECTORS_FILTER_ID = 'lectors_ms';
export const CONDUCTOR_FILTER_ID = 'conductor_ms';
export const TRANSLATORS_FILTER_ID = 'translators_ms';

export const FILTERS_WITH_SEARCH_REQUEST = [
    AUTHORS_FILTER_ID,
    COMPOSERS_FILTER_ID,
    DIRECTORS_FILTER_ID,
    PRODUCERS_FILTER_ID,
    PUBLISHERS_FILTER_ID,
    SERIES_FILTER_ID,
    ACTORS_FILTER_ID,
    PERFORMERS_FILTER_ID,
    LECTORS_FILTER_ID,
    CONDUCTOR_FILTER_ID,
    TRANSLATORS_FILTER_ID,
];
export const RATINGS_SUMMARY_LABELS = {
    20: __('1 star'),
    40: __('%s stars', 2),
    60: __('%s stars', 3),
    80: __('%s stars', 4),
    100: __('%s plural stars', 5),
};

export const PRODUCT_ATTRIBUTE_SET_NAME = {
    TOY: 'toy',
    ARTPAP: 'artpap',
    BOOK: 'book',
    EBOOK: 'ebook',
    AUDIOBOOK: 'audiobook',
    MOVIE: 'movie',
    MUSIC: 'music',
};
