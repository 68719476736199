import { CUR_CURRENCY } from 'SourceUtil/Currency/Currency';
import BrowserDatabase from 'Util/BrowserDatabase';

export * from 'SourceUtil/Currency/Currency';

export const DEFAULT_CURRENCY = 'PLN';

/** @namespace SwiatKsiazkiBasic/Util/Currency/getCurrency */
export const getCurrency = () => {
    const currency = BrowserDatabase.getItem(CUR_CURRENCY);

    return typeof currency === 'string' ? currency : DEFAULT_CURRENCY;
};

/** @namespace SwiatKsiazkiBasic/Util/Currency/getDefaultCurrency */
export const getDefaultCurrency = () => DEFAULT_CURRENCY;
