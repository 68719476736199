import { PAGINATION_QUERY_KEY } from 'Component/Pagination/Pagination.config';
import history from 'Util/History';
import { getQueryParam } from 'Util/Url';

/** @namespace SwiatKsiazkiBasic/Util/Wishlist/Params/getCurrentPageFromUrl */
export const getCurrentPageFromUrl = () => {
    const { location } = history;

    return parseInt(getQueryParam(PAGINATION_QUERY_KEY, location), 10) || 1;
};

/** @namespace SwiatKsiazkiBasic/Util/Wishlist/Params/getCurrentCategoryFromUrl */
export const getCurrentCategoryFromUrl = () => {
    const { location } = history;

    return parseInt(getQueryParam('category', location), 10) || 0;
};
