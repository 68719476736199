import {
    getBundleOptions,
    getIndexedAttributeOption,
    getIndexedConfigurableOptions,
    getIndexedCustomOptions,
    getIndexedReviews,
} from '@scandipwa/scandipwa/src/util/Product/Product';

import Link from 'Component/Link';
import OmnibusPriceInfo from 'Component/OmnibusPriceInfo';
import PRODUCT_TYPE from 'Component/Product/Product.config';
import { PRODUCT_ATTRIBUTE_SET_NAME } from 'Component/ProductAttributeValue/ProductAttributeValue.config';
import ProductAuthors from 'Component/ProductAuthors';
import ProductLabel from 'Component/ProductLabel';
import { REVIEW_POPUP_ID } from 'Component/ProductReviews/ProductReviews.config';
import Tooltip from 'Component/Tooltip';
import { BOARD_AND_PARTY_GAMES_CATEGORY_ID, GAMES_AND_TOYS_CATEGORY_ID } from 'Route/CategoryPage/CategoryPage.config';
import { DELIVERY_POPUP_ID } from 'Route/ProductPage/ProductPage.config';
import { showNotification } from 'Store/Notification/Notification.action';
import { showPopup } from 'Store/Popup/Popup.action';
import { isSignedIn } from 'Util/Auth';
import { formatDate } from 'Util/Date';
import { DICTIONARY_ATTRIBUTES } from 'Util/Dictionary';
import getStore from 'Util/Store';

export * from 'SourceUtil/Product/Product';

/** @namespace SwiatKsiazkiBasic/Util/Product/getRootCategoriesForCategory */
export const getRootCategoriesForCategory = (category) =>
    [
        ...(category?.breadcrumbs
            ?.sort((a, b) => (b?.category_level > a?.category_level ? -1 : 1))
            ?.map((cat) => cat?.category_id) || []),
        category?.id || undefined,
    ].filter(Boolean);

/** @namespace SwiatKsiazkiBasic/Util/Product/getRootCategoriesForProduct */
export const getRootCategoriesForProduct = (product) =>
    product?.categories?.map((cat) => cat?.id || undefined).filter(Boolean);

/** @namespace SwiatKsiazkiBasic/Util/Product/getProductAuthor */
export const getProductAuthor = (product, className, isShort = true, separate = true, checkElements = true) => {
    if (!product) {
        return null;
    }

    const { dictionary_label } = product || {};

    let authorsElements = null;

    if (dictionary_label?.length > 0) {
        authorsElements = dictionary_label.map((author, index) => {
            if (index === 0 && author?.name) {
                return (
                    <Link to={author?.url} block={className} elem="Author" key={index}>
                        {author?.name}
                    </Link>
                );
            }

            return (
                <>
                    {separate && `, `}
                    <Link to={author?.url} block={className} elem="Author" key={index}>
                        {author?.name}
                    </Link>
                </>
            );
        });
    }

    if (checkElements && authorsElements === null) {
        return null;
    }

    return (
        <ProductAuthors mixClassName={className} isShort={isShort}>
            {authorsElements}
        </ProductAuthors>
    );
};

/** @namespace SwiatKsiazkiBasic/Util/Product/getProductAuthorLabel */
export const getProductAuthorLabel = (product) => {
    const { dictionary_label = {} } = product || {};
    const isMultiple = dictionary_label?.length > 1;
    const { type = null } = dictionary_label[0] || {};

    const single = {
        author: __('Author'),
        performer: __('Performer'),
        director: __('Director'),
        producer: __('Producer'),
        composer: __('Composer'),
        conductor: __('Conductor'),
        default: __('Author'),
    };

    const multiple = {
        author: __('Authors'),
        performer: __('Performers'),
        director: __('Directors'),
        producer: __('Producers'),
        composer: __('Composers'),
        conductors: __('Conductors'),
        translators: __('Translators'),
        default: __('Authors'),
    };

    if (isMultiple) {
        return multiple[type] ?? multiple.default;
    }

    return single[type] ?? single.default;
};

/** @namespace SwiatKsiazkiBasic/Util/Product/renderFullAuthors */
export const renderFullAuthors = (product, className) => {
    const authorLabel = getProductAuthorLabel(product);
    const authorName = getProductAuthor(product, className, false, false);

    if (!authorName) {
        return null;
    }

    return (
        <div block={className} elem="AttributeWrapper" mods={{ isAuthor: true }}>
            <span block={className} elem="AuthorLabel">
                {`${authorLabel}:`}
            </span>
            <span block={className} elem="AuthorValueColor">
                {authorName}
            </span>
        </div>
    );
};

/** @namespace SwiatKsiazkiBasic/Util/Product/renderEan */
export const renderEan = (product, className) => {
    const { ean } = product;

    if (!ean) {
        return null;
    }

    return (
        <div block={className} elem="AttributeWrapper">
            <span block={className} elem="AttributeLabel AttributeLabelEan">
                {__('EAN: ')}
            </span>
            <span block={className} elem="AttributeValue AttributeValueEan">
                {ean}
            </span>
        </div>
    );
};

/** @namespace SwiatKsiazkiBasic/Util/Product/renderFileSize */
export const renderFileSize = (product, className) => {
    const { file_size } = product;

    if (!file_size) {
        return null;
    }

    return (
        <div block={className} elem="AttributeWrapper">
            <span block={className} elem="AttributeLabel AttributeLabelEan">
                {__('File size: ')}
            </span>
            <span block={className} elem="AttributeValue AttributeValueEan">
                {file_size}
            </span>
        </div>
    );
};

/** @namespace SwiatKsiazkiBasic/Util/Product/renderPublisher */
export const renderPublisher = (product, className, isColor = false) => {
    const { dictionary: { publishers = [] } = {} } = product || {};

    if (publishers.length === 0) {
        return null;
    }

    return (
        <div block={className} elem="AttributeWrapper">
            <span block={className} elem="AttributeLabel">
                {__('Publisher: ')}
            </span>
            <span block={className} elem={isColor ? 'AuthorValueColor' : 'AttributeValue'}>
                <Link to={publishers[0]?.url}>{publishers[0]?.name}</Link>
            </span>
        </div>
    );
};

/** @namespace SwiatKsiazkiBasic/Util/Product/renderBrandMeta */
export const renderBrandMeta = (product) => {
    const { dictionary: { publishers = [], producers = [] } = {} } = product || {};

    if (producers.length === 0 && publishers.length === 0) {
        return null;
    }

    if (producers.length > 0) {
        return (
            <div itemProp="brand" itemType="https://schema.org/Brand" itemScope hidden>
                <meta itemProp="name" content={producers[0]?.name} />
            </div>
        );
    }

    return (
        <div itemProp="brand" itemType="https://schema.org/Brand" itemScope hidden>
            <meta itemProp="name" content={publishers[0]?.name} />
        </div>
    );
};

/** @namespace SwiatKsiazkiBasic/Util/Product/getCodeGTIN */
export const getCodeGTIN = (value) => {
    const codes = {
        8: 'gtin8',
        12: 'gtin12',
        13: 'gtin13',
        14: 'gtin14',
    };

    return codes[value.length] || 'gtin';
};

/** @namespace SwiatKsiazkiBasic/Util/Product/renderEanMeta */
export const renderEanMeta = (product) => {
    const { ean } = product || {};

    if (!ean) {
        return null;
    }

    return <meta itemProp={getCodeGTIN(ean)} content={ean} />;
};

/** @namespace SwiatKsiazkiBasic/Util/Product/renderMeta */
export const renderMeta = ({ metaLink, product, inStock }) => {
    const {
        ean,
        is_preorder,
        attribute_set_name,
        attributes: { pages_count: { attribute_value } = {} } = {},
        dictionary: { publishers = [] } = {},
        dictionary_label = [],
        availability_status,
    } = product || {};

    let priceValidUntil = new Date();
    priceValidUntil = new Date(priceValidUntil.setMonth(priceValidUntil.getMonth() + 6));

    let stock = '';
    if (is_preorder !== 0) {
        stock = 'http://schema.org/PreOrder';
    } else if (inStock) {
        stock = 'https://schema.org/InStock';
    } else {
        stock = 'https://schema.org/OutOfStock';
    }

    return (
        <>
            <meta itemProp="url" content={metaLink} />
            <meta itemProp="availability" content={stock} />
            <meta itemProp="priceValidUntil" content={priceValidUntil.toDateString()} />
            <meta itemProp="itemCondition" content="http://schema.org/NewCondition" />
            <div itemProp="seller" itemType="http://schema.org/Organization" itemScope hidden>
                <meta itemProp="name" content="Świat Książki" />
            </div>
            <div itemProp="hasMerchantReturnPolicy" itemType="https://schema.org/MerchantReturnPolicy" itemScope hidden>
                <meta itemProp="applicableCountry" content="PL" />
                <meta itemProp="returnPolicyCategory" content="https://schema.org/MerchantReturnFiniteReturnWindow" />
                <meta itemProp="merchantReturnDays" content="14" />
                <meta itemProp="returnMethod" content="https://schema.org/ReturnInStore" />
                <meta itemProp="returnFees" content="https://schema.org/FreeReturn" />
            </div>
            <div itemProp="shippingDetails" itemType="https://schema.org/OfferShippingDetails" itemScope>
                <div itemProp="shippingRate" itemType="https://schema.org/MonetaryAmount" itemScope>
                    <meta itemProp="value" content="0" />
                    <meta itemProp="currency" content="PLN" />
                </div>
                <div itemProp="shippingDestination" itemType="https://schema.org/DefinedRegion" itemScope>
                    <meta itemProp="addressCountry" content="PL" />
                </div>
                <div itemProp="deliveryTime" itemType="https://schema.org/ShippingDeliveryTime" itemScope>
                    <div itemProp="handlingTime" itemType="https://schema.org/QuantitativeValue" itemScope>
                        <meta itemProp="minValue" content="0" />
                        <meta itemProp="maxValue" content={availability_status} />
                        <meta itemProp="unitCode" content="DAY" />
                    </div>
                    <div itemProp="transitTime" itemType="https://schema.org/QuantitativeValue" itemScope>
                        <meta itemProp="minValue" content="1" />
                        <meta itemProp="maxValue" content="5" />
                        <meta itemProp="unitCode" content="DAY" />
                    </div>
                </div>
            </div>
            {attribute_set_name === PRODUCT_ATTRIBUTE_SET_NAME.BOOK ? (
                <div itemScope itemProp="subjectOf" itemType="http://schema.org/Book" hidden>
                    <meta itemProp="numberOfPages" content={attribute_value} />
                    <meta itemProp="publisher" content={publishers.map(({ name }) => name).join(', ')} />
                    <meta itemProp="isbn" content={ean} />
                    <meta itemProp="author" content={dictionary_label.map(({ name }) => name).join(', ')} />
                </div>
            ) : null}
        </>
    );
};

/** @namespace SwiatKsiazkiBasic/Util/Product/renderProductCategoriesBreadcrumbs */
export const renderProductCategoriesBreadcrumbs = (productCategoriesBreadcrumbs, className) => {
    if (productCategoriesBreadcrumbs?.length === 0) {
        return null;
    }

    return (
        <div block={className} elem="AttributeWrapper">
            <span block={className} elem="AttributeLabel">
                {__('Categories: ')}
            </span>
            <div block={className} elem="AuthorValueColor" mods={{ categoriesLinks: true }}>
                {productCategoriesBreadcrumbs?.map((category, index) => {
                    if (index === 0) {
                        return (
                            <Link to={category.url} key={index}>
                                {category.text}
                            </Link>
                        );
                    }

                    return (
                        <>
                            <br />
                            <Link to={category.url} key={index}>
                                {category.text}
                            </Link>
                        </>
                    );
                })}
            </div>
        </div>
    );
};

/** @namespace SwiatKsiazkiBasic/Util/Product/renderReleaseDate */
export const renderReleaseDate = (product, className, isTooltip = false) => {
    const { attributes: { release_date: { attribute_value: releaseDate } = {} } = {} } = product;
    const categories = getRootCategoriesForProduct(product);

    if (
        !categories?.length ||
        (categories?.[0] === GAMES_AND_TOYS_CATEGORY_ID && categories?.[1] !== BOARD_AND_PARTY_GAMES_CATEGORY_ID)
    ) {
        return null;
    }

    if (releaseDate) {
        const releaseDateContent = (
            <>
                <span block={className} elem="AttributeLabel">
                    {__('Release date: ')}
                </span>
                <span block={className} elem="AttributeValue">
                    {formatDate(releaseDate)}
                </span>
            </>
        );

        if (isTooltip) {
            return (
                <div block={className} elem="AttributeWrapper">
                    <Tooltip
                        tooltipText={__('This is the date when the item appeared on the market')}
                        placement="top-start"
                    >
                        {releaseDateContent}
                    </Tooltip>
                </div>
            );
        }

        return (
            <div block={className} elem="AttributeWrapper">
                {releaseDateContent}
            </div>
        );
    }

    return null;
};

/** @namespace SwiatKsiazkiBasic/Util/Product/renderDuration */
export const renderDuration = (product, className) => {
    const { attribute_set_name = '', attributes: { duration: { attribute_value: itemDuration } = {} } = {} } = product;

    if (!itemDuration) {
        return null;
    }

    const label = attribute_set_name === PRODUCT_ATTRIBUTE_SET_NAME.MOVIE ? __('Duration:') : __('Length of record:');

    return (
        <div block={className} elem="AttributeWrapper">
            <span block={className} elem="AttributeLabel">
                {label}
            </span>
            <span block={className} elem="AttributeValue">
                {itemDuration}
            </span>
        </div>
    );
};

/** @namespace SwiatKsiazkiBasic/Util/Product/renderTranslator */
export const renderTranslator = (product, className) => {
    const { dictionary: { translators = [] } = {} } = product || {};

    if (translators.length === 0) {
        return null;
    }

    return (
        <div block={className} elem="AttributeWrapper">
            <span block={className} elem="AttributeLabel">
                {__('Translator: ')}
            </span>
            <span block={className} elem="AuthorValueColor">
                <Link to={translators[0]?.url}>{translators[0]?.name}</Link>
            </span>
        </div>
    );
};

/** @namespace SwiatKsiazkiBasic/Util/Product/renderSelectBindingType */
export const renderSelectBindingType = (label, value, className) => {
    if (!value) return null;

    return (
        <div block={className} elem="AttributeWrapper">
            <span block={className} elem="AttributeLabel">
                {label}
            </span>
            <span block={className} elem="AttributeValue">
                {value}
            </span>
        </div>
    );
};

/** @namespace SwiatKsiazkiBasic/Util/Product/renderFormats */
export const renderFormats = (product, className, separate = false, pluralLabel = false) => {
    const { formats = '' } = product || {};

    if (!formats) {
        return null;
    }

    const formatValue = {
        mp3_in_zip: 'mp3/zip',
    };

    const preparedFormats = formats
        .split(',')
        .map((item) => formatValue[item] || item)
        .join(separate ? ',' : ', ')
        .split(',');

    const formatLabel = pluralLabel || preparedFormats?.length > 0 ? __('Formats: ') : __('Format: ');

    return (
        <div block={className} elem="AttributeWrapper" mods={{ formats: true }}>
            <span block={className} elem="AttributeLabel">
                {formatLabel}
            </span>
            <span block={className} elem="AttributeValue">
                {separate ? preparedFormats.map((item) => <span>{item}</span>) : preparedFormats}
            </span>
        </div>
    );
};

/** @namespace SwiatKsiazkiBasic/Util/Product/renderBindingType */
export const renderBindingType = (product, className) => {
    const {
        type_id,
        attributes: {
            binding: { attribute_value: itemTypeBook } = {},
            drive: { attribute_value: itemTypeMultimedia } = {},
        } = {},
    } = product;

    switch (true) {
        case itemTypeMultimedia !== '' && itemTypeMultimedia !== undefined:
            return <>{renderSelectBindingType(__('Media carrier:'), itemTypeMultimedia, className)}</>;
        case type_id === PRODUCT_TYPE.downloadable:
            return <>{renderFormats(product, className, false)}</>;
        case type_id === PRODUCT_TYPE.simple && itemTypeBook !== '' && itemTypeBook !== null:
            return <>{renderSelectBindingType(__('Cover type:'), itemTypeBook, className)}</>;
        default:
            return null;
    }
};

/** @namespace SwiatKsiazkiBasic/Util/Product/renderBindingLength */
export const renderBindingLength = (product, className) => {
    const {
        attributes: {
            drive: { attribute_value: itemTypeMultimedia } = {},
            uom_text: { attribute_value: uomText } = {},
        } = {},
    } = product;

    if (!uomText) {
        return null;
    }

    switch (true) {
        case itemTypeMultimedia !== '' && itemTypeMultimedia !== undefined:
            return <>{renderSelectBindingType(__('Number of carriers:'), uomText, className)}</>;
        default:
            return null;
    }
};

/** @namespace SwiatKsiazkiBasic/Util/Product/renderLector */
export const renderLector = (product, className) => {
    const { dictionary: { lectors = [] } = {} } = product || {};

    if (lectors.length === 0 || lectors[0]?.name === '') {
        return null;
    }

    return (
        <div block={className} elem="AttributeWrapper">
            <span block={className} elem="AttributeLabel">
                {__('Lector: ')}
            </span>
            <span block={className} elem="AttributeValue">
                {lectors[0]?.name}
            </span>
        </div>
    );
};

/** @namespace SwiatKsiazkiBasic/Util/Product/renderActors */
export const renderActors = (product, className) => {
    const { dictionary: { actors = [] } = {} } = product || {};

    if (actors.length === 0 || actors[0]?.name === '') {
        return null;
    }

    return (
        <div block={className} elem="AttributeWrapper">
            <span block={className} elem="AttributeLabel">
                {actors.length > 0 ? __('Actors: ') : __('Actor: ')}
            </span>

            <span block={className} elem="AttributeValueColor">
                {actors.map((actor, index) => (
                    <>
                        {index >= 1 && ', '}
                        <Link to={actor.url}>{actor.name}</Link>
                    </>
                ))}
            </span>
        </div>
    );
};

/** @namespace SwiatKsiazkiBasic/Util/Product/renderAudio */
export const renderAudio = (product, className) => {
    const { attributes: { audio = {} } = {} } = product || {};

    if (audio && audio.attribute_value)
        return (
            <div block={className} elem="AttributeWrapper">
                <span block={className} elem="AttributeLabel">
                    {__('Audio: ')}
                </span>
                <span block={className} elem="AttributeValue">
                    {audio.attribute_value}
                </span>
            </div>
        );
};

/** @namespace SwiatKsiazkiBasic/Util/Product/renderPerformers */
export const renderPerformers = (product, className) => {
    const { dictionary_label: dictionaryLabel = [] } = product || {};
    const { dictionary: { performers = [] } = {} } = product || {};
    const { type } = dictionaryLabel[0] || [];

    if (!performers?.length || type === 'performer') return null;

    return (
        <div block={className} elem="AttributeWrapper">
            <span block={className} elem="AttributeLabel">
                {performers?.length > 1 ? __('Performers') : __('Performers ms: ')}
            </span>
            <span block={className} elem="AuthorValueColor">
                {performers.map((performer) => performer?.name).join(', ')}
            </span>
        </div>
    );
};

/** @namespace SwiatKsiazkiBasic/Util/Product/renderDictionaryFields */
export const renderDictionaryFields = (product, className) => {
    const { dictionary, dictionary_label: dictionaryLabel = [] } = product || {};
    const { type } = dictionaryLabel[0] || {};

    if (!dictionary || dictionary.length === 0) {
        return null;
    }

    const dictionaryAttributes = DICTIONARY_ATTRIBUTES.filter(
        (attr) => attr !== 'lectors' && attr !== 'conductor' && !attr.includes(type)
    );

    return dictionaryAttributes.map((dictionaryAttribute, index) => {
        const attribute = dictionaryAttribute.replace('_ms', '');

        if (dictionary[attribute]?.length > 0) {
            const attributeName =
                dictionaryAttribute.charAt(0).toUpperCase() + dictionaryAttribute.slice(1).replace('_ms', '');

            if (dictionary[dictionaryAttribute]?.length) {
                return (
                    <div block={className} elem="AttributeWrapper" key={index}>
                        <span block={className} elem="AttributeLabel">
                            {`${__(attributeName)}: `}
                        </span>
                        <span block={className} elem="AuthorValueColor">
                            {dictionary[dictionaryAttribute.replace('_ms', '')].map((dictionaryItem, index) => (
                                <Link to={dictionaryItem.url} block={className} elem="Author" key={index}>
                                    {dictionaryItem.name}
                                </Link>
                            ))}
                        </span>
                    </div>
                );
            }
        }

        return null;
    });
};

/** @namespace SwiatKsiazkiBasic/Util/Product/renderSeries */
export const renderSeries = (product, className) => {
    const { dictionary: { series = [] } = {} } = product || {};

    if (series.length === 0) {
        return null;
    }

    return (
        <div block={className} elem="AttributeWrapper">
            <span block={className} elem="AttributeLabel">
                {__('Series: ')}
            </span>
            <span block={className} elem="AuthorValueColor">
                <Link to={series[0]?.url}>{series[0]?.name}</Link>
            </span>
        </div>
    );
};

/** @namespace SwiatKsiazkiBasic/Util/Product/renderProducer */
export const renderProducer = (product, className) => {
    const { dictionary: { producers = [] } = {}, dictionary_label: dictionaryLabel = [] } = product || {};
    const { type } = dictionaryLabel[0] || [];

    if (producers.length === 0 || type === 'producer') {
        return null;
    }

    return (
        <div block={className} elem="AttributeWrapper">
            <span block={className} elem="AttributeLabel">
                {__('Producers: ')}
            </span>
            <span block={className} elem="AuthorValueColor">
                <Link to={producers[0]?.url}>{producers[0]?.name}</Link>
            </span>
        </div>
    );
};

/** @namespace SwiatKsiazkiBasic/Util/Product/renderConductor */
export const renderConductor = (product, className) => {
    const { dictionary: { conductors = [] } = {}, dictionary_label = [] } = product || {};
    const { type } = dictionary_label[0] || [];

    if (conductors.length === 0 || type === 'conductor') {
        return null;
    }

    return (
        <div block={className} elem="AttributeWrapper">
            <span block={className} elem="AttributeLabel">
                {__('Conductors: ')}
            </span>

            <div block={className} elem="AttributeArrayValue">
                {conductors.map(({ name }, index) => (
                    <span block={className} elem="AttributeValue" key={index}>
                        {name}
                    </span>
                ))}
            </div>
        </div>
    );
};

/** @namespace SwiatKsiazkiBasic/Util/Product/renderPagesCount */
export const renderPagesCount = (product, className) => {
    const { attributes: { pages_count: { attribute_value } = {} } = {}, attribute_set_name } = product;

    if (!attribute_value || attribute_set_name === PRODUCT_ATTRIBUTE_SET_NAME.EBOOK) {
        return null;
    }

    return (
        <div block={className} elem="AttributeWrapper">
            <span block={className} elem="AttributeLabel">
                {__('Pages count: ')}
            </span>
            <span block={className} elem="AttributeValue">
                {attribute_value}
            </span>
        </div>
    );
};

/** @namespace SwiatKsiazkiBasic/Util/Product/renderDimensions */
export const renderDimensions = (product, className) => {
    const { format } = product;

    if (!format) {
        return null;
    }

    return (
        <div block={className} elem="AttributeWrapper">
            <span block={className} elem="AttributeLabel">
                {__('Dimensions: ')}
            </span>
            <span block={className} elem="AttributeValue">
                {format}
            </span>
        </div>
    );
};

/** @namespace SwiatKsiazkiBasic/Util/Product/renderSubtitles */
export const renderSubtitles = (product, className) => {
    const { subtitles } = product;

    if (!subtitles) {
        return null;
    }

    return (
        <div block={className} elem="AttributeWrapper">
            <span block={className} elem="AttributeLabel">
                {__('Subtitles: ')}
            </span>
            <span block={className} elem="AttributeValue">
                {subtitles}
            </span>
        </div>
    );
};

/** @namespace SwiatKsiazkiBasic/Util/Product/renderImageFormat */
export const renderImageFormat = (product, className) => {
    const { image_format } = product;

    if (!image_format) {
        return null;
    }

    return (
        <div block={className} elem="AttributeWrapper">
            <span block={className} elem="AttributeLabel">
                {__('Image format: ')}
            </span>
            <span block={className} elem="AttributeValue">
                {image_format}
            </span>
        </div>
    );
};

/** @namespace SwiatKsiazkiBasic/Util/Product/renderLanguageVersion */
export const renderLanguageVersion = (product, className) => {
    const { language_version } = product;

    if (!language_version) {
        return null;
    }

    return (
        <div block={className} elem="AttributeWrapper">
            <span block={className} elem="AttributeLabel">
                {__('Language version: ')}
            </span>
            <span block={className} elem="AttributeValue">
                {language_version}
            </span>
        </div>
    );
};

/** @namespace SwiatKsiazkiBasic/Util/Product/renderRequirements */
export const renderRequirements = (product, className) => {
    const { requirements } = product;

    if (!requirements) {
        return null;
    }

    return (
        <div block={className} elem="AttributeWrapper">
            <span block={className} elem="AttributeLabel">
                {__('Requirements: ')}
            </span>
            <span block={className} elem="AttributeValue">
                {requirements}
            </span>
        </div>
    );
};

/** @namespace SwiatKsiazkiBasic/Util/Product/showNewReviewPopup */
export const showNewReviewPopup = () => {
    const store = getStore();
    const { ConfigReducer: { reviews_allow_guest: isGuestEnabled } = {} } = store.getState();
    const { dispatch } = store;

    // if not logged in and guest reviews are not enabled
    if (!isSignedIn() && !isGuestEnabled) {
        dispatch(showNotification('info', __('You must login or register to review products.')));

        return;
    }

    dispatch(showPopup(REVIEW_POPUP_ID, { title: __('Add a review') }));
};

/** @namespace SwiatKsiazkiBasic/Util/Product/showNewDeliveryPopup */
export const showNewDeliveryPopup = () => {
    const store = getStore();
    const { dispatch } = store;

    dispatch(showPopup(DELIVERY_POPUP_ID));
};

/** @namespace SwiatKsiazkiBasic/Util/Product/getProductLabelType */
export const getProductLabelType = (itemClass) => {
    switch (itemClass) {
        case 'new':
            return 'new';
        case 'recommend':
            return 'recommended';
        case 'preview':
            return 'preview';
        case 'preorder':
            return 'preview';
        case 'renewal':
            return 'renewal';
        case 'e-book':
            return 'ebook';
        case 'audiobook':
            return 'audiobook';
        case 'label-only-here':
            return 'only_here';
        case 'bestseller':
            return 'top';
        case 'reprint':
            return 'reprint';
        case 'in-a-warehouse':
            return 'in_a_warehouse';
        case 'discount':
            return 'discount';
        default:
            return 'default';
    }
};

/** @namespace SwiatKsiazkiBasic/Util/Product/getCodazonLabelPosition */
export const getCodazonLabelPosition = (name) => {
    switch (name) {
        case 'e-book':
        case 'audiobook': {
            return 3;
        }
        case 'in-a-warehouse': {
            return 1;
        }
        case 'discount': {
            return 2;
        }
        default: {
            return 0;
        }
    }
};

/** @namespace SwiatKsiazkiBasic/Util/Product/getOmnibusPriceInfo */
export const getOmnibusPriceInfo = (product, props = {}) => <OmnibusPriceInfo product={product} {...props} />;

/** @namespace SwiatKsiazkiBasic/Util/Product/verifyOmnibus */
export const verifyOmnibus = (product, exist, noexist = null) => {
    if (product?.omnibus?.is_discount) {
        return exist;
    }

    return noexist;
};

/** @namespace SwiatKsiazkiBasic/Util/Product/getCodazonLabelWithDiscount */
export const getCodazonLabelWithDiscount = (product) => {
    const labels = product?.codazon_labels || [];

    if (verifyOmnibus(product, true, false)) {
        const discountLabel = labels?.find((label) => label?.custom_class === 'discount');

        return discountLabel
            ? labels
            : [
                  {
                      content: 'Rabat',
                      custom_class: 'discount',
                      custom_css: null,
                  },
                  ...labels,
              ];
    }

    return labels?.filter((label) => label?.custom_class !== 'discount');
};

/** @namespace SwiatKsiazkiBasic/Util/Product/getCodazonLabelByType */
export const getCodazonLabelByType = (product, type) =>
    product?.codazon_labels?.find((item) => item?.custom_class === type);

/** @namespace SwiatKsiazkiBasic/Util/Product/getCodazonLabels */
export const getCodazonLabels = (product, isSingleProduct = false, className) => {
    const labelsToShow = getCodazonLabelWithDiscount(product)
        ?.filter((item) =>
            isSingleProduct
                ? item?.custom_class !== 'sale'
                : item?.custom_class !== 'sale' && item?.custom_class !== 'bestseller'
        )
        ?.sort((a, b) =>
            getCodazonLabelPosition(a?.custom_class) >= getCodazonLabelPosition(b?.custom_class) ? -1 : 0
        );

    return (
        <div block={className} elem="Labels" mods={{ empty: !labelsToShow?.length }}>
            {labelsToShow &&
                labelsToShow?.length !== 0 &&
                labelsToShow?.map(({ custom_class, content, custom_css, label_link }, index) => (
                    <ProductLabel
                        type={getProductLabelType(custom_class)}
                        text={content}
                        customClass={custom_class}
                        customCSS={custom_css}
                        key={index}
                        labelLink={label_link}
                    />
                ))}
        </div>
    );
};

/** @namespace SwiatKsiazkiBasic/Util/Product/getProductSeries */
export const getProductSeries = (product) => {
    const { dictionary: { series = [] } = {} } = product || {};
    const { name } = series[0] || [];

    return name;
};

/** @namespace SwiatKsiazkiBasic/Util/Product/getProductSets */
export const getProductSets = (product) => {
    const { attributes: { sale_set: { attribute_value = '' } = {} } = {} } = product || {};

    return attribute_value;
};

/** @namespace SwiatKsiazkiBasic/Util/Product/getIndexedAttributes */
export const getIndexedAttributes = (attributes) =>
    attributes.reduce((indexedAttributes, attribute) => {
        const { attribute_code, attribute_options = [] } = attribute;

        return {
            ...indexedAttributes,
            [attribute_code]: {
                ...attribute,
                attribute_options: attribute_options?.reduce((acc, option) => {
                    const { value } = option;

                    return {
                        ...acc,
                        [value]: getIndexedAttributeOption(option),
                    };
                }, {}),
            },
        };
    }, {});

/** @namespace SwiatKsiazkiBasic/Util/Product/getIndexedVariants */
export const getIndexedVariants = (variants) =>
    variants.map(({ product }) => {
        const { attributes } = product;

        return {
            ...product,
            attributes: getIndexedAttributes(attributes || []),
        };
    });

/** @namespace SwiatKsiazkiBasic/Util/Product/getIndexedSingleVariant */
export const getIndexedSingleVariant = (variants, itemSku) => {
    const index = variants.findIndex(({ product: { sku } }) => sku === itemSku || itemSku.includes(sku));

    if (index < 0) {
        return getIndexedVariants(variants);
    }

    const indexedProduct = variants[index].product;
    const { attributes } = indexedProduct;

    return [{ ...indexedProduct, attributes: getIndexedAttributes(attributes || []) }];
};

/** @namespace SwiatKsiazkiBasic/Util/Product/getIndexedProduct */
export const getIndexedProduct = (product, itemSku) => {
    const {
        variants: initialVariants = [],
        configurable_options: initialConfigurableOptions = [],
        attributes: initialAttributes = [],
        options: initialOptions = [],
        rating_summary,
        review_count,
        reviews: initialReviews,
        items = [],
        bundle_options = [],
    } = product || {};

    const attributes = getIndexedAttributes(initialAttributes || []);
    const reviews = getIndexedReviews(initialReviews);

    const updatedProduct = {
        ...product,
        configurable_options: getIndexedConfigurableOptions(initialConfigurableOptions, attributes),
        variants: itemSku ? getIndexedSingleVariant(initialVariants, itemSku) : getIndexedVariants(initialVariants),
        options: getIndexedCustomOptions(initialOptions || []),
        attributes,
        // Magento 2.4.1 review endpoint compatibility
        reviews,
        review_summary: {
            rating_summary,
            review_count,
        },
    };

    if (bundle_options.length) {
        updatedProduct.items = getBundleOptions(bundle_options, items);
    }

    return updatedProduct;
};

/** @namespace SwiatKsiazkiBasic/Util/Product/getIndexedProducts */
export const getIndexedProducts = (products) => products.map((product) => getIndexedProduct(product));

/** @namespace SwiatKsiazkiBasic/Util/Product/getProductItemsText */
export const getProductItemsText = (items) => {
    switch (items) {
        case 1:
            return __('1 item');
        case 2:
        case 3:
        case 4:
            return __('%s plural items', items);
        default:
            return __('%s items', items);
    }
};

/** @namespace SwiatKsiazkiBasic/Util/Product/getProductIdentificationAttribs */
export const getProductIdentificationAttribs = (product) => {
    const { sku } = product || {};

    if (sku) {
        return {
            'data-id': sku,
        };
    }

    return {};
};
