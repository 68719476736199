import { UPDATE_CURRENT_CATEGORY } from 'Store/Category/Category.action';

export * from 'SourceStore/Category/Category.action';

export const LOADING_CURRENT_CATEGORY = 'LOADING_CURRENT_CATEGORY';
export const RESET_CURRENT_CATEGORY = 'RESET_CURRENT_CATEGORY';

/** @namespace SwiatKsiazkiBasic/Store/Category/Action/setLoadingCurrentCategory */
export const setLoadingCurrentCategory = () => ({
    type: LOADING_CURRENT_CATEGORY,
});

/** @namespace SwiatKsiazkiBasic/Store/Category/Action/resetCurrentCategory */
export const resetCurrentCategory = () => ({
    type: RESET_CURRENT_CATEGORY,
});

/**
 * Update Current Category
 * @param {String} categoryUrlPath url path Main Category object
 * @return {void}
 * @namespace SwiatKsiazkiBasic/Store/Category/Action/updateCurrentCategory */
export const updateCurrentCategory = (category, seoDictionary = {}) => ({
    type: UPDATE_CURRENT_CATEGORY,
    category,
    seoDictionary,
});
