import { PureComponent } from 'react';

import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import Form from 'Component/Form';
import GiftCard from 'Component/GiftCard';
import Icon from 'Component/Icon';
import Loader from 'Component/Loader';
import { VALIDATION_INPUT_TYPE } from 'Util/Validator/Config';

import './GiftCardsActivateCardForm.style.scss';

/** @namespace SwiatKsiazkiBasic/Component/GiftCardsActivateCardForm/Component */
export class GiftCardsActivateCardForm extends PureComponent {
    renderTitle() {
        return (
            <h2 block="GiftCardsActivateCardForm" elem="TabTitle">
                {__('Activate card form')}
            </h2>
        );
    }

    renderAdditionalInfo() {
        return (
            <p block="GiftCardsActivateCardForm" elem="AdditionalInfo">
                {__('* Required fields')}
            </p>
        );
    }

    renderSubmitButton() {
        const { isLoading } = this.props;

        return (
            <div block="GiftCardsActivateCardForm" elem="Buttons">
                <button block="Button" type="submit" mix={{ block: 'GiftCardsActivateCardForm', elem: 'CheckButton' }}>
                    {isLoading ? __('Checking...') : __('Look')}
                </button>
            </div>
        );
    }

    renderActivateCard() {
        const { activateResponse: response, cardNumber, activateResponse: { status } = {} } = this.props;

        if (Object.values(response).length === 0 || !cardNumber || !status) {
            return null;
        }

        return <GiftCard response={response} card={cardNumber} />;
    }

    renderActivateInfo() {
        const { activateResponse: { status, message } = {} } = this.props;

        if (status === false) {
            return (
                <div block="GiftCardsActivateCardForm" elem="ActivateInfo" mods={{ fail: status === false }}>
                    <Icon name="info" width="16" height="16" fill="#fff" />
                    {message}
                </div>
            );
        }

        if (status === true) {
            return (
                <div block="GiftCardsActivateCardForm" elem="ActivateInfo" mods={{ success: true }}>
                    <Icon name="checkmark" width="16" height="16" fill="#fff" />
                    {message}
                </div>
            );
        }

        return null;
    }

    renderCreateActivateFields() {
        return (
            <>
                <Field
                    type={FIELD_TYPE.text}
                    label={__('Card number')}
                    attr={{
                        id: 'cardNumber',
                        name: 'cardNumber',
                        placeholder: __('Card number'),
                    }}
                    validateOn={['onChange']}
                    validationRule={{
                        inputType: VALIDATION_INPUT_TYPE.numeric,
                        isRequired: true,
                    }}
                    addRequiredTag
                />
                <Field
                    type={FIELD_TYPE.password}
                    label={__('Security code')}
                    attr={{
                        id: 'secureCode',
                        name: 'secureCode',
                        placeholder: __('Security code'),
                    }}
                    validateOn={['onChange']}
                    validationRule={{
                        inputType: VALIDATION_INPUT_TYPE.numeric,
                        isRequired: true,
                    }}
                    addRequiredTag
                />
            </>
        );
    }

    renderCreateActivateForm() {
        const { onSuccess } = this.props;

        return (
            <Form key="activate-card" onSubmit={onSuccess}>
                {this.renderCreateActivateFields()}
                {this.renderAdditionalInfo()}
                {this.renderSubmitButton()}
            </Form>
        );
    }

    render() {
        const { isLoading } = this.props;

        return (
            <div block="GiftCardsActivateCardForm">
                {this.renderTitle()}
                <div block="GiftCardsActivateCardForm" elem="Wrapper">
                    <div block="GiftCardsActivateCardForm" elem="FormWrapper">
                        {this.renderCreateActivateForm()}
                        {this.renderActivateInfo()}
                    </div>
                    <div block="GiftCardsCheckValidityForm" elem="InfoWrapper">
                        <Loader isLoading={isLoading} />
                        {this.renderActivateCard()}
                    </div>
                </div>
            </div>
        );
    }
}

export default GiftCardsActivateCardForm;
