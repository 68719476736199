import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    ProductContainer as SourceProductContainer,
} from 'SourceComponent/Product/Product.container';
import { ADD_TO_CART } from 'Util/Product';
import { magentoProductWishlistTransform, transformParameters } from 'Util/Product/Transform';

import { QTY_VALUE_CHANGER } from './Stock.config';

export { mapDispatchToProps, mapStateToProps };

/** @namespace SwiatKsiazkiBasic/Component/Product/Container */
export class ProductContainer extends SourceProductContainer {
    state = {
        enteredOptions: this.setDefaultProductOptions('defaultEnteredOptions', 'enteredOptions'),
        selectedOptions: this.setDefaultProductOptions('defaultSelectedOptions', 'selectedOptions'),
        addToCartTriggeredWithError: false,
        downloadableLinks: [],
        quantity: 1,
        adjustedPrice: {},
        selectedProduct: null,
        parameters: this.props.parameters,
        qtyNumberVisible: false,
    };

    containerFunctions = {
        addToCart: this.addToCart.bind(this),
        updateSelectedValues: this.updateSelectedValues.bind(this),
        setDownloadableLinks: this.setStateOptions.bind(this, 'downloadableLinks'),
        setQuantity: this.setQuantity.bind(this),
        setAdjustedPrice: this.setAdjustedPrice.bind(this),
        getActiveProduct: this.getActiveProduct.bind(this),
        setActiveProduct: this.updateConfigurableVariant.bind(this),
        getMagentoProduct: this.getMagentoProduct.bind(this),
        setValidator: this.setValidator.bind(this),
        scrollOptionsIntoView: this.scrollOptionsIntoView.bind(this),
        updateAddToCartTriggeredWithError: this.updateAddToCartTriggeredWithError.bind(this),
        setVisibleSelectField: this.setVisibleSelectField.bind(this),
    };

    containerProps() {
        const { qtyNumberVisible } = this.state;
        const magentoProductWishlist = this.getMagentoProductWishlist();

        return {
            ...super.containerProps(),
            qtyNumberVisible,
            magentoProductWishlist,
        };
    }

    validateConfigurableProduct() {
        const { parameters } = this.state;

        const { product: { configurable_options = {} } = {} } = this.props;
        const unselectedOptions = Object.keys(configurable_options).reduce((accumulator, value) => {
            if (!parameters[value]) {
                accumulator.push(value);
            }

            return accumulator;
        }, []);

        this.setState({ unselectedOptions });

        return unselectedOptions.length > 0;
    }

    getMagentoProductWishlist() {
        const { quantity, enteredOptions, selectedOptions, downloadableLinks, parameters } = this.state;

        const {
            product,
            product: { attributes },
        } = this.props;

        const configurableOptions = transformParameters(parameters, attributes);

        return magentoProductWishlistTransform(ADD_TO_CART, product, quantity, enteredOptions, [
            ...selectedOptions,
            ...downloadableLinks,
            ...configurableOptions,
        ]);
    }

    setQuantity(quantity) {
        if (typeof quantity === 'object') {
            const { target: { value } = {} } = quantity;

            this.setState({ quantity: +value });
            this.setVisibleNumberField(+value);
            return;
        }

        this.setState({ quantity: +quantity });
        this.setVisibleNumberField(+quantity);
    }

    setVisibleNumberField(value) {
        if (value >= QTY_VALUE_CHANGER) {
            this.setState({ qtyNumberVisible: true });
        }
    }

    setVisibleSelectField(value) {
        const { target: { value: newValue } = {} } = value;

        if (newValue < QTY_VALUE_CHANGER) {
            this.setState({ qtyNumberVisible: false });
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductContainer);
