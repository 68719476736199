import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import {
    mapDispatchToProps,
    mapStateToProps,
    NewsletterSubscriptionContainer as SourceNewsletterSubscriptionContainer,
} from 'SourceComponent/NewsletterSubscription/NewsletterSubscription.container';
import history from 'Util/History';

export { mapStateToProps, mapDispatchToProps };

/** @namespace SwiatKsiazkiBasic/Component/NewsletterSubscription/Container */
export class NewsletterSubscriptionContainer extends SourceNewsletterSubscriptionContainer {
    state = {
        isLoading: false,
        successInfo: '',
    };

    componentDidMount() {
        history.listen(() => {
            this.setState({ successInfo: '' });
        });
    }

    containerProps() {
        const { successInfo } = this.state;

        return {
            ...super.containerProps(),
            successInfo: successInfo || '',
        };
    }

    onFormSubmit(form, fields) {
        const { subscribeToNewsletter, allowGuestSubscribe, isSignedIn, showErrorNotification } = this.props;

        const { value: email } = fields.find(({ name }) => name === 'newsletterEmail') || {};

        if (!allowGuestSubscribe && !isSignedIn) {
            showErrorNotification(
                __('Guests can not subscribe to the newsletter. You must create an account or login to subscribe.')
            );

            return;
        }

        this.setState({ isLoading: true });

        subscribeToNewsletter(email)
            .then(this.onFormSubmitDone)
            .finally(
                /** @namespace SwiatKsiazkiBasic/Component/NewsletterSubscription/Container/NewsletterSubscriptionContainer/onFormSubmit/then/finally */
                () => this.setLoading()
            );
    }

    onFormSubmitDone(data) {
        const { status, message } = data;

        if (status) {
            this.setState({ successInfo: message });
        }
    }

    setLoading() {
        this.setState((prevState) => ({
            ...prevState,
            isLoading: false,
        }));
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewsletterSubscriptionContainer));
