export * from 'SourceRoute/ProductPage/ProductPage.config.js';

export const PRODUCT_DOWNLOADS = 'download';
export const PRODUCT_ASK_FOR_PRODUCT = 'ask';

export const PRODUCT_DOWNLOADS_ID = 'product-download';
export const PRODUCT_ASK_FOR_PRODUCT_ID = 'product-ask';
export const PRODUCT_INFORMATION_ID = 'product-information';
export const PRODUCT_ATTRIBUTES_ID = 'product-attributes';
export const PRODUCT_REVIEWS_ID = 'product-reviews';
export const PRODUCT_TRACK_LIST = 'track-list';
export const PRODUCT_TRACK_LIST_ID = 'product-track-list';

export const DELIVERY_POPUP_ID = 'delivery-popup';
