import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component/build';

import { LAZY_TRESHOLD_DEFAULT } from 'Component/ImageLazyLoading/ImageLazyLoading.config';

import 'react-lazy-load-image-component/src/effects/opacity.css';
import './ImageLazyLoading.style';

/** @namespace SwiatKsiazkiBasic/Component/ImageLazyLoading/Component */
export class ImageLazyLoading extends PureComponent {
    static propTypes = {
        lazyLoadingType: PropTypes.oneOf(['default']),
    };

    renderMap = {
        default: this.renderDefaultImage.bind(this),
    };

    renderDefaultImage() {
        // eslint-disable-next-line no-unused-vars
        const { lazyLoadingType, src, ...rest } = this.props;

        return <LazyLoadImage treshold={LAZY_TRESHOLD_DEFAULT} {...rest} src={src || ''} effect="opacity" />;
    }

    render() {
        const { lazyLoadingType } = this.props;
        const render = this.renderMap[lazyLoadingType];

        return render ? render() : null;
    }
}

export default ImageLazyLoading;
