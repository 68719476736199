import { IS_LOADING_URL_REWRITE, UPDATE_URL_REWRITE } from 'Store/UrlRewrites/UrlRewrites.action';

/** @namespace SwiatKsiazkiBasic/Store/UrlRewrites/Reducer/getInitialState */
export const getInitialState = () => ({
    urlRewrite: {},
    isLoading: false,
});

/** @namespace SwiatKsiazkiBasic/Store/UrlRewrites/Reducer/UrlRewritesReducer */
export const UrlRewritesReducer = (state = getInitialState(), action) => {
    switch (action.type) {
        case UPDATE_URL_REWRITE:
            const { urlRewrite, requestedUrl } = action;

            return {
                ...state,
                isLoading: false,
                urlRewrite,
                requestedUrl,
            };

        case IS_LOADING_URL_REWRITE:
            const { isLoading } = action;

            return {
                ...state,
                isLoading,
            };
        default:
            return state;
    }
};

export default UrlRewritesReducer;
