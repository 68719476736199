import SliderNextArrow from '@swiat-ksiazki-basic/slider/src/components/SliderNextArrow/SliderNextArrow.component';
import SliderPrevArrow from '@swiat-ksiazki-basic/slider/src/components/SliderPrevArrow/SliderPrevArrow.component';
import React, { PureComponent } from 'react';
import SlickSlider from 'react-slick';

import Image from 'Component/Image';
import Link from 'Component/Link';
import { getHrefAttributes } from 'Util/MagezoneWidget';

import './ImageCarousel.style.scss';

/** @namespace SwiatKsiazkiBasic/Component/ImageCarousel/Component */
export class ImageCarousel extends PureComponent {
    renderImage(slide) {
        const { options } = this.props;
        const { src, title, description, contentPosition, overlayColor, link, nofollow, linkTitle, blank } = slide;

        const content = (
            <>
                <Image className="image-default-style" src={src} alt="image" isPlain />
                {(title || description) && contentPosition !== 'none' ? (
                    <div className="item-content">
                        {title ? <div className="item-title">{title}</div> : null}
                        {description ? <div className="item-description">{description}</div> : null}
                    </div>
                ) : null}
                {overlayColor ? <div className="mgz-overlay" /> : null}
            </>
        );

        if (link) {
            return (
                <Link
                    block="ImageCarousel"
                    elem="Image"
                    className={contentPosition}
                    to={link}
                    title={linkTitle}
                    style={{ marginRight: options?.margin || 0 }}
                    {...getHrefAttributes({ nofollow, blank })}
                >
                    {content}
                </Link>
            );
        }

        return (
            <div
                block="ImageCarousel"
                elem="Image"
                className={contentPosition}
                style={{ marginRight: options?.margin || 0 }}
            >
                {content}
            </div>
        );
    }

    renderSlide() {
        return () => {};
    }

    render() {
        const { options, images, sliderOptions } = this.props;

        return (
            <div
                className=" mgz-carousel"
                block="ImageCarousel"
                mods={{ dots: 'dots' in sliderOptions }}
                style={{ marginRight: Math.abs(options?.margin || 0) * -1 }}
            >
                <SlickSlider {...sliderOptions} prevArrow={<SliderPrevArrow />} nextArrow={<SliderNextArrow />}>
                    {images.map((slide, key) => (
                        <div className="item mgz-carousel-item" key={key}>
                            {this.renderImage(slide)}
                        </div>
                    ))}
                </SlickSlider>
            </div>
        );
    }
}

export default ImageCarousel;
