/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { isSignedIn } from 'Util/Auth';
import { getGuestQuoteId } from 'Util/Cart';

import { DL_VAL_CHECKOUT_BILLING_STEP, DL_VAL_CHECKOUT_SHIPPING_STEP } from '../../data/checkout';
import { fireCheckoutEvent, fireCheckoutOptionEvent, firePurchaseEvent } from '../../event/checkout';

const addFireCheckoutOptionEventForBilling = (args, callback, instance) => {
    callback(...args);

    const { paymentMethod } = instance.state;

    fireCheckoutOptionEvent(DL_VAL_CHECKOUT_BILLING_STEP, paymentMethod);
};

const addFireCheckoutEventForBilling = (args, callback) => {
    callback(...args);
    fireCheckoutEvent(DL_VAL_CHECKOUT_BILLING_STEP);
};

const addFireCheckoutOptionEventForShipping = (args, callback, instance) => {
    callback(...args);

    const {
        selectedShippingMethod: { carrier_title }
    } = instance.state;

    fireCheckoutOptionEvent(DL_VAL_CHECKOUT_SHIPPING_STEP, carrier_title);
};

const addFireCheckoutEventForShipping = (args, callback) => {
    callback(...args);
    fireCheckoutEvent(DL_VAL_CHECKOUT_SHIPPING_STEP);
};

const addFirePurchaseEvent = (args, callback, instance) => {
    const { orderIds } = instance.props;
    const guestQuoteId = !isSignedIn() ? getGuestQuoteId(true) : '';
    // ^^^ getting this here, as it will soon be reset
    firePurchaseEvent(orderIds, guestQuoteId);
    // vvv This must be called after guest quote id is obtained
    callback(...args);
};

export default {
    'SwiatKsiazkiBasic/Component/CheckoutBilling/Container': {
        'member-function': {
            onBillingSuccess: addFireCheckoutOptionEventForBilling,
            componentDidMount: addFireCheckoutEventForBilling
        }
    },
    'SwiatKsiazkiBasic/Component/CheckoutShipping/Container': {
        'member-function': {
            onShippingSuccess: addFireCheckoutOptionEventForShipping,
            componentDidMount: addFireCheckoutEventForShipping
        }
    },
    'SwiatKsiazkiBasic/Component/CheckoutSuccess/Component': {
        'member-function': {
            componentDidMount: addFirePurchaseEvent
        }
    }
};
