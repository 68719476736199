import { PRODUCTS_IN_WISHLIST, removeItemFromWishlist, updateItemOptions } from 'SourceStore/Wishlist/Wishlist.reducer';
import {
    CLEAR_WISHLIST,
    REMOVE_ITEM_FROM_WISHLIST,
    UPDATE_ALL_PRODUCTS_IN_WISHLIST,
    UPDATE_IS_LOADING_IN_WISHLIST,
    UPDATE_ITEM_OPTIONS,
} from 'Store/Wishlist/Wishlist.action';
import BrowserDatabase from 'Util/BrowserDatabase';
import { getIndexedParameteredProducts } from 'Util/Product';

import {
    SET_WISHLIST_PRICE_DROP,
    UPDATE_ALL_PRODUCTS_IN_WISHLIST_ARRAY,
    UPDATE_ALL_PRODUCTS_IN_WISHLIST_COUNT,
} from './Wishlist.action';

/** @namespace SwiatKsiazkiBasic/Store/Wishlist/Reducer/getInitialState */
export const getInitialState = () => ({
    productsInWishlistArray: [],
    productsInWishlist: {},
    productsInWishlistCount: 0,
    productsInWishlistCountByCategory: 0,
    isWishlistHavePriceDrop: false,
    isLoading: true,
});

/** @namespace SwiatKsiazkiBasic/Store/Wishlist/Reducer/clearWishlist */
export const clearWishlist = () => {
    const productsInWishlist = {};

    BrowserDatabase.setItem(productsInWishlist, PRODUCTS_IN_WISHLIST);

    return {
        productsInWishlistArray: [],
        productsInWishlist: {},
        productsInWishlistCount: 0,
        productsInWishlistCountByCategory: 0,
        isWishlistHavePriceDrop: false,
    };
};

/** @namespace SwiatKsiazkiBasic/Store/Wishlist/Reducer/updateAllProductsInWishlist */
export const updateAllProductsInWishlist = (action) => {
    const { products: initialProducts } = action;

    const products = getIndexedParameteredProducts(initialProducts);

    return { productsInWishlist: products, isLoading: false };
};

/** @namespace SwiatKsiazkiBasic/Store/Wishlist/Reducer/WishlistReducer */
export const WishlistReducer = (state = getInitialState(), action) => {
    const { type, options } = action;

    switch (type) {
        case REMOVE_ITEM_FROM_WISHLIST:
            return {
                ...state,
                isLoading: false,
                ...removeItemFromWishlist(action, state),
            };

        case CLEAR_WISHLIST:
            return {
                ...state,
                ...clearWishlist(),
            };

        case UPDATE_ALL_PRODUCTS_IN_WISHLIST:
            return {
                ...state,
                isLoading: false,
                ...updateAllProductsInWishlist(action),
            };

        case UPDATE_ITEM_OPTIONS:
            return {
                ...state,
                ...updateItemOptions(options, state),
            };

        case UPDATE_ALL_PRODUCTS_IN_WISHLIST_COUNT:
            const { productsInWishlistCount, productsInWishlistCountByCategory } = action;

            return {
                ...state,
                productsInWishlistCount: productsInWishlistCount || 0,
                productsInWishlistCountByCategory: productsInWishlistCountByCategory || 0,
            };

        case UPDATE_ALL_PRODUCTS_IN_WISHLIST_ARRAY: {
            const { productsInWishlistArray } = action;

            return {
                ...state,
                productsInWishlistArray,
            };
        }
        case UPDATE_IS_LOADING_IN_WISHLIST:
            const { isLoading } = action;

            return {
                ...state,
                isLoading,
            };

        case SET_WISHLIST_PRICE_DROP:
            const { isWishlistHavePriceDrop } = action;

            return {
                ...state,
                isWishlistHavePriceDrop,
            };

        default:
            return state;
    }
};

export default WishlistReducer;
