import { PAGINATION_QUERY_KEY } from 'Component/Pagination/Pagination.config';
import WishlistQuery from 'Query/Wishlist.query';
import {
    isWishlistEnabled,
    WishlistDispatcher as SourceWishlistDispatcher,
} from 'SourceStore/Wishlist/Wishlist.dispatcher';
import { showNotification } from 'Store/Notification/Notification.action';
import {
    setWishlistPriceDrop,
    updateAllProductsInWishlist,
    updateAllProductsInWishlistArray,
    updateAllProductsInWishlistCount,
    updateIsLoading,
} from 'Store/Wishlist/Wishlist.action';
import { getAuthorizationToken, isSignedIn } from 'Util/Auth';
import history from 'Util/History';
import { fetchMutation, fetchQuery, getErrorMessage } from 'Util/Request';
import { getQueryParam, setQueryParams } from 'Util/Url';

export { isWishlistEnabled };

/** @namespace SwiatKsiazkiBasic/Store/Wishlist/Dispatcher */
export class WishlistDispatcher extends SourceWishlistDispatcher {
    loadWishlistItems(dispatch) {
        this.updateInitialWishlistData(dispatch);
    }

    _syncWishlistWithBE(dispatch) {
        dispatch(updateIsLoading(true));

        // Need to get current wishlist from BE, update wishlist
        return fetchQuery([WishlistQuery.getWishlistQuery(), WishlistQuery.getWishlistSkuQuery()]).then(
            /** @namespace SwiatKsiazkiBasic/Store/Wishlist/Dispatcher/WishlistDispatcher/_syncWishlistWithBE/fetchQuery/then */
            (data) => {
                if (!getAuthorizationToken()) {
                    return;
                }

                if (data && data.wishlist) {
                    const { wishlist, customer } = data;
                    const { wishlist: wishlistSku } = customer || {};
                    const { items } = wishlistSku || {};
                    const wishlistSkuArray = items || [];

                    const { is_wishlist_have_price_drop } = wishlist;

                    const productsToAdd = wishlist.query_items.result.reduce((prev, wishlistItem) => {
                        const {
                            id,
                            sku,
                            product,
                            description,
                            buy_request,
                            options,
                            price_alert,
                            qty: quantity,
                        } = wishlistItem;

                        return {
                            ...prev,
                            [id]: {
                                ...product,
                                quantity,
                                price_alert,
                                wishlist: {
                                    id,
                                    sku,
                                    quantity,
                                    description,
                                    buy_request,
                                    options,
                                },
                            },
                        };
                    }, {});

                    dispatch(
                        updateAllProductsInWishlistCount(
                            wishlist?.items_count,
                            wishlist?.query_items?.filtered_items_count
                        )
                    );
                    dispatch(updateAllProductsInWishlistArray(wishlistSkuArray));
                    dispatch(updateAllProductsInWishlist(productsToAdd));
                    dispatch(setWishlistPriceDrop(is_wishlist_have_price_drop));
                }

                dispatch(updateIsLoading(false));
            },
            /** @namespace SwiatKsiazkiBasic/Store/Wishlist/Dispatcher/WishlistDispatcher/_syncWishlistWithBE/fetchQuery/then/catch */
            () => {
                dispatch(updateIsLoading(false));
            }
        );
    }

    async addItemToWishlist(dispatch, options) {
        if (!isSignedIn()) {
            return;
        }

        try {
            const { items = [], wishlistId = '' } = options;

            let productNames = '';

            const itemsWithoutNameValue = items.map((item) => {
                const { name, ...rest } = item;
                productNames += name;
                return { ...rest };
            });

            dispatch(updateIsLoading(true));
            const {
                addProductsToWishlist: { user_errors = [] },
            } = await fetchMutation(WishlistQuery.addProductsToWishlist(wishlistId, itemsWithoutNameValue));

            if (user_errors?.length > 0) {
                dispatch(showNotification('error', getErrorMessage(user_errors)));
            } else {
                dispatch(showNotification('success', __('Product %s added to wishlist', productNames)));
                await this._syncWishlistWithBE(dispatch);
            }
        } catch {
            dispatch(showNotification('error', __('Error updating wish list!')));
        } finally {
            dispatch(updateIsLoading(false));
        }
    }

    async removeItemFromWishlist(dispatch, { item_id, name, noMessages, lastItem }) {
        if (!item_id || !isSignedIn()) {
            return Promise.reject();
        }

        dispatch(updateIsLoading(true));

        try {
            await fetchMutation(WishlistQuery.getRemoveProductFromWishlistMutation(item_id));

            if (lastItem) {
                this._goToPreviousPage();
            } else {
                await this._syncWishlistWithBE(dispatch);
            }
        } catch (e) {
            if (!noMessages) {
                dispatch(showNotification('error', __('Error updating wish list!')));
            }

            return Promise.reject();
        }

        if (!noMessages) {
            dispatch(showNotification('success', __('Product %s has been removed from your wishlist', name)));
        }

        return Promise.resolve();
    }

    removeItemsFromWishlist(dispatch, itemIdMap) {
        if (!itemIdMap.length || !isSignedIn()) {
            return Promise.reject();
        }

        return itemIdMap.map((id) =>
            fetchMutation(WishlistQuery.getRemoveProductFromWishlistMutation(id)).then(
                /** @namespace SwiatKsiazkiBasic/Store/Wishlist/Dispatcher/WishlistDispatcher/removeItemsFromWishlist/itemIdMap/map/fetchMutation/then */
                () => {
                    dispatch(showNotification('success', __('Products has been removed from your wishlist')));
                },
                /** @namespace SwiatKsiazkiBasic/Store/Wishlist/Dispatcher/WishlistDispatcher/removeItemsFromWishlist/itemIdMap/map/fetchMutation/then/catch */
                (error) => {
                    dispatch(showNotification('error', getErrorMessage(error, __('Error updating wishlist!'))));
                }
            )
        );
    }

    clearWishlist(dispatch) {
        if (!isSignedIn()) {
            return Promise.reject();
        }

        return fetchMutation(WishlistQuery.getClearWishlist())
            .then(
                /** @namespace SwiatKsiazkiBasic/Store/Wishlist/Dispatcher/WishlistDispatcher/clearWishlist/then/catch/fetchMutation/then */
                () => {
                    this._syncWishlistWithBE(dispatch);
                }
            )
            .catch(
                /** @namespace SwiatKsiazkiBasic/Store/Wishlist/Dispatcher/WishlistDispatcher/clearWishlist/then/catch/dispatch */
                () => dispatch(showNotification('error', __('Error clearing wish list!')))
            );
    }

    _goToPreviousPage() {
        const firstPage = 1;
        const currentPage = +(getQueryParam(PAGINATION_QUERY_KEY, location) || 1);
        const prevPage = currentPage - 1;

        if (prevPage === firstPage) {
            setQueryParams(
                {
                    [PAGINATION_QUERY_KEY]: '',
                },
                history.location,
                history
            );
        } else if (currentPage > firstPage) {
            setQueryParams(
                {
                    [PAGINATION_QUERY_KEY]: prevPage,
                },
                history.location,
                history
            );
        }
    }
}

export default new WishlistDispatcher();
