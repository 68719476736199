import { connect } from 'react-redux';

import OmnibusPriceInfo from './OmnibusPriceInfo.component';

/** @namespace SwiatKsiazkiBasic/Component/OmnibusPriceInfo/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device,
});

/** @namespace SwiatKsiazkiBasic/Component/OmnibusPriceInfo/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(OmnibusPriceInfo);
