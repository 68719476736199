import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { CategoriesGridImagesWithSingleImageType } from 'Component/CategoriesGridImagesWithSingleImage/CategoriesGridImagesWithSingleImage.config';
import Image from 'Component/Image';
import Link from 'Component/Link';

import './CategoriesGridImagesWithSingleImage.style';

/** @namespace SwiatKsiazkiBasic/Component/CategoriesGridImagesWithSingleImage/Component */
export class CategoriesGridImagesWithSingleImage extends PureComponent {
    static propTypes = {
        singleItem: CategoriesGridImagesWithSingleImageType.isRequired,
        items: PropTypes.arrayOf(CategoriesGridImagesWithSingleImageType).isRequired,
    };

    render() {
        const { singleItem, items } = this.props;

        const CategoriesGridImagesWithSingleImageComponent = ({ url, text, src, heading }) => (
            <Link
                to={url}
                block="CategoriesGridImagesWithSingleImage"
                elem="Link"
                mods={{ isHeading: heading?.length > 0 }}
            >
                <Image src={src} />
                <p block="CategoriesGridImagesWithSingleImage" elem="Text">
                    {text}
                </p>
                <span block="CategoriesGridImagesWithSingleImage" elem="Heading">
                    {heading}
                </span>
            </Link>
        );

        return (
            <div block="CategoriesGridImagesWithSingleImage">
                <div block="CategoriesGridImagesWithSingleImage" elem="Single">
                    <CategoriesGridImagesWithSingleImageComponent
                        url={singleItem.url}
                        text={singleItem.text}
                        src={singleItem.src}
                        heading={singleItem.heading}
                    />
                </div>
                <div block="CategoriesGridImagesWithSingleImage" elem="List">
                    {items?.map((item) => (
                        <CategoriesGridImagesWithSingleImageComponent
                            url={item.url}
                            text={item.text}
                            src={item.src}
                            heading={item.heading}
                        />
                    ))}
                </div>
            </div>
        );
    }
}

export default CategoriesGridImagesWithSingleImage;
