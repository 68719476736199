import { UrlRewritesQuery as SourceUrlRewritesQuery } from 'SourceQuery/UrlRewrites.query';

/** @namespace SwiatKsiazkiBasic/Query/UrlRewrites/Query */
export class UrlRewritesQuery extends SourceUrlRewritesQuery {
    _getUrlResolverFields() {
        return ['id', 'sku', 'type', 'dictionary_type', 'custom_root_category'];
    }
}

export default new UrlRewritesQuery();
