import { Container, ImageList, ImageListItem } from '@mui/material';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Icon from 'Component/Icon';

import { NEW_OPENINGS_WIDGET_ROW_HEIGHT } from './NewOpeningsWidget.config';

import './NewOpeningsWidget.style';

/** @namespace SwiatKsiazkiBasic/Component/NewOpeningsWidget/Component */
export class NewOpeningsWidget extends PureComponent {
    static propTypes = {
        title: PropTypes.string,
        description: PropTypes.string,
        gallery: PropTypes.any,
        getSrcset: PropTypes.func,
    };

    renderDescription() {
        const { description = '' } = this.props;

        if (!description) {
            return null;
        }

        const [address, date] = description.split('::');

        return (
            <>
                {address ? (
                    <p block="NewOpeningsWidget" elem="ContentDetail" mods={{ type: 'Localization' }}>
                        <Icon name="marker_2" width="14" height="20" />
                        <span dangerouslySetInnerHTML={{ __html: address }} />
                    </p>
                ) : null}
                {date ? (
                    <p block="NewOpeningsWidget" elem="ContentDetail" mods={{ type: 'Date' }}>
                        <Icon name="calendar_2" width="19" height="20" />
                        <span dangerouslySetInnerHTML={{ __html: date }} />
                    </p>
                ) : null}
            </>
        );
    }

    renderImageListItems() {
        const { gallery, getSrcset, imageCols } = this.props;

        if (!gallery) return null;

        return gallery.map(({ src, title, rows, cols }, i) => (
            <ImageListItem key={i} cols={imageCols || cols || 1} rows={rows || 1}>
                <img
                    {...getSrcset(src, NEW_OPENINGS_WIDGET_ROW_HEIGHT, rows, imageCols || cols)}
                    alt={title}
                    loading="lazy"
                />
            </ImageListItem>
        ));
    }

    renderGallery() {
        const { gallery, cols } = this.props;

        if (gallery.length === 1) {
            const { src, title } = gallery[0];
            return <img alt={title} src={src} loading="lazy" />;
        }

        return (
            <Container disableGutters sx={{ padding: 0 }}>
                <ImageList
                    variant="quilted"
                    cols={cols}
                    gap={3}
                    rowHeight={NEW_OPENINGS_WIDGET_ROW_HEIGHT}
                    sx={{ width: '100%', height: '100%' }}
                >
                    {this.renderImageListItems()}
                </ImageList>
            </Container>
        );
    }

    render() {
        const { title } = this.props;

        return (
            <div block="NewOpeningsWidget">
                <div block="NewOpeningsWidget" elem="ContentWrapper">
                    {title ? (
                        <h3 block="NewOpeningsWidget" elem="Title">
                            {title}
                        </h3>
                    ) : null}
                    {this.renderDescription()}
                </div>
                <div block="NewOpeningsWidget" elem="Gallery">
                    {this.renderGallery()}
                </div>
            </div>
        );
    }
}

export default NewOpeningsWidget;
