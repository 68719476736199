import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Image from 'Component/Image';

import './GridImages.style';

/** @namespace SwiatKsiazkiBasic/Component/GridImages/Component */
export class GridImages extends PureComponent {
    static propType = {
        images: PropTypes.arrayOf({
            src: PropTypes.string,
            alt: PropTypes.string,
        }),
    };

    render() {
        const { images } = this.props;

        return (
            <div block="GridImages" elem="Grid">
                {images &&
                    images.map(({ src, alt }) => (
                        <div block="GridImages" elem="ImageContainer">
                            <Image src={src} alt={alt} />
                        </div>
                    ))}
            </div>
        );
    }
}

export default GridImages;
