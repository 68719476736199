// import PropTypes from 'prop-types';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import BookstoreLocationsQuery from 'Query/BookstoreLocations.query';
import { showNotification } from 'Store/Notification/Notification.action';
import { fetchQuery, getErrorMessage } from 'Util/Request';

import BookstoreLocations from './BookstoreLocations.component';

/** @namespace SwiatKsiazkiBasic/Component/BookstoreLocations/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    googleMapsApiKey: state.ConfigReducer.cms_pagebuilder_google_maps_api_key,
});

/** @namespace SwiatKsiazkiBasic/Component/BookstoreLocations/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace SwiatKsiazkiBasic/Component/BookstoreLocations/Container */
export class BookstoreLocationsContainer extends PureComponent {
    static propTypes = {
        googleMapsApiKey: PropTypes.string,
    };

    state = {
        locations: [],
        isLoading: false,
    };

    componentDidMount() {
        this._getLocationsQuery();
    }

    async _getLocationsQuery() {
        this.setState({
            isLoading: true,
        });

        try {
            const { bookstoreLocations: locations } = await fetchQuery(BookstoreLocationsQuery.getQuery());

            this.setState({ locations });
        } catch (e) {
            showNotification('error', getErrorMessage(e));
        } finally {
            this.setState({ isLoading: false });
        }
    }

    _getDescription(location) {
        return `
            ${this._getBasicDescription(location)}
            <br /><br />
            ${this._getAdditionalDescription(location)}
        `;
    }

    _getBasicDescription(location) {
        const { address, place } = location;

        return `
            ${place}<br />
            ${address}   
        `;
    }

    _getAdditionalDescription(location) {
        const { custom_html, opening_hours, phone } = location;

        return `
            ${__('Contact')}<br />
            ${phone}<br /><br />
            ${__('Opening hours')}<br />
            <div class="OpeningHours">${opening_hours}</div>
            ${custom_html && `<br />  <div class="AdditionalInfo">${custom_html} </div>`}
        `;
    }

    containerProps() {
        const { googleMapsApiKey } = this.props;
        const { locations, isLoading } = this.state;

        const markers = locations.map((e, index) => {
            const { address, place, latitude, longitude } = e;

            return {
                address,
                place,
                description: this._getDescription(e),
                sidebarShownInfo: this._getBasicDescription(e),
                sidebarHiddenInfo: this._getAdditionalDescription(e),
                lat: parseFloat(latitude),
                lng: parseFloat(longitude),
                point_key: index,
            };
        });

        return {
            locations: markers,
            googleMapsApiKey,
            isLoading,
        };
    }

    render() {
        return <BookstoreLocations {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BookstoreLocationsContainer);
