export const LOADED_AGREEMENT = 'LOADED_AGREEMENT';
export const SELECT_AGREEMENT = 'SELECT_AGREEMENT';
export const CLEAR_AGREEMENTS = 'CLEAR_AGREEMENTS';
export const CLEAR_AGREEMENT = 'CLEAR_AGREEMENT';

/** @namespace SwiatKsiazkiBasic/Store/Agreements/Action/loadAgreements */
export const loadAgreements = (name, agreements) => ({
    type: LOADED_AGREEMENT,
    agreements,
    name,
});

/** @namespace SwiatKsiazkiBasic/Store/Agreements/Action/updateSelectAgreement */
export const updateSelectAgreement = (name, agreement) => ({
    type: SELECT_AGREEMENT,
    agreement,
    name,
});

/** @namespace SwiatKsiazkiBasic/Store/Agreements/Action/clearAgreement */
export const clearAgreement = (name) => ({
    type: CLEAR_AGREEMENT,
    name,
});

/** @namespace SwiatKsiazkiBasic/Store/Agreements/Action/clearAgreements */
export const clearAgreements = () => ({
    type: CLEAR_AGREEMENTS,
});
