import { PRODUCT_TYPE } from 'Component/Product/Product.config';
import { STOCK_TYPE } from 'Component/Product/Stock.config';
import { DEFAULT_CURRENCY } from 'Util/Currency/Currency';
import { formatPrice } from 'Util/Price';
import { DEFAULT_MAX_PRODUCTS, getQuantity, MAX_SALE_QTY, SALABLE_QTY } from 'Util/Product/Extract';

export * from 'SourceUtil/Product/Extract';

/** @namespace SwiatKsiazkiBasic/Util/Product/Extract/getMaxQuantity */
export const getMaxQuantity = (product, configIndex = -1) => {
    const maxQuantity = getQuantity(product, DEFAULT_MAX_PRODUCTS, MAX_SALE_QTY, configIndex);
    const salableQuantity = getQuantity(product, DEFAULT_MAX_PRODUCTS, SALABLE_QTY, configIndex);
    const { availability = null } = product;

    // With this condition, it is possible that there is a backorder - in which case salableQuantity may be minus.
    if (availability && availability > 1) {
        return maxQuantity;
    }

    return Math.min(maxQuantity, salableQuantity);
};

/** @namespace SwiatKsiazkiBasic/Util/Product/Extract/getProductInStock */
export const getProductInStock = (product, parentProduct = {}) => {
    if (!product) {
        return false;
    }

    const {
        type_id: type,
        variants = [],
        items = [],
        stock_item: { in_stock: inStock = true } = {},
        availability,
    } = product;
    const availableInStock = availability && availability > 0;

    if (type === PRODUCT_TYPE.bundle) {
        const { items = [] } = product;
        const requiredItems = items.filter(({ required }) => required);
        const requiredItemsInStock = requiredItems.filter(({ options }) =>
            options.some(({ product }) => getProductInStock(product))
        );

        return inStock && requiredItemsInStock.length === requiredItems.length;
    }

    if (type === PRODUCT_TYPE.configurable && parentProduct === product) {
        return inStock && !!variants.some((variant) => getProductInStock(variant, product));
    }

    const { type_id: parentTypeId = false } = parentProduct;

    if (parentTypeId === PRODUCT_TYPE.configurable && parentProduct !== product) {
        const { stock_item: { in_stock: parentInStock = true } = {}, stock_status: parentStockStatus } = parentProduct;

        return parentInStock && parentStockStatus !== STOCK_TYPE.OUT_OF_STOCK && getProductInStock(product);
    }

    if (type === PRODUCT_TYPE.grouped) {
        return inStock && !!items.some(({ product }) => getProductInStock(product));
    }

    const { stock_status: stockStatus } = product;

    return (
        stockStatus !== STOCK_TYPE.OUT_OF_STOCK && (inStock || stockStatus === STOCK_TYPE.IN_STOCK) && availableInStock
    );
};

/**
 * Returns price object for product.
 * @param priceRange
 * @param dynamicPrice
 * @param adjustedPrice
 * @param type
 * @param options
 * @returns {{originalPrice: {maxFinalPriceExclTax: {valueFormatted: string}, minFinalPrice: {valueFormatted: string}, minFinalPriceExclTax: {valueFormatted: string}, maxFinalPrice: {valueFormatted: string}}, price: {originalPriceExclTax: {currency: string, value: (number|number)}, originalPrice: {currency: string, value: (number|number)}, finalPriceExclTax: {currency: string, value: (number|number)}, finalPrice: {currency: string, value: (number|number)}, discount: {percentOff: number}}}}
 * @namespace SwiatKsiazkiBasic/Util/Product/Extract/getPrice */
export const getPrice = (
    priceRange,
    dynamicPrice = false,
    adjustedPrice = {},
    type = PRODUCT_TYPE.simple,
    options = []
) => {
    const priceAcc = type === PRODUCT_TYPE.bundle ? 'final_price' : 'regular_price';
    const priceExcTaxAcc =
        type === PRODUCT_TYPE.bundle || type === PRODUCT_TYPE.configurable ? 'final_price' : 'regular_price';
    const accessRange =
        type === PRODUCT_TYPE.virtual || type === PRODUCT_TYPE.downloadable ? 'minimum_price' : 'minimum_price';

    const {
        [accessRange]: {
            [priceAcc]: { currency = DEFAULT_CURRENCY, value: basePrice = 0 } = {},
            [priceExcTaxAcc]: { value: basePriceExclTax = 0 } = {},
            discount: { percent_off: percentOffRef = 0, amount_off: amountOff = 0 } = {},
        } = {},
        minimum_price: {
            regular_price: minRegularPrice = {},
            final_price: minFinalPrice = {},
            final_price_excl_tax: minFinalPriceExclTax = {},
        } = {},
        maximum_price: {
            regular_price: maxRegularPrice = {},
            final_price: maxFinalPrice = {},
            final_price_excl_tax: maxFinalPriceExclTax = {},
        } = {},
    } = priceRange || {};

    // Fixes decimal misplacement for discount
    // eslint-disable-next-line no-magic-numbers
    const percentOffCalc = (amountOff / basePrice) * 100;
    // eslint-disable-next-line no-magic-numbers
    const percentOff = Math.round(percentOffCalc * 100) / 100 === percentOffRef ? percentOffCalc : percentOffRef;

    // eslint-disable-next-line no-magic-numbers
    const discountValue = 1 - percentOff / 100;
    // eslint-disable-next-line no-magic-numbers
    const discountValueRevert = discountValue === 0 ? 1 : discountValue;

    const basePriceExclDiscount = priceAcc === 'default_final_price' ? basePrice / discountValueRevert : basePrice;
    const basePriceExclDiscountExclTax =
        priceAcc === 'default_final_price' ? basePriceExclTax / discountValueRevert : basePriceExclTax;

    const priceValue = { value: dynamicPrice ? 0 : basePriceExclDiscount * discountValue, currency };
    const priceValueExclTax = { value: dynamicPrice ? 0 : basePriceExclDiscountExclTax * discountValue, currency };
    const priceValueExclDiscount = { value: dynamicPrice ? 0 : basePriceExclDiscount, currency };
    const priceValueExclDiscountExclTax = { value: dynamicPrice ? 0 : basePriceExclDiscountExclTax, currency };

    // Adds adjusted price
    Object.keys(adjustedPrice || {}).forEach((key) => {
        const { [key]: group } = adjustedPrice;
        const { inclTax = 0, exclTax = 0, requiresDiscountCalculations = true, hasDiscountCalculated = false } = group;

        if (requiresDiscountCalculations) {
            if (hasDiscountCalculated) {
                priceValue.value += inclTax;
                priceValueExclTax.value += exclTax;
                priceValueExclDiscount.value += inclTax / discountValueRevert;
                priceValueExclDiscountExclTax.value += exclTax / discountValueRevert;
            } else {
                priceValue.value += inclTax * discountValue;
                priceValueExclTax.value += exclTax * discountValue;
                priceValueExclDiscount.value += inclTax;
                priceValueExclDiscountExclTax.value += exclTax;
            }
        } else {
            priceValue.value += inclTax;
            priceValueExclTax.value += exclTax;
            priceValueExclDiscount.value += inclTax;
            priceValueExclDiscountExclTax.value += exclTax;
        }
    });

    // Adds formatted price option
    priceValue.valueFormatted = formatPrice(priceValue.value, currency);
    priceValueExclTax.valueFormatted = formatPrice(priceValueExclTax.value, currency);
    priceValueExclDiscount.valueFormatted = formatPrice(priceValueExclDiscount.value, currency);
    priceValueExclDiscountExclTax.valueFormatted = formatPrice(priceValueExclDiscountExclTax.value, currency);

    const configuration = {
        containsOptions: options && !!options.length,
        containsOptionsWithPrice: false,
        containsRequiredOptions: false,
        containsRequiredOptionsWithPrice: false,
    };

    if (options) {
        configuration.containsOptionsWithPrice = !!options.find(
            ({ value = [] }) => Array.isArray(value) && value.find(({ price }) => price)
        );
        const requiredOptions = options.filter(({ required }) => required);
        configuration.containsRequiredOptions = !!requiredOptions.length;

        if (requiredOptions.length) {
            configuration.containsRequiredOptionsWithPrice = !!requiredOptions.find(
                ({ value = [] }) => Array.isArray(value) && value.find(({ price }) => price)
            );
        }
    }

    return {
        price: {
            finalPrice: priceValue,
            finalPriceExclTax: priceValueExclTax,
            originalPrice: priceValueExclDiscount,
            originalPriceExclTax: priceValueExclDiscountExclTax,
            discount: {
                percentOff,
                amountOff,
            },
        },
        originalPrice: {
            minRegularPrice: {
                ...minRegularPrice,
                valueFormatted: formatPrice(minRegularPrice.value || 0, currency),
            },
            minFinalPrice: {
                ...minFinalPrice,
                valueFormatted: formatPrice(minFinalPrice.value || 0, currency),
            },
            minFinalPriceExclTax: {
                ...minFinalPriceExclTax,
                valueFormatted: formatPrice(minFinalPriceExclTax.value || 0, currency),
            },
            maxRegularPrice: {
                ...maxRegularPrice,
                valueFormatted: formatPrice(maxRegularPrice.value || 0, currency),
            },
            maxFinalPrice: {
                ...maxFinalPrice,
                valueFormatted: formatPrice(maxFinalPrice.value || 0, currency),
            },
            maxFinalPriceExclTax: {
                ...maxFinalPriceExclTax,
                valueFormatted: formatPrice(maxFinalPriceExclTax.value || 0, currency),
            },
        },
        configuration,
    };
};

/** @namespace SwiatKsiazkiBasic/Util/Product/Extract/getDownloadableProductHash */
export const getDownloadableProductHash = (link) => {
    link = new URL(link).pathname.split('/').filter(Boolean);
    return decodeURIComponent(link[link.length - 1]);
};

/** @namespace SwiatKsiazkiBasic/Util/Product/Extract/getProductQuantityIsNotAvailable */
export const getProductQuantityIsNotAvailable = (product, qty = 0) => qty > getMaxQuantity(product);
