import CookieQuery from 'Query/Cookie.query';
import {
    AMASTY_ALLOWED_COOKIE_NAME,
    Cookie,
    getAmastyAllowedCookie,
    hasAmastyAllowedCookie,
    updateGoogleConsent,
} from 'Util/Cookie';
import { setCookie } from 'Util/Cookie/Cookie';
import { fetchMutation, fetchQuery } from 'Util/Request';

/** @namespace SwiatKsiazkiBasic/Store/Cookie/Dispatcher */
export class CookieDispatcher {
    acceptGroups(identifiers, refreshPage = false) {
        return fetchMutation(CookieQuery.setCookieGroups(identifiers)).then(
            /** @namespace SwiatKsiazkiBasic/Store/Cookie/Dispatcher/CookieDispatcher/acceptGroups/fetchMutation/then */
            (response) => {
                if (process.env.NODE_ENV === 'development') {
                    setCookie(AMASTY_ALLOWED_COOKIE_NAME, identifiers.join(','));
                }

                updateGoogleConsent(identifiers);

                Cookie.runScripts(refreshPage);
                return response;
            }
        );
    }

    getGroups() {
        return fetchQuery(CookieQuery.getCookieGroups());
    }

    isAccepted() {
        return hasAmastyAllowedCookie();
    }

    getAccepted() {
        return getAmastyAllowedCookie();
    }

    async saveGroupsOnRegister() {
        if (!this.isAccepted()) {
            return Promise.resolve();
        }

        try {
            await this.acceptGroups(this.getAccepted());
            return Promise.resolve();
        } catch {
            return Promise.resolve();
        }
    }
}

export default new CookieDispatcher();
