import { PureComponent } from 'react';
import { createPortal } from 'react-dom';

import Icon from 'Component/Icon';
import Link from 'Component/Link';

import { CMS_PAGE_SUBMENU_SELECTOR, LINKS_ICONS } from './CmsStaticSidebarMenu.config';

import './CmsStaticSidebarMenu.style.scss';

/** @namespace SwiatKsiazkiBasic/Component/CmsStaticSidebarMenu/Component */
export class CmsStaticSidebarMenu extends PureComponent {
    componentDidMount() {
        const submenuSelector = document.querySelector(CMS_PAGE_SUBMENU_SELECTOR);
        this.setState({ submenuSelector });
    }

    __construct(props) {
        super.__construct(props);
        this.state = { submenuSelector: false };
    }

    renderTitle() {
        const { title } = this.props;

        if (!title) return null;

        return (
            <h4 block="CmsStaticSidebarMenu" elem="Title">
                {title}
            </h4>
        );
    }

    renderLinks() {
        const { links, isMobile, isTablet, isExpandedOnMobile, handleSelectChange } = this.props;
        return links.map(({ active, src, title, ...link }, i) => {
            const [iconObj] = LINKS_ICONS.filter(({ name }) => name === title);
            const iconName = link?.icon || iconObj?.icon;

            if (!isMobile && !isTablet) {
                return (
                    <div block="CmsStaticSidebarMenu" elem="LinkWrapper" mods={{ active }} key={i}>
                        {iconName && <Icon name={iconName} width="20" height="20" fill={active ? '#000' : '#8A8A8A'} />}
                        <Link to={src}>{title}</Link>
                    </div>
                );
            }

            if (isMobile || isTablet) {
                if (active) {
                    return null;
                }

                return (
                    <button
                        block="CmsStaticSidebarMenu"
                        elem="CmsStaticSidebarMenuElement"
                        mods={{ isExpandedOnMobile }}
                        onClick={() => handleSelectChange(src)}
                        key={i}
                    >
                        {iconName && (
                            <Icon
                                block="CmsStaticSidebarMenu"
                                elem="ReadFragmentElementIcon"
                                name={iconName}
                                width="18"
                                height="18"
                                fill={active ? '#fff' : '#8A8A8A'}
                            />
                        )}
                        {title}
                    </button>
                );
            }

            return null;
        });
    }

    renderDesktopContent() {
        const { submenuSelector } = this.state;

        if (!submenuSelector) return null;

        return createPortal(
            <div block="CmsStaticSidebarMenu">
                {this.renderTitle()}
                {this.renderLinks()}
            </div>,
            submenuSelector
        );
    }

    renderMobileDropdown() {
        const { handleMobileExpand, isExpandedOnMobile, links } = this.props;

        const activeLinks = links && links.find(({ active }) => active);
        if (!activeLinks) return;

        const { title, ...link } = activeLinks;
        const [iconObj] = LINKS_ICONS.filter(({ name }) => name === title);
        const iconName = link?.icon || iconObj?.icon;

        return (
            <div block="CmsStaticSidebarMenu" elem="CmsStaticSidebarMenuButtonWrapper">
                <button
                    block="CmsStaticSidebarMenu"
                    elem="CmsStaticSidebarMenuButton"
                    mods={{ isExpandedOnMobile }}
                    onClick={handleMobileExpand}
                >
                    <div block="CmsStaticSidebarMenu" elem="CmsStaticSidebarMenuButtonContent">
                        {iconName && (
                            <Icon
                                block="CmsStaticSidebarMenu"
                                elem="CmsStaticSidebarMenuButtonIcon"
                                name={iconName}
                                fill={isExpandedOnMobile ? '#fff' : '#000'}
                                width="16"
                            />
                        )}
                        <span
                            block="CmsStaticSidebarMenu"
                            elem="CmsStaticSidebarButtonTitle"
                            mods={{ isExpandedOnMobile }}
                        >
                            {title}
                        </span>
                    </div>
                    <Icon
                        block="CmsStaticSidebarMenu"
                        elem="CmsStaticSidebarMenuButtonIcon"
                        name="small_arrow_down"
                        width="10"
                        fill={isExpandedOnMobile ? '#fff' : '#000'}
                    />
                </button>
                <ul
                    block="CmsStaticSidebarMenu"
                    elem="CmsStaticSidebarMenuElementsWrapper"
                    mods={{ isExpandedOnMobile }}
                >
                    {this.renderLinks()}
                </ul>
            </div>
        );
    }

    renderMobileContent() {
        const { submenuSelector } = this.state;

        if (!submenuSelector) return null;

        return createPortal(this.renderMobileDropdown(), submenuSelector);
    }

    render() {
        const { isMobile, isTablet } = this.props;

        return isMobile || isTablet ? this.renderMobileContent() : this.renderDesktopContent();
    }
}

export default CmsStaticSidebarMenu;
