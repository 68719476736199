import PropTypes from 'prop-types';
import { Fragment, PureComponent } from 'react';

import './Tabs.style';

/** @namespace SwiatKsiazkiBasic/Component/Tabs/Component */
export class TabsComponent extends PureComponent {
    static propTypes = {
        titles: PropTypes.arrayOf(PropTypes.string).isRequired,
        children: PropTypes.node.isRequired,
        activeTab: PropTypes.number.isRequired,
        handleTabClick: PropTypes.func.isRequired,
        prefixClassName: PropTypes.string,
    };

    static defaultProps = {
        prefixClassName: '',
    };

    renderChildren() {
        const { activeTab, titles, children } = this.props;

        return <Fragment key={titles[activeTab]}>{children[activeTab] || null}</Fragment>;
    }

    renderTabs() {
        const { titles, prefixClassName } = this.props;
        const { activeTab, handleTabClick } = this.props;

        return titles.map((name, key) => (
            <button
                key={name}
                onClick={() => handleTabClick(key)}
                block={prefixClassName}
                elem="Tab"
                mods={{ isActive: activeTab === key }}
            >
                {name}
            </button>
        ));
    }

    render() {
        const { prefixClassName } = this.props;

        return (
            <div block={prefixClassName}>
                <div block={prefixClassName} elem="Tabs">
                    {this.renderTabs()}
                </div>
                {this.renderChildren()}
            </div>
        );
    }
}

export default TabsComponent;
