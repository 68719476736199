import { AgreementsContainer } from 'component/Agreements/Agreements.container';
import { GIFT_CARD_ORDER_CONSENT } from 'component/GiftCardsBuyCardForm/GiftCardsBuyCardForm.config';
import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';
import { connect } from 'react-redux';

import { REQUEST_TYPE_PAYMENT, REQUEST_TYPE_QUERY } from 'Component/GiftCardsBuyCardForm/GiftCardsBuyCardForm.config';
import GiftCardQuery from 'Query/GiftCard.query';
import { GIFT_CARDS_ORDER_DETAILS } from 'Route/GiftCardsOrderSuccess/GiftCardsOrderSuccess.config';
import { showNotification } from 'Store/Notification/Notification.action';
import { CountriesType } from 'Type/Config.type';
import transformToNameValuePair from 'Util/Form/Transform';
import { fetchMutation, getErrorMessage } from 'Util/Request';
import transformCountriesToOptions from 'Util/Store/Transform';

import GiftCardsBuyCardForm from './GiftCardsBuyCardForm.component';

/** @namespace SwiatKsiazkiBasic/Component/GiftCardsBuyCardForm/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    countries: transformCountriesToOptions(state.ConfigReducer.countries || []),
    defaultCountry: state.ConfigReducer.default_country,
    isMobile: state.ConfigReducer.device.isMobile,
    giftCardsDeliveryCost: state.ConfigReducer.giftcard_cards_offer_delivery_cost,
    giftCardsAvailableCardValues: state.ConfigReducer.giftcard_cards_offer_available_card_values,
    storeConfig: state.ConfigReducer,
    advoxFreeDeliveryEnabled: state.ConfigReducer.advox_free_delivery_enabled,
    advoxFreeDeliveryThreshold: state.ConfigReducer.advox_free_delivery_minimum_order_amount,
});

/** @namespace SwiatKsiazkiBasic/Component/GiftCardsBuyCardForm/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message) => dispatch(showNotification(type, message)),
});

/** @namespace SwiatKsiazkiBasic/Component/GiftCardsBuyCardForm/Container */
export class GiftCardsBuyCardFormContainer extends PureComponent {
    static propTypes = {
        countries: CountriesType.isRequired,
        defaultCountry: PropTypes.string.isRequired,
    };

    formRef = createRef();

    containerFunctions = {
        setSubmitType: this.setSubmitType.bind(this),
        setFormRef: this.setFormRef.bind(this),
        setCardQuantity: this.setCardQuantity.bind(this),
        onError: this.onError.bind(this),
        onSuccess: this.onSuccess.bind(this),
        setCardVariant: this.setCardVariant.bind(this),
    };

    state = {
        isLoading: false,
        requestType: undefined,
        cardVariant: undefined,
        ...this.giftCardFields,
    };

    get giftCardFields() {
        const { giftCardsAvailableCardValues = [] } = this.props;

        return giftCardsAvailableCardValues.reduce((cards, amount) => ({ ...cards, [String(amount)]: 0 }), {});
    }

    setFormRef(elem) {
        if (elem) {
            this.formRef.current = elem;
        }
    }

    setSubmitType(requestType) {
        return () => {
            this.setState({
                requestType,
            });
        };
    }

    setCardQuantity(value, { name }) {
        this.setState({ [name]: value });
    }

    onError() {
        const { showNotification } = this.props;
        showNotification('info', __('Incorrect data! Please resolve all field validation errors.'));
    }

    async onSuccess(form, fields) {
        const { showNotification } = this.props;
        const { requestType, isLoading, ...state } = this.state;

        if (isLoading) {
            return;
        }

        try {
            const {
                firstName,
                lastName,
                address,
                flatNumber,
                cardType,
                city,
                companyName,
                countryId,
                email,
                confirmed_email,
                nip,
                postcode,
                phone,
                // eslint-disable-next-line no-unused-vars
                cardVariant,
                ...giftCards
            } = {
                ...transformToNameValuePair(fields),
                ...state,
            };

            if (Object.values(giftCards).every((qty) => qty === 0)) {
                showNotification('info', __('Choose card values and quantities'));
                return;
            }

            this.setState({
                isLoading: true,
            });

            const agreements = AgreementsContainer.getSelectedAgreements(GIFT_CARD_ORDER_CONSENT);

            const { orderGiftcard: { redirect_url, status } = {} } = await fetchMutation(
                GiftCardQuery.getOrderCardMutation({
                    cardType,
                    email,
                    emailRepeat: confirmed_email,
                    firstName,
                    lastName,
                    companyName,
                    nip,
                    city,
                    postcode,
                    countryId,
                    phone,
                    requestType,
                    street: [address, flatNumber].filter(Boolean).join(' '),
                    giftCards: Object.entries(giftCards)
                        .filter(([_, qty]) => qty > 0)
                        .map(([value, qty]) => ({ qty, value: Number(value) })),
                    agreements,
                })
            );

            if (status) {
                if (requestType === REQUEST_TYPE_QUERY) {
                    showNotification('success', __('The request has been sent'));
                    form.reset();
                    this.setState(this.giftCardFields);
                } else if (requestType === REQUEST_TYPE_PAYMENT) {
                    if (redirect_url) {
                        window.sessionStorage.setItem(
                            GIFT_CARDS_ORDER_DETAILS,
                            JSON.stringify({
                                cardType,
                            })
                        );
                        window.location = redirect_url;
                    }
                }
            } else {
                throw new Error('Something went wrong!');
            }
        } catch (error) {
            showNotification('error', getErrorMessage(error));
        } finally {
            this.setState({
                isLoading: false,
            });
        }
    }

    setCardVariant(variant) {
        this.setState((prevState) => ({
            ...prevState,
            cardVariant: variant,
        }));
    }

    containerProps() {
        const { cardVariant } = this.state;

        const {
            countries,
            defaultCountry,
            isMobile,
            giftCardsDeliveryCost,
            giftCardsAvailableCardValues,
            advoxFreeDeliveryEnabled,
            advoxFreeDeliveryThreshold,
        } = this.props;

        return {
            ...this.state,
            countries,
            defaultCountry,
            isMobile,
            cardVariant,
            giftCardsDeliveryCost,
            giftCardsAvailableCardValues,
            advoxFreeDeliveryEnabled,
            advoxFreeDeliveryThreshold,
        };
    }

    render() {
        return <GiftCardsBuyCardForm {...this.containerFunctions} {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GiftCardsBuyCardFormContainer);
