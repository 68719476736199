import { forwardRef } from 'react';
import DatePicker from 'react-datepicker';

import FIELD_TYPE from 'Component/Field/Field.config';
import Icon from 'Component/Icon';
import { DatePickerComponent as SourceDatePickerComponent } from 'SourceComponent/DatePicker/DatePicker.component';

import './DatePicker.override.style';

/** @namespace SwiatKsiazkiBasic/Component/DatePicker/Component */
export class DatePickerComponent extends SourceDatePickerComponent {
    renderCustomInput({ value, onClick }, ref) {
        const { selectedDate, uid, placeholder, disabled, label, name } = this.props;

        return (
            <>
                <label>{label}</label>
                <div className="react-datepicker__input-wrapper" onClick={disabled ? null : onClick}>
                    <input
                        id={uid}
                        name={name}
                        type={FIELD_TYPE.text}
                        value={value}
                        ref={ref}
                        data-date={selectedDate}
                        placeholder={placeholder || this.getPlaceholder()}
                        inputMode="none"
                        readOnly
                        disabled={disabled}
                    />
                    <Icon name="calendar_3" />
                </div>
            </>
        );
    }

    render() {
        const {
            selectedDate,
            onSetDate,
            showTimeSelect,
            showTimeSelectOnly,
            minDate,
            maxDate,
            dateFormat,
            timeFormat,
            isClearable,
            datePickerProps,
            ...rest
        } = this.props;

        const DateInput = forwardRef(this.renderCustomInput.bind(this));

        return (
            <DatePicker
                selected={selectedDate}
                onChange={onSetDate}
                showTimeSelect={showTimeSelect}
                showTimeSelectOnly={showTimeSelectOnly}
                timeFormat={timeFormat}
                timeIntervals={15}
                dateFormat={dateFormat}
                minDate={minDate}
                maxDate={maxDate}
                isClearable={isClearable}
                customInput={<DateInput />}
                locale="pl"
                {...datePickerProps}
                {...rest}
            />
        );
    }
}

export default DatePickerComponent;
