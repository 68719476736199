import { PureComponent } from 'react';

import { formatPrice } from 'Util/Price';

import './OmnibusPriceInfo.style';
/** @namespace SwiatKsiazkiBasic/Component/OmnibusPriceInfo/Component */
export class OmnibusPriceInfo extends PureComponent {
    static defaultProps = {
        alwaysShortMessage: false,
        alwaysLongMessage: false,
    };

    getInformation(price) {
        const {
            alwaysLongMessage,
            alwaysShortMessage,
            device: { isMobile },
        } = this.props;

        if (alwaysLongMessage) {
            return __('Lowest price from 30 days before the discount: %s', formatPrice(price));
        }

        if (isMobile || alwaysShortMessage) {
            return __('Lowest price: %s', formatPrice(price));
        }

        return __('Lowest price from 30 days before the discount: %s', formatPrice(price));
    }

    render() {
        const { product: { omnibus = {} } = {} } = this.props;
        const { price, is_discount } = omnibus || {};

        if (!is_discount) {
            return null;
        }

        return (
            <div block="OmnibusPriceInfo">
                <p block="OmnibusPriceInfo" elem="Content">
                    {this.getInformation(price)}
                </p>
            </div>
        );
    }
}

export default OmnibusPriceInfo;
