export const CMS_PAGE_SUBMENU_SELECTOR = '.CmsPage-SubMenu';
export const LINKS_ICONS = [
    {
        name: 'Nasze księgarnie',
        icon: 'marker',
    },
    {
        name: 'Nasze kawiarnie',
        icon: 'coffe',
    },
    {
        name: 'Nowe otwarcia',
        icon: 'new_openings',
    },
    {
        name: 'Warsztaty dla dzieci',
        icon: 'workshops',
    },
    {
        name: 'Katalog Księgarnie Świat Książki',
        icon: 'catalog',
    },
    {
        name: 'Aktualności',
        icon: 'news',
    },
    {
        name: 'Kariera',
        icon: 'career',
    },
    {
        name: 'Regulaminy promocji',
        icon: 'promotion_rules',
    },
    {
        name: 'Karty podarunkowe i e-vouchery',
        icon: 'promotion_cards',
    },
    {
        name: 'Karta dużej rodziny',
        icon: 'big_family',
    },
    {
        name: 'Karta stałego klienta',
        icon: 'client_icon',
    },
];
