/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import WishlistQuery from 'Query/Wishlist.query';
import { showNotification } from 'Store/Notification/Notification.action';
import {
    CLEAR_WISHLIST,
    REMOVE_ITEM_FROM_WISHLIST,
    removeItemFromWishlist,
    setWishlistPriceDrop,
    UPDATE_ALL_PRODUCTS_IN_WISHLIST,
    updateAllProductsInWishlist,
    updateAllProductsInWishlistArray,
    updateAllProductsInWishlistCount,
    updateIsLoading
} from 'Store/Wishlist/Wishlist.action';
import { isWishlistEnabled } from 'Store/Wishlist/Wishlist.dispatcher';
import { getAuthorizationToken, isSignedIn } from 'Util/Auth';
import { fetchMutation, fetchQuery } from 'Util/Request';

import { getCustomerData } from '../../data/customer';
import {
    addToWishlistEventHandler,
    fireRemoveAllWishlistItemsEvent,
    removeItemFromWishlistEventHandler,
    wishlistUpdateHandler
} from '../../event/wishlist';

const addFireAddToWishlistEventHandler = async (args, callback) => {
    if (!isSignedIn()) {
        return;
    }
    const [, options] = args;

    await callback(...args);
    // ^^^ wait for 2 queries inside callback
    addToWishlistEventHandler(options.items);
};

const addWishlistEventHandlers = (args, callback) => {
    const [state, action] = args;
    const { customerId, type } = action;

    // vvv for clear all button on MyAccount>Wishlist
    if (type === CLEAR_WISHLIST) {
        fireRemoveAllWishlistItemsEvent(customerId);
    }

    // vvv for heart button on PLP/PDP or etc.. and 'x' button on MyAccount>Wishlist
    if (type === REMOVE_ITEM_FROM_WISHLIST) {
        const { item_id: itemId } = action;
        const { productsInWishlist } = state;

        removeItemFromWishlistEventHandler(itemId, productsInWishlist, customerId);
    }

    // vvv for '+' and '-' button on MyAccount>Wishlist
    if (type === UPDATE_ALL_PRODUCTS_IN_WISHLIST) {
        const { products: newProductsInWishlist } = action;
        const { productsInWishlist } = state;

        wishlistUpdateHandler(newProductsInWishlist, productsInWishlist, customerId);
    }

    return callback(...args);
};

const aroundClearWishlist = (args) => {
    const [dispatch] = args;

    if (!isSignedIn()) {
        return Promise.reject();
    }

    return fetchMutation(WishlistQuery.getClearWishlist())
        .then(() => {
            getCustomerData()
                .then(({ customerId }) => {
                    dispatch(updateAllProductsInWishlistCount(0, 0));
                    dispatch(updateAllProductsInWishlistArray([]));
                    dispatch(updateAllProductsInWishlist({}, customerId));
                })
                .catch(() => {
                    dispatch(updateAllProductsInWishlistCount(0, 0));
                    dispatch(updateAllProductsInWishlistArray([]));
                    dispatch(updateAllProductsInWishlist({}, null));
                });
        })
        .catch(() => dispatch(showNotification('error', __('Error clearing wish list!'))));
};

const aroundUpdateInitialWishlistData = async (args, _callback, instance) => {
    const [dispatch] = args;
    const { customerId } = await getCustomerData();

    if (isSignedIn() && isWishlistEnabled()) {
        instance._syncWishlistWithBE(dispatch);
    } else {
        dispatch(updateAllProductsInWishlist({}, customerId));
    }
};

const aroundSyncWishlistWithBE = (args) => {
    const [dispatch] = args;
    dispatch(updateIsLoading(true));

    return fetchQuery([WishlistQuery.getWishlistQuery(), WishlistQuery.getWishlistSkuQuery()]).then(
        /** @namespace SwiatKsiazkiBasic/Store/Wishlist/Dispatcher/WishlistDispatcher/_syncWishlistWithBE/fetchQuery/then */
        (data) => {
            if (!getAuthorizationToken()) {
                return;
            }

            if (data && data.wishlist) {
                const { wishlist, customer } = data;
                const { wishlist: wishlistSku } = customer || {};
                const { items } = wishlistSku || {};
                const wishlistSkuArray = items || [];
                const { is_wishlist_have_price_drop } = wishlist;

                const productsToAdd = wishlist.query_items.result.reduce((prev, wishlistItem) => {
                    const {
                        id,
                        sku,
                        product,
                        description,
                        buy_request,
                        options,
                        price_alert,
                        qty: quantity,
                    } = wishlistItem;

                    return {
                        ...prev,
                        [id]: {
                            ...product,
                            quantity,
                            price_alert,
                            wishlist: {
                                id,
                                sku,
                                quantity,
                                description,
                                buy_request,
                                options,
                            },
                        },
                    };
                }, {});

                dispatch(
                    updateAllProductsInWishlistCount(wishlist?.items_count, wishlist?.query_items?.filtered_items_count)
                );
                dispatch(updateAllProductsInWishlistArray(wishlistSkuArray));
                dispatch(setWishlistPriceDrop(is_wishlist_have_price_drop));
                getCustomerData()
                    .then(({ customerId }) => {
                        dispatch({
                            ...updateAllProductsInWishlist(productsToAdd),
                            customerId,
                        });
                    })
                    .catch(() => {
                        dispatch({
                            ...updateAllProductsInWishlist(productsToAdd),
                            customerId: null,
                        });
                    });
            }

            dispatch(updateIsLoading(false));
        },
        /** @namespace SwiatKsiazkiBasic/Store/Wishlist/Dispatcher/WishlistDispatcher/_syncWishlistWithBE/fetchQuery/then/catch */
        () => {
            dispatch(updateIsLoading(false));
        }
    );
};

const aroundRemoveItemFromWishlist = async (args, _callback, instance) => {
    const [dispatch, { item_id, noMessages, lastItem }] = args;

    if (!item_id || !isSignedIn()) {
        return Promise.reject();
    }

    dispatch(updateIsLoading(true));

    try {
        await fetchMutation(WishlistQuery.getRemoveProductFromWishlistMutation(item_id));

        if (lastItem) {
            instance._goToPreviousPage();
        } else {
            await instance._syncWishlistWithBE(dispatch);
        }
    } catch (e) {
        if (!noMessages) {
            dispatch(showNotification('error', __('Error updating wish list!')));
        }

        return Promise.reject();
    }

    const { customerId } = await getCustomerData();

    dispatch({ ...removeItemFromWishlist(item_id), customerId });

    if (!noMessages) {
        dispatch(showNotification('success', __('Product has been removed from your Wish List!')));
    }

    return Promise.resolve();
};

export default {
    'Store/Wishlist/Dispatcher': {
        'member-function': {
            addItemToWishlist: addFireAddToWishlistEventHandler,
            clearWishlist: aroundClearWishlist,
            updateInitialWishlistData: aroundUpdateInitialWishlistData,
            _syncWishlistWithBE: aroundSyncWishlistWithBE,
            removeItemFromWishlist: aroundRemoveItemFromWishlist,
        },
    },
    'Store/Wishlist/Reducer/WishlistReducer': {
        function: addWishlistEventHandlers,
    },
};
