import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import CeneoScript from 'Component/CeneoScript';
import { CENEO_VARIANT } from 'Component/CeneoScript/CeneoScript.config';
import CmsBlock from 'Component/CmsBlock';
import CmsBlockProvider from 'Component/CmsBlockProvider';
import Footer from 'Component/Footer';
import InstallPrompt from 'Component/InstallPrompt';
import { DEFAULT_STATE_NAME } from 'Component/NavigationAbstract/NavigationAbstract.config';
import CmsPage from 'Route/CmsPage';
import { changeNavigationState } from 'Store/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import { HistoryType, LocationType, MatchType } from 'Type/Router.type';

import { HOMEPAGE_SLIDER_CMS_ID, HOMEPAGE_USP_CMS_ID } from './HomePage.config';

import './HomePage.style';
import './HomePage.override.style';

/** @namespace SwiatKsiazkiBasic/Route/HomePage/Container/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace SwiatKsiazkiBasic/Route/HomePage/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    changeHeaderState: (state) => dispatch(changeNavigationState(TOP_NAVIGATION_TYPE, state)),
});

/** @namespace SwiatKsiazkiBasic/Route/HomePage/Container */
export class HomePageContainer extends PureComponent {
    static propTypes = {
        changeHeaderState: PropTypes.func.isRequired,
        location: LocationType.isRequired,
        match: MatchType.isRequired,
        history: HistoryType.isRequired,
    };

    componentDidMount() {
        const { changeHeaderState } = this.props;

        changeHeaderState({
            name: DEFAULT_STATE_NAME,
            isHiddenOnMobile: false,
        });
    }

    containerProps() {
        const { history, location, match, pageIds } = this.props;

        return {
            location,
            match,
            history,
            pageIds,
        };
    }

    render() {
        return (
            <CmsBlockProvider identifiers={[HOMEPAGE_SLIDER_CMS_ID, HOMEPAGE_USP_CMS_ID]}>
                <div block="HomePage">
                    <InstallPrompt />
                    <CeneoScript variant={CENEO_VARIANT.GLOBAL} />
                    <CmsBlock identifier={HOMEPAGE_SLIDER_CMS_ID} />
                    <CmsBlock identifier={HOMEPAGE_USP_CMS_ID} />
                    <div id="edrone_homepage" />
                    <CmsPage isHomePage {...this.containerProps()} />
                    <Footer isVisibleOnMobile />
                </div>
            </CmsBlockProvider>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePageContainer);
