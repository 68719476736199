import CmsBlock from 'Component/CmsBlock';
import MenuItem from 'Component/MenuItem';
import { Menu as SourceMenu } from 'SourceComponent/Menu/Menu.component';
import { getSortedItems } from 'Util/Menu';

import { MOBILE_MENU_LINKS_CMS_ID } from './Menu.config';

import './Menu.override.style';

/** @namespace SwiatKsiazkiBasic/Component/Menu/Component */
export class Menu extends SourceMenu {
    renderAdditionalContent() {
        const { device: { isMobile, isTablet } = {} } = this.props;

        if (!isMobile && !isTablet) {
            return null;
        }

        return <CmsBlock identifier={MOBILE_MENU_LINKS_CMS_ID} />;
    }

    componentDidMount() {
        window.removeEventListener('scroll', this.debouncedCloseMenu);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.debouncedCloseMenu);
    }

    renderSubMenuDesktopItems(item) {
        const { item_id, children } = item;

        if (!Object.keys(children).length) {
            return null;
        }

        const { activeMenuItemsStack, closeMenu } = this.props;
        const isVisible = activeMenuItemsStack.includes(item_id);

        return (
            <div block="Menu" elem="SubCategoriesWrapper" mods={{ isVisible }} key={item_id}>
                <div block="Menu" elem="SubCategoriesWrapperInner" mods={{ isVisible }}>
                    <div block="Menu" elem="SubCategories">
                        {this.renderSubLevel(item)}
                    </div>
                    {this.renderAdditionalInformation()}
                </div>
                <div block="Menu" elem="Overlay" mods={{ isVisible }} onMouseEnter={closeMenu} />
            </div>
        );
    }

    renderSubMenuDesktop(itemList) {
        const { device } = this.props;

        if (device.isMobile || device.isTablet) {
            return null;
        }

        const childrenArray = getSortedItems(Object.values(itemList));

        return childrenArray.map(this.renderSubMenuDesktopItems.bind(this));
    }

    renderFirstLevelItems(item) {
        const { activeMenuItemsStack, handleSubcategoryClick, onCategoryHover, closeMenu, device } = this.props;

        const { children, item_id } = item;
        const childrenArray = Object.values(children);
        const itemMods = { type: 'main' };

        if (childrenArray.length && (device.isMobile || device.isTablet)) {
            return (
                <div
                    // TODO: split into smaller components
                    // eslint-disable-next-line react/jsx-no-bind
                    onClick={(e) => handleSubcategoryClick(e, item)}
                    tabIndex="0"
                    block="Menu"
                    elem="SubCatLink"
                    role="button"
                >
                    <MenuItem
                        activeMenuItemsStack={activeMenuItemsStack}
                        item={item}
                        itemMods={{ ...itemMods, isExpanded: activeMenuItemsStack.includes(item_id) }}
                        onCategoryHover={onCategoryHover}
                        closeMenu={closeMenu}
                        isExpandable
                    />
                    {this.renderSubLevel(item, true)}
                </div>
            );
        }

        return (
            <MenuItem
                activeMenuItemsStack={activeMenuItemsStack}
                item={item}
                itemMods={itemMods}
                onCategoryHover={onCategoryHover}
                closeMenu={closeMenu}
                isLink
            />
        );
    }

    renderFirstLevel(item) {
        const { item_id } = item;
        const { activeMenuItemsStack, device, isNestedOpen } = this.props;
        const isVisible = activeMenuItemsStack.includes(item_id) || activeMenuItemsStack?.length === 0;

        if ((device.isMobile || device.isTablet) && !isVisible) {
            return null;
        }

        return (
            <li block="Menu" elem="Item" key={item_id} mods={{ isNestedOpen }}>
                {this.renderFirstLevelItems.call(this, item)}
            </li>
        );
    }

    renderTopLevel() {
        const { menu } = this.props;
        const categoryArray = Object.values(menu);

        if (!categoryArray.length) {
            return null;
        }

        const [{ title: mainCategoriesTitle }] = categoryArray;

        return (
            <>
                <div block="Menu" elem="MainCategories">
                    <ul block="Menu" elem="ItemList" mods={{ type: 'main' }} aria-label={mainCategoriesTitle}>
                        {categoryArray.map(this.renderFirstLevel.bind(this))}
                    </ul>
                </div>
                {this.renderSubMenuDesktop(menu)}
                {this.renderAdditionalContent()}
            </>
        );
    }

    prepareBlocks(childrenArray) {
        const { device } = this.props;
        const categoriesBlock = [];
        const otherBlock = [];
        const extraBlock = [];
        const additionalBlock = [];
        childrenArray.map((item) => {
            const innerChildrenArray = getSortedItems(Object.values(item.children));

            if (!device.isMobile && innerChildrenArray?.length > 0) {
                const hasLevel3Item = innerChildrenArray.some((x) => x.item_class.indexOf('level3') > -1);

                if (hasLevel3Item) {
                    item.children = {};
                }
            }

            if (!item.item_class.includes('show-more')) {
                if (item.item_class.includes('empty-space') || item.item_class.includes('other-block')) {
                    additionalBlock.push(item);
                } else if (item.image_url || item.top_product?.name) {
                    extraBlock.push(item);
                } else if (Object.values(item.children)?.length === 0) {
                    categoriesBlock.push(item);
                } else {
                    otherBlock.push(item);
                }
            }

            return null;
        });

        return {
            additionalBlock,
            categoriesBlock,
            otherBlock,
            extraBlock,
        };
    }

    renderSubLevel(category, isSecondLevel = false) {
        const { activeMenuItemsStack, device, onCategoryBlockTitleClick } = this.props;
        const { item_id, children, title, url, parent_id } = category;
        const childrenArray = getSortedItems(Object.values(children));
        const isVisible = activeMenuItemsStack.includes(item_id);
        const subcategoryMods = { type: 'subcategory' };

        const categoryTitle = title;
        const categoryLink = url;

        const { categoriesBlock, otherBlock, extraBlock, additionalBlock } = this.prepareBlocks(childrenArray);
        const showMoreItem = childrenArray.find((item) => item?.item_class?.includes('show-more')) || {};
        const categoriesWithoutExtraBlock = childrenArray.filter(
            (item) => !item.image_url && !item.top_product?.name && !item?.item_class?.includes('show-more')
        );

        if (device.isMobile || device.isTablet) {
            return (
                <div block="Menu" elem="SubMenu" mods={{ isVisible }} key={item_id}>
                    <div
                        block="Menu"
                        elem="ItemList"
                        mods={{ ...subcategoryMods, withExtraBlock: extraBlock.length !== 0 }}
                    >
                        {categoryLink ? (
                            <MenuItem
                                activeMenuItemsStack={activeMenuItemsStack}
                                item={{
                                    ...category,
                                    title:
                                        parent_id === 0
                                            ? `${__('Go to section')}: ${categoryTitle}`
                                            : `${__('Show all from the category')}: ${categoryTitle}`,
                                }}
                                itemMods={{ ...subcategoryMods, isSecondLevel, isAllLabel: true }}
                                isLink
                                noIcon
                            />
                        ) : null}
                        {categoriesWithoutExtraBlock?.length > 0 &&
                            categoriesWithoutExtraBlock.map((item) => this.renderSubLevelItems(item, isSecondLevel))}
                    </div>
                </div>
            );
        }

        return (
            <div block="Menu" elem="SubMenu" mods={{ isVisible }} key={item_id}>
                <div
                    block="Menu"
                    elem="ItemList"
                    mods={{ ...subcategoryMods, withExtraBlock: extraBlock.length !== 0 }}
                >
                    {categoriesBlock?.length > 0 && (
                        <div block="Menu" elem="ItemList" mods={{ categoriesBlock: true }}>
                            <div
                                block="Menu"
                                elem="CategoriesBlockTitle"
                                onClick={() => onCategoryBlockTitleClick(categoryLink)}
                            >
                                {categoryTitle}
                            </div>
                            <div>
                                {categoriesBlock.map((item) => this.renderSubLevelItems(item, isSecondLevel))}
                                {!device.isMobile && !device.isTablet && (
                                    <>
                                        <MenuItem
                                            activeMenuItemsStack={activeMenuItemsStack}
                                            item={{ ...category, title: __('') }}
                                            itemMods={{ ...subcategoryMods, isSecondLevel, isAllLabel: true }}
                                            noIcon
                                        />
                                        <MenuItem
                                            activeMenuItemsStack={activeMenuItemsStack}
                                            item={{ ...showMoreItem, title: __('Show more') }}
                                            itemMods={{ ...subcategoryMods, isSecondLevel, isAllLabel: true }}
                                            withShowMore
                                            isLink
                                            noIcon
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                    {otherBlock?.length > 0 && otherBlock.map((item) => this.renderSubLevelItems(item, isSecondLevel))}
                    {additionalBlock?.length > 0 &&
                        additionalBlock.map((item) => (
                            <div block="Menu" elem="ItemList" mods={{ extraBlock: true }}>
                                <div>{this.renderSubLevelItems(item, isSecondLevel)}</div>
                            </div>
                        ))}
                    {extraBlock?.length > 0 && (
                        <div block="Menu" elem="ItemList" mods={{ extraBlock: true }}>
                            <div>{extraBlock.map((item) => this.renderSubLevelItems(item, isSecondLevel))}</div>
                        </div>
                    )}
                </div>
            </div>
        );
    }

    renderSubLevelItems(item, isSecondLevel) {
        const { handleSubcategoryClick, activeMenuItemsStack, onCategoryHover, closeMenu, device } = this.props;

        const { image_url, item_id, children, item_class, top_product } = item;

        const childrenArray = Object.values(children);
        const subcategoryMods = { type: 'subcategory', isSecondLevel };

        if (childrenArray.length && (device.isMobile || device.isTablet)) {
            return (
                <div
                    key={item_id}
                    // eslint-disable-next-line react/jsx-no-bind
                    onClick={(e) => handleSubcategoryClick(e, item)}
                    tabIndex="0"
                    role="button"
                >
                    <MenuItem
                        activeMenuItemsStack={activeMenuItemsStack}
                        item={item}
                        itemMods={{ ...subcategoryMods, isExpanded: activeMenuItemsStack.includes(item_id) }}
                        onCategoryHover={onCategoryHover}
                        closeMenu={closeMenu}
                        isExpandable
                        mods={{ hasChildren: childrenArray.length > 0 }}
                    />
                    {this.renderSubLevel(item)}
                </div>
            );
        }

        return (
            <div
                block="Menu"
                elem="SubItemWrapper"
                key={item_id}
                onClick={this.stopPropagation}
                role="button"
                tabIndex="-1"
                mods={{ hasChildren: childrenArray.length > 0, hasCustomClass: !!item_class }}
                className={item_class}
            >
                {!item_class.includes('empty-space') ? (
                    <>
                        {image_url || top_product?.name ? (
                            <MenuItem
                                activeMenuItemsStack={activeMenuItemsStack}
                                item={item}
                                itemMods={subcategoryMods}
                                closeMenu={closeMenu}
                                isLink
                                isBanner={!!image_url}
                                isTopProduct={!!top_product}
                            />
                        ) : (
                            <MenuItem
                                activeMenuItemsStack={activeMenuItemsStack}
                                item={item}
                                itemMods={subcategoryMods}
                                closeMenu={closeMenu}
                                isLink
                            />
                        )}
                        {this.renderDesktopSubLevel(item)}
                    </>
                ) : null}
            </div>
        );
    }

    render() {
        const { closeMenu, isOverlayVisible } = this.props;

        return (
            <>
                <div block="Menu" elem="MenuWrapper" onMouseLeave={closeMenu} mods={{ isOverlayVisible }}>
                    {this.renderTopLevel()}
                </div>
                <div block="Menu" elem="Overlay" mods={{ isVisible: isOverlayVisible }} />
            </>
        );
    }
}

export default Menu;
