import { SHIPPING_FIELDS, SHIPPING_FIELDS_EXPIRATION } from 'Route/Checkout/Checkout.config';
import { UPDATE_SHIPPING_FIELDS } from 'Store/Checkout/Checkout.action';
import BrowserDatabase from 'Util/BrowserDatabase';

const overrideInitialState = (args, callback) => ({
    ...callback(...args),
    shippingFields: BrowserDatabase.getItem(SHIPPING_FIELDS) || {},
});

const overrideReducer = (args, callback, context) => {
    const [, action] = args;
    const originalUpdatedState = callback.apply(context, args);

    if (!action) {
        return originalUpdatedState;
    }

    const { type, shippingFields = {} } = action;

    if (type === UPDATE_SHIPPING_FIELDS) {
        BrowserDatabase.setItem(shippingFields, SHIPPING_FIELDS, SHIPPING_FIELDS_EXPIRATION);
    }

    return originalUpdatedState;
};

export default {
    'Store/Checkout/Reducer/getInitialState': {
        function: overrideInitialState,
    },
    'Store/Checkout/Reducer/CheckoutReducer': {
        function: overrideReducer,
    },
};
