import CmsBlock from 'Component/CmsBlock';
import Newsletter from 'Component/Newsletter';
import { Footer as SourceFooter } from 'SourceComponent/Footer/Footer.component';

import {
    FOOTER_COPYRIGHT_CMS_ID,
    FOOTER_LINKS_CMS_ID,
    FOOTER_PAYMENTS_CMS_ID,
    FOOTER_SEO_CMS_ID,
    FOOTER_SOCIAL_ICONS_CMS_ID,
} from './Footer.config';

import './Footer.override.style';

/** @namespace SwiatKsiazkiBasic/Component/Footer/Component */
export class Footer extends SourceFooter {
    shouldComponentUpdate(nextProps) {
        const {
            device: { isMobile },
            isVisibleOnMobile,
            copyright,
            newsletterActive,
            isVisibleNewsletter,
            isHomePage,
        } = this.props;

        const {
            device: { isMobile: nextIsMobile },
            isVisibleOnMobile: nextIsVisibleOnMobile,
            copyright: nextCopyright,
            newsletterActive: nextNewsletterActive,
            isVisibleNewsletter: nextIsVisibleNewsletter,
            isHomePage: nextIsHomePage,
        } = nextProps;

        return (
            isMobile !== nextIsMobile ||
            isVisibleOnMobile !== nextIsVisibleOnMobile ||
            copyright !== nextCopyright ||
            newsletterActive !== nextNewsletterActive ||
            isVisibleNewsletter !== nextIsVisibleNewsletter ||
            isHomePage !== nextIsHomePage
        );
    }

    renderSeo() {
        const { isHomePage } = this.props;

        if (!isHomePage) {
            return null;
        }

        return (
            <div block="Footer" elem="Seo">
                <div className="ContentWrapper">
                    <CmsBlock identifier={FOOTER_SEO_CMS_ID} />
                </div>
            </div>
        );
    }

    renderPaymentIcons() {
        return (
            <div block="Footer" elem="PaymentIcons">
                <div className="ContentWrapper">
                    <CmsBlock identifier={FOOTER_PAYMENTS_CMS_ID} />
                </div>
            </div>
        );
    }

    renderCopyrightAndSocialIcons() {
        const { device } = this.props;

        return (
            <div className="Footer-CopyrightAndSocialIcons">
                <div className="ContentWrapper">
                    <CmsBlock identifier={FOOTER_SOCIAL_ICONS_CMS_ID} />
                    {device?.isMobile && <CmsBlock identifier={FOOTER_COPYRIGHT_CMS_ID} />}
                </div>
            </div>
        );
    }

    renderCmsBlockWrapper() {
        return (
            <div block="Footer" elem="CmsBlockWrapper" mix={{ block: 'Footer', elem: 'Content' }}>
                <div block="Footer" elem="Columns" mix={{ block: 'ContentWrapper' }}>
                    <CmsBlock identifier={FOOTER_LINKS_CMS_ID} />
                </div>
                {this.renderCopyrightAndSocialIcons()}
                {this.renderSeo()}
                {this.renderPaymentIcons()}
            </div>
        );
    }

    renderCartFooter() {
        return (
            <div block="Footer" elem="CmsBlockWrapper" mix={{ block: 'Footer', elem: 'Content' }}>
                <div block="Footer" elem="Columns" mix={{ block: 'ContentWrapper' }}>
                    <CmsBlock identifier={FOOTER_LINKS_CMS_ID} />
                </div>
            </div>
        );
    }

    renderContent() {
        return this.renderCmsBlockWrapper();
    }

    renderNewsletter() {
        return <Newsletter />;
    }

    render() {
        const { isVisibleOnMobile, device, isVisibleNewsletter } = this.props;

        if (!isVisibleOnMobile && device.isMobile) {
            return null;
        }

        if (isVisibleOnMobile && !device.isMobile) {
            return null;
        }

        if (isVisibleNewsletter) {
            return (
                <footer block="Footer" aria-label="Footer">
                    {this.renderContent()}
                </footer>
            );
        }

        return (
            <>
                {this.renderNewsletter()}
                <footer block="Footer" aria-label="Footer">
                    {this.renderContent()}
                </footer>
            </>
        );
    }
}

export default Footer;
