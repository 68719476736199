// import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import WishlistQuery from 'Query/Wishlist.query';
import { showNotification } from 'Store/Notification/Notification.action';
import { showPopup } from 'Store/Popup/Popup.action';
import history from 'Util/History';
import { debounce, fetchMutation, fetchQuery, getErrorMessage } from 'Util/Request';
import { appendWithStoreCode } from 'Util/Url';

import WishListPriceAlertPopup from './WishListPriceAlertPopup.component';
import { POPUP_DELAY, WISHLIST_PRICE_ALERT_POPUP_ID } from './WishlistPriceAlertPopup.config';

/** @namespace SwiatKsiazkiBasic/Component/WishListPriceAlertPopup/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    productsInWishlistCount: state.WishlistReducer.productsInWishlistCount,
    isSignedIn: state.MyAccountReducer.isSignedIn,
    isWishlistHavePriceDrop: state.WishlistReducer.isWishlistHavePriceDrop,
});

/** @namespace SwiatKsiazkiBasic/Component/WishListPriceAlertPopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showPopup: () => dispatch(showPopup(WISHLIST_PRICE_ALERT_POPUP_ID)),
    showNotification: (type, msg) => dispatch(showNotification(type, msg)),
});

/** @namespace SwiatKsiazkiBasic/Component/WishListPriceAlertPopup/Container */
export class WishListPriceAlertPopupContainer extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    state = {
        products: [],
        shouldShowPopup: false,
    };

    containerFunctions = {
        navigateToWishList: this.navigateToWishList.bind(this),
    };

    componentDidUpdate(prevProps) {
        const { isSignedIn: prevIsSignedIn } = prevProps;
        const { isSignedIn, isWishlistHavePriceDrop, productsInWishlistCount } = this.props;
        const { shouldShowPopup } = this.state;

        if (isSignedIn !== prevIsSignedIn && isSignedIn) {
            this.setState({ shouldShowPopup: true });
        }

        if (isWishlistHavePriceDrop && shouldShowPopup && productsInWishlistCount > 0) {
            this.setProductsToDisplay();
            this.setState({ shouldShowPopup: false });
        }
    }

    async setProductsToDisplay() {
        const { showPopup } = this.props;

        try {
            const { getWishlistPriceAlertPopup: { result = [] } = {} } = await fetchQuery(
                WishlistQuery.getWishlistPriceAlertPopup()
            );

            if (result) {
                this.setState(
                    {
                        products:
                            result?.map((item) => {
                                const {
                                    id,
                                    sku,
                                    product,
                                    description,
                                    buy_request,
                                    options,
                                    price_alert,
                                    qty: quantity,
                                } = item;

                                return {
                                    ...product,
                                    quantity,
                                    price_alert,
                                    wishlist: {
                                        id,
                                        sku,
                                        quantity,
                                        description,
                                        buy_request,
                                        options,
                                    },
                                };
                            }) || [],
                    },
                    () => {
                        debounce(showPopup, POPUP_DELAY)();
                        this.setProductsViewed();
                    }
                );
            }
        } catch (error) {
            showNotification('error', getErrorMessage(error));
        }
    }

    setProductsViewed() {
        const { products } = this.state;
        const viewedProducts = products?.map(({ sku }) => sku);
        const query = WishlistQuery.setWishlistPriceAlertViewed(viewedProducts);
        fetchMutation(query);
    }

    navigateToWishList() {
        const { products } = this.state;

        const productSku = products?.map(({ sku }) => sku);
        const query = WishlistQuery.setWishlistPriceAlertViewed(productSku);

        history.push(appendWithStoreCode('/wishlist'), { replace: true });
        fetchMutation(query);
    }

    containerProps() {
        const { products } = this.state;

        return {
            products,
        };
    }

    render() {
        return <WishListPriceAlertPopup {...this.containerProps()} {...this.containerFunctions} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WishListPriceAlertPopupContainer);
