import { connect } from 'react-redux';

import { getBooleanValue } from 'Util/Data';

import CeneoScript from './CeneoScript.component';

/** @namespace SwiatKsiazkiBasic/Component/CeneoScript/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    enabled: getBooleanValue(state.ConfigReducer.ceneo_trusted_reviews_status),
    guid: state.ConfigReducer.ceneo_trusted_reviews_guid,
    workDaysToSendQuestionnaire: state.ConfigReducer.ceneo_trusted_reviews_work_days_to_send_questionnaire,
});

/** @namespace SwiatKsiazkiBasic/Component/CeneoScript/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CeneoScript);
