import {
    ANIMATION_DURATION,
    ERROR_NOTIFICATION_LIFETIME,
    ERROR_TYPE,
} from 'SourceComponent/Notification/Notification.config';

export { ANIMATION_DURATION, ERROR_NOTIFICATION_LIFETIME, ERROR_TYPE };

export const NOTIFICATION_LIFETIME = 5000;

/** @namespace SwiatKsiazkiBasic/Component/Notification/Config/containsHTML */
export const containsHTML = (str) => /<[a-z][\s\S]*>/i.test(str);
