import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ChevronIcon from 'Component/ChevronIcon';
import Icon from 'Component/Icon';
import Link from 'Component/Link';
import Tooltip from 'Component/Tooltip';

import './ProductAdditionalInformation.style';

/** @namespace SwiatKsiazkiBasic/Component/ProductAdditionalInformation/Component */
export class ProductAdditionalInformation extends PureComponent {
    static propTypes = {
        icon: PropTypes.string.isRequired,
        title: PropTypes.oneOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
        value: PropTypes.string,
        tooltipText: PropTypes.oneOf(PropTypes.oneOfType([PropTypes.string, PropTypes.node])),
        link: PropTypes.string,
        isTooltip: PropTypes.bool,
        showPopup: PropTypes.func,
        valueAsIcon: PropTypes.string,
    };

    static defaultProps = {
        link: null,
        isTooltip: false,
        showPopup: null,
    };

    renderTooltip(children) {
        const { title, value, tooltipText } = this.props;

        return (
            <Tooltip
                tooltipText={
                    <>
                        <b>
                            {title} {value}
                        </b>
                        <span> {tooltipText}</span>
                    </>
                }
                styles={{ maxWidth: 240 }}
                placement="bottom"
            >
                <div block="AdditionalInformation">{children}</div>
            </Tooltip>
        );
    }

    renderTag(children) {
        const { link, isTooltip, showPopup } = this.props;

        if (link) {
            return (
                <Link block="AdditionalInformation" to={link}>
                    {children}
                </Link>
            );
        }

        if (isTooltip) {
            return this.renderTooltip(children);
        }

        return (
            <div block="AdditionalInformation" mods={{ isClicable: showPopup !== null }} onClick={showPopup}>
                {children}
            </div>
        );
    }

    render() {
        const { icon, title, value, valueAsIcon } = this.props;

        return this.renderTag(
            <>
                <Icon name={icon} width="16" height="16" />
                <span block="AdditionalInformation" elem="Title">
                    {title}
                </span>

                <div block="AdditionalInformation" elem="IconValue">
                    {valueAsIcon && <ChevronIcon direction="right" />}
                </div>
                {value && (
                    <span block="AdditionalInformation" elem="Value">
                        {value}
                    </span>
                )}
            </>
        );
    }
}

export default ProductAdditionalInformation;
