import PropTypes from 'prop-types';
import { Children, PureComponent } from 'react';
import ShowMoreText from 'react-show-more-text';

import './ShowMoreLessText.style.scss';

/** @namespace SwiatKsiazkiBasic/Component/ShowMoreLessText/Component */
export class ShowMoreLessText extends PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired,
        lines: PropTypes.number,
        expanded: PropTypes.bool,
        contentClass: PropTypes.string,
        anchorClass: PropTypes.string,
        moreText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        lessText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        isExpanded: PropTypes.bool,
        isSimpleVersion: PropTypes.bool,
    };

    static defaultProps = {
        lines: 2,
        expanded: false,
        moreText: __('show more'),
        lessText: __('show less'),
        contentClass: '',
        anchorClass: '',
        isSimpleVersion: false,
    };

    render() {
        const {
            children,
            lines,
            expanded,
            contentClass,
            anchorClass,
            moreText,
            lessText,
            isExpanded,
            toggleExpanded,
            isSimpleVersion,
        } = this.props;

        if (isSimpleVersion) {
            return (
                <div block="ShowMoreLessText" mods={{ isSimpleVersion }}>
                    {Children.map(children, (child, index) =>
                        index === 0 ? (
                            <span key={index}>{child}</span>
                        ) : (
                            <span key={index} hidden={!isExpanded}>
                                {child}
                            </span>
                        )
                    )}{' '}
                    <a block="ShowMoreLessText" elem="Toggle" onClick={toggleExpanded}>
                        {isExpanded ? lessText : moreText}
                    </a>
                </div>
            );
        }

        return (
            <ShowMoreText
                lines={lines}
                more={moreText}
                less={lessText}
                expanded={expanded}
                className={`ShowMoreLessText ${isExpanded ? 'ShowMoreLessText-IsExpanded' : ''} ${contentClass}`}
                onClick={toggleExpanded}
                anchorClass={`ShowMoreLessText-ToggleLink ${
                    isExpanded ? 'ShowMoreLessText-ToggleLink_IsExpanded' : ''
                } ${anchorClass}`}
            >
                {children}
            </ShowMoreText>
        );
    }
}

export default ShowMoreLessText;
