import {
    DEFAULT_CATGORY_URL_SUFFIX,
    filterStoreConfig,
    getCheckoutAgreementData,
    getCountryData,
    getCurrencyData,
    getIndexedRatings,
    getInitialState as sourceGetInitialState,
    MAX_HEIGHT,
    MAX_WIDTH,
} from 'SourceStore/Config/Config.reducer';
import { UPDATE_CONFIG, UPDATE_CONFIG_DEVICE } from 'Store/Config/Config.action';

export {
    MAX_WIDTH,
    MAX_HEIGHT,
    DEFAULT_CATGORY_URL_SUFFIX,
    filterStoreConfig,
    getIndexedRatings,
    getCurrencyData,
    getCountryData,
    getCheckoutAgreementData,
};

/** @namespace SwiatKsiazkiBasic/Store/Config/Reducer/getInitialState */
export const getInitialState = () => ({
    ...sourceGetInitialState(),
    storeConfig: window.storeConfig || {},
    cookieSettings: {
        gdpr_cookie_bar: false,
    },
    mfblog_post_view_comments_type: '0',
});

/** @namespace SwiatKsiazkiBasic/Store/Config/Reducer/ConfigReducer */
export const ConfigReducer = (state = getInitialState(), action) => {
    const {
        type,
        config: {
            countries,
            reviewRatings,
            checkoutAgreements,
            currencyData,
            storeConfig = {},
            cartDisplayConfig = {},
            cookieSettings = {},
        } = {},
        device,
    } = action;

    switch (type) {
        case UPDATE_CONFIG:
            const filteredStoreConfig = filterStoreConfig(storeConfig);
            const { secure_base_media_url } = filteredStoreConfig;
            window.secure_base_media_url = secure_base_media_url;

            return {
                ...state,
                countries: getCountryData(countries, state),
                reviewRatings: getIndexedRatings(reviewRatings),
                checkoutAgreements: getCheckoutAgreementData(checkoutAgreements, state),
                currencyData: getCurrencyData(currencyData, state),
                ...filteredStoreConfig,
                // Should be updated manually as filteredStoreConfig does not contain header_logo_src when it is null
                // and header_logo_src takes old value
                isLoading: false,
                cartDisplayConfig,
                cookieSettings,
            };

        case UPDATE_CONFIG_DEVICE:
            return {
                ...state,
                device: {
                    ...state.device,
                    ...device,
                },
            };

        default:
            return state;
    }
};

export default ConfigReducer;
