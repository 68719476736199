import {
    VALIDATION_MESSAGES as SOURCE_VALIDATION_MESSAGES,
    VALIDATION_RULES as SOURCE_VALIDATION_RULES,
} from 'SourceUtil/Validator/Config.js';
import { VALIDATION_INPUT_TYPE } from 'Util/Validator/Config';

export * from 'SourceUtil/Validator/Config.js';

export const VALIDATION_MESSAGES = {
    ...SOURCE_VALIDATION_MESSAGES,
    isRequired: __('* This field is required!'),
};

export const VALIDATION_RULES = {
    ...SOURCE_VALIDATION_RULES,
    [VALIDATION_INPUT_TYPE.alphaSpace]: /^[a-zA-ZąĄęĘóśŚÓłŁżŻźŹćĆńŃ\s]+$/im,
    [VALIDATION_INPUT_TYPE.email]: /^([A-Z0-9_+-]+\.?)*[A-Z0-9_+-]@([A-Z0-9][A-Z0-9-]*\.)+[A-Z]{2,}$/i,
    [VALIDATION_INPUT_TYPE.phone]:
        /^[\\+]?[(]?[0-9]{2,3}[)]?[-\s\\.]?[0-9]{2,3}[-\s\\.]?[0-9]{3}[-\s\\.]?([\s]?[0-9]{2,5})?$/im,
};
