import ProductListQuery from 'Query/ProductList.query';
import { MenuQuery as SourceMenuQuery } from 'SourceQuery/Menu.query';
import { Field } from 'Util/Query';

/** @namespace SwiatKsiazkiBasic/Query/Menu/Query */
export class MenuQuery extends SourceMenuQuery {
    _getMenuItemFields() {
        return [
            'url',
            'icon',
            'title',
            'item_id',
            'position',
            'url_type',
            'parent_id',
            'is_active',
            'item_class',
            'category_id',
            'cms_page_identifier',
            'image_path',
            'image_url',
            this._getTopProductField(),
        ];
    }

    _getTopProductField() {
        return new Field('top_product').addFieldList(this._getTopProductFields());
    }

    _getTopProductFields() {
        return Array.from(ProductListQuery._getProductInterfaceFields(false, false, true));
    }

    getTopLevelCategories({ identifier }) {
        return new Field('scandiwebMenu')
            .addArgument('identifier', 'String!', identifier)
            .addField(this._getTopLevelCategoriesFields())
            .setAlias('menu');
    }

    _getTopLevelCategoriesFields() {
        return new Field('items').addFieldList([
            'title',
            'item_id',
            'position',
            'parent_id',
            'is_active',
            'category_id',
        ]);
    }
}

export default new MenuQuery();
