import { Field } from 'Util/Query';

/** @namespace SwiatKsiazkiBasic/Query/Cookie/Query */
export class CookieQuery {
    getCookieSettings() {
        return new Field('getCookieSettings').setAlias('cookieSettings').addFieldList(this._getCookieSettingsFields());
    }

    getCookieGroups() {
        return new Field('getCookieGroups').setAlias('cookieGroups').addFieldList(this._getCookieGroupsFields());
    }

    setCookieGroups(identifiers) {
        return new Field('setCookieGroups')
            .setAlias('cookieGroups')
            .addArgument('selected_identifiers', '[String]', identifiers)
            .addFieldList(this._setCookieGroupsFields());
    }

    _getCookieSettingsFields() {
        return ['gdpr_cookie_bar', 'gdpr_cookie_bar_text', 'gdpr_cookie_lifetime'];
    }

    _getCookieGroupsFields() {
        return [
            'group_id',
            'is_essential',
            'name',
            'description',
            'accepted',
            'identifier',
            this._getCookieGroupsCookieField(),
        ];
    }

    _setCookieGroupsFields() {
        return ['result', 'message'];
    }

    _getCookieGroupsCookieField() {
        return new Field('cookies').addFieldList(this._getCookieGroupsCookieFields());
    }

    _getCookieGroupsCookieFields() {
        return ['name', 'description', 'lifetime', 'provider'];
    }
}

export default new CookieQuery();
