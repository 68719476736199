import { CLEAR_AGREEMENT, CLEAR_AGREEMENTS, LOADED_AGREEMENT, SELECT_AGREEMENT } from './Agreements.action';

/** @namespace SwiatKsiazkiBasic/Store/Agreements/Reducer/getInitialState */
export const getInitialState = () => ({});

/** @namespace SwiatKsiazkiBasic/Store/Agreements/Reducer/isSelectedAgreement */
export const isSelectedAgreement = (agreements, agreementToCheck) =>
    agreements.findIndex((agreement) => agreement.agreement_id === agreementToCheck.agreement_id) !== -1;

/** @namespace SwiatKsiazkiBasic/Store/Agreements/Reducer/isValidatedAgreements */
export const isValidatedAgreements = (requiredAgreements, selectedAgreements = []) => {
    if (!requiredAgreements.length) {
        return true;
    }

    return (
        selectedAgreements.filter((selectedAgreement) =>
            requiredAgreements.some(
                (requiredAgreement) => selectedAgreement.agreement_id === requiredAgreement.agreement_id
            )
        ).length > 0
    );
};

/** @namespace SwiatKsiazkiBasic/Store/Agreements/Reducer/getInitialStateAgreements */
export const getInitialStateAgreements = (agreements) => {
    const requiredAgreements = agreements.filter((agreement) => agreement.is_required);

    return {
        selected: [],
        all: agreements,
        required: requiredAgreements,
        isValidated: isValidatedAgreements(requiredAgreements),
    };
};

/** @namespace SwiatKsiazkiBasic/Store/Agreements/Reducer/updateSelectedAgreement */
export const updateSelectedAgreement = (agreement, agreementToUpdate) => {
    if (isSelectedAgreement(agreement.selected, agreementToUpdate)) {
        const selected = agreement.selected.filter(
            (agreement) => agreement.agreement_id !== agreementToUpdate.agreement_id
        );

        return {
            ...agreement,
            selected,
            isValidated: isValidatedAgreements(agreement.required, selected),
        };
    }

    const selected = Array.from(new Set([...agreement.selected, agreementToUpdate]));

    return {
        ...agreement,
        selected,
        isValidated: isValidatedAgreements(agreement.required, selected),
    };
};

/** @namespace SwiatKsiazkiBasic/Store/Agreements/Reducer/AgreementsReducer */

/** @namespace SwiatKsiazkiBasic/Store/Agreements/Reducer/AgreementsReducer */
export const AgreementsReducer = (state = getInitialState(), action) => {
    const { name, type } = action;

    switch (type) {
        case SELECT_AGREEMENT: {
            const { agreement } = action;

            if (!(name in state)) {
                throw new Error('Agreement not found');
            }

            return {
                ...state,
                [name]: updateSelectedAgreement(state[name], agreement),
            };
        }
        case LOADED_AGREEMENT: {
            const { agreements } = action;

            if (name in state) {
                return state;
            }

            return {
                ...state,
                [name]: getInitialStateAgreements(agreements),
            };
        }
        case CLEAR_AGREEMENT: {
            return Object.entries(state).reduce((previousValue, currentValue) => {
                if (currentValue[0] === name) {
                    return previousValue;
                }

                return {
                    ...previousValue,
                    ...Object.fromEntries([currentValue]),
                };
            }, {});
        }
        case CLEAR_AGREEMENTS: {
            return getInitialState();
        }
        default:
            return state;
    }
};

export default AgreementsReducer;
