import { MyAccountQuery as SourceMyAccountQuery } from 'SourceQuery/MyAccount.query';
import { Field } from 'Util/Query';

/** @namespace SwiatKsiazkiBasic/Query/MyAccount/Query */
export class MyAccountQuery extends SourceMyAccountQuery {
    getCustomerRevokeConsentMutation(options) {
        return new Field('customerRevokeConsent')
            .addArgument('input', 'CustomerRevokeConsentInput!', options)
            .addFieldList(['message', 'status']);
    }

    getResetPasswordMutation(options) {
        const { token, email, password, password_confirmation } = options;

        return new Field('s_resetPassword')
            .addArgument('token', 'String!', token)
            .addArgument('email', 'String!', email)
            .addArgument('password', 'String!', password)
            .addArgument('password_confirmation', 'String!', password_confirmation)
            .addField('status');
    }

    getUpdateAddressMutation(id, options) {
        return new Field('updateCustomerAddress')
            .addArgument('id', 'Int!', id)
            .addArgument('input', 'CustomerAddressInput!', options)
            .addFieldList(this._getAddressFields());
    }

    getAddDownloadableDeviceMutation(options) {
        return new Field('addCustomerDevice')
            .addArgument('input', 'DeviceInput!', options)
            .addFieldList(this._getDownloadableDeviceStatusFields());
    }

    getSendToDeviceMutation(device, hash) {
        return new Field('sendToDevice')
            .addArgument('device', 'DeviceType', device)
            .addArgument('hash', 'String', hash)
            .addFieldList(this._getDownloadableDeviceStatusFields());
    }

    getDeleteDeviceMutation(type) {
        return new Field('deleteDevice')
            .addArgument('device', 'DeviceType', type)
            .addFieldList(this._getDownloadableDeviceStatusFields());
    }

    getGenerateDownloadTokenMutation(hash) {
        return new Field('generateDownloadToken')
            .addArgument('hash', 'String', hash)
            .addFieldList(this._getGenerateDownloadTokenFields());
    }

    getLoginAsUserQuery(hash) {
        return new Field('getLoginAsCustomerToken')
            .setAlias('loginAsUser')
            .addArgument('hash', 'String!', hash)
            .addFieldList(this._getLoginAsUserFields());
    }

    _getGenerateDownloadTokenFields() {
        return ['status', 'token'];
    }

    _getAddressFields() {
        return [
            'id',
            'customer_id',
            'country_id',
            'street',
            'telephone',
            'postcode',
            'city',
            'firstname',
            'lastname',
            'middlename',
            'prefix',
            'suffix',
            'default_shipping',
            'default_billing',
            'vat_id',
            this._getRegionField(),
            'address_name',
            'company',
        ];
    }

    getCustomerQuery() {
        return this._getCustomerField();
    }

    getNewsletterSubscriptionHistory() {
        return new Field('getNewsletterSubscriptionHistory').addFieldList(['date', 'is_subscribed']);
    }

    _getCustomerField() {
        return new Field('customer').addFieldList([
            ...this._getCustomerFields(),
            this._getCustomerBookshopField(),
            this._getCustomerInpostField(),
            this._getCustomerOrlenPaczkaField(),
            this._getCustomerDpdPickupPaczkaField(),
            this.getReviewsField(),
        ]);
    }

    _getCustomerFields() {
        return [
            'created_at',
            'confirmation_required',
            'group_id',
            'prefix',
            'firstname',
            'middlename',
            'lastname',
            'suffix',
            'email',
            'default_billing',
            'default_shipping',
            'dob',
            'taxvat',
            'id',
            'is_subscribed',
            'group_id',
            'is_wishlist_price_alert_enabled',
            'is_bookstore',
            this._getAddressesField(),
            this._getDownloadableDevicesField(),
        ];
    }

    mutateResendConfirmEmail(options) {
        const { email } = options;

        return new Field('resendConfirmationEmail').addArgument('email', 'String!', email).addFieldList(['status']);
    }

    getCustomerReviews(options) {
        return new Field('customer').addField(this.getReviewsField(options));
    }

    getReviewsField(options = {}) {
        const { pageSize = null, currentPage = null } = options;

        const mutation = new Field('reviews').addFieldList([
            this._getSearchResultPageInfoField(),
            this.getReviewsItemsField(),
        ]);

        if (pageSize) {
            mutation.addArgument('pageSize', 'Int', pageSize);
        }

        if (currentPage) {
            mutation.addArgument('currentPage', 'Int', currentPage);
        }

        return mutation;
    }

    getReviewsItemsField() {
        return new Field('items').addFieldList([
            'text',
            'summary',
            'average_rating',
            'created_at',
            'review_id ',
            'answer',
            'status',
            this.getReviewUsefulnessField(),
            this.getReviewReviewProductField(),
        ]);
    }

    getReviewUsefulnessField() {
        return new Field('usefulness').addFieldList(['helpful', 'unhelpful']);
    }

    getReviewReviewProductField() {
        return new Field('product').addFieldList([
            'name',
            'id',
            'url',
            this.getReviewProductImageField(),
            this.getReviewProductDictionaryLabelField(),
            this.getReviewProductDictionaryField(),
        ]);
    }

    getReviewProductDictionaryLabelField() {
        return new Field('dictionary_label').addFieldList(['url', 'name', 'type']);
    }

    getReviewProductDictionaryField() {
        return new Field('dictionary').addFieldList(this.getReviewProductDictionaryFields());
    }

    getReviewProductImageField() {
        return new Field('small_image').addFieldList(['url']);
    }

    getDictionaryItemField() {
        return ['url', 'name'];
    }

    getReviewProductDictionaryFields() {
        const fields = [
            'authors',
            'directors',
            'actors',
            'performers',
            'lectors',
            'publishers',
            'composers',
            'producers',
            'series',
            'conductors',
        ];

        return fields.map((field) => new Field(field).addFieldList(this.getDictionaryItemField()));
    }

    _getDownloadableDevicesField() {
        return new Field('send_to_device').addFieldList(this._getDownloadableDevicesFields());
    }

    _getDownloadableDevicesFields() {
        return ['device_email_full', 'device_email_username', 'device_email_domain', 'device_type', 'file_type'];
    }

    _getDownloadableDeviceStatusFields() {
        return ['status', 'message'];
    }

    _getSearchResultPageInfoField() {
        return new Field('page_info').addFieldList(this._getSearchResultPageInfoFields());
    }

    _getSearchResultPageInfoFields() {
        return ['current_page', 'page_size', 'total_pages'];
    }

    _getCustomerBookshopField() {
        return new Field('shipping_spot_bookshop').addFieldList(this._getCustomerBookshopFields());
    }

    _getCustomerInpostField() {
        return new Field('shipping_spot_inpost').addFieldList(this._getCustomerInpostFields());
    }

    _getCustomerOrlenPaczkaField() {
        return new Field('shipping_spot_ruch').addFieldList(this._getCustomerOrlenPaczkaFields());
    }

    _getCustomerDpdPickupPaczkaField() {
        return new Field('shipping_spot_dpd').addFieldList(this._getCustomerDpdPickupPaczkaFields());
    }

    _getCustomerBookshopFields() {
        return ['symbol'];
    }

    _getCustomerInpostFields() {
        return ['name'];
    }

    _getCustomerOrlenPaczkaFields() {
        return ['destination_code'];
    }

    _getCustomerDpdPickupPaczkaFields() {
        return ['id_pudo'];
    }

    _getLoginAsUserFields() {
        return ['token'];
    }
}

export default new MyAccountQuery();
