import PropTypes from 'prop-types';

import FIELD_TYPE from 'Component/Field/Field.config';
import { FieldContainer as SourceFieldContainer } from 'SourceComponent/Field/Field.container';
import { noopFn } from 'Util/Common';
import { validate } from 'Util/Validator';

import Field from './Field.component';

/**
 * Field
 * @class FieldContainer
 * @namespace SwiatKsiazkiBasic/Component/Field/Container */
export class FieldContainer extends SourceFieldContainer {
    static propTypes = {
        ...SourceFieldContainer.propTypes,
        isDynamicWidth: PropTypes.bool,
        onError: PropTypes.func,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    };

    static defaultProps = {
        ...SourceFieldContainer.defaultProps,
        isDynamicWidth: false,
        onError: noopFn,
    };

    state = {
        validationResponse: null,
        showLengthError: false,
        visiblePassword: false,
        passwordStrength: '',
    };

    containerFunctions = {
        ...this.containerFunctions,
        toggleVisiblePassword: this.toggleVisiblePassword.bind(this),
        updatePasswordStrength: this.updatePasswordStrength.bind(this),
    };

    componentWillUnmount() {
        const { validationRule } = this.props;

        if (this.fieldRef && this.fieldRef?.current) {
            this.fieldRef.removeEventListener('resetField', this.resetField.bind(this));

            if (validationRule && Object.keys(validationRule).length > 0) {
                this.fieldRef.removeEventListener('validate', this.validate.bind(this));
            }
        }
    }

    toggleVisiblePassword() {
        this.setState((prev) => ({
            ...prev,
            visiblePassword: !prev.visiblePassword,
        }));
    }

    updatePasswordStrength(e) {
        const { value } = e?.target;

        this.setState((prev) => ({
            ...prev,
            passwordStrength: value,
        }));
    }

    containerProps() {
        const { visiblePassword, passwordStrength } = this.state;
        const { passwordStrengthVisible, isDynamicWidth, updateMaxValue } = this.props;

        return {
            ...super.containerProps(),
            visiblePassword,
            passwordStrengthVisible,
            passwordStrength,
            isDynamicWidth,
            updateMaxValue,
        };
    }

    validate(data) {
        const {
            validationRule: { range: { max: maxValidLength = 0 } = {} },
            type,
            attr: { name } = {},
            onError,
        } = this.props;
        const { showLengthError } = this.state;
        const value =
            type === FIELD_TYPE.checkbox || type === FIELD_TYPE.radio || type === FIELD_TYPE.switcher
                ? !!this.fieldRef.checked
                : this.fieldRef.value;
        const newValidRule = this.handleShowLengthError();
        const response = validate(type === FIELD_TYPE.file ? value.toLowerCase() : value, newValidRule);
        const output = response !== true ? { ...response, type, name } : response;

        // If validation is called from different object you can pass object
        // to store validation error values
        if (data && data.detail && response !== true) {
            if (!data.detail.errors) {
                // eslint-disable-next-line no-param-reassign
                data.detail.errors = [];
            }

            // Validates length on submit, renders special message
            if (maxValidLength && value.length > maxValidLength && !showLengthError) {
                this.setState({ showLengthError: true });
                output.errorMessages.unshift(__('Please enter no more than %s characters.', maxValidLength));
            }

            data.detail.errors.push(output);
        }

        // When submit and response equals true (it can be object) reset show length error
        if (response === true) {
            this.setState({ showLengthError: false });
        }

        if (typeof onError === 'function') {
            onError(output, this.fieldRef);
        }

        this.setState({ validationResponse: output });

        return output;
    }

    validateOnEvent(hook, ...args) {
        const validationResponse = this.validate();

        if (hook) {
            const { attr, type } = this.props;
            const { value } = this.fieldRef;
            hook(
                ...[
                    ...args,
                    {
                        ...attr,
                        fieldRef: this.fieldRef,
                        value,
                        validationResponse,
                        type: type === FIELD_TYPE.switcher ? FIELD_TYPE.checkbox : type,
                    },
                ]
            );
        }
    }

    render() {
        return <Field {...this.containerProps()} {...this.containerFunctions} />;
    }
}

export default FieldContainer;
