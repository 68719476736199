import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';

import { CART_URL } from 'Route/CartPage/CartPage.config';
import { CHECKOUT_URL } from 'Route/Checkout/Checkout.config';

import ScrollToTop from './ScrollToTop.component';
import { SHOW_AFTER_VIEWPORTS } from './ScrollToTop.config';

/** @namespace SwiatKsiazkiBasic/Component/ScrollToTop/Container */
export class ScrollToTopContainer extends PureComponent {
    static propTypes = {
        alwaysVisible: PropTypes.bool,
        showAfterViewPorts: PropTypes.number,
    };

    static defaultProps = {
        alwaysVisible: false,
        showAfterViewPorts: SHOW_AFTER_VIEWPORTS,
    };

    state = {
        isScrollVisible: false,
    };

    containerFunctions = {
        scrollToTop: this.scrollToTop.bind(this),
    };

    componentDidMount() {
        const { alwaysVisible } = this.props;

        if (!alwaysVisible) {
            window.addEventListener('scroll', this.handleScroll.bind(this), false);
        }
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.handleScroll.bind(this));
    }

    containerProps() {
        const { isScrollVisible } = this.state;
        const {
            alwaysVisible,
            location: { pathname },
        } = this.props;

        return {
            isScrollVisible: alwaysVisible ? true : isScrollVisible,
            isVisibleOnCurrentLocation: !pathname.includes(CHECKOUT_URL) && !pathname.includes(CART_URL),
            isCart: !!pathname.includes(CART_URL),
        };
    }

    handleScroll() {
        const { showAfterViewPorts } = this.props;

        const viewportHeight = document.documentElement.clientHeight;
        const scrolledHeight = document.documentElement.scrollTop;

        if (scrolledHeight > viewportHeight * showAfterViewPorts) {
            this.showScrollToTop();
        } else {
            this.hideScrollToTop();
        }
    }

    hideScrollToTop() {
        this.setState({ isScrollVisible: false });
    }

    showScrollToTop() {
        this.setState({ isScrollVisible: true });
    }

    scrollToTop() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    render() {
        return <ScrollToTop {...this.containerFunctions} {...this.containerProps()} />;
    }
}

export default withRouter(ScrollToTopContainer);
