import { getCurrency } from 'Util/Currency/Currency';
import getStore from 'Util/Store';

import * as FB_EVENTS from './Pixel.config';
import { track } from './Pixel.script';

/** @namespace SwiatKsiazkiBasic/Component/Pixel/Event/trackViewContent */
export const trackViewContent = (params) =>
    track(FB_EVENTS.FB_VIEW_CONTENT, {
        content_ids: [params?.sku].filter(Boolean),
        content_name: params?.name,
        content_type: 'product',
        content_category: params?.categories?.map((category) => category?.name?.trim()).join('/') || '',
        currency: params?.price_range?.minimum_price?.final_price?.currency || getCurrency(),
        value: params?.price_range?.minimum_price?.final_price?.value || 0,
        contents: [{ id: params?.sku || '', quantity: params?.stock_item?.min_sale_qty || 1 }],
    });

/** @namespace SwiatKsiazkiBasic/Component/Pixel/Event/trackInitiateCheckout */
export const trackInitiateCheckout = (params) =>
    track(FB_EVENTS.FB_INITIATE_CHECKOUT, {
        content_ids: params?.items?.map((item) => item?.sku),
        content_name: 'Checkout',
        contents: params?.items?.map((item) => ({
            id: item?.sku || '',
            quantity: item?.qty || 1,
        })),
        currency: params?.quote_currency_code || getCurrency(),
        num_items: params?.items_qty || 0,
        value: params?.grand_total || 0,
    });

/** @namespace SwiatKsiazkiBasic/Component/Pixel/Event/trackPurchase */
export const trackPurchase = (params) =>
    track(FB_EVENTS.FB_PURCHASE, {
        content_ids: params?.products?.map((product) => product?.id || ''),
        content_name: 'Purchase',
        content_type: 'product',
        contents: params?.products?.map((item) => ({
            id: item?.id || '',
            quantity: item?.quantity || 1,
        })),
        currency: params?.currencyCode || getCurrency(),
        num_items: params?.products?.reduce((sum, item) => sum + item?.quantity || 1, 0) || 0,
        value: Number(params?.revenue) || 0,
        order_id: params?.orderId || '',
    });

/** @namespace SwiatKsiazkiBasic/Component/Pixel/Event/trackAddToCart */
export const trackAddToCart = (params) =>
    track(FB_EVENTS.FB_ADD_TO_CART, {
        content_ids: [params?.sku].filter(Boolean),
        content_name: params?.name,
        content_type: 'product',
        currency: params?.price_range?.minimum_price?.final_price?.currency || getCurrency(),
        value: params?.price_range?.minimum_price?.final_price?.value || 0,
        contents: [{ id: params?.sku || '', quantity: params?.quantity || params?.stock_item?.min_sale_qty || 1 }],
    });

/** @namespace SwiatKsiazkiBasic/Component/Pixel/Event/trackAddToWishlist */
export const trackAddToWishlist = (params) =>
    track(FB_EVENTS.FB_ADD_TO_WISHLIST, {
        content_ids: [params?.sku].filter(Boolean),
        content_name: params?.name,
        content_type: 'product',
        currency: params?.price_range?.minimum_price?.final_price?.currency || getCurrency(),
        value: params?.price_range?.minimum_price?.final_price?.value || 0,
        contents: [{ id: params?.sku || '', quantity: params?.quantity || params?.stock_item?.min_sale_qty || 1 }],
    });

/** @namespace SwiatKsiazkiBasic/Component/Pixel/Event/trackViewCategory */
export const trackViewCategory = (params) =>
    track(
        FB_EVENTS.FB_VIEW_CATEGORY,
        {
            content_ids: params?.products?.map((product) => product?.sku || ''),
            content_name: getStore()?.getState()?.CategoryReducer?.category?.name || '',
            content_type: 'product_list',
            currency: params?.currencyCode || getCurrency(),
        },
        'trackCustom'
    );

/** @namespace SwiatKsiazkiBasic/Component/Pixel/Event/trackSearch */
export const trackSearch = (params) =>
    track(FB_EVENTS.FB_SEARCH, {
        search_string: params,
    });

/** @namespace SwiatKsiazkiBasic/Component/Pixel/Event/trackCompleteRegistration */
export const trackCompleteRegistration = () => track(FB_EVENTS.FB_COMPLETE_REGISTRATION);

/** @namespace SwiatKsiazkiBasic/Component/Pixel/Event/trackAddPaymentInfo */
export const trackAddPaymentInfo = () => track(FB_EVENTS.FB_ADD_PAYMENT_INFO);

/** @namespace SwiatKsiazkiBasic/Component/Pixel/Event/trackContact */
export const trackContact = () => track(FB_EVENTS.FB_CONTACT);
