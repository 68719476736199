import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Icon from 'Component/Icon';

import { PLACEHOLDER_BALANCE, STATUSES } from './GiftCard.config';

import './GiftCard.style';

/** @namespace SwiatKsiazkiBasic/Component/GiftCard/Component */
export class GiftCard extends PureComponent {
    static propTypes = {
        response: PropTypes.shape({
            balance: PropTypes.string,
            expire_date: PropTypes.string,
            status: PropTypes.string,
        }),
        card: PropTypes.string.isRequired,
    };

    renderBalanceWrapper(balance) {
        const [value, decimalValue] = balance.split('.');

        return (
            <>
                <p block="GiftCard" elem="CardLabel" mods={{ balance: true }}>
                    {__('Balance')}
                </p>
                <p block="GiftCard" elem="BalanceValueWrapper">
                    <span block="GiftCard" elem="BalanceValue" mods={{ value: true }}>
                        {value}
                    </span>
                    <span block="GiftCard" elem="BalanceValue" mods={{ decimalValue: true }}>
                        ,{decimalValue || '00'} zł
                    </span>
                </p>
            </>
        );
    }

    renderSingleDetail(label, value) {
        return (
            <div block="GiftCard" elem="SingleDetailWrapper">
                <p block="GiftCard" elem="CardLabel">
                    {label}
                </p>
                <p block="GiftCard" elem="DetailsValue">
                    {value || '---'}
                </p>
            </div>
        );
    }

    render() {
        const { card, response: { balance, expire_date, status } = {} } = this.props;

        return (
            <div block="GiftCard" mods={{ success: status === 'OK' }}>
                <div block="GiftCard" elem="BalanceWrapper">
                    {this.renderBalanceWrapper(balance || PLACEHOLDER_BALANCE)}
                </div>
                <div block="GiftCard" elem="DetailsWrapper">
                    {this.renderSingleDetail(__('Number:'), card)}
                    {this.renderSingleDetail(__('Status:'), STATUSES[status])}
                    {this.renderSingleDetail(__('Expire date:'), expire_date)}
                </div>
                <Icon name="gift" />
            </div>
        );
    }
}

export default GiftCard;
