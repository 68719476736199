import ProductListQuery from 'Query/ProductList.query';
import { CartQuery as SourceCartQuery } from 'SourceQuery/Cart.query';
import { isSignedIn } from 'Util/Auth';
import { getGuestQuoteId } from 'Util/Cart';
import { Field } from 'Util/Query';

/** @namespace SwiatKsiazkiBasic/Query/Cart/Query */
export class CartQuery extends SourceCartQuery {
    getRemoveCartItemsMutation(productsId, quoteId) {
        const mutation = new Field('removeCartItems')
            .addArgument('item_id', '[Int]!', productsId)
            .addFieldList(this._getRemoveCartItemFields(quoteId));

        if (!isSignedIn()) {
            mutation.addArgument('guestCartId', 'String', quoteId);
        }

        return mutation;
    }

    getAddProductToCartMutation(cartId, cartItems) {
        return new Field('addProductsToCart')
            .addArgument('cartId', 'String!', cartId)
            .addArgument('cartItems', '[CartItemInput!]!', cartItems)
            .addField(this._getUserErrorsField())
            .addFieldList(['user_warning']);
    }

    getApplyGiftcardMutation({ cardNumber, secureCode, pinCode }, key) {
        const mutation = new Field('applyGiftcard')
            .addArgument('cardNumber', 'String!', cardNumber)
            .addArgument('secureCode', 'String!', secureCode)
            .addArgument('pinCode', 'String!', pinCode)
            .addArgument('guestCartId', 'String!', getGuestQuoteId())
            .addFieldList(['message', 'status']);

        if (key) {
            mutation.setAlias(`applyGiftcard_${key}`);
        }

        return mutation;
    }

    getApplyCustomerGiftcardMutation({ cardId, password }, key) {
        const mutation = new Field('applyCustomerGiftcard')
            .addArgument('cardId', 'Int', cardId)
            .addArgument('password', 'String', password)
            .addFieldList(['message', 'status']);

        if (key) {
            mutation.setAlias(`applyCustomerGiftcard_${key}`);
        }

        return mutation;
    }

    getRemoveGiftcardMutation({ cardNumber, giftcardQuoteId }) {
        const mutation = new Field('removeAppliedGiftcard')
            .addArgument('cardNumber', 'String!', cardNumber)
            .addArgument('giftcardQuoteId', 'Int!', giftcardQuoteId)
            .addFieldList(['message', 'status']);

        if (!isSignedIn()) {
            mutation.addArgument('guestCartId', 'String', getGuestQuoteId());
        }

        return mutation;
    }

    getPromotedProductListQuery(guestCartId) {
        const field = new Field('getPromotedProductList').addFieldList(['skus']);

        if (guestCartId) {
            field.addArgument('guestCartId', 'String', guestCartId);
        }

        return field;
    }

    getRecommendedProductListQuery(guestCartId) {
        const field = new Field('getCartRecommendedProducts').addFieldList(['skus']);

        if (guestCartId) {
            field.addArgument('guestCartId', 'String', guestCartId);
        }

        return field;
    }

    getPromotionsQuery(guestCartId) {
        const field = new Field('getProductsForPromotion').addFieldList(this._getPromotionsFields());

        if (guestCartId) {
            field.addArgument('guestCartId', 'String', guestCartId);
        }

        return field;
    }

    _getPromotionsFields() {
        return [
            'promotion_name',
            'promotion_description',
            'custom_link_to_more_products_in_popup_promotion',
            'custom_link_button_text_popup_promotion',
            new Field('items').addFieldList(ProductListQuery._getProductInterfaceFields(false, false, true)),
        ];
    }

    _getCartTotalsFields() {
        return [
            'id',
            'subtotal',
            'subtotal_incl_tax',
            'items_qty',
            'tax_amount',
            'grand_total',
            'discount_amount',
            'quote_currency_code',
            'subtotal_with_discount',
            'coupon_code',
            'shipping_amount',
            'shipping_incl_tax',
            'shipping_tax_amount',
            'is_virtual',
            'applied_rule_ids',
            'shipping_amount',
            'shipping_incl_tax',
            'shipping_tax_amount',
            'shipping_method',
            'shipping_method_title',
            'shipping_carrier_title',
            'is_in_store_pickup_available',
            'physical_price',
            'virtual_price',
            this._getCartItemsField(),
            this._getAppliedTaxesField(),
            this._getDeliveryDate(),
            'need_declaration',
            'selected_declaration_country',
            'last_declaration_validation_result',
            this._getGiftCardDetailsField(),
            this._getDiscountDetailsField(),
            this._getCartPriceDetails(),
            ...this._getOrderDividedFields(),
        ];
    }

    _getCartItemFields() {
        return [...super._getCartItemFields(), 'warning_message'];
    }

    _getGiftCardDetailsField() {
        return new Field('giftcard_details').addFieldList(this._getGiftCardDetailsFields());
    }

    _getGiftCardDetailsFields() {
        return ['amount', 'label', this._getGiftCardsField()];
    }

    _getGiftCardsField() {
        return new Field('gift_cards').addFieldList(this._getGiftCardsFields());
    }

    _getGiftCardsFields() {
        return ['card_number', 'giftcard_quote_id', 'label', 'amount', 'id', 'customer_card_id'];
    }

    _getDiscountDetailsField() {
        return new Field('discount_details').addFieldList([
            'amount',
            'label',
            this._getSalesRuleField(),
            this._getSeriesDiscounts(),
            this._getSetsDiscounts(),
        ]);
    }

    _getCartPriceDetails() {
        return new Field('cart_price_details').addFieldList(['suggested_starting_price', 'saved_amount']);
    }

    _getSalesRuleField() {
        return new Field('salesrule_discounts').addFieldList(['name', 'amount']);
    }

    _getSeriesDiscounts() {
        return new Field('series_discounts').addFieldList(['name', 'amount']);
    }

    _getSetsDiscounts() {
        return new Field('sets_discounts').addFieldList(['name', 'amount']);
    }

    _getDeliveryDate() {
        return new Field('delivery_date').addFieldList([
            'date',
            'message',
            'is_24h_delivery',
            'polish_book_preorder_tooltip',
        ]);
    }

    _getProductField() {
        return new Field('product').addFieldList([
            ...ProductListQuery._getCartProductInterfaceFields(),
            ProductListQuery.getCodazonLabels(),
            ProductListQuery.getDictionaryLabel(),
        ]);
    }

    _getOrderDividedFields() {
        return ['is_divided', 'divided_available', this._getOrderDividedDataField()];
    }

    _getOrderDividedDataField() {
        return new Field('divided_data').addFieldList([
            'shipping_cost',
            'shipping_date',
            'total_row',
            'total_row_physical',
            'total_row_virtual',
            'total_row_package',
            this._getDeliveryDate(),
            this._getOrderDividedPackages(),
            this._getOrderDividedProductField(),
        ]);
    }

    _getOrderDividedPackages() {
        return new Field('packages').addFieldList(['product_id', 'qty']);
    }

    _getOrderDividedProductField() {
        return new Field('products').addFieldList([
            'name',
            'price',
            'qty',
            'thumbnail',
            'entity_id',
            this._getOrderDividedProductDictionaryField(),
        ]);
    }

    _getOrderDividedProductDictionaryField() {
        return new Field('dictionary_label').addFieldList(['name', 'type', 'url']);
    }
}

export default new CartQuery();
