export const CLICKONOMETRICS_VARIANT = {
    MAIN: 'main',
    PRODUCT_BROWSED: 'product_browsed',
    PRODUCT_ORDERED: 'products_ordered',
};

export const CLICKONOMETRICS_BLOCK_ID = {
    [CLICKONOMETRICS_VARIANT.PRODUCT_BROWSED]: 'ccx_tr_54d39d004d6acbf831adc4ca8de3ef69',
    [CLICKONOMETRICS_VARIANT.PRODUCT_ORDERED]: 'ccx_tr_e9d85c6bbc79ededfb565262fa1e166a',
};
