import { connect } from 'react-redux';

import CloseIcon from 'Component/CloseIcon';
import Logo from 'Component/Logo';
import { InstallPromptAndroid as SourceInstallPromptAndroid } from 'SourceComponent/InstallPromptAndroid/InstallPromptAndroid.component';
import media from 'Util/Media';
import { LOGO_MEDIA } from 'Util/Media/Media';

import './InstallPromptAndroid.style';

/** @namespace SwiatKsiazkiBasic/Component/InstallPromptAndroid/Component/mapStateToProps */
export const mapStateToProps = (state) => ({
    header_logo_src: state.ConfigReducer.header_logo_src,
});

/** @namespace SwiatKsiazkiBasic/Component/InstallPromptAndroid/Component/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace SwiatKsiazkiBasic/Component/InstallPromptAndroid/Component */
export class InstallPromptAndroid extends SourceInstallPromptAndroid {
    renderLogoImage() {
        const { header_logo_src, logo_alt } = this.props;
        const logoSrc = header_logo_src ? media(header_logo_src, LOGO_MEDIA) : null;

        return <Logo src={logoSrc} alt={logo_alt} title={logo_alt} />;
    }

    renderCloseButton() {
        const { handleBannerClose } = this.props;

        return (
            <button block="InstallPromptAndroid" elem="Close" onClick={handleBannerClose} aria-label={__('Close')}>
                <CloseIcon />
            </button>
        );
    }

    renderInstallButton() {
        const { handleAppInstall } = this.props;

        return (
            <button block="InstallPromptAndroid" elem="Button" onClick={handleAppInstall}>
                {this.renderLogoImage()}
                <p block="InstallPromptAndroid" elem="Content">
                    {__('Add the swiatksiazki.pl application to your home screen!')}
                </p>
            </button>
        );
    }

    render() {
        return (
            <div block="InstallPromptAndroid">
                {this.renderInstallButton()}
                {this.renderCloseButton()}
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InstallPromptAndroid);
