import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { TabsContainer } from 'Component/Tabs/Tabs.container';

import CareerTabs from './CareerTabs.component';

/** @namespace SwiatKsiazkiBasic/Component/CareerTabs/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace SwiatKsiazkiBasic/Component/CareerTabs/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device,
});

/** @namespace SwiatKsiazkiBasic/Component/CareerTabs/Container */
export class CareerTabsContainer extends TabsContainer {
    render() {
        return <CareerTabs {...this.containerFunctions} {...this.containerProps()} />;
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CareerTabsContainer));
