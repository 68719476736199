import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { EVENT_ADD_TO_CART, EVENT_CATEGORY_VIEW, EVENT_ORDER, EVENT_PRODUCT_VIEW } from './Edrone.config';
import { prepareAddToCart, prepareCategory, prepareOrder, prepareProduct } from './Edrone.event';
import EdroneObservable from './Edrone.observable';

/** @namespace SwiatKsiazkiBasic/Component/Edrone/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    appId: state.ConfigReducer.edrone_app_id,
    isAddToCartEnabled: state.ConfigReducer.edrone_add_to_cart,
});

/** @namespace SwiatKsiazkiBasic/Component/Edrone/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace SwiatKsiazkiBasic/Component/Edrone/Container */
export class EdroneContainer extends PureComponent {
    events = {
        [EVENT_PRODUCT_VIEW]: prepareProduct,
        [EVENT_CATEGORY_VIEW]: prepareCategory,
        [EVENT_ADD_TO_CART]: prepareAddToCart,
        [EVENT_ORDER]: prepareOrder,
    };

    componentDidMount() {
        this.initialSubscribe();
    }

    componentDidUpdate(prevProps) {
        const { appId } = this.props;
        const { appId: prevAppId } = prevProps;

        if (prevAppId !== appId) {
            this.initialSubscribe();
        }
    }

    componentWillUnmount() {
        EdroneObservable.unsubscribe(this.handleObservable.bind(this));
    }

    initialSubscribe() {
        const { appId } = this.props;

        if (appId) {
            EdroneObservable.subscribe(this.handleObservable.bind(this));
        }
    }

    async handleObservable(event, options) {
        const { isAddToCartEnabled } = this.props;
        const callback = this.events[event];

        if (!callback || (event === EVENT_ADD_TO_CART && !isAddToCartEnabled)) {
            return;
        }

        await callback(event, options);
    }

    render() {
        return null;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EdroneContainer);
