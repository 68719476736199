import lazy from '@loadable/component';
import parser from 'html-react-parser';
import attributesToProps from 'html-react-parser/lib/attributes-to-props';
import domToReact from 'html-react-parser/lib/dom-to-react';
import omit from 'lodash/omit';
import React, { Suspense } from 'react';
import { withRouter } from 'react-router-dom';

import Image from 'Component/Image';
import Link from 'Component/Link';
import Loader from 'Component/Loader';
import MagezonRenderer from 'Component/MagezonRenderer';
import ShowMoreLessText from 'Component/ShowMoreLessText';
import WidgetFactory from 'Component/WidgetFactory';
import { Html as SourceHtml } from 'SourceComponent/Html/Html.component';
import { getMediaURL, isMagentoMedia } from 'Util/Common';
import { removeMagezoneTags } from 'Util/MagezoneWidget';
import getStore from 'Util/Store';
import { isHomePageUrl } from 'Util/Url/Url';

export const Accordion = lazy(() =>
    import(
        /* webpackMode: "lazy", webpackChunkName: "widget" */
        'Component/Accordion'
    )
);

export const Icon = lazy(() =>
    import(
        /* webpackMode: "lazy", webpackChunkName: "widget" */
        'Component/Icon'
    )
);

/** @namespace SwiatKsiazkiBasic/Component/Html/Component */
export class Html extends SourceHtml {
    rules = [
        {
            query: { attribs: [{ class: 'mgz-element' }, { class: 'mgz-container' }, 'data-widget-row'] },
            replace: this.renderMagezoneWidget,
        },
        ...this.rules,
        {
            query: { attribs: ['data-accordion'] },
            replace: this.replaceAccordionTabs,
        },
        {
            query: { name: ['a'] },
            replace: this.replaceLinks,
        },
        {
            query: { attribs: ['data-icon'] },
            replace: this.renderIcon,
        },
        {
            query: { attribs: ['data-content-type'] },
            replace: this.renderContentType,
        },
    ];

    parserOptions = {
        replace: (domNode) => {
            const { data, name: domName, attribs: domAttrs } = domNode;

            // Let's remove empty text nodes
            if (data && !data.replace(/\u21b5/g, '').replace(/\s/g, '').length) {
                // eslint-disable-next-line react/jsx-no-useless-fragment
                return <></>;
            }

            const rule = this.rules.find((rule) => {
                const {
                    query: { name, attribs },
                } = rule;

                if (name && domName && name.indexOf(domName) !== -1) {
                    return true;
                }

                if (attribs && domAttrs) {
                    // eslint-disable-next-line fp/no-loops, fp/no-let
                    for (let i = 0; i < attribs.length; i++) {
                        const attrib = attribs[i];

                        if (typeof attrib === 'object') {
                            const queryAttrib = Object.keys(attrib)[0];

                            if (Object.prototype.hasOwnProperty.call(domAttrs, queryAttrib)) {
                                return domAttrs[queryAttrib].match(Object.values(attrib)[0]);
                            }
                        } else if (Object.prototype.hasOwnProperty.call(domAttrs, attrib)) {
                            return true;
                        }
                    }
                }

                return false;
            });

            if (rule) {
                const { replace } = rule;

                return replace.call(this, domNode);
            }
        },
    };

    renderMagezoneWidget({ children, ...element }) {
        return (
            <MagezonRenderer
                {...element}
                attributesToProps={(attribs) => this.attributesToProps(attribs)}
                domToReact={(value, parserOptions = this.parserOptions) => domToReact(value, parserOptions)}
            >
                {children}
            </MagezonRenderer>
        );
    }

    replaceImages({ attribs: { 'data-device': device, 'lazy-mode': lazyMode, ...attribs } = {} }) {
        const attributes = attributesToProps(attribs);

        if (device) {
            const showOnDesktop = device === 'desktop';
            const showOnMobile = device === 'mobile';
            const showOnTablet = device === 'tablet';
            const showOnTabletDesktop = device === 'tabletAndDesktop';

            const {
                ConfigReducer: {
                    device: { isMobile, isTablet },
                },
            } = getStore().getState() || {};

            const isDesktopImgHidden = showOnDesktop && (isMobile || isTablet);
            const isTabletImgHidden = showOnTablet && !isTablet;
            const isMobileImgHidden = showOnMobile && (isTablet || !isMobile);
            const isTabletDesktopImgHidden =
                showOnTabletDesktop && device === 'tabletAndDesktop' && isMobile && !isTablet;

            if (isDesktopImgHidden || isTabletImgHidden || isMobileImgHidden || isTabletDesktopImgHidden) {
                return <div />;
            }
        }

        if (attribs.src && !attribs?.class?.includes('mgz-')) {
            return (
                <Image
                    {...attributes}
                    src={getMediaURL(attributes.src)}
                    useNativeLazyLoading={lazyMode === 'native' || isMagentoMedia(attributes.src)}
                    removeDefaultClasses={lazyMode === 'native' || isMagentoMedia(attributes.src)}
                    isPlain
                />
            );
        }
    }

    replaceLinks({ name: TagName, attribs: { href, 'data-icon-link': icon, ...attrs } = {}, children }) {
        if (href) {
            const isAbsoluteUrl = (value) => new RegExp('^(?:[a-z]+:)?//', 'i').test(value);
            const isSpecialLink = (value) => new RegExp('^(sms|tel|mailto):', 'i').test(value);

            if (isAbsoluteUrl(href) && icon) {
                return <Link {...this.attributesToProps({ ...attrs, to: href })}>{icon && <Icon name={icon} />}</Link>;
            }

            if (!isAbsoluteUrl(href) && !isSpecialLink(href)) {
                return (
                    <Link {...this.attributesToProps({ ...attrs, to: href })}>
                        {domToReact(children, this.parserOptions)}
                    </Link>
                );
            }
        }

        return (
            <TagName {...this.attributesToProps(omit({ ...attrs, href }, ['style']))}>
                {domToReact(children, this.parserOptions)}
            </TagName>
        );
    }

    renderIcon({ attribs: { 'data-icon': icon, ...attribs } = {} }) {
        return (
            <Suspense fallback={null}>
                <Icon name={icon} {...this.attributesToProps(attribs)} />
            </Suspense>
        );
    }

    replaceAccordionTabs({ attribs: { 'data-device': device, ...attribs } = {}, children }) {
        const attributes = this.attributesToProps({ 'data-device': device, ...attribs });

        const {
            ConfigReducer: {
                device: { isMobile, isTablet },
            },
        } = getStore().getState() || {};

        if (device === 'mobileAndTablet' && !isMobile && !isTablet) {
            return null;
        }

        return (
            <Suspense fallback={<Loader isLoading />}>
                <Accordion {...attributes}>{domToReact(children, this.parserOptions)}</Accordion>
            </Suspense>
        );
    }

    renderContentType({ attribs: { 'data-content-type': dataContentType } = {}, children }) {
        const { disableContentType } = this.props;

        if (!disableContentType) {
            if (dataContentType === 'show-more') {
                return <ShowMoreLessText isSimpleVersion>{domToReact(children, this.parserOptions)}</ShowMoreLessText>;
            }
        }
    }

    replaceWidget({ attribs }) {
        return <WidgetFactory {...this.attributesToProps(attribs)} />;
    }

    render() {
        const {
            content,
            location: { pathname },
        } = this.props;

        return parser(removeMagezoneTags(content, isHomePageUrl(pathname)), this.parserOptions);
    }
}

export default withRouter(Html);
