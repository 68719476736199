import React, { PureComponent } from 'react';
import LazyLoad from 'react-lazyload';

import { LAZY_LOAD_DEFAULT_TRESHOLD } from 'Component/LazyLoad/LazyLoad.config';
import { isCrawler } from 'Util/Browser';

/** @namespace SwiatKsiazkiBasic/Component/LazyLoad/Component */
export class LazyLoadComponent extends PureComponent {
    render() {
        const { children, ...rest } = this.props;

        if (isCrawler()) {
            return children;
        }

        return (
            <LazyLoad once threshold={LAZY_LOAD_DEFAULT_TRESHOLD} {...rest}>
                {children}
            </LazyLoad>
        );
    }
}

export default LazyLoadComponent;
