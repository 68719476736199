import { PureComponent } from 'react';

import CmsBlock from 'Component/CmsBlock';
import NewsletterSubscription from 'Component/NewsletterSubscription';

import { NEWSLETTER_INFO_CMS_ID } from './Newsletter.config';

import './Newsletter.style';

/** @namespace SwiatKsiazkiBasic/Component/Newsletter/Component */
export class Newsletter extends PureComponent {
    render() {
        return (
            <section block="Newsletter">
                <div block="Newsletter" elem="Container">
                    <div className="ContentWrapper">
                        <div block="Newsletter" elem="Info">
                            <CmsBlock identifier={NEWSLETTER_INFO_CMS_ID} />
                        </div>
                        <div block="Newsletter" elem="FormWrapper">
                            <NewsletterSubscription />
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Newsletter;
