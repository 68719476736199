export const DEFAULT_SLICK_SETTINGS = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
};

export const SLIDER_DESKTOP_HEIGHT = 500;
export const SLIDER_TABLET_HEIGHT = 430;
export const SLIDER_MOBILE_HEIGHT = 280;

export const PRODUCT_SLIDER_WITH_BANNER = {
    dots: false,
    arrows: true,
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 4,
    infinite: false,
    draggable: true,
    responsive: [
        {
            breakpoint: 1280,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
            },
        },
        {
            breakpoint: 1023,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            },
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            },
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1.25,
                slidesToScroll: 1,
            },
        },
    ],
};

export const PRODUCT_SLIDER_SETTINGS = {
    dots: false,
    arrows: true,
    speed: 800,
    slidesToShow: 5,
    slidesToScroll: 5,
    infinite: false,
    draggable: true,
    responsive: [
        {
            breakpoint: 1280,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
            },
        },
        {
            breakpoint: 1023,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            },
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            },
        },
        {
            breakpoint: 480,
            settings: {
                arrows: false,
                slidesToShow: 1.25,
                slidesToScroll: 1,
            },
        },
    ],
};

export const PRODUCT_SLIDER_LIGHT_VARIANT_SETTINGS = {
    dots: false,
    arrows: true,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 3,
    infinite: false,
    draggable: true,
    rows: 2,
    responsive: [
        {
            breakpoint: 1280,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 1023,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            },
        },
        {
            breakpoint: 767,
            settings: {
                arrows: false,
                slidesToShow: 2,
                slidesToScroll: 2,
            },
        },
        {
            breakpoint: 480,
            settings: {
                arrows: false,
                slidesToShow: 2,
                slidesToScroll: 2,
            },
        },
    ],
};

export const PRODUCT_SLIDER_LANDING_SETTINGS = {
    dots: false,
    arrows: true,
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 4,
    infinite: false,
    draggable: true,
    responsive: [
        {
            breakpoint: 1280,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
            },
        },
        {
            breakpoint: 1023,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            },
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            },
        },
        {
            breakpoint: 480,
            settings: {
                arrows: false,
                slidesToShow: 1.25,
                slidesToScroll: 1,
            },
        },
    ],
};

export const PRODUCT_SLIDER_PROMOTED = {
    dots: false,
    arrows: true,
    speed: 800,
    slidesToShow: 2.5,
    slidesToScroll: 1,
    infinite: false,
    draggable: true,
    responsive: [
        {
            breakpoint: 1280,
            settings: {
                slidesToShow: 2.5,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 1023,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};

export const PRODUCT_RECOMMENDED_SLIDER_SETTINGS = {
    dots: false,
    arrows: true,
    speed: 800,
    slidesToShow: 5,
    slidesToScroll: 5,
    infinite: false,
    draggable: true,
    responsive: [
        {
            breakpoint: 1280,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
            },
        },
        {
            breakpoint: 1023,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            },
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            },
        },
        {
            breakpoint: 480,
            settings: {
                arrows: false,
                slidesToShow: 1.25,
                slidesToScroll: 1,
            },
        },
    ],
};
