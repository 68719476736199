import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';

import { decodeString } from 'Util/Common';
import history from 'Util/History';

import Tabs from './Tabs.component';

/** @namespace SwiatKsiazkiBasic/Component/Tabs/Container */
export class TabsContainer extends PureComponent {
    static propTypes = {
        titles: PropTypes.arrayOf(PropTypes.string).isRequired,
        children: PropTypes.node.isRequired,
    };

    containerFunctions = {
        handleTabClick: this.handleTabClick.bind(this),
    };

    state = {
        activeTab: 0,
    };

    componentDidMount() {
        this.setActiveTabByHash();
    }

    componentDidUpdate(prevProps) {
        const {
            location: { hash },
        } = this.props;

        const {
            location: { hash: prevHash },
        } = prevProps;

        if (hash !== prevHash) {
            this.setActiveTabByHash();
        }
    }

    setActiveTabByHash() {
        const {
            titles,
            location: { hash },
        } = this.props;
        const decodedHash = decodeString(hash);

        const activeTab = titles.findIndex((title) => decodedHash.includes(decodeString(this.encodeTabName(title))));
        this.setState({ activeTab: activeTab !== -1 ? activeTab : 0 });
    }

    handleTabClick(id) {
        const { titles } = this.props;
        this.setState({ activeTab: id });

        if (titles && titles[id]) {
            history.push(`#${this.encodeTabName(titles[id])}`);
        }
    }

    encodeTabName(name) {
        return encodeURIComponent(name?.toLowerCase());
    }

    containerProps() {
        const { titles, children, device, prefixClassName } = this.props;
        const { activeTab } = this.state;

        return {
            titles,
            children,
            activeTab,
            device,
            prefixClassName,
        };
    }

    render() {
        return <Tabs {...this.containerFunctions} {...this.containerProps()} />;
    }
}

export default withRouter(TabsContainer);
