/** @namespace SwiatKsiazkiBasic/Component/Edrone/Observable */
export class EdroneObservable {
    __construct() {
        this.observers = new Map();
    }

    subscribe(func) {
        this.observers.set(func.name, func);
    }

    unsubscribe(func) {
        this.observers.delete(func.name);
    }

    send(event, options) {
        this.observers.forEach((observer) => observer(event, options));
    }
}

export default new EdroneObservable();
