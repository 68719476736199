import { createRef, PureComponent } from 'react';

/** @namespace SwiatKsiazkiBasic/Component/Script/Container */
export class Script extends PureComponent {
    static defaultProps = {
        to: 'body',
    };

    events = {
        load: 'load',
        error: 'error',
    };

    states = {
        idle: 'idle',
        ready: 'ready',
        error: 'error',
    };

    state = {
        state: this.states.idle,
    };

    scriptRef = createRef();

    componentDidMount() {
        const { onMount } = this.props;

        this.loadScript();

        if (typeof onMount === 'function') {
            onMount();
        }

        this.scriptRef.current.addEventListener(this.events.load, this.handleState.bind(this));
        this.scriptRef.current.addEventListener(this.events.error, this.handleState.bind(this));
    }

    componentWillUnmount() {
        const { to, onUnmount } = this.props;

        if (this.scriptRef.current) {
            this.scriptRef.current.removeEventListener(this.events.load, this.handleState.bind(this));
            this.scriptRef.current.removeEventListener(this.events.error, this.handleState.bind(this));

            if (typeof onUnmount === 'function') {
                onUnmount();
            }

            document[to].removeChild(this.scriptRef.current);
        }
    }

    loadScript() {
        const { to, type, url, async } = this.props;

        this.scriptRef.current = document.querySelector(`script[src="${url}"]`);

        if (!this.scriptRef.current) {
            this.scriptRef.current = document.createElement('script');

            if (type) {
                this.scriptRef.current.type = type;
            }

            if (async) {
                this.scriptRef.current.async = !!async;
            }

            this.scriptRef.current.src = url;

            if (document[to]) {
                document[to].appendChild(this.scriptRef.current);
            }
        } else {
            this.setState({
                state: this.states.ready,
            });
        }
    }

    handleState({ type }) {
        const { onLoad, onError } = this.props;

        if (type === this.events.load) {
            this.setState({
                state: this.states.ready,
            });

            if (typeof onLoad === 'function') {
                onLoad();
            }
        } else {
            this.setState({
                state: this.states.error,
            });

            if (typeof onError === 'function') {
                onError();
            }
        }
    }

    render() {
        const { children } = this.props;
        const { state } = this.state;

        if (!children) {
            return null;
        }

        if (typeof children === 'function') {
            return children({ state });
        }

        return children;
    }
}

export default Script;
