/* eslint-disable max-classes-per-file, @scandipwa/scandipwa-guidelines/only-one-class */

import * as Sentry from '@sentry/react';
import { GRAPHQL_AUTHENTICATION, GRAPHQL_AUTHORIZATION } from 'util/Request/Request';

import composer from '../../../composer.json';

export const DEFAULT_ERROR_MESSAGE = __('Something went wrong!');

/** @namespace SwiatKsiazkiBasic/Util/Request/Error/getErrorMessage */
export const getErrorMessage = (error, defaultMessage = DEFAULT_ERROR_MESSAGE) => {
    const { message = defaultMessage } = error?.length ? error[0] : error || {};

    return message;
};

/** @namespace SwiatKsiazkiBasic/Util/Request/Error/getErrorCategory */
export const getErrorCategory = (error) => {
    const { extensions } = error?.length ? error[0] : error || {};
    const { category } = extensions || {};

    return category;
};

/** @namespace SwiatKsiazkiBasic/Util/Request/Error */
export class GraphqlError extends Error {
    __construct(message) {
        super.__construct(message);
        this.name = 'GraphQLError';
    }
}

/** @namespace SwiatKsiazkiBasic/Util/Request/Error */
export class AuthorizationError extends Error {
    __construct(message) {
        super.__construct(message);
        this.name = 'AuthorizationError';
    }
}

/** @namespace SwiatKsiazkiBasic/Util/Request/Error */
export class AuthenticationError extends Error {
    __construct(message) {
        super.__construct(message);
        this.name = 'AuthenticationError';
    }
}

/** @namespace SwiatKsiazkiBasic/Util/Request/Error/captureGraphqlException */
export const captureGraphqlException = (errors, options = {}) => {
    Sentry.withScope((scope) => {
        const context = {
            ...(options ?? {}),
        };

        if (typeof errors !== 'string') {
            context.error = JSON.stringify(errors ?? {});
        } else {
            context.error = errors;
        }

        if (composer.version) {
            scope.setTag('frontend_version', composer.version);
        }

        scope.setTransactionName('GraphQL Request');
        scope.setContext('GraphQL', context);

        switch (getErrorCategory(errors)) {
            case GRAPHQL_AUTHORIZATION: {
                Sentry.captureException(new AuthorizationError(getErrorMessage(errors)));
                break;
            }
            case GRAPHQL_AUTHENTICATION: {
                Sentry.captureException(new AuthenticationError(getErrorMessage(errors)));
                break;
            }
            default: {
                Sentry.captureException(new GraphqlError(getErrorMessage(errors)));
                break;
            }
        }
    });
};
