import React, { PureComponent } from 'react';

import Icon from 'Component/Icon';
import { parseInlineStyle } from 'Util/CSS/CSS';

import './ProductLabel.style';

/** @namespace SwiatKsiazkiBasic/Component/ProductLabel/Component */
export class ProductLabel extends PureComponent {
    getText() {
        const { type, text, discount } = this.props;

        const labels = {
            new: __('New'),
            bestseller: __('Bestsellers'),
            discount: __('Discount'),
            top: __('Bestseller'),
            top_rounded: __('Bestseller'),
            top_with_number: __('Bestseller'),
            recommended: __('Recommended'),
            renewal: __('Renewal'),
            preview: __('Preview'),
            reprint: __('Renewal'),
            in_a_warehouse: __('We are already shipping!'),
            sale: discount ? `-${Number(discount)?.toFixed()}%` : __('Sale'),
        };

        if (type in labels) {
            return labels[type];
        }

        return text;
    }

    getIcon() {
        const { type } = this.props;

        const icons = {
            in_a_warehouse: <Icon name="price_delivery_car" height="20px" width="20px" />,
        };

        return icons[type] || null;
    }

    getCSS() {
        const { customCSS } = this.props;

        if (!customCSS) {
            return undefined;
        }

        try {
            return JSON.parse(customCSS);
        } catch {
            return parseInlineStyle(customCSS);
        }
    }

    render() {
        const { type, customClass, topNumber, mods, labelLink, goToLabelLink } = this.props;

        return (
            <div block="ProductLabel" elem="Wrapper" mods={{ type, ...mods }}>
                <div
                    block="ProductLabel"
                    mods={{ type, isLink: !!labelLink, ...mods }}
                    className={customClass || ''}
                    style={this.getCSS()}
                    onClick={(e) => goToLabelLink(e)}
                >
                    {this.getIcon()}
                    {this.getText()}
                </div>
                {type === 'top_with_number' && topNumber && <span>{topNumber}</span>}
            </div>
        );
    }
}

export default ProductLabel;
