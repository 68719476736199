import lazy from '@loadable/component';

import HomePage from 'Route/HomePage';
import { UrlRewrites as SourceUrlRewrites } from 'SourceRoute/UrlRewrites/UrlRewrites.component';
import { isHomePageUrl } from 'Util/Url';

import { TYPE_CATEGORY, TYPE_CMS_PAGE, TYPE_DICTIONARY, TYPE_NOTFOUND, TYPE_PRODUCT } from './UrlRewrites.config';

export const DictionaryPage = lazy(() =>
    import(/* webpackMode: "lazy", webpackChunkName: "pages" */ 'Route/DictionaryPage')
);
export const ProductPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "pages" */ 'Route/ProductPage'));
export const CategoryPage = lazy(() =>
    import(/* webpackMode: "lazy", webpackChunkName: "pages" */ 'Route/CategoryPage')
);
export const CmsPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "pages" */ 'Route/CmsPage'));
export const NoMatch = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "pages" */ 'Route/NoMatch'));

/** @namespace SwiatKsiazkiBasic/Route/UrlRewrites/Component */
export class UrlRewrites extends SourceUrlRewrites {
    renderContent() {
        const { props, type } = this.props;
        const {
            id,
            history,
            location,
            match,
            productSKU,
            categoryIds,
            pageIds,
            dictionaryId,
            dictionaryType,
            customRootCategory,
        } = props;

        switch (type) {
            case TYPE_PRODUCT:
                return (
                    <ProductPage
                        history={history}
                        location={location}
                        match={match}
                        productSKU={productSKU}
                        productID={id}
                        key={id}
                    />
                );
            case TYPE_CMS_PAGE:
                if (isHomePageUrl(location.pathname)) {
                    return <HomePage history={history} location={location} match={match} pageIds={pageIds} />;
                }

                return <CmsPage history={history} location={location} match={match} pageIds={pageIds} />;
            case TYPE_CATEGORY:
                return (
                    <CategoryPage
                        history={history}
                        location={location}
                        match={match}
                        categoryIds={categoryIds}
                        customRootCategory={customRootCategory}
                    />
                );
            case TYPE_DICTIONARY:
                return (
                    <DictionaryPage
                        history={history}
                        location={location}
                        match={match}
                        dictionaryId={dictionaryId}
                        dictionaryType={dictionaryType}
                    />
                );
            case TYPE_NOTFOUND:
                return <NoMatch history={history} location={location} match={match} />;
            default:
                return this.renderDefaultPage();
        }
    }
}

export default UrlRewrites;
