import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

import CmsStaticSidebarMenu from './CmsStaticSidebarMenu.component';

/** @namespace SwiatKsiazkiBasic/Component/CmsStaticSidebarMenu/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace SwiatKsiazkiBasic/Component/CmsStaticSidebarMenu/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device,
});

/** @namespace SwiatKsiazkiBasic/Component/CmsStaticSidebarMenu/Container */
export class CmsStaticSidebarMenuContainer extends PureComponent {
    static propTypes = {
        title: PropTypes.string,
        links: PropTypes.object,
    };

    state = {
        isExpandedOnMobile: false,
    };

    containerFunctions = {
        handleSelectChange: this.handleSelectChange.bind(this),
        handleMobileExpand: this.handleMobileExpand.bind(this),
    };

    handleMobileExpand(src) {
        if (!src) {
            return null;
        }

        this.setState((prev) => ({
            ...prev,
            isExpandedOnMobile: !prev.isExpandedOnMobile,
        }));
    }

    getLastIndexUrl(path) {
        return path.substring(path.lastIndexOf('/') + 1);
    }

    reducerLinks() {
        const { links } = this.props;

        const { pathname } = window.location;
        const currentPath = this.getLastIndexUrl(pathname);

        const reducedLinks = links.map((link) => {
            const title = link.children[0]?.children[0]?.data;
            const src = link.children[0]?.attribs?.href;
            const icon = link.children[0]?.attribs?.['data-icon'];
            const lastIndexOfSrc = this.getLastIndexUrl(src);

            return {
                active: currentPath === lastIndexOfSrc,
                src,
                title,
                icon,
            };
        });

        return reducedLinks;
    }

    handleSelectChange(src) {
        if (!src) {
            return null;
        }

        this.setState((prev) => ({
            ...prev,
            isExpandedOnMobile: !prev.isExpandedOnMobile,
        }));

        history.push({ pathname: appendWithStoreCode(src) });
    }

    containerProps() {
        const {
            title,
            device: { isMobile, isTablet },
        } = this.props;
        const { isExpandedOnMobile } = this.state;

        return {
            title,
            links: this.reducerLinks(),
            isMobile,
            isTablet,
            isExpandedOnMobile,
        };
    }

    render() {
        return <CmsStaticSidebarMenu {...this.containerProps()} {...this.containerFunctions} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CmsStaticSidebarMenuContainer);
