/**
 * ZIP codes config taken directly from Magento2 backend
 */

export const zipCodes = {
    AD: {
        code: {
            example: 'AD100',
            text: '^AD\\d{3}$',
        },
    },

    AM: {
        code: {
            example: '1234',
            text: '^[0-9]{4}$',
        },
    },

    AR: {
        code: [
            {
                example: '1234',
                text: '^[0-9]{4}$',
            },
            {
                example: 'A1234BCD',
                text: '^[a-zA-z]{1}[0-9]{4}[a-zA-z]{3}$',
            },
        ],
    },

    AS: {
        code: {
            example: '12345',
            text: '^[0-9]{5}$',
        },
    },

    AT: {
        code: {
            example: '1234',
            text: '^[0-9]{4}$',
        },
    },

    AU: {
        code: {
            example: '1234',
            text: '^[0-9]{4}$',
        },
    },

    AX: {
        code: {
            example: '22123',
            text: '^22\\d{3}$',
        },
    },

    AZ: {
        code: [
            {
                example: '1234',
                text: '^[0-9]{4}$',
            },
            {
                example: '123456',
                text: '^[0-9]{6}$',
            },
        ],
    },

    BA: {
        code: {
            example: '12345',
            text: '^[0-9]{5}$',
        },
    },

    BB: {
        code: {
            example: 'BB12345',
            text: '^(BB\\d{5})?$',
        },
    },

    BD: {
        code: {
            example: '1234',
            text: '^[0-9]{4}$',
        },
    },

    BE: {
        code: {
            example: '1234',
            text: '^[0-9]{4}$',
        },
    },

    BG: {
        code: {
            example: '1234',
            text: '^[0-9]{4}$',
        },
    },

    BH: {
        code: {
            example: '323',
            text: '^((1[0-2]|[1-9])\\d{2})?$',
        },
    },

    BM: {
        code: {
            example: 'MA 02',
            text: '^[A-Z]{2}[ ]?[A-Z0-9]{2}$',
        },
    },

    BN: {
        code: {
            example: 'AB1234',
            text: '^[a-zA-z]{2}[0-9]{4}$',
        },
    },

    BR: {
        code: [
            {
                example: '12345678',
                text: '^[0-9]{8}$',
            },
            {
                example: '12345-678',
                text: '^[0-9]{5}\\-[0-9]{3}$',
            },
        ],
    },

    BY: {
        code: {
            example: '123456',
            text: '^[0-9]{6}$',
        },
    },

    CA: {
        code: [
            {
                example: 'A1B 2C3',
                text: '^[a-zA-z]{1}[0-9]{1}[a-zA-z]{1}\\s[0-9]{1}[a-zA-z]{1}[0-9]{1}$',
            },
            {
                example: 'A1B2C3',
                text: '^[a-zA-z]{1}[0-9]{1}[a-zA-z]{1}[0-9]{1}[a-zA-z]{1}[0-9]{1}$',
            },
            {
                example: 'A1B',
                text: '^[a-zA-z]{1}[0-9]{1}[a-zA-z]{1}$',
            },
        ],
    },

    CC: {
        code: {
            example: '6799',
            text: '^6799$',
        },
    },

    CH: {
        code: {
            example: '1234',
            text: '^[0-9]{4}$',
        },
    },

    CK: {
        code: {
            example: '1234',
            text: '^\\d{4}$',
        },
    },

    CL: {
        code: {
            example: '1234567',
            text: '^\\d{7}$',
        },
    },

    CN: {
        code: {
            example: '123456',
            text: '^[0-9]{6}$',
        },
    },

    CR: {
        code: {
            example: '12345 or 123-1234',
            text: '^\\d{4,5}|\\d{3}-\\d{4}$',
        },
    },

    CS: {
        code: {
            example: '12345',
            text: '^[0-9]{5}$',
        },
    },

    CU: {
        code: {
            example: '12345',
            text: '^[0-9]{5}$',
        },
    },

    CV: {
        code: {
            example: '1234',
            text: '^\\d{4}$',
        },
    },

    CX: {
        code: {
            example: '6798',
            text: '^6798$',
        },
    },

    CY: {
        code: {
            example: '1234',
            text: '^[0-9]{4}$',
        },
    },

    CZ: {
        code: {
            example: '123 45',
            text: '^[0-9]{3}\\s[0-9]{2}$',
        },
    },

    DE: {
        code: {
            example: '12345',
            text: '^[0-9]{5}$',
        },
    },

    DK: {
        code: {
            example: '1234',
            text: '^[0-9]{4}$',
        },
    },

    DO: {
        code: {
            example: '12345',
            text: '^\\d{5}$',
        },
    },

    DZ: {
        code: {
            example: '12345',
            text: '^[0-9]{5}$',
        },
    },

    EC: {
        code: {
            example: 'A1234B or AB123456 or 123456',
            text: '^([A-Z]\\d{4}[A-Z]|(?:[A-Z]{2})?\\d{6})?$',
        },
    },

    EE: {
        code: {
            example: '12345',
            text: '^[0-9]{5}$',
        },
    },

    EG: {
        code: {
            example: '12345',
            text: '^\\d{5}$',
        },
    },

    ES: {
        code: {
            example: '12345',
            text: '^[0-9]{5}$',
        },
    },

    ET: {
        code: {
            example: '1234',
            text: '^\\d{4}$',
        },
    },

    FI: {
        code: {
            example: '12345',
            text: '^[0-9]{5}$',
        },
    },

    FK: {
        code: {
            example: 'FIQQ 1ZZ',
            text: '^FIQQ 1ZZ$',
        },
    },

    FM: {
        code: {
            example: '96941',
            text: '^(9694[1-4])([ \\-]\\d{4})?$',
        },
    },

    FO: {
        code: {
            example: '123',
            text: '^\\d{3}$',
        },
    },

    FR: {
        code: {
            example: '12345',
            text: '^[0-9]{5}$',
        },
    },

    GB: {
        code: [
            {
                example: 'AB12 3CD',
                text: '^[a-zA-Z]{2}[0-9]{2}\\s?[0-9]{1}[a-zA-Z]{2}$',
            },
            {
                example: 'A1B 2CD',
                text: '^[a-zA-Z]{1}[0-9]{1}[a-zA-Z]{1}\\s?[0-9]{1}[a-zA-Z]{2}$',
            },
            {
                example: 'AB1 2CD',
                text: '^[a-zA-Z]{2}[0-9]{1}\\s?[0-9]{1}[a-zA-Z]{2}$',
            },
            {
                example: 'AB1C 2DF',
                text: '^[a-zA-Z]{2}[0-9]{1}[a-zA-Z]{1}\\s?[0-9]{1}[a-zA-Z]{2}$',
            },
            {
                example: 'A12 3BC',
                text: '^[a-zA-Z]{1}[0-9]{2}\\s?[0-9]{1}[a-zA-Z]{2}$',
            },
            {
                example: 'A1 2BC',
                text: '^[a-zA-Z]{1}[0-9]{1}\\s?[0-9]{1}[a-zA-Z]{2}$',
            },
        ],
    },

    GE: {
        code: {
            example: '1234',
            text: '^[0-9]{4}$',
        },
    },

    GF: {
        code: {
            example: '12345',
            text: '^[0-9]{5}$',
        },
    },

    GG: {
        code: {
            example: 'AB1 2CD',
            text: '^[a-zA-Z]{2}[0-9]{1}\\s[0-9]{1}[a-zA-Z]{2}$',
        },
    },

    GH: {
        code: {
            example: 'GA18400',
            text: '^[A-Z][A-Z0-9]\\d{3,5}$',
        },
    },

    GL: {
        code: {
            example: '1234',
            text: '^[0-9]{4}$',
        },
    },

    GN: {
        code: {
            example: '123',
            text: '^\\d{3}$',
        },
    },

    GP: {
        code: {
            example: '12345',
            text: '^[0-9]{5}$',
        },
    },

    GR: {
        code: {
            example: '123 45',
            text: '^[0-9]{3}\\s[0-9]{2}$',
        },
    },

    GS: {
        code: {
            example: 'SIQQ 1ZZ',
            text: '^SIQQ 1ZZ$',
        },
    },

    GT: {
        code: {
            example: '12345',
            text: '^\\d{5}$',
        },
    },

    GU: {
        code: {
            example: '12345',
            text: '^[0-9]{5}$',
        },
    },

    GW: {
        code: {
            example: '1234',
            text: '^\\d{4}$',
        },
    },

    HM: {
        code: {
            example: '1234',
            text: '^\\d{4}$',
        },
    },

    HN: {
        code: {
            example: '12345',
            text: '^(?:\\d{5})?$',
        },
    },

    HR: {
        code: {
            example: '12345',
            text: '^[0-9]{5}$',
        },
    },

    HT: {
        code: {
            example: '1234',
            text: '^\\d{4}$',
        },
    },

    HU: {
        code: {
            example: '1234',
            text: '^[0-9]{4}$',
        },
    },

    IC: {
        code: {
            example: '12345',
            text: '^[0-9]{5}$',
        },
    },

    ID: {
        code: {
            example: '12345',
            text: '^[0-9]{5}$',
        },
    },

    IL: {
        code: {
            example: '6687865',
            text: '^[0-9]{7}$',
        },
    },

    IM: {
        code: {
            example: 'IM1 1AD',
            text: '^IM\\d[\\dA-Z]?[ ]?\\d[ABD-HJLN-UW-Z]{2}$',
        },
    },

    IN: {
        code: {
            example: '123456',
            text: '^[0-9]{6}$',
        },
    },

    IO: {
        code: {
            example: 'BBND 1ZZ',
            text: '^BBND 1ZZ$',
        },
    },

    IS: {
        code: {
            example: '123',
            text: '^[0-9]{3}$',
        },
    },

    IQ: {
        code: {
            example: '12345',
            text: '^\\d{5}$',
        },
    },

    IT: {
        code: {
            example: '12345',
            text: '^[0-9]{5}$',
        },
    },

    JE: {
        code: {
            example: 'AB1 2CD',
            text: '^[a-zA-Z]{2}[0-9]{1}\\s[0-9]{1}[a-zA-Z]{2}$',
        },
    },

    JO: {
        code: {
            example: '12345',
            text: '^\\d{5}$',
        },
    },

    JP: {
        code: [
            {
                example: '123-4567',
                text: '^[0-9]{3}-[0-9]{4}$',
            },
            {
                example: '1234567',
                text: '^[0-9]{7}$',
            },
        ],
    },

    KE: {
        code: {
            example: '12345',
            text: '^[0-9]{5}$',
        },
    },

    KG: {
        code: {
            example: '123456',
            text: '^[0-9]{6}$',
        },
    },

    KR: {
        code: [
            {
                example: '123-456',
                text: '^[0-9]{3}-[0-9]{3}$',
            },
            {
                example: '12345',
                text: '^[0-9]{5}$',
            },
        ],
    },

    KH: {
        code: {
            example: '12345',
            text: '^\\d{5}$',
        },
    },

    KW: {
        code: {
            example: '12345',
            text: '^\\d{5}$',
        },
    },

    KZ: {
        code: {
            example: '123456',
            text: '^[0-9]{6}$',
        },
    },

    LA: {
        code: {
            example: '12345',
            text: '^\\d{5}$',
        },
    },

    LB: {
        code: {
            example: '1234 5678',
            text: '^(\\d{4}([ ]?\\d{4})?)?$',
        },
    },

    LI: {
        code: {
            example: '1234',
            text: '^[0-9]{4}$',
        },
    },

    LK: {
        code: {
            example: '12345',
            text: '^\\d{5}$',
        },
    },

    LT: {
        code: {
            example: '12345',
            text: '^[0-9]{5}$',
        },
    },

    LU: {
        code: {
            example: '1234',
            text: '^[0-9]{4}$',
        },
    },

    LV: {
        code: {
            example: '1234',
            text: '^[0-9]{4}$',
        },
    },

    MA: {
        code: {
            example: '12345',
            text: '^[0-9]{5}$',
        },
    },

    MC: {
        code: {
            example: '12345',
            text: '^[0-9]{5}$',
        },
    },

    MD: {
        code: {
            example: '1234',
            text: '^[0-9]{4}$',
        },
    },

    ME: {
        code: {
            example: '81101',
            text: '^8\\d{4}$',
        },
    },

    MG: {
        code: {
            example: '123',
            text: '^[0-9]{3}$',
        },
    },

    MH: {
        code: {
            example: '12345',
            text: '^[0-9]{5}$',
        },
    },

    MK: {
        code: {
            example: '1234',
            text: '^[0-9]{4}$',
        },
    },

    MN: {
        code: {
            example: '123456',
            text: '^[0-9]{6}$',
        },
    },

    MP: {
        code: {
            example: '12345',
            text: '^[0-9]{5}$',
        },
    },

    MS: {
        code: {
            example: 'MSR1250',
            text: '^MSR\\s?\\d{4}$',
        },
    },

    MQ: {
        code: {
            example: '12345',
            text: '^[0-9]{5}$',
        },
    },

    MT: {
        code: [
            {
                example: 'ABC 1234',
                text: '^[a-zA-Z]{3}\\s[0-9]{4}$',
            },
            {
                example: 'ABC 123',
                text: '^[a-zA-Z]{3}\\s[0-9]{3}$',
            },
            {
                example: 'ABC 12',
                text: '^[a-zA-Z]{3}\\s[0-9]{2}$',
            },
        ],
    },

    MU: {
        code: {
            example: 'A1201 or 80110',
            text: '^([AR]|[0-9])\\d{4,5}$',
        },
    },

    MV: {
        code: [
            {
                example: '12345',
                text: '^[0-9]{5}$',
            },
            {
                example: '1234',
                text: '^[0-9]{4}$',
            },
        ],
    },

    MX: {
        code: {
            example: '12345',
            text: '^[0-9]{5}$',
        },
    },

    MY: {
        code: {
            example: '12345',
            text: '^[0-9]{5}$',
        },
    },

    NC: {
        code: {
            example: '98800',
            text: '^988\\d{2}$',
        },
    },

    NE: {
        code: {
            example: '1234',
            text: '^\\d{4}$',
        },
    },

    NF: {
        code: {
            example: '2899',
            text: '^2899$',
        },
    },

    NG: {
        code: {
            example: '123456',
            text: '^(\\d{6})?$',
        },
    },

    NI: {
        code: {
            example: '22500',
            text: '^\\d{5}$',
        },
    },

    NL: {
        code: {
            example: '1234 AB/1234AB',
            text: '^[1-9][0-9]{3}\\s?[a-zA-Z]{2}$',
        },
    },

    NO: {
        code: {
            example: '1234',
            text: '^[0-9]{4}$',
        },
    },

    NP: {
        code: {
            example: '12345',
            text: '^\\d{5}$',
        },
    },

    NZ: {
        code: {
            example: '1234',
            text: '^\\d{4}$',
        },
    },

    OM: {
        code: {
            example: 'PC 123 or 123',
            text: '^(PC )?\\d{3}$',
        },
    },

    PA: {
        code: {
            example: '1234',
            text: '^\\d{4}$',
        },
    },

    PF: {
        code: {
            example: '98701',
            text: '^987\\d{2}$',
        },
    },

    PG: {
        code: {
            example: '123',
            text: '^\\d{3}$',
        },
    },

    PH: {
        code: {
            example: '1234',
            text: '^[0-9]{4}$',
        },
    },

    PK: {
        code: {
            example: '12345',
            text: '^[0-9]{5}$',
        },
    },

    PL: {
        code: {
            example: '12-345',
            text: '^[0-9]{2}-[0-9]{3}$',
        },
    },

    PM: {
        code: {
            example: '97500',
            text: '^9[78]5\\d{2}$',
        },
    },

    PN: {
        code: {
            example: 'PCRN 1ZZ',
            text: '^PCRN 1ZZ$',
        },
    },

    PR: {
        code: {
            example: '12345',
            text: '^[0-9]{5}$',
        },
    },

    PT: {
        code: [
            {
                example: '1234',
                text: '^[0-9]{4}$',
            },
            {
                example: '1234-567',
                text: '^[0-9]{4}-[0-9]{3}$',
            },
        ],
    },

    PW: {
        code: {
            example: '96939 or 96940',
            text: '^(?:96939|96940)$',
        },
    },

    PY: {
        code: {
            example: '1234',
            text: '^\\d{4}$',
        },
    },

    RE: {
        code: {
            example: '12345',
            text: '^[0-9]{5}$',
        },
    },

    RO: {
        code: {
            example: '123456',
            text: '^[0-9]{6}$',
        },
    },

    RS: {
        code: {
            example: '123456',
            text: '^\\d{6}$',
        },
    },

    RU: {
        code: {
            example: '123456',
            text: '^[0-9]{6}$',
        },
    },

    SA: {
        code: {
            example: '12345',
            text: '^\\d{5}$',
        },
    },

    SE: {
        code: {
            example: '123 45',
            text: '^[0-9]{3}\\s[0-9]{2}$',
        },
    },

    SG: {
        code: {
            example: '123456',
            text: '^[0-9]{6}$',
        },
    },

    SI: {
        code: {
            example: '1234',
            text: '^[0-9]{4}$',
        },
    },

    SK: {
        code: {
            example: '123 45',
            text: '^[0-9]{3}\\s[0-9]{2}$',
        },
    },

    SH: {
        code: {
            example: 'ASCN 1ZZ',
            text: '^(ASCN|STHL) 1ZZ$',
        },
    },

    SJ: {
        code: {
            example: '1234',
            text: '^\\d{4}$',
        },
    },

    SM: {
        code: {
            example: '47890',
            text: '^4789\\d$',
        },
    },

    SN: {
        code: {
            example: '12345',
            text: '^\\d{5}$',
        },
    },

    SO: {
        code: {
            example: '12345',
            text: '^\\d{5}$',
        },
    },

    SZ: {
        code: {
            example: 'A123',
            text: '^[a-zA-Z]{1}[0-9]{3}$',
        },
    },

    TC: {
        code: {
            example: 'TKCA 1ZZ',
            text: '^TKCA 1ZZ$',
        },
    },

    TH: {
        code: {
            example: '12345',
            text: '^[0-9]{5}$',
        },
    },

    TJ: {
        code: {
            example: '123456',
            text: '^[0-9]{6}$',
        },
    },

    TM: {
        code: {
            example: '123456',
            text: '^[0-9]{6}$',
        },
    },

    TN: {
        code: {
            example: '1234',
            text: '^\\d{4}$',
        },
    },

    TR: {
        code: {
            example: '12345',
            text: '^[0-9]{5}$',
        },
    },

    TT: {
        code: {
            example: '120110',
            text: '^\\d{6}$',
        },
    },

    TW: {
        code: [
            {
                example: '12345',
                text: '^[0-9]{5}$',
            },
            {
                example: '123',
                text: '^[0-9]{3}$',
            },
        ],
    },

    UA: {
        code: {
            example: '12345',
            text: '^[0-9]{5}$',
        },
    },

    US: {
        code: [
            {
                example: '12345-6789',
                text: '^[0-9]{5}\\-[0-9]{4}$',
            },
            {
                example: '12345',
                text: '^[0-9]{5}$',
            },
        ],
    },

    UY: {
        code: {
            example: '12345',
            text: '^[0-9]{5}$',
        },
    },

    UZ: {
        code: {
            example: '123456',
            text: '^[0-9]{6}$',
        },
    },

    VA: {
        code: {
            example: '00120',
            text: '^00120$',
        },
    },

    VE: {
        code: {
            example: '1234',
            text: '^\\d{4}$',
        },
    },

    VI: {
        code: {
            example: '12345',
            text: '^[0-9]{5}$',
        },
    },

    WF: {
        code: {
            example: '98601',
            text: '^986\\d{2}$',
        },
    },

    XK: {
        code: {
            example: '12345',
            text: '^\\d{5}$',
        },
    },

    XY: {
        code: {
            example: '12345',
            text: '^[0-9]{5}$',
        },
    },

    YT: {
        code: {
            example: '97601',
            text: '^976\\d{2}$',
        },
    },

    ZA: {
        code: {
            example: '1234',
            text: '^[0-9]{4}$',
        },
    },

    ZM: {
        code: {
            example: '12345',
            text: '^\\d{5}$',
        },
    },
};

export default zipCodes;
