import ChevronIcon from 'Component/ChevronIcon';
import { BOTTOM, TOP } from 'Component/ChevronIcon/ChevronIcon.config';
import ClickOutside from 'Component/ClickOutside';
import Icon from 'Component/Icon';
import { FieldSelect as SourceFieldSelect } from 'SourceComponent/FieldSelect/FieldSelect.component';

import { PRODUCT_CATEGORY_SORT_SELECT_ID, PRODUCT_PER_PAGE_SELECT_ID } from './FieldSelect.config';

import './FieldSelect.override.style';

/** @namespace SwiatKsiazkiBasic/Component/FieldSelect/Component */
export class FieldSelect extends SourceFieldSelect {
    componentDidUpdate() {
        this.getDefaultWidthSelect();
    }

    getDefaultWidthSelect() {
        const {
            attr: { id: attrId },
            selectedOptionIndex,
            isDynamicWidth,
        } = this.props;

        if (!isDynamicWidth) {
            return;
        }

        const select = document.getElementById(attrId);
        const helperSelect = document.querySelector('.helper-element');

        if (select && helperSelect) {
            const label = select?.options[selectedOptionIndex]?.label;
            helperSelect.innerHTML = label;

            this.resizeSelect(helperSelect.offsetWidth);
        }
    }

    resizeSelect(width) {
        const { style } = document.documentElement;
        style.setProperty('--dynamic-select-width', `${width + 10}px`);
    }

    checkIfOptionActive(id) {
        const {
            attr: { id: attrId },
            sortDirection,
            sortKey,
            pageSize,
        } = this.props;

        const idArray = typeof id === 'string' ? id.split(' ') : id;

        if (attrId === PRODUCT_CATEGORY_SORT_SELECT_ID) {
            const isActive = idArray[0] === sortDirection && idArray[1] === sortKey;
            return isActive;
        }

        if (attrId === PRODUCT_PER_PAGE_SELECT_ID) {
            const isActive = Number(idArray[0]) === pageSize;
            return isActive;
        }

        return false;
    }

    renderOption(option) {
        const { id, label, subLabel, isPlaceholder = false, isHovered, isAvailable = true } = option;
        const { isExpanded, handleSelectListOptionClick } = this.props;

        const isActive = this.checkIfOptionActive(id);

        return (
            <li
                block="FieldSelect"
                elem="Option"
                mods={{
                    isDisabled: !isAvailable,
                    isExpanded,
                    isPlaceholder,
                    isHovered,
                    isActive,
                }}
                key={id}
                /**
                 * Added 'o' as querySelector does not work with
                 * ids, that consist of numbers only
                 */
                id={`o${id}`}
                role="menuitem"
                // eslint-disable-next-line react/jsx-no-bind
                onMouseDown={() => handleSelectListOptionClick(option)}
                // eslint-disable-next-line react/jsx-no-bind
                onTouchStart={() => handleSelectListOptionClick(option)}
                // eslint-disable-next-line react/jsx-no-bind
                onKeyPress={() => handleSelectListOptionClick(option)}
                tabIndex={isExpanded ? '0' : '-1'}
            >
                {isActive && (
                    <Icon block="FieldSelect" elem="Tick" name="checkmark" fill="#000" width="12" height="8" />
                )}
                {label}
                {subLabel && <strong>{` ${subLabel}`}</strong>}
            </li>
        );
    }

    renderNativeOption(option) {
        const { id, value, label, subLabel = '', isAvailable = true } = option;

        return (
            <option key={id} id={id} value={value} disabled={!isAvailable}>
                {`${label} ${subLabel}`}
            </option>
        );
    }

    render() {
        const {
            attr: { id = '' } = {},
            isExpanded,
            isDisabled,
            handleSelectExpand,
            handleSelectListKeyPress,
            handleSelectExpandedExpand,
        } = this.props;

        return (
            <ClickOutside onClick={handleSelectExpandedExpand}>
                <div
                    id={`${id}_wrapper`}
                    block="FieldSelect"
                    mods={{ isExpanded, isDisabled }}
                    onClick={handleSelectExpand}
                    onKeyPress={handleSelectListKeyPress}
                    role="button"
                    tabIndex="0"
                    aria-label={__('Select dropdown')}
                    aria-expanded={isExpanded}
                >
                    <div block="FieldSelect" elem="Clickable">
                        {this.renderSortSelect()}
                        {this.renderNativeSelect()}
                        <ChevronIcon direction={isExpanded ? TOP : BOTTOM} />
                    </div>
                    {this.renderOptions()}
                    <span className="helper-element" aria-hidden="true" />
                </div>
            </ClickOutside>
        );
    }
}

export default FieldSelect;
