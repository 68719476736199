import { PureComponent } from 'react';

import Image from 'Component/Image';
import Link from 'Component/Link';
import { decodeString } from 'Util/Common';
import { getHrefAttributes } from 'Util/MagezoneWidget';

import './BannerWidget.style';

/** @namespace SwiatKsiazkiBasic/Component/BannerWidget/Component */
export class BannerWidget extends PureComponent {
    render() {
        const { image, url, title, backgroundColor, blank, linkTitle, nofollow } = this.props;

        return (
            <div block="BannerWidget" style={{ backgroundColor }}>
                <Link
                    to={decodeString(url)}
                    title={linkTitle}
                    {...getHrefAttributes({
                        blank,
                        nofollow,
                    })}
                >
                    {image ? <Image src={image} isPlain alt={title} /> : title}
                </Link>
            </div>
        );
    }
}

export default BannerWidget;
