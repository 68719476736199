export const GET_VIEWED_PRODUCTS = 'GET_VIEWED_PRODUCTS';
export const REGISTER_VIEWED_PRODUCTS = 'REGISTER_VIEWED_PRODUCTS';

/** @namespace SwiatKsiazkiBasic/Store/LastViewedProducts/Action/getLastViewedProductsData */
export const getLastViewedProductsData = (data) => ({
    type: GET_VIEWED_PRODUCTS,
    data,
});

/** @namespace SwiatKsiazkiBasic/Store/LastViewedProducts/Action/registerToLastViewedProducts */
export const registerToLastViewedProducts = (data) => ({
    type: REGISTER_VIEWED_PRODUCTS,
    data,
});
