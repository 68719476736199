import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { showNotification } from 'Store/Notification/Notification.action';
import history from 'Util/History';
import { getQueryParam } from 'Util/Url';

/** @namespace SwiatKsiazkiBasic/Component/NotificationObserver/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message) => dispatch(showNotification(type, message)),
});
/** @namespace SwiatKsiazkiBasic/Component/NotificationObserver/Container/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace SwiatKsiazkiBasic/Component/NotificationObserver/Container */
export class NotificationObserverContainer extends PureComponent {
    componentDidMount() {
        this.getNotifiactionFromUrl();
    }

    getNotifiactionFromUrl() {
        const { showNotification } = this.props;
        const { location = {} } = history || {};

        try {
            const error = getQueryParam('error', location);
            const message = getQueryParam('message', location);

            if (error && message) {
                const type = error === '1' ? 'error' : 'success';

                showNotification(type, decodeURIComponent(message));
            }
        } catch (error) {
            console.error(error);
        }
    }

    render() {
        return null;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationObserverContainer);
