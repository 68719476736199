import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { BESTSELLER_OPTIONS } from 'Route/BestsellersPage/BestsellersPage.config';
import {
    mapDispatchToProps,
    mapStateToProps,
    ProductCardContainer as SourceProductCardContainer,
} from 'SourceComponent/ProductCard/ProductCard.container';

export { mapDispatchToProps, mapStateToProps };

/** @namespace SwiatKsiazkiBasic/Component/ProductCard/Container */
export class ProductCardContainer extends SourceProductCardContainer {
    static propTypes = {
        ...SourceProductCardContainer.propTypes,
        isPriceAlertVariant: PropTypes.bool,
        isMenuVariant: PropTypes.bool,
    };

    state = {
        ...this.state,
        isHovered: false,
        showWishlistTooltip: false,
    };

    containerFunctions = {
        ...this.containerFunctions,
        handleCardHover: this.handleCardHover.bind(this),
        handleCardLeave: this.handleCardLeave.bind(this),
        setShowWishlistTooltip: this.setShowWishlistTooltip.bind(this),
    };

    containerProps() {
        const { isHovered, showWishlistTooltip } = this.state;

        const {
            isLightVariant,
            isPromotedVariant,
            isWishlistItem,
            isWishlistShared,
            isWishlistPriceAlert,
            wishlistItemRemoveAction,
            wishlistItemChangeDescriptionAction,
            isBestsellersPage,
            isMenuVariant,
            itemIndex,
            pageSize,
            pageNumber,
        } = this.props;

        return {
            ...super.containerProps(),
            isHovered,
            isLightVariant,
            isPromotedVariant,
            isWishlistItem,
            isWishlistShared,
            isWishlistPriceAlert,
            wishlistItemChangeDescriptionAction,
            wishlistItemRemoveAction,
            isBestsellersPage,
            isMenuVariant,
            itemPosition: pageSize * (pageNumber - 1) + (itemIndex + 1),
            showBestsellersCounter: this.checkIsSortedByBestsellers(),
            showWishlistTooltip,
        };
    }

    setShowWishlistTooltip(event) {
        event.preventDefault();
        event.stopPropagation();

        const {
            device: { isMobile, isTablet },
        } = this.props;

        if (!isMobile && !isTablet) {
            return;
        }

        this.setState((prevState) => ({
            ...prevState,
            showWishlistTooltip: !prevState.showWishlistTooltip,
        }));
    }

    checkIsSortedByBestsellers() {
        const { sort } = this.props;
        const { sortKey } = sort || {};

        return BESTSELLER_OPTIONS.includes(sortKey);
    }

    handleCardHover() {
        const hoverTimeOut = 150;

        this.cardHoverTimeout = setTimeout(() => {
            this.setState({
                isHovered: true,
            });
        }, hoverTimeOut);
    }

    handleCardLeave() {
        this.setState({
            isHovered: false,
        });
        clearTimeout(this.cardHoverTimeout);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCardContainer);
