import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';

import { Overlay as SourceOverlay } from 'SourceComponent/Overlay/Overlay.component';

import './Overlay.override.style';

/** @namespace SwiatKsiazkiBasic/Component/Overlay/Component */
export class Overlay extends SourceOverlay {
    // TODO implement logic

    static propTypes = {
        ...SourceOverlay.propTypes,
        isAlwaysRenderInPortal: PropTypes.bool,
        isDisableFreeze: PropTypes.bool,
    };

    static defaultProps = {
        ...SourceOverlay.defaultProps,
        isAlwaysRenderInPortal: false,
        isDisableFreeze: false,
    };

    onVisible() {
        const { onVisible, isStatic, isMobile, isDisableFreeze } = this.props;

        if (isStatic) {
            return;
        }

        if (!isDisableFreeze && isMobile) {
            this.freezeScroll();
        }

        this.overlayRef.current.focus();
        onVisible();
    }

    onHide() {
        const { onHide, isStatic, isMobile, isDisableFreeze } = this.props;

        if (isStatic) {
            return;
        }

        if (!isDisableFreeze && isMobile) {
            this.unfreezeScroll();
        }
        onHide();
    }

    renderInPortal(content) {
        const { isStatic, isRenderInPortal, isAlwaysRenderInPortal, isMobile } = this.props;

        if (isAlwaysRenderInPortal && !isStatic) {
            return createPortal(content, document.body);
        }

        if (!isStatic && isRenderInPortal && isMobile) {
            return createPortal(content, document.body);
        }

        return content;
    }

    render() {
        const { children, mix, areOtherOverlaysOpen, isStatic } = this.props;

        const isVisible = this.getIsVisible();

        return this.renderInPortal(
            <div
                block="Overlay"
                ref={this.overlayRef}
                mods={{ isVisible, isStatic, isInstant: areOtherOverlaysOpen }}
                mix={{ ...mix, mods: { ...mix.mods, isVisible } }}
            >
                {children && children}
            </div>
        );
    }
}

export default Overlay;
