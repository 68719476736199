import Icon from 'Component/Icon';
import { ProductReviewRating as SourceProductReviewRating } from 'SourceComponent/ProductReviewRating/ProductReviewRating.component';

import { STAR_EMPTY, STAR_FULL, STAR_HALF_FULL } from './ProductReviewRating.config';

import './ProductReviewRating.override.style';

/** @namespace SwiatKsiazkiBasic/Component/ProductReviewRating/Component */
export class ProductReviewRating extends SourceProductReviewRating {
    getAriaText(code) {
        const rating = this.getRatingScore();

        return code
            ? __(`Review's ${code} rating is ${rating} out of 5`)
            : __(`Product's rating is ${rating} out of 5`);
    }

    getRatingScore(summary) {
        const ONE_FIFTH_OF_A_HUNDRED = 20;
        const rating = parseFloat(summary / ONE_FIFTH_OF_A_HUNDRED).toFixed(2);

        return rating;
    }

    renderStar(count, type) {
        return Array.from(Array(count), (_, i) => <Icon key={i} width="12" height="12" name={type} />);
    }

    render() {
        const { summary, code, placeholder, mix, count, isShort, withoutCounter } = this.props;

        const [fullCount, halfFullCount, emptyCount] = this.getStarCounts();

        const ariaText = this.getAriaText(code);

        if (placeholder) {
            return this.renderPlaceholder();
        }

        return (
            <div
                block="ProductReviewRating"
                title={`${summary}%`}
                ref={this.reviewRating}
                aria-label={ariaText}
                mix={mix}
            >
                {isShort ? (
                    <div block="ProductReviewRating" elem="Score">
                        {this.renderStar(1, STAR_FULL)}
                        <span block="ProductReviewRating" elem="ScoreValue">
                            {this.getRatingScore(summary)}
                        </span>
                    </div>
                ) : (
                    <>
                        {this.renderStar(fullCount, STAR_FULL)}
                        {this.renderStar(halfFullCount, STAR_HALF_FULL)}
                        {this.renderStar(emptyCount, STAR_EMPTY)}
                        {mix.elem !== 'RatingFilter' && !withoutCounter && (
                            <span block="ProductReviewRating" elem="Counter">
                                {`(${count})`}
                            </span>
                        )}
                    </>
                )}
            </div>
        );
    }
}

export default ProductReviewRating;
