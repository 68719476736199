import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import MyAccountQuery from 'Query/MyAccount.query';
import { showNotification } from 'Store/Notification/Notification.action';
import transformToNameValuePair from 'Util/Form/Transform';
import { fetchMutation, getErrorMessage } from 'Util/Request';

import CustomerRevokeConsent from './CustomerRevokeConsent.component';

/** @namespace SwiatKsiazkiBasic/Component/CustomerRevokeConsent/Container/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace SwiatKsiazkiBasic/Component/CustomerRevokeConsent/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message) => dispatch(showNotification(type, message)),
});

/** @namespace SwiatKsiazkiBasic/Component/CustomerRevokeConsent/Container */
export class CustomerRevokeConsentContainer extends PureComponent {
    state = {
        isLoading: false,
    };

    containerFunctions = {
        onSubmit: this.onSubmit.bind(this),
    };

    async onSubmit(form, fields) {
        const { showNotification } = this.props;

        try {
            this.setState({
                isLoading: true,
            });

            const { customerRevokeConsent: { status, message } = {} } = await fetchMutation(
                MyAccountQuery.getCustomerRevokeConsentMutation(transformToNameValuePair(fields))
            );

            if (status) {
                showNotification('success', message);
                form.reset();
            } else {
                showNotification('error', message);
            }
        } catch (error) {
            showNotification('error', getErrorMessage(error));
        } finally {
            this.setState({
                isLoading: false,
            });
        }
    }

    containerProps() {
        const { isLoading } = this.state;

        return {
            isLoading,
        };
    }

    render() {
        return <CustomerRevokeConsent {...this.containerFunctions} {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerRevokeConsentContainer);
