import { PureComponent } from 'react';

import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import Form from 'Component/Form';
import GiftCard from 'Component/GiftCard';
import Loader from 'Component/Loader';
import { VALIDATION_INPUT_TYPE } from 'Util/Validator/Config';

/** @namespace SwiatKsiazkiBasic/Component/GiftCardsCheckValidityForm/Component */
export class GiftCardsCheckValidityForm extends PureComponent {
    renderTitle() {
        return (
            <h2 block="GiftCardsCheckValidityForm" elem="TabTitle">
                {__('Check date and validity')}
            </h2>
        );
    }

    renderAdditionalInfo() {
        return (
            <p block="GiftCardsCheckValidityForm" elem="AdditionalInfo">
                {__('* Required fields')}
            </p>
        );
    }

    renderSubmitButton() {
        const { isLoading } = this.props;

        return (
            <div block="GiftCardsCheckValidityForm" elem="Buttons">
                <button
                    block="Button"
                    type="submit"
                    mix={{ block: 'GiftCardsCheckValidityForm', elem: 'CheckButton' }}
                    disabled={isLoading}
                >
                    {isLoading ? __('Checking...') : __('Look')}
                </button>
            </div>
        );
    }

    renderCheckInfo() {
        const { checkResponse: response, cardNumber } = this.props;

        if (Object.values(response).length === 0 || !cardNumber) {
            return null;
        }

        return <GiftCard response={response} card={cardNumber} />;
    }

    renderCreateCheckValidityFields() {
        return (
            <>
                <Field
                    type={FIELD_TYPE.text}
                    label={__('Card number')}
                    attr={{
                        id: 'cardNumber',
                        name: 'cardNumber',
                        placeholder: __('Card number'),
                    }}
                    validateOn={['onChange onValueChange']}
                    validationRule={{
                        inputType: VALIDATION_INPUT_TYPE.numeric,
                        isRequired: true,
                    }}
                    addRequiredTag
                />
                <Field
                    type={FIELD_TYPE.password}
                    label={__('Security code')}
                    attr={{
                        id: 'securityCode',
                        name: 'securityCode',
                        placeholder: __('Security code'),
                    }}
                    validateOn={['onChange onValueChange']}
                    validationRule={{
                        inputType: VALIDATION_INPUT_TYPE.numeric,
                        isRequired: true,
                    }}
                    addRequiredTag
                />
            </>
        );
    }

    renderCreateCheckValidityForm() {
        const { onSuccess } = this.props;

        return (
            <Form key="check-validity-card" onSubmit={onSuccess}>
                {this.renderCreateCheckValidityFields()}
                {this.renderAdditionalInfo()}
                {this.renderSubmitButton()}
            </Form>
        );
    }

    render() {
        const { isLoading } = this.props;

        return (
            <div block="GiftCardsCheckValidityForm">
                {this.renderTitle()}
                <div block="GiftCardsCheckValidityForm" elem="Wrapper">
                    <div block="GiftCardsCheckValidityForm" elem="FormWrapper">
                        {this.renderCreateCheckValidityForm()}
                    </div>
                    <div block="GiftCardsCheckValidityForm" elem="InfoWrapper">
                        <Loader isLoading={isLoading} />
                        {this.renderCheckInfo()}
                    </div>
                </div>
            </div>
        );
    }
}

export default GiftCardsCheckValidityForm;
