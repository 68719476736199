Mosaic.setPlugins([require('./plugin/CategoryReset.plugin.js'),
require('./plugin/CheckoutReducer.plugin.js'),
require('./plugin/MyAccountReducer.plugin.js'),
require('./plugin/OverlayContainer.plugin.js'),
require('./plugin/PopupComponent.plugin.js'),
require('./plugin/ProductListDispatcher.plugin.js'),
require('./plugin/ProductReset.plugin.js'),
require('./../node_modules/@scandipwa/webpack-i18n-runtime/src/plugin/App.plugin.js'),
require('./../packages/@swiat-ksiazki-basic/@scandiweb/gtm/src/plugin/config/ConfigDispatcher.plugin.js'),
require('./../packages/@swiat-ksiazki-basic/@scandiweb/gtm/src/plugin/config/ConfigQuery.plugin.js'),
require('./../packages/@swiat-ksiazki-basic/@scandiweb/gtm/src/plugin/config/ConfigReducer.plugin.js'),
require('./../packages/@swiat-ksiazki-basic/@scandiweb/gtm/src/plugin/config/MyAccountQuery.plugin.js'),
require('./../packages/@swiat-ksiazki-basic/@scandiweb/gtm/src/plugin/config/ProductListQuery.plugin.js'),
require('./../packages/@swiat-ksiazki-basic/@scandiweb/gtm/src/plugin/events/cart.plugin.js'),
require('./../packages/@swiat-ksiazki-basic/@scandiweb/gtm/src/plugin/events/checkout.plugin.js'),
require('./../packages/@swiat-ksiazki-basic/@scandiweb/gtm/src/plugin/events/errorMessage.plugin.js'),
require('./../packages/@swiat-ksiazki-basic/@scandiweb/gtm/src/plugin/events/filter.plugin.js'),
require('./../packages/@swiat-ksiazki-basic/@scandiweb/gtm/src/plugin/events/general.plugin.js'),
require('./../packages/@swiat-ksiazki-basic/@scandiweb/gtm/src/plugin/events/list.plugin.js'),
require('./../packages/@swiat-ksiazki-basic/@scandiweb/gtm/src/plugin/events/notFound.plugin.js'),
require('./../packages/@swiat-ksiazki-basic/@scandiweb/gtm/src/plugin/events/pageReload.plugin.js'),
require('./../packages/@swiat-ksiazki-basic/@scandiweb/gtm/src/plugin/events/product.plugin.js'),
require('./../packages/@swiat-ksiazki-basic/@scandiweb/gtm/src/plugin/events/search.plugin.js'),
require('./../packages/@swiat-ksiazki-basic/@scandiweb/gtm/src/plugin/events/sort.plugin.js'),
require('./../packages/@swiat-ksiazki-basic/@scandiweb/gtm/src/plugin/events/user.plugin.js'),
require('./../packages/@swiat-ksiazki-basic/@scandiweb/gtm/src/plugin/events/wishlist.plugin.js'),
require('./../packages/@swiat-ksiazki-basic/@advox/recaptcha/src/plugin/Config.plugin.js'),
require('./../packages/@swiat-ksiazki-basic/@advox/recaptcha/src/plugin/Field.plugin.js'),
require('./../packages/@swiat-ksiazki-basic/@advox/recaptcha/src/plugin/Request.plugin.js'),
require('./../packages/@swiat-ksiazki-basic/@advox/blog/src/plugin/ConfigQuery.plugin.js'),
require('./../packages/@swiat-ksiazki-basic/@advox/blog/src/plugin/HeaderComponent.plugin.js'),
require('./../packages/@swiat-ksiazki-basic/@advox/blog/src/plugin/Image.plugin.js'),
require('./../packages/@swiat-ksiazki-basic/@advox/blog/src/plugin/Router.plugin.js'),
require('./../packages/@swiat-ksiazki-basic/@advox/blog/src/plugin/StoreIndex.plugin.js'),
require('./../packages/@swiat-ksiazki-basic/@advox/slider/src/plugin/HtmlComponent.plugin.js')]);
/* eslint-disable @scandipwa/scandipwa-guidelines/export-level-one */
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import pl from 'date-fns/locale/pl';
import { registerLocale } from 'react-datepicker';
import { createRoot } from 'react-dom/client';
import { Workbox } from 'workbox-window';

import App from 'Component/App';
import { subscribeToReloadPageAfterOrder } from 'Util/BroadcastChannel';
import { isCrawler } from 'Util/Browser';
import { initializeCache } from 'Util/Cache';
import { Cookie } from 'Util/Cookie';
import ServiceWorker, { OPEN_SERVICE_WORKER_POPUP, UPDATE_SERVICE_WORKER } from 'Util/ServiceWorker';

import { SENTRY_DNS } from './global-config';

import 'Util/Polyfill';
import 'intersection-observer/intersection-observer';
import 'Style/main.scss';
import 'Style/css/mgz_bootstrap.css';
import 'Style/css/fontawesome5.css';
import 'Style/css/mgz_pagebuilder.css';
import 'Style/css/mgz_pagebuilder_custom.css';
import 'Style/css/mgz_pagebuilder_icons.css';

setTimeout(() => {
    registerLocale('pl', pl);

    subscribeToReloadPageAfterOrder();

    Cookie.initializeCookie();

    initializeCache('block');
}, 0);

const render = createRoot(document.getElementById('root'));

if (process.env.NODE_ENV === 'production' && !isCrawler()) {
    Sentry.init({
        debug: process.env.NODE_ENV !== 'production',
        dsn: SENTRY_DNS,
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 1.0,
        beforeSend(event, hint) {
            const error = hint.originalException;

            if (
                error?.message?.match(
                    /(NetworkError|Fetch Error|XHR Error|adsbygoogle|Failed to fetch|Load failed|Loading chunk|Cannot read properties of undefined \(reading 'getElementsByClassName'\)|undefined is not an object \(evaluating 'document\.getElementsByClassName\("ProductActionsOne-Actions"\)\[0\]\.getElementsByClassName'\)|Cannot read properties of null \(reading 'style'\))/g
                )
            ) {
                return null;
            }

            return event;
        },
    });

    if ('serviceWorker' in navigator) {
        const wb = new Workbox('/service-worker.js');

        wb.addEventListener('waiting', () => {
            ServiceWorker.notify(OPEN_SERVICE_WORKER_POPUP);
            ServiceWorker.subscribe(UPDATE_SERVICE_WORKER, () => {
                wb.addEventListener('controlling', () => {
                    window.location.reload();
                });
                wb.messageSkipWaiting();

                setTimeout(() => {
                    window.location.reload();
                }, 5000);
            });
        });

        wb.register();
    }

    render.render(
        <Sentry.ErrorBoundary fallback={__('An error has occurred')}>
            <App />
        </Sentry.ErrorBoundary>
    );
} else {
    render.render(<App />);
}
