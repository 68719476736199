export const BESTSELLERS_PAGE_URL = __('url:bestsellers');
export const BESTSELLERS_FILTER = { is_bestseller_all: ['1'] };
export const BESTSELLER_WEEKLY = 'bestseller_weekly';
export const BESTSELLER_MONTHLY = 'bestseller_monthly';
export const BESTSELLER_YEARLY = 'bestseller_yearly';
export const BESTSELLER_PREVIOUS_YEARLY = 'bestseller_previous_yearly';
export const BESTSELLER_OPTIONS = [
    BESTSELLER_WEEKLY,
    BESTSELLER_MONTHLY,
    BESTSELLER_YEARLY,
    BESTSELLER_PREVIOUS_YEARLY,
];
