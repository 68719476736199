import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import SlickSlider from 'react-slick';

import BannerWidget from 'Component/BannerWidget';
import HeadingWidget from 'Component/HeadingWidget';
import Icon from 'Component/Icon';
import ProductCard from 'Component/ProductCard';
import { GRID_LAYOUT } from 'Route/CategoryPage/CategoryPage.config';

import { PRODUCT_SLIDER_LANDING_SETTINGS, PRODUCT_SLIDER_SETTINGS } from './ProductSlider.config';

import './slick';
import './ProductSlider.style';

/** @namespace SwiatKsiazkiBasic/Component/ProductSlider/Component/NextArrow */
export const NextArrow = ({ className, style, onClick }) => (
    <div className={className} style={{ ...style }} onClick={onClick} aria-hidden>
        <Icon name="slider_arrow_right" />
    </div>
);

/** @namespace SwiatKsiazkiBasic/Component/ProductSlider/Component/PrevArrow */
export const PrevArrow = ({ className, style, onClick }) => (
    <div className={className} style={{ ...style }} onClick={onClick} aria-hidden>
        <Icon name="slider_arrow_left" />
    </div>
);

/** @namespace SwiatKsiazkiBasic/Component/ProductSlider/Component */
export class ProductSlider extends PureComponent {
    static propTypes = {
        items: PropTypes.array,
        settings: PropTypes.object,
        headingSide: PropTypes.oneOf(['center', 'left', 'right']),
        isLoading: PropTypes.bool,
        isDoubleSlider: PropTypes.bool,
        isAlternativeArrow: PropTypes.bool,
    };

    static defaultProps = {
        items: [],
        settings: PRODUCT_SLIDER_SETTINGS,
        headingSide: 'center',
        isLoading: false,
        isDoubleSlider: false,
        isAlternativeArrow: false,
    };

    __construct(props) {
        super.__construct(props);
        this.handleNext = this.handleNext.bind(this);
        this.handlePrevious = this.handlePrevious.bind(this);
    }

    handleNext() {
        this.slider.slickNext();
    }

    handlePrevious() {
        this.slider.slickPrev();
    }

    handleAfterChange() {
        const activeSlides = Array.from(this.slider?.innerSlider?.list?.querySelectorAll('.slick-active') || []);

        if (activeSlides[activeSlides.length - 1]) {
            activeSlides[activeSlides.length - 1].classList.add('slick-active-last');
        }
    }

    renderSliderItem(product, i) {
        const { isLightVariant, isMenuVariant, isPromotedVariant } = this.props;

        return (
            <ProductCard
                product={product}
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                selectedFilters={{}}
                layout={GRID_LAYOUT}
                isLightVariant={isLightVariant}
                isMenuVariant={isMenuVariant}
                isPromotedVariant={isPromotedVariant}
            />
        );
    }

    render() {
        // eslint-disable next-line
        let { settings } = this.props;
        const {
            items,
            isLoading,
            heading = '',
            headingHtml,
            isLightVariant,
            isPromotedVariant,
            isDoubleSlider,
            classVariant,
            isAlternativeArrow,
            mix,
            isBanerVariant,
            image,
            url,
        } = this.props;
        const { slidesToShow } = settings;

        if (classVariant === 'category-landing') {
            settings = PRODUCT_SLIDER_LANDING_SETTINGS;
        }

        settings.nextArrow = <NextArrow />;
        settings.prevArrow = <PrevArrow />;

        // prevent infinite issue when we don't have enough slides
        // (slick would copy and collapse them to next row)
        const notEnoughItemsLength = Object.values(items).length < slidesToShow;

        if (notEnoughItemsLength) {
            settings.infinite = false;
        }

        if (isDoubleSlider) {
            settings.rows = 2;
        } else {
            settings.rows = 1;
        }

        if (isAlternativeArrow) {
            settings.arrows = false;
        }

        const banerProps = {
            image,
            url,
        };

        if (isLoading) {
            return (
                <div
                    block="ProductSlider"
                    elem="Placeholder"
                    mods={{
                        isLightVariant: !!isLightVariant,
                        isPromotedVariant: !!isPromotedVariant,
                        isDoubleSlider: !!isDoubleSlider,
                        notEnoughItemsLength,
                    }}
                />
            );
        }

        if (!items || items?.length === 0) {
            return (
                <div
                    block="ProductSlider"
                    mods={{
                        isLightVariant: !!isLightVariant,
                        isPromotedVariant: !!isPromotedVariant,
                        isDoubleSlider: !!isDoubleSlider,
                        notEnoughItemsLength,
                    }}
                    mix={mix}
                />
            );
        }

        if (isBanerVariant) {
            return (
                <div
                    block="ProductSlider"
                    mods={{
                        isLightVariant: !!isLightVariant,
                        isPromotedVariant: !!isPromotedVariant,
                        isDoubleSlider: !!isDoubleSlider,
                        notEnoughItemsLength,
                    }}
                    mix={mix}
                >
                    {headingHtml ? <HeadingWidget html={headingHtml} /> : null}
                    {heading ? <HeadingWidget text={heading} /> : null}
                    <div block="ProductSlider" elem="Container">
                        <BannerWidget {...banerProps} />
                        <div
                            block="ProductSlider"
                            elem="Content"
                            mods={(slidesToShow ? { slidesToShow } : { slidesToShow: 5 }, isBanerVariant)}
                        >
                            <SlickSlider {...settings}>
                                {items?.length > 0 ? items.map(this.renderSliderItem.bind(this)) : null}
                            </SlickSlider>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div
                block="ProductSlider"
                mods={{
                    isLightVariant: !!isLightVariant,
                    isDoubleSlider: !!isDoubleSlider,
                    isPromotedVariant: !!isPromotedVariant,
                    notEnoughItemsLength,
                }}
                mix={mix}
            >
                <div block="ProductSlider" elem="HeadingWrapper">
                    {headingHtml ? <HeadingWidget html={headingHtml} /> : null}
                    {heading ? <HeadingWidget text={heading} /> : null}
                    {isAlternativeArrow ? (
                        <div block="ProductSlider" elem="Navigation">
                            <PrevArrow onClick={this.handlePrevious} />
                            <NextArrow onClick={this.handleNext} />
                        </div>
                    ) : null}
                </div>
                <div block="ProductSlider" elem="Content" mods={slidesToShow ? { slidesToShow } : { slidesToShow: 5 }}>
                    {/* eslint-disable no-return-assign */}
                    <SlickSlider
                        {...settings}
                        ref={(slider) => (this.slider = slider)}
                        onReInit={this.handleAfterChange.bind(this)}
                        afterChange={this.handleAfterChange.bind(this)}
                    >
                        {items?.length > 0 ? items.map(this.renderSliderItem.bind(this)) : null}
                    </SlickSlider>
                </div>
            </div>
        );
    }
}

export default ProductSlider;
