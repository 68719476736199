import { ORDER_DETAILS } from 'Route/Checkout/Checkout.config';
import { GUEST_QUOTE_ID } from 'SourceUtil/Cart/Token';
import { isSignedIn } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';

export * from 'SourceUtil/Cart/Token';

/** @namespace SwiatKsiazkiBasic/Util/Cart/Token/getGuestQuoteId */
export const getGuestQuoteId = (session = false) => {
    if (session) {
        try {
            return JSON.parse(sessionStorage.getItem(ORDER_DETAILS) ?? {})?.totals?.id ?? null;
        } catch {
            return null;
        }
    }

    const { token, isCustomerToken } = BrowserDatabase.getItem(GUEST_QUOTE_ID) || {};

    if (isCustomerToken && !isSignedIn()) {
        return null;
    }

    return token;
};
