import { ConfigQuery as SourceConfigQuery } from 'SourceQuery/Config.query';
import { Field } from 'Util/Query';

/** @namespace SwiatKsiazkiBasic/Query/Config/Query */
export class ConfigQuery extends SourceConfigQuery {
    _getPerPageFields() {
        return ['grid_per_page_values', 'grid_per_page', 'list_per_page_values', 'list_per_page'];
    }

    _getAdvoxFreeDeliveryFields() {
        return [
            'advox_free_delivery_enabled',
            'advox_free_delivery_only_for_specific_customer_groups',
            'advox_free_delivery_allowed_countries',
            'advox_free_delivery_minimum_order_amount',
            'advox_free_delivery_shipping_methods',
            'advox_free_delivery_use_subtotal_with_discount',
            'advox_free_delivery_show_free_delivery_message',
            'advox_free_delivery_free_delivery_message',
            'advox_free_delivery_show_countdown',
            'advox_free_delivery_message_before_countdown',
            'advox_free_delivery_free_delivery_available_message',
            'advox_free_delivery_exclude_from_free_delivery_enable',
            'advox_free_delivery_exclude_from_free_delivery_message',
        ];
    }

    _getProductBannersFields() {
        return [
            'is_top_banners_enabled',
            'is_after_banners_enabled',
            'is_product_label_banners_enabled',
            'is_one_banners_enabled',
        ];
    }

    _getProductPackageFields() {
        return [
            'swiatksiazki_is_package_hide',
            'swiatksiazki_no_package_set_as_default',
            'swiatksiazki_is_package_required',
        ];
    }

    _getEdroneFields() {
        return ['edrone_app_id', 'edrone_add_to_cart'];
    }

    _getClickonometricsFields() {
        return ['clickonometrics_tracking_general_enabled'];
    }

    _getCeneoFields() {
        return [
            'ceneo_trusted_reviews_status',
            'ceneo_trusted_reviews_guid',
            'ceneo_trusted_reviews_work_days_to_send_questionnaire',
        ];
    }

    _getBuyBoxFields() {
        return ['buybox_is_available', 'buybox_shop_id', 'buybox_commission_id'];
    }

    _getGiftcardCardsOffer() {
        return ['giftcard_cards_offer_delivery_cost', 'giftcard_cards_offer_available_card_values'];
    }

    getQuery() {
        return new Field('storeConfig')
            .addFieldList(this._getStoreConfigFields())
            .addFieldList(this._getPerPageFields())
            .addFieldList(this._getProductPackageFields())
            .addFieldList(this._getEdroneFields())
            .addFieldList(this._getClickonometricsFields())
            .addFieldList(this._getAdvoxFreeDeliveryFields())
            .addFieldList(this._getProductBannersFields())
            .addFieldList(this._getCeneoFields())
            .addFieldList(this._getBuyBoxFields())
            .addFieldList(this._getGiftcardCardsOffer())
            .addFieldList([
                'trans_email_ident_support_email',
                'general_store_information_phone',
                'catalog_default_sort_by',
                'head_meta_store_name',
            ]);
    }

    _getStoreConfigFields() {
        return [
            ...super._getStoreConfigFields(),
            'cms_pagebuilder_google_maps_api_key',
            'mfblog_post_view_comments_type',
        ];
    }

    getCheckoutAgreements(sectionName) {
        const fields = new Field('checkoutAgreements').addFieldList(this._getCheckoutAgreementFields());

        if (sectionName) {
            fields.addArgument('sectionName', 'String', sectionName);
        }

        return fields;
    }

    _getCheckoutAgreementFields() {
        return [...super._getCheckoutAgreementFields(), 'show_in_checkout', 'is_required'];
    }
}

export default new ConfigQuery();
