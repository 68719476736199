import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Loader from 'Component/Loader';
import Map from 'Component/Map';
import { LocationsType } from 'Type/Locations.type';

import './BookstoreLocations.style';

/** @namespace SwiatKsiazkiBasic/Component/BookstoreLocations/Component */
export class BookstoreLocations extends PureComponent {
    static propTypes = {
        locations: LocationsType,
        googleMapsApiKey: PropTypes.string,
        isLoading: PropTypes.bool,
    };

    render() {
        const { locations, googleMapsApiKey, isLoading } = this.props;

        return (
            <div block="BookstoreLocations">
                {isLoading && <Loader isLoading={isLoading} />}
                <Map defaultLocations={locations} googleMapsApiKey={googleMapsApiKey} isSidebar />
            </div>
        );
    }
}

export default BookstoreLocations;
