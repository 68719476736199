import { styled, Tooltip as MUITooltip, tooltipClasses } from '@mui/material';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { TooltipPlacement } from '../../type/Tooltip.type';

/** @namespace SwiatKsiazkiBasic/Component/Tooltip/Component */
export class Tooltip extends PureComponent {
    static propTypes = {
        tooltipText: PropTypes.oneOf(PropTypes.oneOfType([PropTypes.string, PropTypes.node])),
        children: PropTypes.node,
        placement: TooltipPlacement,
        styles: PropTypes.object,
        open: PropTypes.bool,
    };

    renderTooltip() {
        const { tooltipText, children, placement, styles, enterTouchDelay = 700, ...props } = this.props;

        const LightTooltip = styled(({ className, ...props }) => (
            <MUITooltip {...props} classes={{ popper: className }} />
        ))(({ theme }) => ({
            [`& .${tooltipClasses.tooltip}`]: {
                backgroundColor: theme.palette.common.white,
                color: 'rgba(0, 0, 0, 0.87)',
                boxShadow: theme.shadows[1],
                fontSize: 12,
                borderRadius: 0,
                padding: 15,
                lineHeight: '18px',
                ...styles,
            },
        }));

        return (
            <LightTooltip title={tooltipText} placement={placement} enterTouchDelay={enterTouchDelay} {...props}>
                <div block="Tooltip">{children}</div>
            </LightTooltip>
        );
    }

    render() {
        return this.renderTooltip();
    }
}

export default Tooltip;
