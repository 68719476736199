import { FieldGroup as SourceFieldGroup } from 'SourceComponent/FieldGroup/FieldGroup.component';

/**
 * Field Group
 * @class FieldGroup
 * @namespace SwiatKsiazkiBasic/Component/FieldGroup/Component */
export class FieldGroup extends SourceFieldGroup {
    renderLabel() {
        const { label } = this.props;

        if (!label) {
            return null;
        }

        return label;
    }

    renderSubLabel() {
        const { subLabel } = this.props;

        if (!subLabel) {
            return null;
        }

        return subLabel;
    }

    render() {
        const { validationResponse, children, setRef, attr, events, mods, mix } = this.props;

        return (
            <div block="FieldGroup" elem="Wrapper" mods={mods} mix={mix}>
                {this.renderLabel()}
                <div
                    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                    {...attr}
                    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                    {...events}
                    ref={(elem) => setRef(elem)}
                    block="FieldGroup"
                    mods={{
                        isValid: validationResponse === true,
                        hasError: validationResponse !== true && Object.keys(validationResponse || {}).length !== 0,
                    }}
                >
                    {children}
                </div>
                {this.renderErrorMessages()}
                {this.renderSubLabel()}
            </div>
        );
    }
}

export default FieldGroup;
