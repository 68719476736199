import PropTypes from 'prop-types';

import FIELD_TYPE from 'Component/Field/Field.config';
import { FieldGroupContainer as SourceFieldGroupContainer } from 'SourceComponent/FieldGroup/FieldGroup.container';
import { MixType } from 'Type/Common.type';
import { noopFn } from 'Util/Common';
import getFieldsData from 'Util/Form/Extract';
import { validateGroup } from 'Util/Validator';

/** @namespace SwiatKsiazkiBasic/Component/FieldGroup/Container */
export class FieldGroupContainer extends SourceFieldGroupContainer {
    static propTypes = {
        ...SourceFieldGroupContainer.propTypes,
        onError: PropTypes.func,
        mix: MixType,
    };

    static defaultProps = {
        ...SourceFieldGroupContainer.defaultProps,
        onError: noopFn,
        mix: {},
    };

    validate(data) {
        const { validationRule, onError } = this.props;
        const output = validateGroup(this.groupRef, validationRule);

        // If validation is called from different object you can pass object
        // to store validation error values
        if (data && data.detail && output !== true) {
            if (!data.detail.errors) {
                // eslint-disable-next-line no-param-reassign
                data.detail.errors = [];
            }
            data.detail.errors.push(output);
        }

        if (output !== true) {
            if (typeof onError === 'function') {
                onError(output, this.groupRef);
            }
        }

        this.setState({ validationResponse: output });

        return output;
    }

    validateOnEvent(hook, event, ...args) {
        const validateResponse = this.validate();

        if (typeof hook === 'function') {
            this.surroundEvent(hook, event, validateResponse, ...args);
        }
    }

    surroundEvent(hook, event, validateResponse, ...args) {
        const { attr } = this.props;
        const { validationResponse } = this.state;
        const fields = getFieldsData(this.groupRef, false, [FIELD_TYPE.number, FIELD_TYPE.button]);

        hook(...[event, ...args, { ...attr, formRef: this.groupRef, fields, validationResponse, validateResponse }]);
    }

    containerProps() {
        const { mix } = this.props;

        return {
            ...super.containerProps(),
            mix,
        };
    }
}

export default FieldGroupContainer;
