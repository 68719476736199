import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import SlickSlider from 'react-slick';

import Image from 'Component/Image';
import Link from 'Component/Link';
import { decodeString } from 'Util/Common';
import { parseJSON } from 'Util/Data';
import { inRange } from 'Util/Date';
import { getHrefAttributes, getSliderSettings } from 'Util/MagezoneWidget';

import { DEFAULT_SLICK_SETTINGS } from './CmsSlider.config';

import './CmsSlider.style';

/** @namespace SwiatKsiazkiBasic/Component/CmsSlider/Component */
export class CmsSlider extends PureComponent {
    static propTypes = {
        slides: PropTypes.object.isRequired,
        options: PropTypes.string,
        settings: PropTypes.object,
    };

    static defaultProps = {
        settings: {},
    };

    renderContent(slide) {
        const { url } = slide;

        if (!url) {
            return null;
        }

        return (
            <div block="Slider" elem="Content">
                <Link block="Slider" elem="Button" mix={{ block: 'Button' }} to={url} />
            </div>
        );
    }

    renderImage(slide) {
        const { image, href = '', blank, nofollow, title } = slide;

        return (
            <picture block="Slider" elem="Image">
                <Link
                    to={decodeString(href)}
                    nofollow={nofollow}
                    {...getHrefAttributes({ blank, nofollow })}
                    title={title}
                >
                    <Image src={image} alt="image" isPlain />
                </Link>
            </picture>
        );
    }

    renderSliderSlides() {
        const { slides } = this.props;
        try {
            return parseJSON(slides, [])
                .filter(({ image }) => image)
                .filter(({ start_date, end_date }) => inRange(start_date, end_date))
                .map((slide, index) => <div key={`slider${index}`}>{this.renderImage(slide)}</div>);
        } catch {
            return [];
        }
    }

    render() {
        const { slides, options, settings } = this.props;

        if (!slides) {
            return null;
        }

        return (
            <div block="Slider" elem="SliderContainer" mix={{ block: 'CmsSlider' }}>
                <SlickSlider {...getSliderSettings(options, settings, DEFAULT_SLICK_SETTINGS)}>
                    {this.renderSliderSlides()}
                </SlickSlider>
            </div>
        );
    }
}

export default CmsSlider;
