import PropTypes from 'prop-types';

import Notification from 'Component/Notification';
import { NotificationList as SourceNotificationList } from 'SourceComponent/NotificationList/NotificationList.component';
import { NotificationListType } from 'Type/NotificationList.type';

import './NotificationList.style';

/** @namespace SwiatKsiazkiBasic/Component/NotificationList/Component */
export class NotificationList extends SourceNotificationList {
    static propTypes = {
        notifications: NotificationListType.isRequired,
        onHideNotification: PropTypes.func.isRequired,
    };

    render() {
        const { onHideNotification, notifications } = this.props;

        return (
            <div block="NotificationList">
                {Object.keys(notifications).map((id) => (
                    <Notification
                        key={id}
                        notificationId={id}
                        notification={notifications[id]}
                        onHideNotification={onHideNotification}
                        lifeTime={notifications[id]?.lifeTime}
                    />
                ))}
            </div>
        );
    }
}

export default NotificationList;
