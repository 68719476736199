import omit from 'lodash/omit';

import {
    FILTERS_WITH_SEARCH_REQUEST,
    RATINGS_SUMMARY_FILTER_ID,
} from 'Component/ProductAttributeValue/ProductAttributeValue.config';
import { PRODUCT_REVIEWS_PER_PAGE } from 'Component/ProductReviews/ProductReviews.config';
import { SORT_DIRECTION_TYPE } from 'Route/CategoryPage/CategoryPage.config';
import { NONE_SORT_OPTION_VALUE } from 'Route/SearchPage/SearchPage.config';
import { ProductListQuery as SourceProductListQuery } from 'SourceQuery/ProductList.query';
import { CUSTOMER } from 'Store/MyAccount/MyAccount.dispatcher';
import BrowserDatabase from 'Util/BrowserDatabase';
import { encodeString } from 'Util/Common';
import { Field } from 'Util/Query';

/** @namespace SwiatKsiazkiBasic/Query/ProductList/Query */
export class ProductListQuery extends SourceProductListQuery {
    getQuery(options) {
        if (!options) {
            throw new Error('Missing argument `options`');
        }

        this.options = options;

        const { withOutOfStock = false } = options;

        if (withOutOfStock) {
            return this._getProductWithOutOfStockField();
        }

        return this._getProductsField();
    }

    getQueryArguments(options) {
        this.options = options;

        return this._getProductArguments();
    }

    _getArgumentsMap() {
        const { requireInfo } = this.options;
        const filterArgumentMap = this._getFilterArgumentMap();

        return {
            currentPage: { type: 'Int!' },
            pageSize: {
                type: 'Int!',
                handler: (option) => (requireInfo ? 1 : option),
            },
            search: {
                type: 'String!',
                handler: (option) => option.replace(/\+/g, ' '),
            },
            sort: {
                type: 'ProductAttributeSortInput!',
                handler: ({ sortKey, sortDirection }) => {
                    if (sortKey === NONE_SORT_OPTION_VALUE) {
                        return {};
                    }

                    return { [sortKey]: sortDirection || SORT_DIRECTION_TYPE.asc };
                },
            },
            filter: {
                type: 'ProductAttributeFilterInput!',
                handler: (initialOptions = {}) => {
                    // add customer group by default to all requests
                    const { group_id } = BrowserDatabase.getItem(CUSTOMER) || {};

                    const options = {
                        ...initialOptions,
                        customerGroupId: group_id || '0',
                    };

                    function prepareFilterValues(options, values) {
                        return values.reduce((acc, [key, value]) => {
                            if (key in options) {
                                return {
                                    ...acc,
                                    [key]: {
                                        ...options[key],
                                        ...value,
                                    },
                                };
                            }

                            return {
                                ...acc,
                                [key]: value,
                            };
                        }, {});
                    }

                    return Object.entries(options).reduce((acc, [key, option]) => {
                        // if there is no value, or if the key is just not present in options object
                        if (
                            filterArgumentMap[key] &&
                            (key === 'customerGroupId' || (option && option !== 0 && option !== '0'))
                        ) {
                            return {
                                ...acc,
                                ...prepareFilterValues(acc, Object.entries(filterArgumentMap[key](option))),
                            };
                        }

                        return acc;
                    }, {});
                },
            },
        };
    }

    _getFilterArgumentMap() {
        return {
            categoryIds: (id) => ({ category_id: { eq: id } }),
            categoryUrlPath: (url) => ({ category_url_path: { eq: url } }),
            priceRange: ({ min, max }) => {
                const price = {};

                if (min) {
                    price.from = min;
                }

                if (max) {
                    price.to = max;
                }

                return { price };
            },
            productsSkuArray: (sku) => ({ sku: { in: sku } }),
            productSKU: (sku) => ({ sku: { eq: sku } }),
            productID: (id) => ({ id: { eq: id } }),
            productUrlPath: (url) => ({ url_key: { eq: url } }),
            customFilters: this._getCustomFilters.bind(this),
            newToDate: (date) => ({ news_to_date: { gteq: date } }),
            conditions: (conditions) => ({ conditions: { eq: encodeURIComponent(conditions) } }),
            customerGroupId: (id) => ({ customer_group_id: { eq: id } }),
            series: (series) => ({ series_ms: { in: series } }),
            sets: (sets) => ({ sale_set: { eq: sets } }),
        };
    }

    _getProductWithOutOfStockField() {
        const { args: { filter: { productSKU } = {} } = {} } = this.options;

        return new Field('getProduct')
            .addArgument('sku', 'String!', productSKU)
            .addFieldList(this._getProductFields())
            .setAlias('products');
    }

    _getSortFields() {
        return ['default', this._getSortOptionsField()];
    }

    getEanProductField() {
        return new Field('ean');
    }

    getProductTypeLabel() {
        return new Field('type_label');
    }

    getDictionaryLabel() {
        return new Field('dictionary_label').addFieldList(['url', 'name', 'type']);
    }

    getSampleFilesProductField() {
        return new Field('sample_files').addFieldList(['format', 'name', 'path']);
    }

    _getOtherProductsAndEditionsField() {
        return new Field('items').addField(this._getOtherProductsAndEditionsFields());
    }

    _getOtherProductsAndEditionsFields() {
        return new Field('product_related_lists').addFieldList(['heading', 'products_sku']);
    }

    getTrackFilesProductField() {
        return new Field('track_files').addFieldList(['format', 'name', 'path', 'is_file_exist']);
    }

    _getProductFields() {
        const { requireInfo, isSingleProduct, notRequireInfo, otherProductsAndEditions } = this.options;

        if (otherProductsAndEditions) {
            return [this._getOtherProductsAndEditionsField()];
        }

        // do not request total count for PDP
        if (isSingleProduct || notRequireInfo) {
            return [this._getItemsField()];
        }

        // for filters only request
        if (requireInfo) {
            return [this._getSortField(), this._getAggregationsField()];
        }

        return ['total_count', this._getItemsField(), this._getPageInfoField()];
    }

    getAdditionalAttributesField() {
        return ['url', 'name'];
    }

    getAdditionalAttributesFields() {
        const fields = [
            'authors',
            'directors',
            'actors',
            'performers',
            'lectors',
            'publishers',
            'composers',
            'producers',
            'series',
            'conductors',
            'translators',
        ];

        return fields.map((field) => new Field(field).addFieldList(this.getAdditionalAttributesField()));
    }

    getAdditionalAttributes() {
        return new Field('dictionary').addFieldList(this.getAdditionalAttributesFields());
    }

    _getCustomFilters(filters = {}) {
        return Object.entries(filters).reduce((acc, [key, attribute]) => {
            if (!attribute.length) {
                return acc;
            }

            if (key === 'price') {
                return {
                    ...acc,
                    ...this._getPriceFilter(key, attribute),
                };
            }

            if (key === 'release_date') {
                return {
                    ...acc,
                    ...this._getReleaseDateFilter(key, attribute),
                };
            }

            if (key === RATINGS_SUMMARY_FILTER_ID) {
                const [ratingValue] = attribute;

                return {
                    ...acc,
                    [key]: { in: ratingValue },
                };
            }

            return {
                ...acc,
                [key]: {
                    in: attribute.map((value) => encodeString(value)),
                },
            };
        }, {});
    }

    _getReleaseDateFilter(key, value) {
        const [from, to] = value[0].split('|');

        if (from === '*') {
            return { [key]: { to } };
        }

        if (to === '*') {
            return { [key]: { from } };
        }

        return {
            [key]: { from, to },
        };
    }

    _getReviewsField(pageSize) {
        const { reviewsPageSize } = this.options;

        return new Field('reviews')
            .addArgument('pageSize', 'Int', pageSize || reviewsPageSize || PRODUCT_REVIEWS_PER_PAGE)
            .addArgument('currentPage', 'Int', 1)
            .addFieldList(this._getReviewsFields());
    }

    _getRatingsItemFields() {
        return ['value', 'value_percent', 'count', 'count_percent'];
    }

    _getRatingsFields() {
        return [new Field('items').addFieldList(this._getRatingsItemFields()), 'total_count'];
    }

    _getRatingCount() {
        return new Field('rating_counts').addFieldList(this._getRatingsFields());
    }

    getRatingsUsefullnessFields() {
        return ['helpful', 'unhelpful'];
    }

    getRatingsUsefullnessField() {
        return new Field('usefulness').addFieldList(this.getRatingsUsefullnessFields());
    }

    _getReviewItemsFields() {
        return [
            'average_rating',
            'nickname',
            'status',
            new Field('summary').setAlias('title'),
            new Field('text').setAlias('detail'),
            'created_at',
            'review_id',
            this._getRatingsBreakdownField(),
            this.getRatingsUsefullnessField(),
        ];
    }

    _getProductArguments() {
        const { args } = this.options;
        const argumentMap = this._getArgumentsMap();

        return Object.entries(args).reduce((acc, [key, arg]) => {
            if (!arg) {
                return acc;
            }

            const { type, handler = (option) => option } = argumentMap[key];
            return [...acc, [key, type, handler(arg)]];
        }, []);
    }

    _getItemsField() {
        const { isSingleProduct } = this.options;

        const items = new Field('items')
            .addFieldList(this._getProductInterfaceFields())
            .addField(this.getEanProductField())
            .addField(this.getProductTypeLabel())
            .addField(this.getDictionaryLabel())
            .addField(this.getCodazonLabels())
            .addField(this._getRatingCount())
            .addField(this.getAvailabilityDescriptionFields())
            .addField(this.getIsVirtualField())
            .addField(this.getSampleFilesProductField())
            .addField(this.getTrackFilesProductField())
            .addField(this.getAdditionalAttributes())
            .addField(this.getIsPreorderField())
            .addField(this.getProductOtherEdition())
            .addField(this.getIsReprintField())
            .addFieldList(this._getOmnibusPriceFields());

        if (isSingleProduct) {
            items.addFieldList(this._getUOMFields());
            items.addField(this.getTaxVatProductField());
        } else {
            items.addField(this._getDownloadableProductLinksRequired());
        }

        return items;
    }

    getProductOtherEdition() {
        return new Field('product_other_edition').addFieldList(['type', 'name']).addField(this.getVersionsField());
    }

    getVersionsField() {
        return new Field('versions').addFieldList(['name', 'url']).addField(this.getPriceField());
    }

    getPriceField() {
        return new Field('price').addFieldList(['value', 'currency']);
    }

    getProductCategories() {
        return new Field('categories').addFieldList(['id']);
    }

    getCodazonLabels() {
        return new Field('codazon_labels').addFieldList(['content', 'custom_class', 'custom_css', 'label_link']);
    }

    getAvailabilityDescriptionFields() {
        return new Field('availability_description').addFieldList([
            'availability_description',
            'availability_status_text',
            'availability_preword',
        ]);
    }

    getIsVirtualField() {
        return new Field('is_virtual');
    }

    getIsPreorderField() {
        return new Field('is_preorder');
    }

    getIsReprintField() {
        return new Field('is_reprint');
    }

    getTaxVatProductField() {
        return new Field('tax_vat');
    }

    _getProductInterfaceFields(isVariant, isForLinkedProducts = false, isForWishlist = false) {
        const {
            isPlp = false,
            isSingleProduct,
            noAttributes = false,
            noVariants = false,
            noVariantAttributes = false,
        } = this.options;

        // set option to always request images for product variants if they're requested for wishlist
        if (isForWishlist) {
            this.options.isForWishlist = true;
        }

        // Basic fields returned always
        const fields = [
            'uid',
            'id',
            'sku',
            'name',
            'type_id',
            'stock_status',
            'availability',
            'availability_status',
            'salable_qty',
            'attribute_set_name',
            'is_suggested_price',
            'is_starting_price',
            this._getStockItemField(),
            this._getPriceRangeField(),
            this._getBestsellerProductCategoriesField(),
            this.getIsReprintField(),
            this.getIsPreorderField(),
            this.getDictionaryLabel(),
            this.getAdditionalAttributes(),
            ...this._getOmnibusPriceFields(),
        ];

        if (isForWishlist) {
            fields.push(this.getProductCategories(), this.getCodazonLabels(), this.getAvailabilityDescriptionFields());
        }

        // Additional fields, which we want to return always, except when it's variants on PLP (due to hugh number of items)
        if (!(isPlp && isVariant) || isForWishlist) {
            fields.push(
                this._getProductImageField(),
                this._getProductThumbnailField(),
                this._getProductSmallField(),
                this._getShortDescriptionField(),
                'special_from_date',
                'special_to_date',
                this._getTierPricesField()
            );
        }

        // if it is normal product and we need attributes
        // or if, it is variant, but we need variant attributes or variants them-self
        if ((!isVariant && !noAttributes) || (isVariant && !noVariantAttributes && !noVariants)) {
            fields.push(this._getAttributesField(isVariant));
        }

        // to all products (non-variants)
        if (!isVariant) {
            fields.push(
                'url',
                this._getUrlRewritesFields(),
                this._getReviewCountField(),
                this._getRatingSummaryField(),
                this._getCustomizableProductFragment()
            );

            // if variants are not needed
            if (!noVariants) {
                fields.push(
                    this._getConfigurableProductFragment(),
                    this._getBundleProductFragment(),
                    this._getGroupedProductItems()
                );
            }
        }

        // prevent linked products from looping
        if (isForLinkedProducts) {
            fields.push(this._getProductLinksField());
        }

        // additional information to PDP loads
        if (isSingleProduct) {
            fields.push(
                'stock_status',
                this._getDescriptionField(),
                this._getMediaGalleryField(),
                this._getSimpleProductFragment(),
                'format',
                'formats',
                'subtitles',
                'image_format',
                'language_version',
                'requirements',
                'file_size'
            );

            // for variants of PDP requested product
            if (!isVariant) {
                fields.push(
                    'canonical_url',
                    'meta_title',
                    'meta_keyword',
                    'meta_description',
                    this._getCategoriesField(),
                    this._getReviewsField(),
                    this._getVirtualProductFragment(),
                    this._getCustomizableProductFragment(),
                    this._getProductLinksField()
                );
            }
        }

        return fields;
    }

    _getCartProductInterfaceFields() {
        return [
            'uid',
            'id',
            'sku',
            'name',
            'type_id',
            'stock_status',
            'url',
            'salable_qty',
            'availability',
            'availability_status',
            'is_package',
            'is_virtual',
            'formats',
            'is_suggested_price',
            'is_starting_price',
            this._getStockItemField(),
            this._getProductThumbnailField(),
            this._getProductSmallField(),
            this.getAvailabilityDescriptionFields(),
            this._getCartConfigurableProductFragment(),
            this._getAttributesField(false, true),
            this._getProductLinksField(),
            this.getIsReprintField(),
            this.getIsPreorderField(),
            this._getPriceRangeField(),
            ...this._getOmnibusPriceFields(),
        ];
    }

    getViewMoreFilter(filterName, search, categoryId, filter = {}) {
        const filterArguments = { category_id: { eq: `${categoryId}` }, ...filter };

        if (!filterName || !search || !categoryId) {
            throw new Error('Missing required viewMoreFilter arguments: `filterName`, `search` or `categoryId`');
        }

        return new Field('viewMoreFilter')
            .setAlias('filter')
            .addArgument('filterName', 'String!', filterName)
            .addArgument('search', 'String', search)
            .addArgument('filter', 'ProductAttributeFilterInput', filterArguments)
            .addFieldList([this._getViewMoreFilterAggregationsField()]);
    }

    _getViewMoreFilterAggregationsField() {
        return new Field('aggregations')
            .setAlias('results')
            .addFieldList([
                'has_more',
                'label',
                'rel_nofollow',
                'attribute_code',
                'count',
                this._getViewMoreFilterAggregationsOptionsField(),
            ]);
    }

    _getViewMoreFilterAggregationsOptionsField() {
        return new Field('options').addFieldList(['label', 'count', 'value']);
    }

    getSearchFilterItemsQuery(filterName, search, categoryId, productSearchQuery = '', filter = {}) {
        const filterArguments = this._getArgumentsMap().filter.handler({
            ...(categoryId ? { categoryIds: categoryId } : {}),
            customFilters: omit(filter, FILTERS_WITH_SEARCH_REQUEST),
        });

        if (!filterName || !search) {
            throw new Error('Missing required searchFilterItems arguments: `filterName`, `search` or `categoryId`');
        }

        return new Field('searchFilterItems')
            .addArgument('filterName', 'String!', filterName)
            .addArgument('search_query', 'String', productSearchQuery)
            .addArgument('search', 'String', search)
            .addArgument('filter', 'ProductAttributeFilterInput', filterArguments)
            .addFieldList(this._getSearchFilterItemsFields());
    }

    _getSearchFilterItemsFields() {
        return [
            'has_more',
            'label',
            'rel_nofollow',
            'attribute_code',
            'count',
            this._getSearchFilterItemsOptionsField(),
        ];
    }

    _getSearchFilterItemsOptionsField() {
        return new Field('options').addFieldList(['label', 'count', 'value']);
    }

    _getBestsellerProductCategoriesField() {
        return new Field('bestseller_product_categories').addFieldList([
            'position',
            this._getBestsellerCategoryField(),
        ]);
    }

    _getBestsellerCategoryField() {
        return new Field('category').addFieldList(['name']);
    }

    _getPriceRangeFields() {
        return [this._getMinimalPriceField()];
    }

    _getMinimalPriceFields() {
        return [this._getDiscountField(), this._getFinalPriceField(), this._getRegularPriceField()];
    }

    // eslint-disable-next-line no-unused-vars
    _getAttributeFields(isVariant = false, isCart = false) {
        return [
            'attribute_id',
            'attribute_value',
            'attribute_code',
            'attribute_label',
            ...this._getAdditionalAttributeFields(isCart),
        ];
    }

    _getOmnibusPriceFields() {
        const { isSingleProduct } = this.options;

        const field = new Field('omnibus').addFieldList(['price', 'is_discount']);

        if (isSingleProduct) {
            field.addField(
                new Field('discount_categories').addFieldList([
                    'name',
                    'omnibus_discount_description',
                    'omnibus_discount_enabled',
                    'omnibus_discount_url',
                ])
            );
        }

        return [field, 'is_omnibus_discount', 'lowest_30_price'];
    }

    _getUOMFields() {
        return [new Field('uom_price').addFieldList(['value', 'currency']), 'uom_value', 'uom_quantity'];
    }
}

export default new ProductListQuery();
