import { HIDE_NOTIFICATION, SHOW_NOTIFICATION } from './Notification.action';

/** @namespace SwiatKsiazkiBasic/Store/Notification/Reducer/getInitialState */
export const getInitialState = () => ({
    notifications: {},
});

/** @namespace SwiatKsiazkiBasic/Store/Notification/Reducer/NotificationReducer */
export const NotificationReducer = (state = getInitialState(), action) => {
    const notifications = { ...state.notifications };

    switch (action.type) {
        case SHOW_NOTIFICATION:
            const { msgType, msgText, msgDebug, lifeTime } = action;
            notifications[Date.now()] = { msgType, msgText, msgDebug, lifeTime };

            return {
                ...state,
                notifications,
            };

        case HIDE_NOTIFICATION:
            // eslint-disable-next-line no-unused-vars
            const { [action.id]: id, ...shownNotifications } = notifications;

            return {
                ...state,
                notifications: shownNotifications,
            };

        default:
            return state;
    }
};

export default NotificationReducer;
