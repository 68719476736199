export * from 'SourceUtil/Form/Form';
export { default } from 'SourceUtil/Form/Form';

/** @namespace SwiatKsiazkiBasic/Util/Form/blockedAutocompleteField */
export const blockedAutocompleteField = () => ({
    onPaste: (event) => {
        event.preventDefault();
    },
    onCopy: (event) => {
        event.preventDefault();
    },
    onCut: (event) => {
        event.preventDefault();
    },
    onFocus: (event) => {
        event.target.removeAttribute('readonly');
    },
    onBlur: (event) => {
        event.target.setAttribute('readonly', true);
    },
    onTouchStart: (event) => {
        event.target.removeAttribute('readonly');
    },
});

/** @namespace SwiatKsiazkiBasic/Util/Form/wait */
export const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

/** @namespace SwiatKsiazkiBasic/Util/Form/scrollToFieldError */
export const scrollToFieldError = async (field) => {
    if (!field) return;

    await wait(100);

    const element = field.querySelector('.FieldGroup_hasError, .Field_hasError, .PayUCardsSecureForm-Errors');

    if (element) {
        element.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
        });
    }
};
