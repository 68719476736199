import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import CmsElementsTabs from 'Component/CmsElementsTabs';
import LazyLoad from 'Component/LazyLoad';
import ProductSliderTabs from 'Component/ProductSliderTabs';

/** @namespace SwiatKsiazkiBasic/Component/CmsTabChecker/Component */
export class CmsTabChecker extends PureComponent {
    static propTypes = {
        title: PropTypes.string,
        tabs: PropTypes.array,
        isHomepage: PropTypes.bool,
    };

    static defaultProps = {
        title: '',
        tabs: [],
        isHomepage: false,
    };

    cmsElementsTabChecker() {
        const { tabs } = this.props;
        const isCmsElements = tabs?.every(({ title }) => title.split(' ')[0] === 'Karty');

        return isCmsElements;
    }

    render() {
        const { title, tabs, isHomepage, children } = this.props;

        if (!tabs) {
            return null;
        }

        if (isHomepage) {
            if (this.cmsElementsTabChecker()) {
                return <CmsElementsTabs title={title} cmsElementsTabs={tabs} />;
            }

            return (
                <LazyLoad placeholder={<div block="ProductSliderTabs" elem="Placeholder" />}>
                    <ProductSliderTabs title={title} productSliderTabs={tabs} />
                </LazyLoad>
            );
        }

        return children;
    }
}

export default CmsTabChecker;
