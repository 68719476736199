import PropTypes from 'prop-types';

import { Loader as SourceLoader } from 'SourceComponent/Loader/Loader.component';

import './Loader.override.style';

/** @namespace SwiatKsiazkiBasic/Component/Loader/Component */
export class Loader extends SourceLoader {
    static propTypes = {
        ...SourceLoader.propTypes,
        isFullPage: PropTypes.bool,
        loadingText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    };

    static defaultProps = {
        ...SourceLoader.defaultProps,
        isFullPage: false,
        loadingText: __('Loading...'),
    };

    renderMain() {
        const { loadingText } = this.props;

        return (
            <div block="Loader" elem="Spinner">
                {loadingText ? <span>{loadingText}</span> : null}
            </div>
        );
    }

    render() {
        const { isLoading, isFullPage, mix } = this.props;

        if (!isLoading) {
            return null;
        }

        return (
            <div block="Loader" mods={{ isFullPage }}>
                <div block="Loader" mix={mix} elem="Scale">
                    {this.renderMain()}
                </div>
            </div>
        );
    }
}

export default Loader;
