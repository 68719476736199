import { Field } from 'Util/Query';

/**
 * GiftCardCheckValidity Mutations
 * @class GiftCardActivateCardQuery
 * @namespace SwiatKsiazkiBasic/Query/GiftCard/Query */
export class GiftCardQuery {
    getOrderCardMutation({
        cardType,
        email,
        emailRepeat,
        firstName,
        lastName,
        companyName,
        nip,
        street,
        city,
        postcode,
        countryId,
        phone,
        giftCards,
        requestType,
        agreements,
    }) {
        return new Field('orderGiftcard')
            .addArgument('cardType', 'CardTypes!', cardType)
            .addArgument('email', 'String!', email)
            .addArgument('emailRepeat', 'String!', emailRepeat)
            .addArgument('firstName', 'String!', firstName)
            .addArgument('lastName', 'String!', lastName)
            .addArgument('companyName', 'String!', companyName)
            .addArgument('nip', 'String!', nip)
            .addArgument('street', 'String!', street)
            .addArgument('city', 'String!', city)
            .addArgument('postcode', 'String!', postcode)
            .addArgument('countryId', 'String!', countryId)
            .addArgument('phone', 'String!', phone)
            .addArgument('giftcards', '[GiftcardInput]', giftCards)
            .addArgument('requestType', 'OrderGiftcardRequestType!', requestType)
            .addArgument('agreements', '[GiftcardAgreementData]!', agreements)
            .addFieldList(this._getOrderCardFields());
    }

    getActivateCardMutation({ cardNumber, secureCode }) {
        return new Field('giftcardActivate')
            .addArgument('cardNumber', 'String!', cardNumber)
            .addArgument('secureCode', 'String!', secureCode)
            .addFieldList(this._getActivateCardFields());
    }

    getCheckCardMutation({ cardNumber, secureCode }) {
        return new Field('giftcardCheck')
            .addArgument('cardNumber', 'String!', cardNumber)
            .addArgument('secureCode', 'String!', secureCode)
            .addFieldList(this._getCheckCardFields());
    }

    _getCheckCardFields() {
        return ['status', 'balance', 'expire_date'];
    }

    _getActivateCardFields() {
        return ['status', 'message'];
    }

    _getOrderCardFields() {
        return ['status', 'message', 'redirect_url'];
    }
}

export default new GiftCardQuery();
