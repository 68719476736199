/* eslint-disable no-restricted-syntax */

import moment from 'moment';
import { createRef, PureComponent } from 'react';

import { parseJSON } from 'Util/Data';

/** @namespace SwiatKsiazkiBasic/Component/CountDown/Container */
export class CountDownContainer extends PureComponent {
    countDownRef = createRef();

    interval;

    componentDidMount() {
        const { type } = this.props;

        this.setTimeRemaining();

        if (type === 'circle') {
            this.setCircleCount();
        }

        this.interval = setInterval(() => {
            this.setTimeRemaining();

            if (type === 'circle') {
                this.setCircleCount();
            }
        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    get dateWrapper() {
        return this.countDownRef.current?.querySelector('.mgz-countdown-days') ?? undefined;
    }

    get dateLabel() {
        return parseJSON(this.dateWrapper?.querySelector('.mgz-countdown-unit-label')?.getAttribute('data-label'));
    }

    get hoursWrapper() {
        return this.countDownRef.current?.querySelector('.mgz-countdown-hours') ?? undefined;
    }

    get hoursLabel() {
        return parseJSON(this.hoursWrapper?.querySelector('.mgz-countdown-unit-label')?.getAttribute('data-label'));
    }

    get minutesWrapper() {
        return this.countDownRef.current?.querySelector('.mgz-countdown-minutes') ?? undefined;
    }

    get minutesLabel() {
        return parseJSON(this.minutesWrapper?.querySelector('.mgz-countdown-unit-label')?.getAttribute('data-label'));
    }

    get secondsWrapper() {
        return this.countDownRef.current?.querySelector('.mgz-countdown-seconds') ?? undefined;
    }

    get secondsLabel() {
        return parseJSON(this.secondsWrapper?.querySelector('.mgz-countdown-unit-label')?.getAttribute('data-label'));
    }

    prepareEndTime() {
        const { time } = this.props;

        return moment(time).utcOffset(0).format('YYYY-MM-DD HH:mm');
    }

    getTimeRemaining() {
        const endTime = this.prepareEndTime();

        const time = Date.parse(endTime) - Date.parse(new Date());
        const seconds = Math.floor((time / 1000) % 60);
        const minutes = Math.floor((time / 1000 / 60) % 60);
        const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
        const days = Math.floor(time / (1000 * 60 * 60 * 24));
        return {
            total: time,
            days: days < 10 ? `0${days}` : days,
            hours: `0${hours}`.slice(-2),
            minutes: `0${minutes}`.slice(-2),
            seconds: `0${seconds}`.slice(-2),
        };
    }

    setTimeRemaining() {
        const time = this.getTimeRemaining();
        const wrappers = {
            days: this.dateWrapper,
            hours: this.hoursWrapper,
            minutes: this.minutesWrapper,
            seconds: this.secondsWrapper,
        };
        const labels = {
            days: this.dateLabel,
            hours: this.hoursLabel,
            minutes: this.minutesLabel,
            seconds: this.secondsLabel,
        };

        if (time.total <= 0) {
            clearInterval(this.interval);

            for (const element of Object.values(wrappers)) {
                if (element) {
                    element.querySelector('.mgz-countdown-unit-number').innerHTML = '00';
                }
            }
        } else {
            for (const [type, element] of Object.entries(wrappers)) {
                if (element) {
                    element.querySelector('.mgz-countdown-unit-number').innerHTML = time[type] || '00';
                    element.querySelector('.mgz-countdown-unit-label').innerHTML =
                        parseInt(time[type], 10) !== 1
                            ? labels[type].plural
                            : type === 'seconds'
                            ? labels[type].plural
                            : labels[type].singular;
                }
            }
        }
    }

    setCircleCount() {
        const t = this.getTimeRemaining();
        const max = {
            days: 365,
            hours: 24,
            minutes: 60,
            seconds: 60,
        };
        const circles = {
            days: this.dateWrapper?.querySelector('svg'),
            hours: this.hoursWrapper?.querySelector('svg'),
            minutes: this.minutesWrapper?.querySelector('svg'),
            seconds: this.secondsWrapper?.querySelector('svg'),
        };

        for (const [type, element] of Object.entries(circles)) {
            if (element) {
                const $circle = element.querySelector('.mgz-element-bar');
                const strokeDashoffset = Number($circle?.getAttribute('stroke-dashoffset')) || 0;
                const r = Number($circle?.getAttribute('r')) || 0;
                const circle = Math.PI * (r * 2);
                const val = t[type];
                const total = max[type];
                const stroke = (1 - val / total) * circle;

                if ($circle) {
                    $circle.style.strokeDashoffset = stroke || strokeDashoffset;
                }
            }
        }
    }

    render() {
        const { containerProps, children } = this.props;

        return (
            <div {...containerProps} ref={this.countDownRef}>
                {children}
            </div>
        );
    }
}

export default CountDownContainer;
