import { CmsPageQuery as SourceCmsPageQuery } from 'SourceQuery/CmsPage.query';

/** @namespace SwiatKsiazkiBasic/Query/CmsPage/Query */
export class CmsPageQuery extends SourceCmsPageQuery {
    _getPageFields() {
        return [
            'title',
            'content',
            'page_width',
            'content_heading',
            'meta_title',
            'meta_description',
            'meta_keywords',
            'identifier',
        ];
    }
}

export default new CmsPageQuery();
