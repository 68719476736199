import { connect } from 'react-redux';

import { FieldSelectContainer as SourceFieldSelectContainer } from 'SourceComponent/FieldSelect/FieldSelect.container';

import FieldSelect from './FieldSelect.component';

/** @namespace SwiatKsiazkiBasic/Component/FieldSelect/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace SwiatKsiazkiBasic/Component/FieldSelect/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    pageSize: state.ProductListReducer.currentArgs?.pageSize,
    sortDirection: state.ProductListReducer.currentArgs?.sort?.sortDirection,
    sortKey: state.ProductListReducer.currentArgs?.sort?.sortKey,
});

/** @namespace SwiatKsiazkiBasic/Component/FieldSelect/Container */
export class FieldSelectContainer extends SourceFieldSelectContainer {
    containerProps() {
        const { pageSize, sortDirection, sortKey, isDynamicWidth } = this.props;
        const { selectedOptionIndex } = this.state;

        return {
            ...super.containerProps(),
            selectedOptionIndex,
            pageSize,
            sortDirection,
            sortKey,
            isDynamicWidth,
        };
    }

    handleSelectExpand(event) {
        const { isDisabled } = this.props;

        if (!this.isSelectDisabled() && !isDisabled) {
            if (!event) {
                this.setState({ isExpanded: false });
                return;
            }

            const clickedItem = event.target;

            if (clickedItem === this.fieldRef.parentElement) {
                this.setState(({ isExpanded }) => ({ isExpanded: !isExpanded }));
            } else if (clickedItem.localName === 'li' || clickedItem.parentElement.localName === 'li') {
                this.setState({ isExpanded: false });
            }
        }
        this.handleDropdownOpenDirection();
    }

    handleSelectListOptionClick(option) {
        const { changeValueOnDoubleClick, events: { onChange } = {} } = this.props;
        const { value, target: { value: targetValue } = {} } = option;

        const fieldValue = value || targetValue || '';

        if (changeValueOnDoubleClick) {
            this.fieldRef.value = this.fieldRef.value === value ? '' : fieldValue;
        } else {
            this.fieldRef.value = fieldValue;
        }

        if (onChange) {
            onChange(fieldValue);
        }

        this.handleSelectExpand();
    }

    render() {
        return <FieldSelect {...this.containerProps()} {...this.containerFunctions} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FieldSelectContainer);
