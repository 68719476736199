import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import CmsElementsTabs from './CmsElementsTabs.component';

/** @namespace SwiatKsiazkiBasic/Component/CmsElementsTabs/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace SwiatKsiazkiBasic/Component/CmsElementsTabs/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device,
});

/** @namespace SwiatKsiazkiBasic/Component/CmsElementsTabs/Container */
export class CmsElementsTabsContainer extends PureComponent {
    static propTypes = {
        title: PropTypes.string,
        cmsElementsTabs: PropTypes.arrayOf(PropTypes.object).isRequired,
    };

    containerFunctions = {
        handleTabClick: this.handleTabClick.bind(this),
    };

    state = {
        activeTab: 1,
        title: '',
    };

    componentDidMount() {
        this.changeActiveTab();
    }

    componentDidUpdate(prevProps, prevState) {
        const { activeTab } = this.state;

        if (prevState.activeTab !== activeTab) {
            this.changeActiveTab();
        }
    }

    changeActiveTab() {
        const cmsElementsTabs = document.querySelectorAll('.CmsElementsTabs-Content');
        const { activeTab } = this.state;

        cmsElementsTabs.forEach((contentTab, index) => {
            if (index + 1 === activeTab && !contentTab.classList.contains('CmsElementsTabs-Content_isActive')) {
                contentTab.classList.add('CmsElementsTabs-Content_isActive');
            } else {
                contentTab.classList.remove('CmsElementsTabs-Content_isActive');
            }
        });
    }

    handleTabClick(id) {
        this.setState({ activeTab: id });
    }

    getArrayCmsElementsTabs() {
        const { cmsElementsTabs } = this.props;
        const preparedCmsElementsTabs = [];

        cmsElementsTabs.forEach((tab, index) => {
            if (index === 0) {
                this.setState({
                    title: tab.title,
                });
            }

            preparedCmsElementsTabs.push({
                name: tab.title,
                id: index + 1,
                rows: tab.rows,
            });
        });

        return preparedCmsElementsTabs;
    }

    containerProps() {
        const { activeTab, title } = this.state;
        const { device } = this.props;

        return {
            cmsElementsTabs: this.getArrayCmsElementsTabs(),
            activeTab,
            title,
            device,
        };
    }

    render() {
        return <CmsElementsTabs {...this.containerFunctions} {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CmsElementsTabsContainer);
