import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import NewOpeningsWidget from './NewOpeningsWidget.component';
import { NEW_OPENINGS_WIDGET_DEFAULT_COLS } from './NewOpeningsWidget.config';

/** @namespace SwiatKsiazkiBasic/Component/NewOpeningsWidget/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace SwiatKsiazkiBasic/Component/NewOpeningsWidget/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device,
});

/** @namespace SwiatKsiazkiBasic/Component/NewOpeningsWidget/Container */
export class NewOpeningsWidgetContainer extends PureComponent {
    static propTypes = {
        title: PropTypes.string,
        description: PropTypes.string,
        images: PropTypes.any,
    };

    containerFunctions = {
        getSrcset: this.getSrcset.bind(this),
    };

    state = {
        cols: NEW_OPENINGS_WIDGET_DEFAULT_COLS,
        imageCols: null,
    };

    componentDidMount() {
        this.updateState();
        window.addEventListener('resize', () => this.updateState());
    }

    componentWillUnmount() {
        window.removeEventListener('resize', () => this.updateState());
    }

    updateState() {
        const { device: { isTablet, isLargeMobile, isSmallMobile } = {} } = this.props;

        if (isSmallMobile) {
            this.setState({
                // for small mobile
                cols: 1,
                imageCols: 1,
            });

            return null;
        }

        if (isLargeMobile || isTablet) {
            this.setState({
                // for mobile set 2-columns grid
                cols: 2,
                imageCols: 1,
            });

            return null;
        }

        this.setState({
            cols: NEW_OPENINGS_WIDGET_DEFAULT_COLS,
            imageCols: null,
        });
    }

    getSrcset(src: string, size: number, rows = 1, cols = 1) {
        return {
            src: `${src}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
            srcSet: `${src}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format&dpr=2 2x`,
        };
    }

    _prepareGalleryArr() {
        const { images } = this.props;

        return JSON.parse(images).map(({ src, title, description }) => {
            if (!description) {
                return {
                    src,
                    title,
                };
            }

            let rows = description.match(/(?:rows: )(\d+)/);
            let cols = description.match(/(?:cols: )(\d+)/);
            rows = rows && parseInt(rows[1], 10);
            cols = cols && parseInt(cols[1], 10);

            return {
                src,
                title,
                rows,
                cols,
            };
        });
    }

    containerProps() {
        const { title, description } = this.props;
        const { cols, imageCols } = this.state;

        return {
            title,
            description,
            cols,
            imageCols,
            gallery: this._prepareGalleryArr(),
        };
    }

    render() {
        return <NewOpeningsWidget {...this.containerFunctions} {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewOpeningsWidgetContainer);
