import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { showPopup } from 'Store/Popup/Popup.action';
import { isSignedIn } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';

import NewsletterPopup from './NewsletterPopup.component';
import {
    NEWSLETTER_POPUP_COOKIE_DURATION,
    NEWSLETTER_POPUP_COOKIE_NAME,
    NEWSLETTER_POPUP_DELAY,
    NEWSLETTER_POPUP_ID,
} from './NewsletterPopup.config';

/** @namespace SwiatKsiazkiBasic/Component/NewsletterPopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showPopup: (payload) => dispatch(showPopup(NEWSLETTER_POPUP_ID, payload)),
});
/** @namespace SwiatKsiazkiBasic/Component/NewsletterPopup/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    customer: state.MyAccountReducer.customer,
    device: state.ConfigReducer.device,
});

/** @namespace SwiatKsiazkiBasic/Component/NewsletterPopup/Container */
export class NewsletterPopupContainer extends PureComponent {
    state = {
        isPopupTriggered: false,
    };

    containerFunctions = {
        onNewsletterPopupClose: this.onNewsletterPopupClose.bind(this),
    };

    componentDidMount() {
        const {
            customer,
            device: { isMobile },
        } = this.props;
        const { isPopupTriggered } = this.state;

        if (!isMobile) {
            const customerIsSignedIn = isSignedIn();
            const isPopupAlreadySeen = BrowserDatabase.getItem(NEWSLETTER_POPUP_COOKIE_NAME);

            if (isPopupAlreadySeen || (customerIsSignedIn && customer?.is_subscribed)) {
                return null;
            }

            if (!isPopupTriggered) {
                this.showNewsletterPopup();
                this.setState({ isPopupTriggered: true });
            }
        }
    }

    componentDidUpdate(prevProps) {
        const {
            customer,
            device: { isMobile },
        } = this.props;
        const { isMobile: prevIsMobile } = prevProps;
        const { isPopupTriggered } = this.state;

        if (!isMobile && prevIsMobile !== isMobile) {
            const customerIsSignedIn = isSignedIn();
            const isPopupAlreadySeen = BrowserDatabase.getItem(NEWSLETTER_POPUP_COOKIE_NAME);

            if (isPopupAlreadySeen || (customerIsSignedIn && customer?.is_subscribed)) {
                return null;
            }

            if (!isPopupTriggered) {
                this.showNewsletterPopup();
                this.setState({ isPopupTriggered: true });
            }
        }
    }

    containerProps() {
        const {
            device: { isMobile },
        } = this.props;

        return {
            isMobile,
        };
    }

    showNewsletterPopup() {
        const { showPopup } = this.props;

        if (!this.showNewsletterPopupTimeout) {
            this.showNewsletterPopupTimeout = setTimeout(() => {
                showPopup({
                    title: '',
                });
            }, NEWSLETTER_POPUP_DELAY);
        }
    }

    onNewsletterPopupClose() {
        BrowserDatabase.setItem(true, NEWSLETTER_POPUP_COOKIE_NAME, NEWSLETTER_POPUP_COOKIE_DURATION);

        clearTimeout(this.showNewsletterPopupTimeout);
        clearTimeout(this.handleNewsletterPopupTimeout);
    }

    render() {
        return <NewsletterPopup {...this.containerFunctions} {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsletterPopupContainer);
