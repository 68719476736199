// import PropTypes from 'prop-types';
import { findNearest } from 'geolib';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { CoordinatesType, LocationsType } from 'Type/Locations.type';

import Map from './Map.component';

/** @namespace SwiatKsiazkiBasic/Component/Map/Container */
export class MapContainer extends PureComponent {
    static propTypes = {
        defaultLocations: LocationsType.isRequired,
        defaultCenter: CoordinatesType,
        defaultZoom: PropTypes.number,
        isSidebar: PropTypes.bool,
    };

    static defaultProps = {
        // default center of Poland
        defaultCenter: {
            lat: 51.9194,
            lng: 19.1451,
        },
        // default zoom for Poland
        defaultZoom: 6,
        isSidebar: false,
    };

    state = {
        locations: [],
        searchedLocations: [],
        center: {},
        zoom: null,
    };

    containerFunctions = {
        handleSearch: this.handleSearch.bind(this),
        handleClickLocation: this.handleClickLocation.bind(this),
        handleClickMarker: this.handleClickMarker.bind(this),
        handleCloseMarker: this.handleCloseMarker.bind(this),
        handleNearestLocation: this.handleNearestLocation.bind(this),
    };

    componentDidMount() {
        this._setLocations();
        this._setMapData();
    }

    componentDidUpdate(prevProps) {
        const { defaultLocations } = this.props;
        const { defaultLocations: prevDefaultLocations } = prevProps;

        if (!Object.keys(prevDefaultLocations).length && Object.keys(defaultLocations).length) {
            this._setLocations();
        }
    }

    _setLocations() {
        const { defaultLocations } = this.props;

        this.setState({
            locations: defaultLocations,
            searchedLocations: defaultLocations,
        });
    }

    _setMapData() {
        const { defaultCenter: center, defaultZoom: zoom } = this.props;

        this.setState({
            center,
            zoom,
        });
    }

    handleSearch(e) {
        const { defaultLocations } = this.props;
        const value = e?.target?.value;

        if (!value) {
            this.setState({
                searchedLocations: defaultLocations,
            });
        }

        const splittedValue = value.split(' ');

        // Search by keywords and set the relevance key,
        // e.g. search term "Galeria Wrocław" will be split and match relevance will be added to the array.
        const searchedLocations = defaultLocations.reduce((obj, item) => {
            const { address, place } = item;
            const searchedText = `${address.toLowerCase()} ${place.toLowerCase()}`;
            item.relevance = splittedValue.filter((e) => searchedText.includes(e.toLowerCase())).length;

            return [...obj, { ...item }];
        }, []);

        // sort by previously set relevance key
        const sortedSearchedLocations = searchedLocations
            .filter(({ relevance }) => relevance > 0)
            .sort((a, b) => b.relevance - a.relevance);

        this.setState({
            searchedLocations: sortedSearchedLocations,
        });
    }

    handleNearestLocation() {
        const { locations } = this.state;

        navigator.geolocation.getCurrentPosition((position) => {
            const { coords } = position || {};
            const { latitude, longitude } = coords || {};

            const { point_key } = findNearest({ latitude, longitude }, locations);

            this.handleClickMarker(point_key);
        });
    }

    handleClickLocation(i) {
        const { defaultCenter, defaultZoom } = this.props;
        const { searchedLocations } = this.state;
        const { isExpanded } = searchedLocations[i];
        let center = defaultCenter;
        let zoom = defaultZoom;

        const locationsArr = Array.from(searchedLocations);
        locationsArr.map((location) => {
            location.isExpanded = false;
            location.isMarkerExpanded = false;
            return location;
        });

        const location = locationsArr[i];
        location.isExpanded = !isExpanded;

        if (location.isExpanded) {
            center = {
                lat: location.lat,
                lng: location.lng,
            };

            zoom = 15;
        }

        this.setState({
            searchedLocations: locationsArr,
            center,
            zoom,
        });
    }

    handleClickMarker(i) {
        const { defaultCenter, defaultZoom } = this.props;
        const { locations } = this.state;
        const { isMarkerExpanded } = locations[i];
        let center = defaultCenter;
        let zoom = defaultZoom;

        const locationsArr = Array.from(locations);
        locationsArr.map((location) => {
            location.isMarkerExpanded = false;
            return location;
        });

        const location = locationsArr[i];
        location.isMarkerExpanded = !isMarkerExpanded;

        if (location.isMarkerExpanded) {
            center = {
                lat: location.lat + 0.0001,
                lng: location.lng,
            };

            zoom = 15;
        }

        this.setState({
            locations: locationsArr,
            center,
            zoom,
        });
    }

    handleCloseMarker(i) {
        const { defaultCenter, defaultZoom } = this.props;
        const { locations } = this.state;

        const locationsArr = Array.from(locations);
        const location = locationsArr[i];
        location.isMarkerExpanded = false;

        this.setState({
            locations: locationsArr,
            center: defaultCenter,
            zoom: defaultZoom,
        });
    }

    containerProps() {
        const { googleMapsApiKey, isSidebar } = this.props;
        const { locations, searchedLocations, center, zoom } = this.state;

        return {
            locations,
            center,
            zoom,
            googleMapsApiKey,
            isSidebar,
            searchedLocations,
        };
    }

    render() {
        return <Map {...this.containerFunctions} {...this.containerProps()} />;
    }
}

export default MapContainer;
