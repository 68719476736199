import { connect } from 'react-redux';

import { POPUP } from 'Component/Header/Header.config';
import {
    mapDispatchToProps,
    mapStateToProps,
    PopupContainer as SourcePopupContainer,
} from 'SourceComponent/Popup/Popup.container';

export { mapStateToProps, mapDispatchToProps };

/** @namespace SwiatKsiazkiBasic/Component/Popup/Container */
export class PopupContainer extends SourcePopupContainer {
    static defaultProps = {
        ...SourcePopupContainer.defaultProps,
        clickOutside: true,
        showCloseBtn: true,
        noHeaderStateChange: false,
    };

    onVisible() {
        const { changeHeaderState, onVisible, noHeaderStateChange } = this.props;

        if (!noHeaderStateChange) {
            changeHeaderState({
                name: POPUP,
                title: this._getPopupTitle(),
                onCloseClick: () => {
                    history.back();
                },
            });
        }

        onVisible();
    }

    containerProps() {
        const { showCloseBtn, noHeaderStateChange, clickOutside } = this.props;

        return {
            ...super.containerProps(),
            clickOutside,
            showCloseBtn,
            noHeaderStateChange,
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PopupContainer);
