import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './PasswordStrength.style';

/** @namespace SwiatKsiazkiBasic/Component/PasswordStrength/Component */
export class PasswordStrength extends PureComponent {
    static propTypes = {
        password: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
    };

    state = {
        score: 0,
    };

    componentDidMount() {
        this.checkPasswordStrength();
    }

    componentDidUpdate() {
        this.checkPasswordStrength();
    }

    async checkPasswordStrength() {
        const { password } = this.props;

        this.setState({
            score: (await import('zxcvbn')).default(password).score,
        });
    }

    getPasswordLabel(result) {
        switch (result) {
            case 0:
                return __('Password weak');
            case 1:
                return __('Password weak');
            case 2:
                return __('Password medium');
            case 3:
                return __('Password strong');
            case 4:
                return __('Password very strong');
            default:
                return __('Password weak');
        }
    }

    renderContent() {
        const { password, id } = this.props;
        const { score } = this.state;
        const type = this.getPasswordLabel(score);

        if (password?.length === 0) {
            return (
                <>
                    <progress
                        block="PasswordStrength"
                        elem="Progress"
                        mods={{ type, noPassword: true }}
                        value={score}
                    />
                    <label block="PasswordStrength" elem="Label" mods={{ type }} htmlFor={id} id={id}>
                        <span>{__('No password')}</span>
                    </label>
                </>
            );
        }

        return score !== undefined ? (
            <>
                <progress block="PasswordStrength" elem="Progress" mods={{ type }} value={score || 1} />
                <label block="PasswordStrength" elem="Label" mods={{ type }} htmlFor={id} id={id}>
                    <span>{type}</span>
                </label>
            </>
        ) : null;
    }

    render() {
        return <div block="PasswordStrength">{this.renderContent()}</div>;
    }
}

export default PasswordStrength;
