import React, { PureComponent } from 'react';

import { DEFAULT_SLICK_SETTINGS } from 'Component/CmsSlider/CmsSlider.config';
import { parseJSON } from 'Util/Data';
import { getSliderSettings } from 'Util/MagezoneWidget';

import ImageCarousel from './ImageCarousel.component';

/** @namespace SwiatKsiazkiBasic/Component/ImageCarousel/Container */
export class ImageCarouselContainer extends PureComponent {
    getImages() {
        const { images } = this.props;
        try {
            return parseJSON(images, []).filter(({ src }) => src);
        } catch {
            return [];
        }
    }

    containerProps() {
        const { options } = this.props;

        return {
            options: parseJSON(options),
            sliderOptions: getSliderSettings(options, undefined, DEFAULT_SLICK_SETTINGS),
            images: this.getImages(),
        };
    }

    render() {
        return <ImageCarousel {...this.containerProps()} />;
    }
}

export default ImageCarouselContainer;
