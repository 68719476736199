import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { showNotification } from 'Store/Notification/Notification.action';
import transformToNameValuePair from 'Util/Form/Transform';

import GiftCardsActivateCardForm from './GiftCardsActivateCardForm.component';

export const GiftCardActivateCardDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/GiftCardActivateCard/GiftCardActivateCard.dispatcher'
);

/** @namespace SwiatKsiazkiBasic/Component/GiftCardsActivateCardForm/Container/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace SwiatKsiazkiBasic/Component/GiftCardsActivateCardForm/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    activateCard: (cardNumber, secureCode) =>
        GiftCardActivateCardDispatcher.then(({ default: dispatcher }) =>
            dispatcher.activateCard(dispatch, cardNumber, secureCode)
        ),
});

/** @namespace SwiatKsiazkiBasic/Component/GiftCardsActivateCardForm/Container */
export class GiftCardsActivateCardFormContainer extends PureComponent {
    state = {
        isLoading: false,
        activateResponse: {},
        cardNumber: '',
    };

    containerFunctions = {
        onSuccess: this.onSuccess.bind(this),
    };

    async onSuccess(_, fields) {
        const { activateCard } = this.props;
        const { cardNumber, secureCode } = transformToNameValuePair(fields);

        this.setState({ isLoading: true });

        try {
            const { giftcardActivate: activateResponse } = await activateCard(cardNumber, secureCode);

            if (activateResponse) {
                this.setState({
                    isLoading: false,
                    activateResponse,
                    cardNumber,
                });
            }
        } catch (error) {
            this.setState({
                activateResponse: {},
                cardNumber: '',
            });
        } finally {
            this.setState({ isLoading: false });
        }
    }

    containerProps() {
        const { isLoading, activateResponse, cardNumber } = this.state;

        return {
            isLoading,
            activateResponse,
            cardNumber,
        };
    }

    render() {
        return <GiftCardsActivateCardForm {...this.containerFunctions} {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GiftCardsActivateCardFormContainer);
