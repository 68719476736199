import { PureComponent } from 'react';

import ChevronIcon from 'Component/ChevronIcon';
import { BOTTOM, TOP } from 'Component/ChevronIcon/ChevronIcon.config';

/** @namespace SwiatKsiazkiBasic/Component/CookieShowMore/Component */
export class CookieShowMore extends PureComponent {
    state = {
        isOpen: false,
    };

    onToggle() {
        this.setState((prev) => ({
            isOpen: !prev.isOpen,
        }));
    }

    renderButton() {
        const { isOpen } = this.state;

        return (
            <button block="CookieShowMore" elem="Button" type="button" onClick={this.onToggle.bind(this)}>
                {isOpen ? (
                    <>
                        <ChevronIcon direction={TOP} />
                        {__('Hide list')}
                    </>
                ) : (
                    <>
                        <ChevronIcon direction={BOTTOM} />
                        {__('Show list')}
                    </>
                )}
            </button>
        );
    }

    renderContent() {
        const { children } = this.props;
        const { isOpen } = this.state;

        if (!isOpen) return null;

        return (
            <div block="CookieShowMore" elem="Content">
                {children}
            </div>
        );
    }

    render() {
        return (
            <div block="CookieShowMore">
                {this.renderButton()}
                {this.renderContent()}
            </div>
        );
    }
}
export default CookieShowMore;
