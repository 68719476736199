import { camelCase } from 'lodash';

/**
 * Set of helpers related to CSS
 * @class CSS
 * @namespace SwiatKsiazkiBasic/Util/CSS
 */
export class CSS {
    static setVariable(ref, name, value) {
        if (ref && ref.current) {
            ref.current.style.setProperty(`--${name}`, value);
        }
    }

    static getVariable(name) {
        if (name) {
            const styles = getComputedStyle(document.documentElement);

            return String(styles.getPropertyValue(name)).trim();
        }
    }
}

/** @namespace SwiatKsiazkiBasic/Util/CSS/getElementHeight */
export const getElementHeight = (id) =>
    Array.from(document.getElementsByClassName(id)).reduce((acc, item) => {
        const { offsetHeight } = item;

        return acc + offsetHeight;
    }, 0);

/** @namespace SwiatKsiazkiBasic/Util/CSS/getFixedElementHeight */
export const getFixedElementHeight = () => {
    const top = getElementHeight('FixedElement-Top');
    const bottom = getElementHeight('FixedElement-Bottom');

    return {
        total: top + bottom,
        top,
        bottom,
    };
};

/** @namespace SwiatKsiazkiBasic/Util/CSS/isRtl */
export const isRtl = () => document.documentElement.getAttribute('dir') === 'rtl';

/** @namespace SwiatKsiazkiBasic/Util/CSS/parseInlineStyle */
export const parseInlineStyle = (style) => {
    try {
        return style
            .replace(/\n|\r|\r\n/gm, '')
            .split(';')
            .reduce((styles, styleItem) => {
                const [key, value] = styleItem.split(':');

                if (value) {
                    return {
                        ...styles,
                        [camelCase(key)]: value,
                    };
                }

                return styles;
            }, {});
    } catch {
        return {};
    }
};

export default CSS;
