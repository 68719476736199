import { isMobile as sourceIsMobile, isMobileClientHints, isUsingClientHints } from 'SourceUtil/Mobile/isMobile';

export { isUsingClientHints, isMobileClientHints };

export const isMobile = {
    ...sourceIsMobile,
    android: (agent = navigator.userAgent) => /android/i.test(agent) && !/GSA/i.test(agent),
    iOS: (agent = navigator.userAgent) => /iphone|ipod|ipad/i.test(agent) && !/GSA/i.test(agent),
    tablet: () => window.matchMedia('(min-width:768px) and (max-width: 1023px)').matches,
    smallDesktop: () => window.matchMedia('(min-width:1024px) and (max-width: 1088px)').matches,
    largeMobile: () => window.matchMedia('(min-width:481px) and (max-width: 767px)').matches,
    smallMobile: () => window.matchMedia('(max-width: 480px)').matches,
};

export default isMobile;
