// import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import parser from 'html-react-parser';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Icon from 'Component/Icon';
import Sidebar from 'Component/MapSidebar';
import { CoordinatesType, ExtendedLocationsType, LocationsType } from 'Type/Locations.type';

import { mapStyles } from './Map.config';

import './Map.style';

/** @namespace SwiatKsiazkiBasic/Component/Map/Component */
export class Map extends PureComponent {
    static propTypes = {
        locations: LocationsType.isRequired,
        center: CoordinatesType.isRequired,
        zoom: PropTypes.number.isRequired,
        googleMapsApiKey: PropTypes.string,
        isSidebar: PropTypes.bool.isRequired,
        searchedLocations: ExtendedLocationsType.isRequired,
        handleSearch: PropTypes.func.isRequired,
        handleClickLocation: PropTypes.func.isRequired,
        handleClickMarker: PropTypes.func.isRequired,
        handleCloseMarker: PropTypes.func.isRequired,
    };

    renderMarkers() {
        const { locations, handleClickMarker, handleCloseMarker } = this.props;

        const markers = locations.map(({ lat, lng, description, isMarkerExpanded }, i) => (
            <div block="Marker" lat={lat} lng={lng}>
                <div block="Marker" elem="Marker" onClick={() => handleClickMarker(i)}>
                    <Icon name="marker_with_logo" height="38" width="30" />
                </div>
                <div block="Marker" elem="Hint" mods={{ isExpanded: !!isMarkerExpanded }}>
                    {parser(description)}
                    <span block="Marker" elem="HintClose" onClick={() => handleCloseMarker(i)}>
                        <Icon name="close" width="8" height="8" fill="#8a8a8a" />
                    </span>
                </div>
            </div>
        ));

        return markers;
    }

    render() {
        const {
            searchedLocations,
            center,
            zoom,
            googleMapsApiKey,
            isSidebar,
            handleSearch,
            handleClickLocation,
            handleNearestLocation,
        } = this.props;

        return (
            <div block="Map">
                <div block="Map" elem="Wrapper">
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: googleMapsApiKey }}
                        center={center}
                        zoom={zoom}
                        options={{ styles: Array.from(mapStyles) }}
                    >
                        {this.renderMarkers()}
                    </GoogleMapReact>
                </div>
                {isSidebar && (
                    <Sidebar
                        locations={searchedLocations}
                        handleSearch={handleSearch}
                        handleClickLocation={handleClickLocation}
                        handleNearestLocation={handleNearestLocation}
                    />
                )}
            </div>
        );
    }
}

export default Map;
