import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';
import { connect } from 'react-redux';

import ClickonometricsQuery from 'Query/Clickonometrics.query';
import { showNotification } from 'Store/Notification/Notification.action';
import { isSignedIn } from 'Util/Auth';
import { getGuestQuoteId } from 'Util/Cart';
import { Cookie } from 'Util/Cookie';
import { fetchQuery, getErrorMessage } from 'Util/Request';

import Clickonometrics from './Clickonometrics.component';
import { CLICKONOMETRICS_VARIANT } from './Clickonometrics.config';

/** @namespace SwiatKsiazkiBasic/Component/Clickonometrics/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    totals: state.CartReducer.cartTotals,
    isClickonometricsTrackingEnabled: state.ConfigReducer.clickonometrics_tracking_general_enabled,
});

/** @namespace SwiatKsiazkiBasic/Component/Clickonometrics/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message) => dispatch(showNotification(type, message)),
});

/** @namespace SwiatKsiazkiBasic/Component/Clickonometrics/Container */
export class ClickonometricsContainer extends PureComponent {
    static propTypes = {
        variant: PropTypes.oneOf([
            CLICKONOMETRICS_VARIANT.MAIN,
            CLICKONOMETRICS_VARIANT.PRODUCT_BROWSED,
            CLICKONOMETRICS_VARIANT.PRODUCT_ORDERED,
        ]),
    };

    scriptWrapperRef = createRef();

    componentDidMount() {
        const { isClickonometricsTrackingEnabled } = this.props;

        if (isClickonometricsTrackingEnabled) {
            this.loadScriptVariant();
        }
    }

    componentDidUpdate(prevProps) {
        const { isClickonometricsTrackingEnabled: prevIsClickonometricsTrackingEnabled } = prevProps;
        const { isClickonometricsTrackingEnabled } = this.props;

        if (
            isClickonometricsTrackingEnabled &&
            prevIsClickonometricsTrackingEnabled !== isClickonometricsTrackingEnabled
        ) {
            this.loadScriptVariant();
        }
    }

    loadScriptVariant() {
        const { variant } = this.props;

        if (variant === CLICKONOMETRICS_VARIANT.MAIN) {
            return this.loadMainScript();
        }

        if (variant === CLICKONOMETRICS_VARIANT.PRODUCT_BROWSED) {
            return this.loadProductBrowsedScript();
        }

        if (variant === CLICKONOMETRICS_VARIANT.PRODUCT_ORDERED) {
            return this.loadProductOrderedScript();
        }
    }

    containerProps = () => {
        const { variant } = this.props;

        return { variant, scriptWrapperRef: this.scriptWrapperRef };
    };

    loadMainScript() {
        window.ccxtgSettings = [{ id: '9158' }];

        const clickonometricsScript = document.createElement('script');

        Cookie.appendCookieAttributes('marketing', {
            element: clickonometricsScript,
            src: 'https://delivery.clickonometrics.pl/service=9158/tm.json?sid=9158&cid=178&pid=18683',
            type: 'text/javascript',
        });

        document.head.appendChild(clickonometricsScript);

        Cookie.runScripts();
    }

    loadScript(scriptSrc) {
        const blockWrapper = this.scriptWrapperRef;

        if (blockWrapper.current) {
            const script = document.createElement('script');

            Cookie.appendCookieAttributes('marketing', {
                element: script,
                src: scriptSrc,
            });

            blockWrapper.current.appendChild(script);

            Cookie.runScripts();
        }
    }

    prepareProductBrowsedInfo() {
        const {
            productInfo: {
                sku: productSku = '',
                price_range: { minimum_price: { final_price: { value } = {} } = {} } = {},
            } = {},
            totals: { items = [] } = {},
        } = this.props;
        const inBasket = items.find(({ sku }) => sku === productSku)?.qty;

        return {
            id: productSku,
            price: value,
            ...(inBasket ? { in_basket: `${inBasket}` } : {}),
        };
    }

    loadProductBrowsedScript() {
        const preparedProductBrowsedInfo = this.prepareProductBrowsedInfo();
        const { id, price } = preparedProductBrowsedInfo;

        if (!id || !price) {
            return null;
        }

        const scriptSrc = `https://delivery.clickonometrics.pl/tracker=8554/track/shoppers/${
            CLICKONOMETRICS_VARIANT.PRODUCT_BROWSED
        }/${JSON.stringify(preparedProductBrowsedInfo)}/track.js`;

        this.loadScript(scriptSrc);
    }

    async loadProductOrderedScript() {
        const { orderIds, showNotification } = this.props;
        const currentGuestCartId = !isSignedIn() ? getGuestQuoteId(true) : '';

        try {
            const { getClickonometricsOrder: orders = [] } = await fetchQuery(
                ClickonometricsQuery.getOrderQuery(orderIds, currentGuestCartId)
            );

            orders.map(({ increment_id, items, order_value }) => {
                const scriptSrc = `https://delivery.clickonometrics.pl/tracker=8559/track/shoppers/${
                    CLICKONOMETRICS_VARIANT.PRODUCT_ORDERED
                }/${order_value}/${increment_id}/${JSON.stringify(items)}/track.js`;

                return this.loadScript(scriptSrc);
            });
        } catch (e) {
            showNotification('error', getErrorMessage(e));
        }
    }

    render() {
        const { variant } = this.props;

        if (variant === CLICKONOMETRICS_VARIANT.MAIN) {
            return null;
        }

        return <Clickonometrics {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClickonometricsContainer);
