import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Icon from 'Component/Icon';
import Link from 'Component/Link';
import { PRODUCT_TYPE } from 'Component/Product/Product.config';
import ProductAdditionalInformation from 'Component/ProductAdditionalInformation/ProductAdditionalInformation.component';
import { formatDate } from 'Util/Date';
import { isObjectEmpty } from 'Util/Object';

import './AvailabilityInfo.style';

/** @namespace SwiatKsiazkiBasic/Component/AvailabilityInfo/Component */
export class AvailabilityInfo extends PureComponent {
    static propTypes = {
        inStock: PropTypes.bool,
        product: PropTypes.object,
        isOnProductSummary: PropTypes.bool,
    };

    static defaultProps = {
        inStock: true,
        isOnProductSummary: false,
    };

    renderDefaultLabel(label, mods = {}) {
        const { is24h } = mods;

        return (
            <div block="AvailabilityInfo">
                <div block="AvailabilityInfo" elem="Item" mods={mods}>
                    {is24h && <Icon name="checkmark" width="13" height="9" />}
                    {label}
                </div>
            </div>
        );
    }

    renderUnavailableLabel() {
        return (
            <div block="AvailabilityInfo" elem="Item" mods={{ isOutOfStock: true }}>
                <Icon name="info" width="16" height="16" />
                {__('Product unavailable')}
            </div>
        );
    }

    renderTooltipAdditionalInfo(title, text, icon = 'shippment') {
        return (
            <Link to="/pomoc/dostepnosci-produktow" block="ProductActions" elem="AdditionalInformation">
                <ProductAdditionalInformation icon={icon} title={title} tooltipText={text} isTooltip />
            </Link>
        );
    }

    renderUnavailable() {
        const { isOnProductSummary, product } = this.props;
        const { availability_description: { availability_description, availability_status_text } = {} } = product || {};

        if (isOnProductSummary) {
            return this.renderTooltipAdditionalInfo(availability_status_text, availability_description, 'info');
        }

        return <div block="AvailabilityInfo">{this.renderUnavailableLabel()}</div>;
    }

    renderDownloadable() {
        const { isOnProductSummary, product } = this.props;
        const { availability_description: { availability_description, availability_status_text } = {} } = product || {};

        if (isOnProductSummary) {
            return this.renderTooltipAdditionalInfo(
                availability_status_text,
                availability_description,
                'downloadable_item'
            );
        }

        return this.renderDefaultLabel(availability_status_text, { isInfo: true });
    }

    renderPreorderOrReprint() {
        const { isOnProductSummary, product } = this.props;
        const {
            availability_description: { availability_description, availability_status_text, availability_preword } = {},
        } = product || {};

        if (isOnProductSummary) {
            return this.renderTooltipAdditionalInfo(
                `${availability_preword} ${formatDate(availability_status_text)}`,
                availability_description
            );
        }

        return this.renderDefaultLabel(`${availability_preword} ${formatDate(availability_status_text)}`, {
            isInfo: true,
        });
    }

    renderDefault() {
        const { isOnProductSummary, product } = this.props;
        const {
            availability_status,
            availability_description: { availability_description, availability_status_text } = {},
        } = product || {};

        if (isOnProductSummary) {
            return this.renderTooltipAdditionalInfo(availability_status_text, availability_description);
        }

        return this.renderDefaultLabel(availability_status_text, { is24h: Number(availability_status) === 1 });
    }

    render() {
        const { product, inStock } = this.props;

        if (isObjectEmpty(product)) {
            return null;
        }

        if (!inStock) {
            return this.renderUnavailable();
        }

        const { is_preorder: isPreorder, is_reprint: isReprint, type_id: productType } = product || {};
        const isPreorderCheck = Number(isPreorder) === 1;
        const isReprintCheck = Number(isReprint) === 1;
        const isDownloadableProduct = productType === PRODUCT_TYPE.downloadable;

        if (isPreorderCheck || isReprintCheck) {
            return this.renderPreorderOrReprint();
        }

        if (isDownloadableProduct) {
            return this.renderDownloadable();
        }

        return this.renderDefault();
    }
}

export default AvailabilityInfo;
