import { RecaptchaContainer } from '../component/Recaptcha/Recaptcha.container';
import { captchaReset } from '../component/Recaptcha/Recaptcha.event';

const appendCaptchaTokenToHeaders = (headers) => {
    const captchaToken = RecaptchaContainer.getCaptchaToken();

    if (!captchaToken) {
        return headers;
    }

    window.dispatchEvent(captchaReset);

    return {
        ...headers,
        'X-ReCaptcha': captchaToken,
    };
};

const Request_appendTokenToHeaders = (args, callback) => appendCaptchaTokenToHeaders(callback(...args));

export default {
    'SwiatKsiazkiBasic/Util/Request/appendTokenToHeaders': {
        function: Request_appendTokenToHeaders,
    },
};
