export * from 'SourceUtil/Common/index';

/** @namespace SwiatKsiazkiBasic/Util/Common/Index/decodeString */
export const decodeString = (string) => {
    try {
        return decodeURIComponent(string)?.replace(/\\"/gi, '"');
    } catch (e) {
        return string;
    }
};

/** @namespace SwiatKsiazkiBasic/Util/Common/Index/encodeString */
export const encodeString = (value) => {
    try {
        return encodeURIComponent(value?.replace(/"/gi, '\\"'));
    } catch {
        return value;
    }
};

/** @namespace SwiatKsiazkiBasic/Util/Common/Index/isMagentoMedia */
export const isMagentoMedia = (src) => src?.includes('media url=');

/** @namespace SwiatKsiazkiBasic/Util/Common/Index/getMediaURL */
export const getMediaURL = (src) => {
    if (isMagentoMedia(src)) {
        const url = new URL(
            process.env.NODE_ENV === 'development' ? process.env.npm_package_proxy : window.location.origin
        );

        url.pathname = `pub/media/${src?.replace(/{{|}}|media url=|"|'/gi, '')}`;

        return url.toString();
    }

    return src;
};
