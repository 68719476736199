import Field from 'Util/Query/Field';

/** @namespace SwiatKsiazkiBasic/Query/Clickonometrics/Query */
export class ClickonometricsQuery {
    getOrderQuery(incrementId, guestCartId) {
        const field = new Field('getClickonometricsOrder')
            .addArgument('incrementId', 'String!', incrementId)
            .addFieldList(this._getOrderFields());

        if (guestCartId) {
            field.addArgument('guestCartId', 'String', guestCartId);
        }

        return field;
    }

    _getOrderFields() {
        return ['increment_id', 'order_value', this._getOrderItemsField()];
    }

    _getOrderItemsField() {
        return new Field('items').addFieldList(this._getOrderItemFields());
    }

    _getOrderItemFields() {
        return ['id', 'in_basket', 'price'];
    }
}

export default new ClickonometricsQuery();
